import Page from "components/platbricks/shared/Page";
import { useNavigate, useParams } from "react-router-dom";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import React, { useEffect, useState } from "react";
import {
  Button,
  CardContent,
  Collapse,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import { PbCard } from "components/platbricks/shared";
import Grid from "@mui/material/Unstable_Grid2";
import ShipmentSearchComponent from "./index/component/ShipmentSearchComponent";

const breadcrumbs = [{ label: "Home", to: "/" }, { label: "Verladen" }];
const ShipmentPage = () => {
  const { locationId } = useParams();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const shipmentIsReady =
    process.env.REACT_APP_TRANSPORTREADY != undefined
      ? process.env.REACT_APP_TRANSPORTREADY
      : "";

  console.log("shipmentIsReady", shipmentIsReady);

  return (
    <Page title="Verladen" breadcrumbs={breadcrumbs}>
      {shipmentIsReady == "1" ? (
        <PbCard>
          <CardContent>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid xs={12}>
                <Item>
                  <ShipmentSearchComponent />
                </Item>
              </Grid>
            </Grid>
          </CardContent>
        </PbCard>
      ) : (
        <AlertBox
          alertType={AlertBoxType.info}
          contentText="This page is still in development"
        />
      )}
    </Page>
  );
};

export default ShipmentPage;
