import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PropaneSharp,
} from "@mui/icons-material";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { generateRandomGuid, guid } from "types/guid";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { useHandlingUnitService } from "services/HandingUnitService";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { useParams } from "react-router-dom";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huCreateRequestV11Dto } from "interfaces/v11/hu/huCreateRequestV11Dto";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import {
  goodReceiptSetting,
  pickingSetting,
} from "interfaces/setting/locationSetting";

interface PickingSettingProp {
  setting: pickingSetting;
  performEdit: () => void;
}

const PickingSettingDisplayComponent: React.FC<PickingSettingProp> = (prop) => {
  const [setting, setSetting] = useState<pickingSetting>(prop.setting);
  const padding: number = 3;

  const display_Picking: string = "Parameter einstellen (Kommissionierung)";
  const display_PrintServer: string = "Druckserver";
  const display_Printer: string = "Drucker";
  const display_Edit: string = "Bearbeiten";
  const display_Save: string = "Speichern";

  return (
    <Box sx={{ width: "100%" }}>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <h4>{display_Picking}</h4>

            <div style={{ padding: padding }}>
              <TextField
                label={display_PrintServer}
                variant="outlined"
                value={setting.printServer}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }}>
              <TextField
                label={display_Printer}
                variant="outlined"
                value={setting.printer}
                disabled={true}
                fullWidth={true}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={prop.performEdit}
            >
              {display_Edit}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default React.memo(PickingSettingDisplayComponent);
