import React, { useCallback, useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  _InboundDeliveryDocumentDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { guid } from "types/guid";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { huSearchRequestV11Dto } from "interfaces/v11/hu/huSearchRequestV11Dto";
import { useHandlingUnitService } from "services/HandingUnitService";
import { useOrderService } from "services/OrderService";
import { orderSearchRequestV11Dto } from "interfaces/v11/order/orderSearchRequestV11Dto";
import useAuth from "hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import {
  OrderStatusEnum,
  orderTaskDto,
  orderV11Dto,
} from "interfaces/v11/order/orderV11Dto";
import { useBinService } from "services/BinService";
import OrderDetailComponent from "./OrderDetailComponent";
import { confirmTaskRequestV2Dto } from "interfaces/v2/order/confirmTaskRequestV2Dto";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import { Box, fontSize } from "@mui/system";
import { huSearchRequestV2Dto } from "interfaces/v2/hu/huSearchRequestV2Dto";
import { HuV2Dto } from "interfaces/v2/hu/huV2Dto";
import { borderRadius } from "polished";
import { Warning as WarningIcon } from "@mui/icons-material";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { orderTaskChangeDestinationRequestV11Dto } from "interfaces/v11/order/orderTaskChangeDestinationRequestV11Dto";
import { useSettingService } from "services/SettingService";
import { locationSetting } from "interfaces/setting/locationSetting";
import { usePrintService } from "services/PrintService";

interface Option {
  label: string;
  value: string;
}

interface Search {
  search: string;
}

interface PutAwayComponentProp {}

interface targetOrderTask {
  orderId: string;
  orderTaskId: string;
  orderNumber: string;
  orderTaskNumber: string;

  srcBinId: string;
  srcBinName: string;

  dstBinId: string;
  dstBinName: string;

  srcHUId: string;
  srcHUName: string;

  dstHUId: string;
  dstHUName: string;
}

const PutAwayComponent: React.FC<PutAwayComponentProp> = (prop) => {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const maxLength: number = 30;
  const padding: number = 1;
  const huService = useHandlingUnitService();
  const orderService = useOrderService();
  const binService = useBinService();
  const settingService = useSettingService();
  const printService = usePrintService();

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  const [taskCompleted, setTaskCompleted] = useState(false);

  var showPrinterNotConfiguredWarning: boolean = true;
  const display_printerNotConfigured: string =
    "Die Druckereinstellungen sind nicht konfiguriert. Es wird kein HU-Label gedruckt. ";
  const [grPrintServerId, setGrPrintServerId] = useState("");
  const [grPrinterId, setGrPrinterId] = useState("");

  const display_settingNotConfigured: string =
    "Good Receipt Setting is not configured properly";
  function navigateToSetting() {
    let path = "/locations/" + locationId + "/settings";
    navigate(path);
  }

  const [debounce, setDebounce] = useState<boolean>(false);
  useEffect(() => {
    //console.log("debounce " + new Date(), debounce);
  }, [debounce]);

  useEffect(() => {
    settingService
      .getLocationSetting(locationId as string)
      .then((result) => {
        if (result) {
          if (result.goodReceiptSetting?.printServerId != undefined) {
            setGrPrintServerId(result.goodReceiptSetting?.printServerId);
          }
          if (result.goodReceiptSetting?.printerId != undefined) {
            setGrPrinterId(result.goodReceiptSetting?.printerId);
          }
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      })
      .catch(() => {
        displayNotification(display_SomethingWentWrong);
      });
  }, [settingService]);

  //hu
  const display_Scan_HU: string = "Scannen (HU)";
  const display_sourceHU: string = "HU";
  const display_search: string = "Weiter";
  const display_CancelScan_HU: string = "Scan abbrechen (HU)";
  const display_DstBin: string = "Ziel Platz";
  const display_Scan_Bin: string = "Scannen (Ziel Platz)";
  const display_CancelScan_Bin: string = "Scan abbrechen (Ziel Platz)";

  const display_InvalidHUNumber: string = "Invalid HU Number";
  const display_HUPlaceHolder: string = "{ScannedHU}";
  const display_CannotFindTargetHU: string =
    "Cannot find target HU ({ScannedHU})";
  const display_MoreThanOneHuFound: string =
    "More than one HU found ({ScannedHU})";
  const display_InvalidBinNumber: string = "Invalid Bin Number";

  //bin
  const display_InvalidSrcBin: string = "Invalid Src Bin";
  const display_InvalidDstBin: string = "Invalid Destination Bin";

  const [scannedHU, setScannedHU] = useState<HuV2Dto | null>(null);
  const [showScanHU, setShowScanHU] = useState(false);
  const searchHUButtonRef = useRef<HTMLButtonElement>(null);
  const searchHUTextRef = useRef<HTMLInputElement>(null);
  const [scannedHUNumber, setScannedHUNumber] = useState("");
  function handleScanHUSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    searchSource(scannedHUNumber);
  }

  function searchSource(scannedHUNumber: string) {
    if (scannedHUNumber.length > 0) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(scannedHUNumber);

      let searchDto: huSearchRequestV2Dto;
      searchDto = {
        name: scannedHUNumber,
      };

      huService
        .searchHandlingUnit(searchDto)
        .then((result) => {
          if (result.length == 0) {
            displayNotification(
              display_CannotFindTargetHU.replace(
                display_HUPlaceHolder,
                scannedHUNumber
              )
            );
          } else if (result.length == 1) {
            setScannedHU(result[0]);
            StopScanningHU();
          } else {
            displayNotification(
              display_MoreThanOneHuFound.replace(
                display_HUPlaceHolder,
                scannedHUNumber
              )
            );
          }
        })
        .catch((response) => {
          StopScanningHU();
          if (response.Status == "401") {
            handleSignOut();
          } else {
            displayNotification(display_InvalidHUNumber);
          }
        });
    } else {
      StopScanningHU();
      displayNotification(display_InvalidHUNumber);
    }
  }
  function handleScanHUKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && searchHUButtonRef.current) {
      searchHUButtonRef.current.click();
    }
  }
  function StartScanningHU() {
    setTaskCompleted(false);
    setScannedHU(null);
    setShowScanHU(true);
    setScannedHUNumber("");
    setTargetTask(null);
    setOrderTask(null);
    setOrderTasks([]);
    if (searchHUTextRef.current) {
      searchHUTextRef.current?.focus();
    }
  }
  function StopScanningHU() {
    setShowScanHU(false);
    setScannedHUNumber("");
  }
  function handleScanHUTextBlur(event: React.FocusEvent<HTMLInputElement>) {
    StopScanningHU();
  }
  const performScanHU = () => {
    StartScanningHU();
  };

  useEffect(() => {
    StartScanningHU();
  }, [searchHUButtonRef, searchHUTextRef]);

  const handleScannedHUTextChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setScannedHUNumber(value);
  };
  function handleScannedHUTextKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (event.key === "Enter") {
      setScannedHUNumber(scannedHUNumber);
    }
  }
  const handleScannedHUTextButtonClick = useCallback(async () => {
    searchSource(scannedHUNumber);
  }, [scannedHUNumber]);

  //order tasks
  const display_NoOrderFound: string = "No Order Found";
  const display_NoOrderTaskFound: string = "No Order Task Found";
  const display_MoreThanOneOrderFoundOnHU: string =
    "Something went wrong. More than one Order found on HU {HU}. ";
  const display_MoreThanOneOpenOrderTaskFoundForOrder: string =
    "Something went wrong. More than one Open Task found for Order {Order}. ";

  //const [orders, setOrders] = useState<orderV11Dto[]>([]);
  //const [order, setOrder] = useState<orderV11Dto | null>(null);
  const [destinationChanged, setDestinationChanged] = useState<boolean>(false);
  const [newDestination, setNewDestination] = useState<string>("");

  const [orderTasks, setOrderTasks] = useState<orderTaskDto[] | null>(null);
  const [orderTask, setOrderTask] = useState<orderTaskDto | null>(null);
  const [targetTask, setTargetTask] = useState<targetOrderTask | null>(null);
  useEffect(() => {
    LoadOrderTask(false, "");
  }, [scannedHU]);

  function LoadOrderTask(
    destinationChanged: boolean,
    destinationBinId: string
  ) {
    if (scannedHU != null) {
      setDestinationChanged(destinationChanged);
      setNewDestination(destinationBinId);

      let searchDto: orderSearchRequestV11Dto = {
        page: 0,
        pageSize: 5,
        locationId: locationId!,
        orderStatuses: [OrderStatusEnum.OPEN],
        srcHuIds: [scannedHU.wmsId],
      };

      orderService
        .getOrderByHandlingUnitId(searchDto)
        .then((orders) => {
          //setOrders(orders);
          if (orders.length <= 0) {
            displayNotification(display_NoOrderFound);
          } else if (orders.length > 1) {
            displayNotification(
              display_MoreThanOneOrderFoundOnHU.replace(
                "{HU}",
                scannedHU.handlingUnit
              )
            );
          } else {
            const order = orders[0];
            const tasks = order.orderTasks;
            if (tasks == undefined || tasks.length <= 0) {
              displayNotification(display_NoOrderTaskFound);
            } else {
              //console.log(tasks);
              const openTasks = tasks.filter(
                (x) =>
                  x.orderTaskStatus == "OPEN" && x.srcHuId == scannedHU.wmsId
              );

              setOrderTasks(openTasks);

              if (openTasks == undefined || openTasks.length <= 0) {
                displayNotification(display_NoOrderTaskFound);
              } else {
                const orderTask = openTasks[0];
                setOrderTask(orderTask);
                //console.log(orderTask);

                binService
                  .getBinByIdV11(orderTask.srcBinId)
                  .then((srcBin) => {
                    binService
                      .getBinByIdV11(orderTask.dstBinId)
                      .then((dstBin) => {
                        const targetTask = CovertResultToTargetTask(
                          order.orderNumber,
                          orderTask,
                          srcBin.bin,
                          dstBin.bin
                        );
                        setTargetTask(targetTask);
                        //displayNotification("Found");
                      })
                      .catch(() => {
                        displayNotification(display_InvalidDstBin);
                      });
                  })
                  .catch(() => {
                    displayNotification(display_InvalidSrcBin);
                  });
              }
            }
          }
        })
        .catch((response) => {
          if (response.Status == "401") {
            handleSignOut();
          } else {
            console.log(response);
            displayNotification(display_NoOrderFound);
          }
        });
    } else {
      Reset();
    }
  }

  function CovertResultToTargetTask(
    orderNo: string,
    orderTask: orderTaskDto,
    srcBinName: string,
    dstBinName: string
  ): targetOrderTask {
    let result: targetOrderTask = {
      orderId: orderTask.orderId,
      orderTaskId: orderTask.orderTaskId,
      orderNumber: orderNo,
      orderTaskNumber: orderTask.orderTaskNumber,
      srcBinId: orderTask.srcBinId,
      srcBinName: srcBinName,
      dstBinId: orderTask.dstBinId,
      dstBinName: dstBinName,
      srcHUId: orderTask.srcHuId,
      srcHUName: orderTask.srcHuName,
      dstHUId: orderTask.dstHuId,
      dstHUName: orderTask.dstHuName,
    };
    return result;
  }

  //scan bin
  const display_BinNotMatch: string =
    "Scanned Bin ({ScannedBin}) not match with current task";
  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";

  const [scannedBin, setScannedBin] = useState<string | null>(null);
  const [showScanBin, setShowScanBin] = useState(false);
  const searchBinButtonRef = useRef<HTMLButtonElement>(null);
  const searchBinTextRef = useRef<HTMLInputElement>(null);
  const [scannedBinNumber, setScannedBinNumber] = useState("");
  function handleScanBinSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (scannedBinNumber.length > 0) {
      if (scannedBinNumber != targetTask?.dstBinName) {
        displayNotification(
          display_BinNotMatch.replace("{ScannedBin}", scannedBinNumber)
        );
      } else {
        PerformMovement();
      }
    } else {
      StopScanningBin();
      displayNotification(display_InvalidBinNumber);
    }
  }
  function handleScanBinKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && searchBinButtonRef.current) {
      searchBinButtonRef.current.click();
    }
  }
  function StartScanningBin() {
    setScannedBin(null);
    setShowScanBin(true);
    setScannedBinNumber("");
    setNewBinId(null);
    if (searchBinTextRef.current) {
      searchBinTextRef.current?.focus();
    }
  }
  function StopScanningBin() {
    setShowScanBin(false);
    setScannedBinNumber("");
  }
  function handleScanBinTextBlur(event: React.FocusEvent<HTMLInputElement>) {
    StopScanningBin();
  }
  const performScanBin = () => {
    StartScanningBin();
  };

  useEffect(() => {
    if (taskCompleted == true) {
      Reset();
    }
  }, [taskCompleted]);

  function Reset() {
    StartScanningHU();
  }

  //confirm movement

  const display_confirmMovement: string = "Bestätige Platz";
  function PerformMovement() {
    if (debounce) {
      return;
    }

    setDebounce(true);

    if (targetTask == null) {
      displayNotification(display_SomethingWentWrong);
      setDebounce(false);
      return;
    }

    let request: confirmTaskRequestV2Dto = {
      id: targetTask.orderTaskId,
    };
    orderService
      .confirmOrderTask(request)
      .then((result) => {
        console.log(result);
        if (result == undefined) {
          displayNotification(display_SomethingWentWrong);
          setDebounce(false);
        } else {
          if (result.status != undefined && result.status == "Success") {
            displayNotification("Completed");
            setTaskCompleted(true);

            if (destinationChanged && newDestination.length > 0) {
              console.log("destination changed. call hu label API. ");
              const newBinId: string = newDestination;
              const huId: string = targetTask.srcHUId;
              const printServerId: string = grPrintServerId;
              const printerId: string = grPrinterId;
              print(huId, printServerId, printerId);
            }
          } else {
            let message: string = display_SomethingWentWrong;
            if (result.messages != undefined && result.messages.length > 0) {
              message = result.messages[0];
            }
            displayNotification(message);
          }
          setDebounce(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.errorMessage && error.errorMessage.length > 0) {
          let display: string = error.errorMessage;
          if (error.message && error.message.length > 0) {
            display += " " + error.message;
          }
          displayNotification(display);
        } else {
          displayNotification(display_SomethingWentWrong);
        }
        setDebounce(false);
      });
  }

  function print(huId: string, printServerId: string, printerId: string) {
    console.log(
      "huId: " +
        huId +
        ", printServerId: " +
        printServerId +
        ", printerId: " +
        printerId
    );
    if (huId.length > 0 && printServerId.length > 0 && printerId.length > 0) {
      printService
        .printHU({
          handlingUnitId: huId,
          printServerId: printServerId,
          printerId: printerId,
        })
        .then((result) => {
          let msg: string = "";
          msg = JSON.stringify(result);

          console.log("Print request sent. " + result);
        })
        .catch((result) => {
          let errorMsg: string = "";
          if (result.errorMessage != undefined) {
            errorMsg = result.errorMessage;
          } else {
            errorMsg = JSON.stringify(result);
          }

          console.log("Failed to print. " + errorMsg);
        });
    }
  }

  const display_confirm: string = "Bestätigen";
  const display_cancel: string = "Abbrechen";
  const display_NewDestination: string = "Neuer Ziel-Platz";
  const display_ChangeDestinationInstruction: string =
    "Verwenden Sie diese Option nur, wenn der Ziel Platz geändert werden soll (oben angezeigter Platz kann nicht verwendet werden)";
  const display_ChangeDestination: string = "Ziel-Platz ändern";
  const display_somethingWentWrongSearchNewBin: string =
    "Something went wrong when search for Bin";
  const handleAttempToChangeDestinationClick = useCallback(async () => {
    if (targetTask == null) {
      displayNotification(display_NoOrderTaskFound);
      return;
    }
    setNewBinId(null);
    setShowChangeDestinationDialog(true);
  }, [targetTask]);
  const [showChangeDestinationDialog, setShowChangeDestinationDialog] =
    useState(false);
  const handleChangeDestinationClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason && reason == "backdropClick") return;
    setShowChangeDestinationDialog(false);
  };

  const display_ChangeDestinationSucceed: string =
    "Die Änderung des Ziel-Platzes war erfolgreich.";
  const display_noOptionMsg: string = "keine Optionen vorhanden";
  const display_PleaseSelectBin: string = "Please select Bin";
  const [newBinOptions, setNewBinOptions] = useState<Option[]>([]);
  const [selectedNewBinOptions, setSelectedNewBinOptions] =
    useState<Option | null>(null);
  const [newBinId, setNewBinId] = useState<string | null>(null);
  const [searchNewBinQuery, setSearchBinQuery] = useState<Search>({
    search: "",
  });
  const handleNewBinChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      setNewBinId(option.value);
      //setHUType(option.label);
      setSelectedNewBinOptions(option);
    } else {
      setNewBinId(null);
      //setHUType("");
      setSelectedNewBinOptions(null);
    }
  };
  const handleNewBinInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchBinQuery({ search: event.target.value });
  };
  function convertNewBinsearchResultToSelectOptions(
    data: binSearchResponseV11Dto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.wmsId,
      label: item.bin,
    }));
  }

  useEffect(() => {
    if (searchNewBinQuery.search.length >= 3) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(searchNewBinQuery.search);

      let searchDto: binSearchRequestV11Dto;
      searchDto = {
        page: 0,
        pageSize: 20,
        locationId: locationId!,
        BinNames: [],
        binNameContains: [],
      };
      searchDto.binNameContains = searchTexts;

      huService
        .getBin(searchDto)
        .then((result) => {
          setNewBinOptions(convertNewBinsearchResultToSelectOptions(result));
        })
        .catch(() => console.log(display_somethingWentWrongSearchNewBin));
    }
  }, [searchNewBinQuery.search]);

  const handleChangeDestination = () => {
    if (debounce) {
      return;
    }
    setDebounce(true);

    if (targetTask == null) {
      displayNotification(display_NoOrderTaskFound);
      setDebounce(false);
      return;
    }

    if (newBinId == null) {
      displayNotification(display_PleaseSelectBin);
      setDebounce(false);
      return;
    }

    const changeDestinationRequest: orderTaskChangeDestinationRequestV11Dto = {
      orderTaskId: targetTask.orderTaskId,
      dstBinId: newBinId,
      dstHuId: "",
    };

    orderService
      .changeOrderTaskDestination(changeDestinationRequest)
      .then((response) => {
        console.log(response);
        if (response.status != undefined && response.status == "Success") {
          displayNotification(display_ChangeDestinationSucceed);
          setShowChangeDestinationDialog(false);
          setDebounce(false);
          setTargetTask(null);
          LoadOrderTask(true, newBinId);
        } else if (
          response.messages != undefined &&
          response.messages.length > 0
        ) {
          displayNotification(response.messages.join(" "));
          setDebounce(false);
        } else {
          displayNotification(display_SomethingWentWrong);
          setDebounce(false);
        }
      })
      .catch((err) => {
        console.log(err);
        displayNotification(display_SomethingWentWrong);
        setDebounce(false);
      });
  };

  useEffect(() => {}, [targetTask]);

  return (
    <div>
      {showPrinterNotConfiguredWarning &&
      (grPrintServerId.length == 0 || grPrinterId.length == 0) ? (
        <div>
          <AlertBox
            alertType={AlertBoxType.info}
            contentText={display_printerNotConfigured}
          />
        </div>
      ) : (
        <div></div>
      )}

      <i hidden={true}>{destinationChanged ? "changed" : "no change"}</i>

      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} hidden={!taskCompleted}>
          <AlertBox
            alertType={AlertBoxType.success}
            contentText="This task is completed"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{ padding: padding }}>
            <TextField
              id="sourceBin"
              name="sourceBin"
              label={display_sourceHU}
              variant="outlined"
              value={scannedHUNumber}
              fullWidth={true}
              onChange={handleScannedHUTextChanged}
              inputProps={{ maxLength }}
              autoComplete="off"
              onKeyDown={handleScannedHUTextKeyDown}
            />
          </div>
          <div style={{ padding: padding }}>
            <Button
              variant="contained"
              fullWidth={true}
              disabled={debounce}
              onClick={async () => {
                await handleScannedHUTextButtonClick();
              }}
            >
              <div>
                <br />
                <br />
              </div>
              {display_search}
            </Button>
          </div>
          <div style={{ padding: padding }}>
            <div hidden={showScanHU}>
              <Button
                onClick={performScanHU}
                variant="contained"
                fullWidth={true}
              >
                <div>
                  <br />
                  <br />
                </div>
                {display_Scan_HU}
              </Button>
            </div>
            <div hidden={!showScanHU}>
              <Button
                onClick={() => {
                  setShowScanHU(false);
                }}
                disabled={!showScanHU || debounce}
                variant="contained"
                fullWidth={true}
              >
                <div>
                  <br />
                  <br />
                </div>
                {display_CancelScan_HU}
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} hidden={targetTask == null}>
          {targetTask != null ? (
            <OrderDetailComponent detail={targetTask} />
          ) : (
            <div></div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} hidden={targetTask == null}>
          <div>
            <div hidden={showScanBin} style={{ padding: "1px" }}>
              <Button
                onClick={performScanBin}
                variant="contained"
                fullWidth={true}
                disabled={targetTask == null || taskCompleted || debounce}
              >
                <div>
                  <br />
                  <br />
                </div>
                {display_Scan_Bin}
              </Button>
            </div>
            <div hidden={!showScanBin} style={{ padding: "1px" }}>
              <Button
                onClick={() => {
                  setShowScanBin(false);
                }}
                disabled={!showScanBin || debounce}
                variant="contained"
                fullWidth={true}
              >
                <div>
                  <br />
                  <br />
                </div>
                {display_CancelScan_Bin}
              </Button>
            </div>
            <div style={{ padding: "1px" }}>
              <Button
                onClick={() => {
                  PerformMovement();
                }}
                disabled={targetTask == null || debounce}
                variant="contained"
                fullWidth={true}
              >
                <div>
                  <br />
                  <br />
                </div>
                {display_confirmMovement}
              </Button>
            </div>
          </div>
          <br />
          <br />
          <div
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <Grid item xs={12} sm={12}>
              <AlertBox
                alertType={AlertBoxType.info}
                contentText={display_ChangeDestinationInstruction}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                fullWidth={true}
                startIcon={<WarningIcon />}
                disabled={debounce}
                onClick={async () => {
                  await handleAttempToChangeDestinationClick();
                }}
              >
                <div>
                  <br />
                </div>
                {display_ChangeDestination}
              </Button>
            </Grid>
          </div>

          <Dialog
            onClose={handleChangeDestinationClose}
            aria-labelledby="simple-dialog-title"
            open={showChangeDestinationDialog}
            hideBackdrop={false}
            PaperProps={{
              style: {
                minWidth: "50%",
              },
            }}
          >
            <DialogTitle>{display_NewDestination}</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>{contentText}</DialogContentText> */}
              <Box sx={{ mt: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      noOptionsText={display_noOptionMsg}
                      value={selectedNewBinOptions}
                      onChange={handleNewBinChange}
                      options={newBinOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={display_DstBin}
                          variant="outlined"
                          onChange={handleNewBinInputChange}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowChangeDestinationDialog(false);
                }}
              >
                {display_cancel}
              </Button>
              <Button onClick={handleChangeDestination}>
                {display_confirm}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>

      <div style={{ overflow: "hidden", position: "relative" }}>
        <div
          style={{
            border: "1px solid black",
            position: "absolute",
            right: "-500px",
          }}
        >
          <i>This technically should be invisible to user</i>
          <form onSubmit={handleScanHUSubmit}>
            <input
              type="text"
              value={scannedHUNumber}
              onChange={(event) => setScannedHUNumber(event.target.value)}
              onKeyDown={handleScanHUKeyDown}
              onBlur={handleScanHUTextBlur}
              ref={searchHUTextRef}
            />
            <button type="button" ref={searchHUButtonRef}>
              Submit
            </button>
          </form>
        </div>
      </div>

      <div style={{ overflow: "hidden", position: "relative" }}>
        <div
          style={{
            border: "1px solid black",
            position: "absolute",
            right: "-500px",
          }}
        >
          <i>This technically should be invisible to user</i>
          <form onSubmit={handleScanBinSubmit}>
            <input
              type="text"
              value={scannedBinNumber}
              onChange={(event) => setScannedBinNumber(event.target.value)}
              onKeyDown={handleScanBinKeyDown}
              onBlur={handleScanBinTextBlur}
              ref={searchBinTextRef}
            />
            <button type="button" ref={searchBinButtonRef}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PutAwayComponent);
