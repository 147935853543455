import { ContentCopy, QrCodeScanner } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import styled from "@emotion/styled";
import useAppDispatch from "hooks/useAppDispatch";

const CopyWrapper = styled.span`
  display: inline-flex;
  &:hover .showOnHover {
    visibility: visible;
  }
`;
const ShowOnHover = styled.span`
  visibility: hidden;
  display: flex;
  align-items: center;
  & svg {
    font-size: 14px;
    line-height: 14px;
    margin-left: 0.25rem;
    cursor: pointer;
  }
`;

interface EasyCopyProps {
  children: React.ReactNode;
  clipboard?: string;
  qrCode?: string;
}

const EasyCopy: React.FC<EasyCopyProps> = (props) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const copyToClipboard = () => {
    props.clipboard && navigator.clipboard.writeText(props.clipboard);
    enqueueSnackbar("Copied value to clipboard");
  };
  return (
    <CopyWrapper>
      {props.children}
      {props.clipboard && (
        <ShowOnHover className="showOnHover" onClick={copyToClipboard}>
          <Tooltip title="Copy" placement="top">
            <ContentCopy></ContentCopy>
          </Tooltip>
        </ShowOnHover>
      )}
      {props.qrCode && (
        <ShowOnHover className="showOnHover">
          <Tooltip title="QR-Code" placement="top">
            <QrCodeScanner></QrCodeScanner>
          </Tooltip>
        </ShowOnHover>
      )}
    </CopyWrapper>
  );
};

export default EasyCopy;
