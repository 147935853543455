import {
  Box,
  Typography,
  Link,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  styled,
  Button,
  Backdrop,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { Link as NavLink } from "react-router-dom";
import ActionsButton, { ActionHelper } from "./ActionsButton";

interface PageHeaderProps {
  children?: React.ReactNode;
  pagename?: string;
  title: string;
  subtitle?: string;
  breadcrumbs: Breadcumb[];
  actions?: ActionHelper[];
  hasSingleActionButton?: boolean;
  showBackdrop?: boolean;
  showLoading?: boolean;
}
interface Breadcumb {
  label: string;
  to?: string;
}

const PageBackdrop = styled(Backdrop)``;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Page: React.FC<PageHeaderProps> = (props) => {
  const showLoading = props.showLoading;
  return (
    <>
      <PageBackdrop
        sx={{
          color: "#fff",
          zIndex: (theme: any) => theme.zIndex.drawer + 1,
        }}
        open={!!props.showBackdrop}
      >
        <CircularProgress color="inherit" />
      </PageBackdrop>
      <Helmet title={props.pagename || props.title + (props.subtitle || "")} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div>
          <Typography variant="h3" gutterBottom display="inline" mr={2}>
            {showLoading ? (
              <Skeleton
                variant="text"
                style={{ display: "inline-flex" }}
                width={200}
              />
            ) : (
              props.title
            )}
          </Typography>
          <Typography
            variant="h4"
            fontWeight="light"
            gutterBottom
            display="inline"
          >
            {showLoading ? (
              <Skeleton
                variant="text"
                style={{ display: "inline-flex" }}
                width={150}
              />
            ) : (
              props.subtitle
            )}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            {showLoading ? (
              <Skeleton variant="text" width={300} />
            ) : (
              props.breadcrumbs.map((item, index) => {
                return item.to ? (
                  <Link component={NavLink} to={item.to} key={index}>
                    {item.label}
                  </Link>
                ) : (
                  <Typography key={index}>{item.label}</Typography>
                );
              })
            )}
          </Breadcrumbs>
        </div>
        {props.actions &&
          props.hasSingleActionButton &&
          props.actions[0] &&
          props.actions[0].to && (
            <Button
              disableElevation
              variant="contained"
              startIcon={props.actions[0].icon}
              component={NavLink}
              to="new"
            >
              {props.actions[0].title}
            </Button>
          )}
        {props.actions &&
          props.hasSingleActionButton &&
          props.actions[0] &&
          !props.actions[0].to && (
            <Button
              disableElevation
              variant="contained"
              onClick={props.actions[0].onclick}
              startIcon={props.actions[0].icon}
            >
              {props.actions[0].title}
            </Button>
          )}
        {props.actions && props.actions.length > 0 && !props.hasSingleActionButton && (
          <ActionsButton actions={props.actions} />
        )}
      </Box>
      <Divider my={6} />
      {showLoading ? (
        <Skeleton variant="rectangular" height={200} />
      ) : (
        props.children
      )}
    </>
  );
};

export default Page;
