import "./wdyr";
import "react-app-polyfill/stable";
import { SnackbarUtilsConfigurator } from "./utils/SnackBarUtils";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "chart.js/auto";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

// Note: Remove the following line if you want to disable the API mocks.
//import "./mocks";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SnackbarProvider } from "notistack";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      autoHideDuration={2000}
    >
      <>
        <SnackbarUtilsConfigurator />
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </>
    </SnackbarProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
