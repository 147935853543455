import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@mui/material";
import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useAzureAppInsightService } from "services/AzureAppInsightService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin
);

export const AzureAppInsightAvailabilityYearToDateChart = () => {
  const AzureAppInsightService = useAzureAppInsightService();

  const [data, setData] = useState<ChartData<"pie"> | undefined>({
    datasets: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isAzureAppInsightConfigured, setIsAzureAppInsightConfigured] =
    useState<boolean>(false);

  const fetchChart = useCallback(async () => {
    try {
      setIsLoading(true);
      const data =
        await AzureAppInsightService.getAzureAppInsightYearToDateAvailabilityChart();

      const availability = data.length > 0 ? data[0].averagePercentage : 100;
      setData({
        labels: ["Availability", "Downtime"],
        datasets: [
          {
            label: "Availability",
            data: [availability, 100 - availability],
            backgroundColor: colorize(),
            borderColor: "transparent",
          },
        ],
      });

      setIsAzureAppInsightConfigured(true);
    } catch (error: any) {
      if (error?.errorCode === "NoAppInsightDefined")
        setIsAzureAppInsightConfigured(false);

      setData(undefined);
    } finally {
      setIsLoading(false);
    }
  }, [AzureAppInsightService]);

  const colorize = () => {
    return (ctx: any) => {
      if (ctx.dataIndex == 0) {
        const v = ctx.parsed;
        return v > 99.9 ? "#AEF1AF" : "#F5B7B1";
      }
      return "transparent";
    };
  };

  const options: ChartOptions<"pie"> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        color: "black",
        formatter: function (value) {
          return value.toLocaleString() + "%";
        },
        display: function (context) {
          return context.dataIndex == 0;
        },
        font: {
          size: 25,
          weight: "bold",
        },
      },
    },
  };

  useEffect(() => {
    async function initialFetchData() {
      await fetchChart();
    }
    initialFetchData();
  }, [fetchChart]);

  return (
    <Fragment>
      {isAzureAppInsightConfigured && (
        <Card sx={{ height: "100%" }}>
          <Box sx={{ height: "5px" }}>{isLoading && <LinearProgress />}</Box>
          <CardHeader title="Availability - Year to date" />
          <CardContent>
            {data ? (
              <Pie options={options} data={data} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "50px",
                }}
              >
                Some problems to load data
              </Box>
            )}
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
};
