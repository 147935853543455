import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import WmsTreeViewWithCheckbox from "components/platbricks/shared/WmsTreeViewWithCheckbox";
import WmsTreeDisplay from "components/platbricks/shared/WmsTreeDisplay";
import { WmsTreeNodeDto } from "interfaces/v1/wmsTree/WmsTreeNodeDto";
import { getWmsTreeRequestDto } from "interfaces/v1/wmsTree/getWmsTreeRequestDto";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { wmsMaterialSearchV11Dto } from "interfaces/v11/materialDetail/wmsMaterialSearchV11Dto";
import { huSearchRequestV2Dto } from "interfaces/v2/hu/huSearchRequestV2Dto";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { useHandlingUnitService } from "services/HandingUnitService";
import { useWmsService } from "services/WmsService";
import { searchStockV11ResquestDto } from "interfaces/v11/stock/searchStockV11RequestDto";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InventoryIcon from "@mui/icons-material/Inventory";
import { WMSMaterialV11Dto } from "interfaces/v11/materialDetail/wmsMaterialDetailsV11Dto";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huSearchResponseV11Dto } from "interfaces/v11/hu/huSearchResponseV11Dto";
import { huSearchRequestV11Dto } from "interfaces/v11/hu/huSearchRequestV11Dto";
import Scanner from "components/platbricks/shared/Scanner";
import { ConfirmationDialog } from "components/platbricks/shared/ConfirmationDialog";
import { createInvDocRequest } from "interfaces/v1/inventoryDocument/createInvDocRequest";
import { InventoryDocumentMethodEnum, InventoryDocumentRecountingDifferenceCheckEnum } from "interfaces/enums/InventoryDocumentEnums";
import { activateInvDocRequest } from "interfaces/v1/inventoryDocument/activateInvDocRequest";
import WmsAutoComplete from "components/platbricks/shared/WmsAutoComplete";
import { WmsAutoCompleteOption, WmsAutoCompleteType } from "interfaces/general/util/AutoCompleteOption";

interface material {
    materialNumber: string;
    quantity: number;
    batch: string;
    owner: string;
}


const StockCountingIndexComponent: React.FC = () => {
    const { locationId } = useParams();
    const maxLength: number = 30;
    const padding: number = 3;

    const huService = useHandlingUnitService();
    const goodReceiptService = useGoodReceiptService();
    const wmsService = useWmsService();
    const navigate = useNavigate();

    //notificaiton dialog
    const [notificationText, setNotificationText] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    function displayNotification(message: string) {
        setNotificationText(message);
        setShowNotification(true);
    }

    const [debounce, setDebounce] = useState<boolean>(false);
    useEffect(() => {
        //console.log("debounce " + new Date(), debounce);
    }, [debounce]);


    const display_search: string = "Weiter";
    const display_sourceHU: string = "Lagerplatz oder HU";
    const display_cancel: string = "Abbrechen";
    const [ScannedSource, setScannedSource] = useState("");
    const [wmsTree, setWmsTree] = useState<WmsTreeNodeDto[]>([]);
    const searchOptionByBin: string = "Lagerplatz";
    const searchOptionByHU: string = "HU";
    const searchOptionByMaterial: string = "Material";
    const [searchMode, setSearchMode] = useState("");
    function updateSearchmode(newSearchMode: string) {
        setSearchMode(newSearchMode);
    }

    function reset() {
        setConfirmBinId("");
        setConfirmBinName("");
        setWmsTree([]);
        setSelectedWmsAutoCompleteOption(null);
        updateSearchmode("");
        setScannedSource("");

    }

    function getSearchModeDisplay() {
        if (searchMode === searchOptionByBin) { return "Lagerplatz"; }
        else if (searchMode === searchOptionByHU) { return "HU"; }
        else if (searchMode === searchOptionByMaterial) { return "Material"; }
        else { return "unknown"; }
    }

    function getSearchModeType(): WmsAutoCompleteType {
        if (searchMode === searchOptionByBin) { return WmsAutoCompleteType.BIN; }
        else if (searchMode === searchOptionByHU) { return WmsAutoCompleteType.HU; }
        else if (searchMode === searchOptionByMaterial) { return WmsAutoCompleteType.MATERIAL; }
        else { return WmsAutoCompleteType.UNKNOWN; }
    }

    //Scan start -------------------------------------------------------------------------
    const [scanningSource, setScanningSource] = useState<boolean>(false);
    const display_ScanSource: string = "Scannen";
    const display_ScanningSource: string = "Scan abbrechen";
    function resetScanSource() {
        setScannedSource("");
    }
    function scanSourceStarted() {
        resetScanSource();
        setScanningSource(true);
    }
    function scanSourceCompleted(scannedValue: string) {
        setScannedSource(scannedValue);
        if (scannedValue.length > 0) {
            performSearch(scannedValue);
        }
    }
    function scanSourceCancelled() {
        setScanningSource(false);
    }
    useEffect(() => {
        if (searchMode.length > 0) {
            setScanningSource(true);
        }
    }, [searchMode]);
    useEffect(() => {
        //console.log("parent scanningSource " + new Date(), scanningSource);
    }, [scanningSource]);

    const handleScannedSourceTextChanged = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target;
        setScannedSource(value);
    };
    function handleScannedSourceTextKeyDown(
        event: React.KeyboardEvent<HTMLInputElement>
    ) {
        if (event.key === "Enter") {
            if (ScannedSource.length > 0) {
                performSearch(ScannedSource);
            }
        }
    }
    const handleScannedSourceButtonClick = useCallback(async () => {
        if (ScannedSource.length > 0) {
            performSearch(ScannedSource);
        }

    }, [ScannedSource]);

    function performSearch(searchValue: string) {
        var searchTypeDisplay = getSearchModeDisplay();
        if (debounce) {
            return;
        }
        setDebounce(true);
        if (searchValue.length <= 0) {
            setDebounce(false);
            displayNotification("Please enter or scan a " + searchTypeDisplay + "value");
            return;
        }

        if (searchMode === searchOptionByBin || searchMode === searchOptionByHU) {
            getSearchSourceRootIdAndPerformSearch(searchValue);
        }
        else if (searchMode === searchOptionByMaterial) {
            searchByMaterial(searchValue);
        }
        else {
            setDebounce(false);
            displayNotification("Search type unknown");
        }
    }

    function getSearchSourceRootIdAndPerformSearch(searchValue: string) {
        let binIds: string[];
        binIds = [];

        var binId = "";
        var binName = "";
        var showConfirmationToCreateNewInventoryIfNotExisted = true;

        if (searchMode === searchOptionByBin) {
            //find BinId to be use
            let searchTexts: string[];
            searchTexts = [];
            searchTexts.push(searchValue);

            let searchDto: binSearchRequestV11Dto = {
                locationId: locationId! as string,
                BinNames: searchTexts,
                binNameContains: [],
            };

            huService
                .getBin(searchDto)
                .then((binResult) => {
                    if (binResult.length == 0) {
                        throw new Error(searchValue + " is not a valid Bin. ");
                    } else if (binResult.length == 1) {
                        binId = binResult[0].wmsId;
                        binName = binResult[0].bin;
                        searchInventoryDocumentByBinIdAndGoToDocument(binId, binName, showConfirmationToCreateNewInventoryIfNotExisted);
                    } else {
                        throw new Error(searchValue + " found More than 1 Bin. ");
                    }
                })
                .catch((err) => {
                    setDebounce(false);
                    displayNotification("Something went wrong when search for Bin. " + err);
                });

        }
        else if (searchMode === searchOptionByHU) {
            //find BinId to be use via HUId
            let searchDto: huSearchRequestV2Dto;
            searchDto = {
                name: searchValue,
            };

            huService
                .searchHandlingUnit(searchDto)
                .then((result) => {
                    if (result.length == 0) {
                        throw new Error(searchValue + " is not a valid HU. ");
                    } else if (result.length == 1) {
                        binId = result[0].rootElement;
                        binName = "";
                        return huService.getBinById(binId);
                    } else {
                        throw new Error(searchValue + " found More than 1 HU. ");
                    }
                }).then((binResult) => {
                    console.log("binResult", binResult);
                    if (binResult.wmsId.length <= 0) {
                        throw new Error("Unable to find valid Bin for " + searchValue);
                    }
                    else {
                        binId = binResult.wmsId;
                        binName = binResult.bin;
                        searchInventoryDocumentByBinIdAndGoToDocument(binId, binName, showConfirmationToCreateNewInventoryIfNotExisted);
                    }
                })
                .catch((err) => {
                    setDebounce(false);
                    displayNotification("Something went wrong when search HU. " + err);
                });
        }
    }

    function searchByMaterial(searchSource: string) {
        let searchDto: wmsMaterialSearchV11Dto;
        searchDto = {
            materialAndNumberContains: [],
            materials: [],
            page: 0,
            pageSize: 5,
        };
        searchDto.materials?.push(searchSource);

        goodReceiptService.searchWmsMaterials(searchDto)
            .then((result) => {
                setDebounce(false);
                if (result.length == 0) {
                    setDebounce(false);
                    displayNotification(searchSource + " is not a valid Material. ");
                } else if (result.length == 1) {
                    searchStockByMaterialId(result[0].materialId, result[0].material + " - " + result[0].name);
                } else {
                    setDebounce(false);
                    displayNotification(searchSource + " found More than 1 Material. ");
                }
            })
            .catch(() => {
                setDebounce(false);
                displayNotification("Something went wrong when search Material");
            });
    }

    function searchStockByMaterialId(materialId: string, materialDisplay: string) {
        const searchStockRequest: searchStockV11ResquestDto = {
            locationId: locationId!,
            materialId: materialId,
        };

        wmsService
            .findStock(searchStockRequest)
            .then((x) => {
                //("search stock", x);
                if (x && x.length > 0) {
                    // setDebounce(false);
                    // displayNotification("Stock found with " + material + "-" + materialName);
                    let searchBinIds: string[];
                    searchBinIds = [];
                    x.forEach((y) => {
                        var existed = searchBinIds.filter((z) => z == y.rootElement);
                        if (existed && existed.length > 0) {

                        }
                        else {
                            searchBinIds.push(y.rootElement)
                        }
                    });
                    getWmsTreeWithBinId(searchBinIds);
                } else {
                    setDebounce(false);
                    displayNotification("No Stock found with " + materialDisplay);
                }
            })
            .catch((e) => {
                setDebounce(false);
                console.log("Find stock error", e);
                displayNotification("Something went wrong when search stock with material. ");
            });
    }


    function getWmsTreeWithBinId(binIds: string[]) {
        const request: getWmsTreeRequestDto = {
            locationId: locationId as string,
            BinIds: binIds,
        };

        wmsService
            .getWMSTree(request)
            .then((result) => {
                setDebounce(false);
                //console.log(result);
                setWmsTree(result);
            })
            .catch((error) => {
                setDebounce(false);
                displayNotification("Could not get items in the target Bin");
            });
    }




    //Auto Complete Start-----------------------------------------------------------------------

    const [selectedWmsAutoCompleteOption, setSelectedWmsAutoCompleteOption] = useState<WmsAutoCompleteOption | null>(null);
    function autoCompleteOptionSelected(selectedAutoCompleteOption: WmsAutoCompleteOption | null) {
        setSelectedWmsAutoCompleteOption(selectedAutoCompleteOption);
    }
    useEffect(() => {

    }, [selectedWmsAutoCompleteOption]);
    const handleSubmitAutoCompleteButtonClick = useCallback(async () => {
        if (debounce) {
            return;
        }
        setDebounce(true);
        if (selectedWmsAutoCompleteOption != null) {
            setScannedSource(selectedWmsAutoCompleteOption.label);
            if (searchMode == searchOptionByBin) {
                var binId = selectedWmsAutoCompleteOption.value;
                var binName = selectedWmsAutoCompleteOption.label;
                var showConfirmationToCreateNewInventoryIfNotExisted = true;
                searchInventoryDocumentByBinIdAndGoToDocument(binId, binName, showConfirmationToCreateNewInventoryIfNotExisted);
            }
            else if (searchMode == searchOptionByHU) {

                var binId = selectedWmsAutoCompleteOption.additionalAttribute1!;
                var binName = "";
                var showConfirmationToCreateNewInventoryIfNotExisted = true;

                huService.getBinById(binId).then((binResult) => {
                    console.log("getBinById result", binResult);
                    binName = binResult.bin;
                    searchInventoryDocumentByBinIdAndGoToDocument(binId, binName, showConfirmationToCreateNewInventoryIfNotExisted);
                })
                    .catch((err) => {
                        setDebounce(false);
                        displayNotification("Faile to get Bin detail for " + selectedWmsAutoCompleteOption.label + ". " + err);
                    });
            }
            else if (searchMode == searchOptionByMaterial) {
                searchStockByMaterialId(selectedWmsAutoCompleteOption.value, selectedWmsAutoCompleteOption.label);
            }
            else {
                setDebounce(false);
                displayNotification("search mode Not supported");
            }
        }
    }, [selectedWmsAutoCompleteOption]);


    function searchInventoryDocumentByBinIdAndGoToDocument(binId: string, binName: string, showConfirmation: boolean) {
        //console.log("searchInventoryDocumentByBinIdAndSetInventoryDocument", showConfirmation);
        wmsService.getInventoryDocumentByBinId(binId)
            .then((getInventoryDocumentByBinIdResult) => {
                //console.log("getInventoryDocumentByBinIdResult", getInventoryDocumentByBinIdResult);

                if (getInventoryDocumentByBinIdResult.length <= 0) {
                    setDebounce(false);
                    setConfirmBinId(binId);
                    setConfirmBinName(binName);

                    if (showConfirmation) {
                        var textToDisplay: string = "Inventurbeleg für Lagerplatz " + binName + " existiert nicht. Inventurbeleg anlegen?";
                        //setShowConfirmationText("Inventory document not existed for " + binName + ". Create Inventory Document? ");
                        setShowConfirmationText(textToDisplay);
                        setShowConfirmation(true);
                    }

                }
                else {
                    //getAndSetInventoryDocumentByDocId(getInventoryDocumentByBinIdResult, binId, binName);
                    navigateToTargetInventoryDocument(getInventoryDocumentByBinIdResult);
                }

            })
            .catch((err) => {
                console.log("searchInventoryDocumentByBinIdAndSetInventoryDocument err", err);
                displayNotification("handleSubmitAutoCompleteButtonClick error. " + err);
            });

    }




    //confirmation dialog
    const [confirmBinId, setConfirmBinId] = useState<string>("");
    const [confirmBinName, setConfirmBinName] = useState<string>("");
    const [showConfirmationText, setShowConfirmationText] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationForDiscard, setShowConfirmationForDiscard] =
        useState(false);

    const handleConfirmCreateInventoryDocumentClick = async () => {
        setShowConfirmation(false);
        CreateAndActivateAndGetInventoryDocument(confirmBinId, confirmBinName);
    };

    function CreateAndActivateAndGetInventoryDocument(binId: string, binName: string) {
        if (debounce) {
            return;
        }
        setDebounce(true);

        var createnInvDocRequest: createInvDocRequest = {
            year: 0,
            storageBinId: binId,
            method: InventoryDocumentMethodEnum.AD_HOC_PHYSICAL_INV_STORAGE_BIN,
            recountingDifferenceCheck: InventoryDocumentRecountingDifferenceCheckEnum.NO_RECOUNTING
        }

        var invDocId: string = "";

        wmsService.createInventoryDocument(createnInvDocRequest).then((createInventoryDocumentResult) => {
            console.log("createInventoryDocument result", createInventoryDocumentResult);
            if (createInventoryDocumentResult.status == "success") {
                invDocId = createInventoryDocumentResult.id;
                var request: activateInvDocRequest = {
                    invDocId: invDocId
                }
                return wmsService.activateInventoryDocument(request);
            }
            else {
                throw new Error("createInventoryDocument failed");
            }
        }).then((activateInventoryDocumentResult) => {
            console.log("activateInventoryDocumentResult result", activateInventoryDocumentResult);
            if (activateInventoryDocumentResult.status && activateInventoryDocumentResult.status == "success") {

                navigateToTargetInventoryDocument(invDocId);


                // return wmsService.getInventoryDocumentByDocId(invDocId).then((getInventoryDocumentByDocIdResult) => {
                //     console.log("getInventoryDocumentByDocId result", getInventoryDocumentByDocIdResult);
                //     //getAndSetInventoryDocumentByDocId(invDocId, binId, binName);

                // }).catch((err) => {
                //     throw err;
                // });
            }
            else {
                throw new Error("activateInventoryDocumentResult failed");
            }
        })
            .catch((err) => {
                setDebounce(false);
                console.log("CreateAndActivateAndGetInventoryDocument err", err);
                if (err.errorMessage && err.errorMessage.length > 0) {
                    displayNotification(err.errorMessage);
                }
                else {
                    displayNotification("CreateAndActivateAndGetInventoryDocument failed. " + err);
                }

            });
    }




    function navigateToTargetInventoryDocument(invDocid: string) {
        let path = "/locations/" + locationId + "/stockCountings/" + invDocid;
        navigate(path);
    }

    return (
        <div>
            <NotificationDialog
                title="Info"
                contentText={notificationText}
                onClose={() => {
                    setShowNotification(false);
                }}
                visible={showNotification}
                onConfirm={() => {
                    setShowNotification(false);
                }}
            />

            <ConfirmationDialog
                contentText={showConfirmationText}
                onClose={() => {
                    setShowConfirmation(false);
                }}
                positiveText="Bestätigen"
                negativeText="Abbrechen"
                visible={showConfirmation}
                onConfirm={async () => {
                    await handleConfirmCreateInventoryDocumentClick();
                }}
            />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <div id="step1" hidden={searchMode.length > 0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3}>
                                <div style={{ padding: padding }}>
                                    <Button
                                        onClick={() => {
                                            updateSearchmode(searchOptionByBin);
                                        }}
                                        variant="contained"
                                        fullWidth={true}
                                    >
                                        <ViewInArIcon fontSize="large" />
                                        <div>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                        {"Bin Info"}
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div style={{ padding: padding }}>
                                    <Button
                                        onClick={() => {
                                            updateSearchmode(searchOptionByHU);
                                        }}
                                        variant="contained"
                                        fullWidth={true}
                                    >
                                        <InventoryIcon fontSize="large" />
                                        <div>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                        {"HU Info"}
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div style={{ padding: padding }}>
                                    <Button
                                        onClick={() => {
                                            updateSearchmode(searchOptionByMaterial);
                                        }}
                                        variant="contained"
                                        fullWidth={true}
                                    >
                                        <LocalOfferIcon fontSize="large" />
                                        <div>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                        {"Material Info"}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div id="DivSearch" hidden={searchMode.length <= 0 || wmsTree.length > 0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                Scan {getSearchModeDisplay()}
                                <div style={{ padding: padding }}>
                                    {
                                        searchMode == searchOptionByBin ?
                                            (<div>
                                                <WmsAutoComplete
                                                    locationId={locationId!}
                                                    populateLength={3}
                                                    displayLabel={getSearchModeDisplay()}
                                                    autoCompleteType={WmsAutoCompleteType.BIN}
                                                    selectedOption={selectedWmsAutoCompleteOption}
                                                    optionSelected={autoCompleteOptionSelected}
                                                    notifyMessage={displayNotification}
                                                    withScanner={false}
                                                />

                                            </div>) :
                                            searchMode == searchOptionByHU ?
                                                (<div>
                                                    <WmsAutoComplete
                                                        locationId={locationId!}
                                                        populateLength={3}
                                                        displayLabel={searchMode}
                                                        autoCompleteType={WmsAutoCompleteType.HU}
                                                        selectedOption={selectedWmsAutoCompleteOption}
                                                        optionSelected={autoCompleteOptionSelected}
                                                        notifyMessage={displayNotification}
                                                        withScanner={false}
                                                    />

                                                </div>) :
                                                searchMode == searchOptionByMaterial ?
                                                    (<div>
                                                        <WmsAutoComplete
                                                            locationId={locationId!}
                                                            populateLength={3}
                                                            displayLabel={searchMode}
                                                            autoCompleteType={WmsAutoCompleteType.MATERIAL}
                                                            selectedOption={selectedWmsAutoCompleteOption}
                                                            optionSelected={autoCompleteOptionSelected}
                                                            notifyMessage={displayNotification}
                                                            withScanner={false}
                                                        />

                                                    </div>) :

                                                    (<div>
                                                        invalid search option
                                                    </div>)
                                    }

                                </div>

                                <div style={{ padding: padding }}>
                                    <Button
                                        variant="contained"
                                        fullWidth={true}
                                        disabled={debounce || selectedWmsAutoCompleteOption == null}
                                        onClick={async () => {
                                            await handleSubmitAutoCompleteButtonClick();
                                        }}
                                    >
                                        <div>
                                            <br />
                                            <br />
                                        </div>
                                        {display_search}
                                    </Button>
                                </div>
                                <div style={{ padding: padding }}>
                                    <Scanner
                                        startScan={scanningSource}
                                        beforeScanText={display_ScanSource}
                                        duringScanText={display_ScanningSource}
                                        scanCompleted={scanSourceCompleted}
                                        scanStarted={scanSourceStarted}
                                        scanCancelled={scanSourceCancelled}
                                    />
                                </div>
                                <div style={{ padding: padding }}>
                                    <Button
                                        variant="contained"
                                        fullWidth={true}
                                        disabled={debounce}
                                        onClick={async () => {
                                            reset();
                                        }}
                                    >
                                        <div>
                                            <br />
                                            <br />
                                        </div>
                                        {display_cancel}
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                    </div>

                    <div id="DivResult" hidden={wmsTree == undefined || wmsTree.length <= 0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                {/* <InventoryDocumentComponent invDoc={invDoc} highlightText={ScannedSource} highlightType={searchMode} />
                                <br /> */}

                                <WmsTreeDisplay treeData={wmsTree} highlightText={ScannedSource} highlightType={searchMode} />
                                <br />
                            </Grid>

                        </Grid>
                    </div>
                    {/* <div id="DivResult2" hidden={invDoc == null}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <InventoryDocumentComponent invDoc={invDoc!} highlightText={ScannedSource}
                                    highlightType={searchMode}
                                    displayNotification={displayNotification} />
                                <br />
                            </Grid>

                        </Grid>
                    </div> */}

                </Grid>
            </Grid>



            <div hidden={true}>
                <br />
                <br />
                <br />
                <br />
                <TextField
                    id="searchSource"
                    name="searchSource"
                    label={display_sourceHU}
                    variant="outlined"
                    value={ScannedSource}
                    fullWidth={true}
                    onChange={handleScannedSourceTextChanged}
                    inputProps={{ maxLength }}
                    autoComplete="off"
                    onKeyDown={handleScannedSourceTextKeyDown}
                />
                <div style={{ padding: padding }}>
                    <Button
                        variant="contained"
                        fullWidth={true}
                        disabled={debounce || ScannedSource.length <= 0}
                        onClick={async () => {
                            await handleScannedSourceButtonClick();
                        }}
                    >
                        <div>
                            <br />
                            <br />
                        </div>
                        {display_search}
                    </Button>
                </div>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>

                        <h3>Debug</h3>
                        <div>
                            search mode: {getSearchModeDisplay()} <br />
                            search source value: {ScannedSource} <br />
                            search source id: <br />
                            wmsTree: {wmsTree != null && wmsTree.length > 0 ? JSON.stringify(wmsTree) : ""}
                        </div>
                        <Button
                            onClick={() => {
                                reset();
                            }}
                            variant="contained"
                            fullWidth={true}
                        >
                            {"Reset"}
                        </Button>
                    </Grid>

                </Grid>
            </div>
        </div>
    );
};

export default React.memo(StockCountingIndexComponent);
