import { InboundDeliveryDetailsV11Dto } from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { InboundDeliverySearchV11Dto } from "interfaces/v11/inboundDelivery/inboundDeliverySearch/inboundDeliverySearchRequestV11Dto";
import React from "react";
import { generateRandomGuid, guid } from "types/guid";
import axios from "../utils/axios";
import WmsMaterialService from "./WmsMaterialService";
import { WMSMaterialV11Dto } from "interfaces/v11/materialDetail/wmsMaterialDetailsV11Dto";
import { wmsMaterialSearchV11Dto } from "interfaces/v11/materialDetail/wmsMaterialSearchV11Dto";
import { wmsStockTypeSearchRequestV2Dto } from "interfaces/v2/stockType/wmsStockTypeSearchRequestV2Dto";
import { wmsStockTypeSearchResponseV2Dto } from "interfaces/v2/stockType/wmsStockTypeSearchResponseV2Dto";
import { WarehouseTaskV2RequestDto } from "interfaces/v2/warehouseTask/warehouseTaskV2RequestDto";
import { warehouseTaskV2ResponseDto } from "interfaces/v2/warehouseTask/warehouseTaskV2ResponseDto";
import { processTypeSearchV11RequestDto } from "interfaces/v11/processType/processTypeSearchV11RequestDto";
import { processTypeSearchV11ResponseDto } from "interfaces/v11/processType/processTypeSearchV11ResponseDto";
import { batchSearchV11RequestDto } from "interfaces/v11/batch/batchSearchV11RequestDto";
import { batchSearchV11ResponseDto } from "interfaces/v11/batch/batchSearchV11Response";
import { batchCreateV11RequestDto } from "interfaces/v11/batch/batchCreateV11RequestDto";
import { batchCreateV11ResponseDto } from "interfaces/v11/batch/batchCreateV11ResponseDto";
import {
  InboundDeliveryItemQuantityRoleCategoryEnum,
  InboundDeliveryItemQuantityRoleEnum,
} from "interfaces/enums/InboundDeliveryEnums";
import { ownerSearchRequestDto } from "interfaces/v1/owner/ownerSearchRequestDto";
import { ownerSearchResponseDto } from "interfaces/v1/owner/ownerSearchResponseDto";
import { InboundDeliveryCreateV2Request } from "interfaces/v2/inboundDelivery/inboundDeliveryCreate/inboundDeliveryCreateV2Request";
import { InboundDeliveryCreateV2Response } from "interfaces/v2/inboundDelivery/inboundDeliveryCreate/inboudDeliveryCreateV2Response";
import { InboundDeliveryCreateV11Request } from "interfaces/v11/inboundDelivery/inboundDeliveryCreate/inboundDeliveryCreateV11Request";
import { InboundDeliveryCreateV11Response } from "interfaces/v11/inboundDelivery/inboundDeliveryCreate/inboudDeliveryCreateV11Response";
import { InboundDeliveryDocumentUploadV11Request } from "interfaces/v11/inboundDelivery/inboundDeliveryDocument/inboundDeliveryDocumentUploadV11Request";
import { InboundDeliveryDocumentUploadV11Response } from "interfaces/v11/inboundDelivery/inboundDeliveryDocument/inboundDeliveryDocumentUploadV11Response";

const { createContext, useContext } = React;

interface IGoodReceiptService {
  searchInboundDeliveries: (
    searchDto: InboundDeliverySearchV11Dto
  ) => Promise<InboundDeliveryDetailsV11Dto[]>;
  countInboundDeliveries: (searchDto: any) => Promise<number>;
  getInboundDeliveryById: (
    inboundDeliveryId: guid
  ) => Promise<InboundDeliveryDetailsV11Dto>;
  createInboundDeliveryV2: (
    createDto: InboundDeliveryCreateV2Request
  ) => Promise<InboundDeliveryCreateV2Response>;
  createInboundDeliveryV11: (
    createDto: InboundDeliveryCreateV11Request
  ) => Promise<InboundDeliveryCreateV11Response>;

  updateInboundDelivery: (
    updateDto: InboundDeliveryDetailsV11Dto
  ) => Promise<string>;

  uploadDocument: (
    request: InboundDeliveryDocumentUploadV11Request
  ) => Promise<InboundDeliveryDocumentUploadV11Response>;

  searchWmsMaterials: (
    searchDto: wmsMaterialSearchV11Dto
  ) => Promise<WMSMaterialV11Dto[]>;
  getWmsMaterialById: (id: string) => Promise<WMSMaterialV11Dto>;
  getWmsStockType: (
    searchDto: wmsStockTypeSearchRequestV2Dto
  ) => Promise<wmsStockTypeSearchResponseV2Dto>;
  getGRProcessType: (
    searchDto: processTypeSearchV11RequestDto
  ) => Promise<processTypeSearchV11ResponseDto[]>;

  getBatch: (
    searchDto: batchSearchV11RequestDto
  ) => Promise<batchSearchV11ResponseDto[]>;

  createBatch: (
    createDto: batchCreateV11RequestDto
  ) => Promise<batchCreateV11ResponseDto>;

  getOwner: (
    searcDto: ownerSearchRequestDto
  ) => Promise<ownerSearchResponseDto[]>;

  createTask: (
    request: WarehouseTaskV2RequestDto
  ) => Promise<warehouseTaskV2ResponseDto>;
}

const GoodReceiptServiceContext = createContext({} as IGoodReceiptService);

export const GoodReceiptServiceProvider: React.FC<{
  children?: React.ReactNode;
  searchInboundDeliveries?: any;
  countInboundDeliveries?: any;
  getInboundDeliveryById?: any;
  createInboundDeliveryV2?: any;
  createInboundDeliveryV11?: any;
  updateInboundDelivery?: any;
  uploadDocument?: any;
  searchWmsMaterials?: any;
  getWmsMaterialById?: any;
  getWmsStockType?: any;
  getGRProcessType?: any;
  getBatch?: any;
  createBatch?: any;
  getOwner?: any;
  createTask?: any;
}> = (props) => {
  const searchInboundDeliveries = async (
    searchDto: InboundDeliverySearchV11Dto
  ) => {
    const res = await axios.post("/v11/inbound-deliveries/search", searchDto);
    return res.data;
  };

  const countInboundDeliveries = async (searchDto: any) => {
    const res = await axios.post("/v11/inbound-deliveries/count", searchDto);
    return res.data;
  };

  const getInboundDeliveryById = async (inboundDeliveryId: guid) => {
    return await axios
      .get<InboundDeliveryDetailsV11Dto>(
        "/v11/inbound-deliveries/" +
        inboundDeliveryId +
        "?randomizeNumberToAvoidCacche=" +
        generateRandomGuid().toString()
      )
      .then(async (res) => {
        let result = res.data;
        // if (result.inboundDeliveryCarriers) {
        //   result.inboundDeliveryCarriers.forEach(async (carrier) => {
        //     if (carrier.carrierConfigurationTenantId) {
        //       carrier.carrierConfigurationTenant =
        //         await CarrierConfigurationService.getCarrierConfigurationById(
        //           carrier.carrierConfigurationTenantId
        //         );
        //     }
        //   });
        // }
        if (result.inboundDeliveryItems) {
          result.inboundDeliveryItems.forEach(async (item) => {
            // if (item.materialId) {
            //   item.material = await WmsMaterialService.getMaterialById(
            //     item.materialId
            //   );
            // }

            if (item.materialId) {
              item.material = await WmsMaterialService.getMaterialById_v2(
                item.materialId
              );
            }

            //item.specialStock = "";

            // if (item.stockTypeId) {
            //   item.stockType = await WmsStockTypeService.getStockTypeById(
            //     item.stockTypeId
            //   );
            // }

            // if (item.transferDocumentItemId) {
            //   item.transferDocumentItem =
            //     await LocationTransferService.getLocationTransferItemById(
            //       item.transferDocumentItemId
            //     );
            // }

            result.inboundDeliveryItems.forEach((x) => {
              if (
                x.inboundDeliveryItemQuantities != null &&
                x.inboundDeliveryItemQuantities.length > 0
              ) {
                const filteredQty = x.inboundDeliveryItemQuantities.filter(
                  (item) =>
                    item.quantityRoleCategory ===
                    InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
                    item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
                );

                if (filteredQty && filteredQty.length == 1) {
                  x.remainingOpenQty = filteredQty[0].quantity;
                }
              }
            });
          });
        }

        // if (result.inboundDeliveryItems != null) {
        //   result.inboundDeliveryItems.map((x) => {
        //     if (
        //       x.inboundDeliveryItemQuantities != null &&
        //       x.inboundDeliveryItemQuantities.length > 0
        //     ) {
        //       const filteredQty = x.inboundDeliveryItemQuantities.filter(
        //         (item) =>
        //           item.quantityRoleCategory ===
        //             InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
        //           item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
        //       );

        //       if (filteredQty && filteredQty.length == 1) {
        //         x.remainingOpenQty = filteredQty[0].quantity;
        //       }
        //     }
        //   });
        // }

        return result;
      });
  };

  const createInboundDeliveryV2 = async (
    createDto: InboundDeliveryCreateV2Request
  ) => {
    const res = await axios.post("/v2/inbounddeliveries", createDto);
    return res.data;
  };

  const createInboundDeliveryV11 = async (
    createDto: InboundDeliveryCreateV11Request
  ) => {
    const res = await axios.post("/v11/inbound-deliveries", createDto);
    return res.data;
  };

  const updateInboundDelivery = async (
    updateDto: InboundDeliveryDetailsV11Dto
  ) => {
    updateDto.locationId = updateDto.inboundDeliveryId;
    const res = await axios.put(
      "/v11/inbound-deliveries/" + updateDto.inboundDeliveryId,
      updateDto
    );
    return res;
  };

  const uploadDocument = async (
    request: InboundDeliveryDocumentUploadV11Request
  ) => {
    var bodyFormData = new FormData();
    bodyFormData.append("type", request.type);
    bodyFormData.append("fileName", request.fileName);
    bodyFormData.append("file", request.file);

    const res = await axios.post(
      "/v11/inbound-deliveries/" +
      request.InboundDeliveryId +
      "/documents/upload",
      bodyFormData
    );
    return res.data;
  };

  const searchWmsMaterials = async (searchDto: wmsMaterialSearchV11Dto) => {
    const res = await axios.post("/v11/materials/search", searchDto);
    return res.data;
  };

  const getWmsMaterialById = (id: string) => {
    return axios.get("/V11/materials/" + id).then((res) => res.data);
  };

  const getWmsStockType = async (searchDto: wmsStockTypeSearchRequestV2Dto) => {
    const res = await axios.get(
      "/v2/stocktypes?locationId=" +
      searchDto.locationId +
      "&shortName=" +
      searchDto.shortName
    );
    if (res.data == null || res.data.length != 1) {
      return null;
    }
    return res.data[0];
  };

  const getGRProcessType = async (
    searchDto: processTypeSearchV11RequestDto
  ) => {
    const res = await axios.get(
      "/ProcessTypes/GetProcessTypesForLocation?locationId=" +
      searchDto.locationId
    );
    if (res.data != undefined && res.data.length > 0) {
      return res.data;
    }
    return null;
  };

  const getBatch = async (searchDto: batchSearchV11RequestDto) => {
    const res = await axios.post("/v11/batches/search", searchDto);
    return res.data;
  };

  const createBatch = async (searchDto: batchCreateV11RequestDto) => {
    const res = await axios.post("/v11/batches", searchDto);
    return res.data;
  };

  const getOwner = async (searchDto: ownerSearchRequestDto) => {
    const url =
      "v1/owners/GetOwnersForLocation?ownername=" +
      searchDto.ownerName +
      "&locationId=" +
      searchDto.locationId;
    const res = await axios.get(url);
    return res.data;
  };

  const createTask = async (request: WarehouseTaskV2RequestDto) => {
    const res = await axios.post("/v2/WarehouseTasks/CreateTasksJWT", request);
    return res.data;
  };

  const value = {
    searchInboundDeliveries:
      props.searchInboundDeliveries || searchInboundDeliveries,
    countInboundDeliveries:
      props.countInboundDeliveries || countInboundDeliveries,
    getInboundDeliveryById:
      props.getInboundDeliveryById || getInboundDeliveryById,
    createInboundDeliveryV2:
      props.createInboundDeliveryV2 || createInboundDeliveryV2,
    createInboundDeliveryV11:
      props.createInboundDeliveryV11 || createInboundDeliveryV11,
    updateInboundDelivery: props.updateInboundDelivery || updateInboundDelivery,
    uploadDocument: props.uploadDocument || uploadDocument,
    searchWmsMaterials: props.searchWmsMaterials || searchWmsMaterials,
    getWmsMaterialById: props.getWmsMaterialById || getWmsMaterialById,
    getWmsStockType: props.getWmsStockType || getWmsStockType,
    getGRProcessType: props.getGRProcessType || getGRProcessType,
    getBatch: props.getBatch || getBatch,
    createBatch: props.createBatch || createBatch,
    getOwner: props.getOwner || getOwner,
    createTask: props.createTask || createTask,
  };

  return (
    <GoodReceiptServiceContext.Provider value={value}>
      {props.children}
    </GoodReceiptServiceContext.Provider>
  );
};

export const useGoodReceiptService = () => {
  return useContext(GoodReceiptServiceContext);
};
