export interface orderV11Dto {
  orderId: string;
  orderNumber: string;
  orderStatus: OrderStatusEnum;
  orderTasks?: orderTaskDto[];
}

export interface orderTaskDto {
  locationId: string;
  orderTaskId: string;
  orderId: string;
  orderTaskNumber: string;
  orderTaskIndex: number;
  product: string;
  productDescription: string;
  productId: string;
  targetBatch: string;
  targetBuom: string;
  targetQtyBuom: number;
  targetAuom: string;
  targetQtyAuom: number;
  targetAuomWeightUnit: string;
  targetSiNetWeight: number;
  targetSiGrossWeight: number;
  targetAuomVolUnit: string;
  targetSiNetVol: number;
  targetSiGrossVol: number;
  actualBuom: string;
  actualQtyBuom: number;
  actualAuom: string;
  actualQtyAuom: number;
  srcHuId: string;
  srcHuName: string;
  dstHuId: string;
  dstHuName: string;
  srcBinId: string;
  dstBinId: string;
  initialDstBinId: string;
  initialDstHuId: string;
  orderTaskStatus: string;
  createdAt: string;
  startedAt: string;
  confirmedAt: string;
  specialStockType: string;
  specialStockNumber: string;
  batch: string;
  printStatus: number;
  printedAt: string;
  printedHuGuid: string;
  isHuTask: true;
  handlingUnitId: string;
  splitOrderTask: true;
  createdById: string;
  reason: string;
  priority: number;
  guidStock: string;
  stockCategory: number;
  customerStockItemNumber: number;
  stockUsage: number;
  owner: string;
  ownerRole: string;
  partyEntitledToDispose: string;
  partyEntitledToDisposeRole: string;
  netWeight: number;
  weightUnit: string;
  netVolume: number;
  volUnit: string;
  capacityUsage: number;
  goodsReceivedAt: string;
  sourceArea: string;
  destinationArea: string;
  plannedDurationSeconds: number;
  inboundDeliveryId: string;
  inboundDeliveryItemId: string;
  documentationBatch: true;
  productionSupplyArea: string;
  confirmedById: string;
  processorId: string;
  exceptionCode: string;
  differenceQuantityBUM: number;
  differenceQuantityAUM: number;
  outboundDeliveryId: string;
  outboundDeliveryItemId: string;
  latestStart: string;
  wmsMaterialId: string;
  wmsProcessTypeId: string;
  inventoryDocumentId: string;
  inventoryDocumentDifferenceId: string;
  srcStockTypeId: string;
  dstStockTypeId: string;
  inboundDeliveryHandlingUnitId: string;
  outboundDeliveryHandlingUnitId: string;
  reversalTaskId: string;
  reversalTaskString: string;
  referenceTaskId: string;
  rebookingDocumentId: string;
}

export enum OrderStatusEnum {
  OPEN = 1,
  INPROCESS = 2,
  FINISHED = 3,
  LOCKED = 4,
  CANCELED = 5,
  INACTIVE = 6,
}
