import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CardContent,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Page, PbCard } from "components/platbricks/shared";
import useUserCache from "hooks/useUserCache";
import {
  InboundDeliveryItemQuantityRoleCategoryEnum,
  InboundDeliveryItemQuantityRoleEnum,
} from "interfaces/enums/InboundDeliveryEnums";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryCarrierDetailsV11Dto,
  _InboundDeliveryDocumentDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
  _InboundDeliveryPartnerDetailsV11Dto,
  _InboundDeliveryQuantityDetailsV11Dto,
  _InboundDeliveryReferenceDetailsV11Dto,
  _InboundDeliveryStatusDetailsV11Dto,
  _InboundDeliveryTextDetailsV11Dto,
  _InboundDeliveryTimeDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { generateRandomGuid, guid } from "types/guid";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import { ConfirmationDialog } from "components/platbricks/shared/ConfirmationDialog";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import InboundDeliveryDetail from "./components/InboundDeliveryDetail";
import InboundDeliveryItemDetail from "./components/InboundDeliveryItemDetail";
import InboundDeliveryItemAddNewItem from "./components/InboundDeliveryItemAddNewItem";
import GoodReceiptHUAssign from "./components/GoodReceiptHUAssign";
import {
  TaskV2Dto,
  WarehouseTaskV2RequestDto,
} from "interfaces/v2/warehouseTask/warehouseTaskV2RequestDto";
import { ItemAssignment } from "interfaces/v11/goodReceiptDto/ItemAssignment";
import GoodReceiptPrintComponent from "./components/GoodReceiptPrintComponent";
import useAuth from "hooks/useAuth";
import { useSettingService } from "services/SettingService";
import { locationSetting } from "interfaces/setting/locationSetting";
import { searchStockV11ResquestDto } from "interfaces/v11/stock/searchStockV11RequestDto";
import { useWmsService } from "services/WmsService";

function GoodReceiptDetailPage() {
  //generic item
  const [getUserById] = useUserCache();
  const [tab, setTab] = useState(0);
  const { locationId, id } = useParams();
  const GoodReceiptService = useGoodReceiptService();
  const wmsService = useWmsService();

  const reservedPlaceHolder: string = "{%!Hu@}";
  const display_GoodReceipt: string = "Wareneingang";
  const display_CouldNotGetGRProcessType: string =
    "Could not get GR Process Type";
  const display_CouldNotGetStockType: string = "Could not get AFS Stock Type";
  const display_huAdded: string =
    "Ladungträger " + reservedPlaceHolder + " wurde hinzugefügt.";

  const display_Detail: string = "Lieferdetails";
  const display_Item: string = "Anlieferung";
  const display_Assignment: string = "Zuordnung LT";
  const display_Ticketing: string = "Buchung";
  const display_Completed: string = "Anlieferpositionen";

  const display_PleaseEnterConsignmentNote: string =
    "Bitte Lieferscheinnummer eingeben, um fortzufahren.";
  const display_PleaseEnterDeliveryDate: string =
    "Please enter Delivery Date to continue";

  const display_NoTask: string = "keine Buchung möglich.";
  const display_SomethingWentWrongSelectedHU: string =
    "Something went wrong. Could not identify selected HU.";
  const display_NotImplemented: string = "This is not implemented yet";
  const display_ConfirmationToPostGoodReceipt: string =
    "Möchten Sie den Wareneingang jetzt buchen?";
  const display_ConfirmationToPostGoodReceiptWhenUnderbook: string =
    "Es sind noch offene Positionen vorhanden. Soll der Wareneingang trotzdem gebucht werden?";
  const display_ConfirmationToDiscard: string =
    "Alle Zuordnungen werden verworfen. Möchten Sie trotzdem fortfahren?";

  const display_next: string = "Weiter";
  const display_confirm: string = "Bestätigen";
  const display_cancel: string = "Abbrechen";
  const display_save: string = "Speichern";
  const display_post: string = "Buchen";

  const display_NoSelectedHU: string = "Bitte Ladungsträger auswählen";
  const display_NoSelectedMaterial: string = "Bitte Material auswählen";
  const display_PleaseEnterQuantity: string =
    "Bitte Menge eingeben, um Buchung abzuschliessen.";
  const display_SomethingWentWrong: string = "Something went wrong";
  const display_somethingWentWrongSearchMaterial: string =
    "Something went wrong. Could not find Material. ";
  const display_MaterialRecorded: string = "Material erfassen";

  const display_ItemAdded: string = "Position wurde hinzugefügt.";
  const display_ItemUpdated: string = "aktualisiert";
  const display_ItemDeleted: string = "gelöscht";
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [snackBarDuration, setSnackBarDuration] = useState(2000);
  const [snackBarContent, setSnackBarContent] = useState("default");

  const [debounce, setDebounce] = useState<boolean>(false);
  useEffect(() => {
    //console.log("debounce " + new Date(), debounce);
  }, [debounce]);

  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };
  function showSnackBar(content: string, type: AlertBoxType) {
    setSnackBarSeverity(type);
    setSnackBarContent(content);
    setSnackBarOpen(true);
  }

  const display_new: string = "Neu";
  const display_HU: string = "Ladungsträger";
  const display_somethingWentWrongSearchBin: string =
    "Something went wrong. Could not find Bin. ";
  const display_somethingWentWrongSearchHUType: string =
    "Something went wrong. Could not find HU Type. ";

  //setting
  const display_settingNotConfigured: string =
    "Good Receipt Setting is not configured properly";
  const SettingService = useSettingService();
  function navigateToSetting() {
    let path = "/locations/" + locationId + "/settings";
    navigate(path);
  }

  const [assignedItems, setAssignedItems] = useState<ItemAssignment[]>([]);

  const navigate = useNavigate();
  const { signOut } = useAuth();
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  //confirmation dialog
  const [showConfirmationText, setShowConfirmationText] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationForDiscard, setShowConfirmationForDiscard] =
    useState(false);

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  //inbound delivery
  const [inboundDelivery, setInboundDelivery] =
    useState<InboundDeliveryDetailsV11Dto | null>(null);
  const [grProcessTypeId, setGRProcessTypeId] = useState("");
  const [destinationBinId, setDestinationBinId] = useState("");
  const [afsStockTypeId, setAfsStockTypeId] = useState("");

  var showPrinterNotConfiguredWarning: boolean = true;
  const display_printerNotConfigured: string =
    "Die Druckereinstellungen sind nicht konfiguriert. Es wird kein HU-Label gedruckt. ";
  const [grPrintServerId, setGrPrintServerId] = useState("");
  const [grPrinterId, setGrPrinterId] = useState("");

  const [requiredToSaveConsignmentNote, setRequiredToSaveConsignmentNote] = useState(false);
  useEffect(() => {
    SettingService.getLocationSetting(locationId as string)
      .then((result) => {
        if (result) {
          if (
            result.goodReceiptSetting?.processTypeId != undefined &&
            result.goodReceiptSetting?.processTypeId.length > 0 &&
            result.goodReceiptSetting?.destinationBinId != undefined &&
            result.goodReceiptSetting?.destinationBinId.length > 0
          ) {
            setGRProcessTypeId(result.goodReceiptSetting.processTypeId);
            setDestinationBinId(result.goodReceiptSetting.destinationBinId);

            if (result.goodReceiptSetting?.printServerId != undefined) {
              setGrPrintServerId(result.goodReceiptSetting?.printServerId);
            }
            if (result.goodReceiptSetting?.printerId != undefined) {
              setGrPrinterId(result.goodReceiptSetting?.printerId);
            }
          } else {
            displayNotification(display_settingNotConfigured);
            navigateToSetting();
          }
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      })
      .catch(() => {
        displayNotification(display_SomethingWentWrong);
      });

    GoodReceiptService.getInboundDeliveryById(id as guid).then(
      (inboundDelivery) => {

        updateRequiredToSaveConsignmentNote(inboundDelivery);

        if (inboundDelivery.inboundDeliveryItems != undefined) {
          const sortedItems = [...inboundDelivery.inboundDeliveryItems].sort(
            (a, b) =>
              a.inboundDeliveryItemNumber!.localeCompare(
                b.inboundDeliveryItemNumber!
              )
          );
          inboundDelivery.inboundDeliveryItems = sortedItems;
        }

        setInboundDelivery(inboundDelivery);
      }
    );

    GoodReceiptService.getWmsStockType({
      locationId: locationId as string,
      shortName: "AFS",
    })
      .then((result) => {
        setAfsStockTypeId(result.stockTypeId);
      })
      .catch((response) => {
        if (response.Status == "401") {
          handleSignOut();
        } else {
          displayNotification(display_CouldNotGetStockType);
        }
      });
  }, [GoodReceiptService, id]);

  //hu
  const defaultHU = {
    HuId: generateRandomGuid(),
    HuName: "Default",
    HUType: generateRandomGuid(),
    BinId: generateRandomGuid(),
    BinName: "Default",
  };
  const [HUs, setHUs] = useState<HuV11Dto[]>([]);
  function AddHU(newItem: HuV11Dto) {
    //items.push(item);
    setHUs((prevItems) => [...prevItems, newItem]);
    //const huName = newItem.handlingUnit + "(" + newItem.handlingUnitType + ")";
    //displayNotification(display_huAdded.replace(reservedPlaceHolder, huName));
  }
  function EditHU(id: guid, updatedItem: HuV11Dto) {
    // Find the index of the item to be updated
    const index = HUs.findIndex((item) => item.wmsId === id);

    // If the item is found, update it and trigger a re-render of the component
    if (index !== -1) {
      setHUs((prevItems) => [
        ...prevItems.slice(0, index),
        updatedItem,
        ...prevItems.slice(index + 1),
      ]);
    }
  }
  function DeleteHU(
    id: guid,
    newAssignment: ItemAssignment[],
    newInboundDelivery: InboundDeliveryDetailsV11Dto
  ) {
    setHUs((prevItems) => prevItems.filter((item) => item.wmsId !== id));
    setAssignedItems([...newAssignment]);
  }

  //stepper
  const steps = [
    display_Detail,
    display_Item,
    display_Assignment,
    display_Ticketing,
    display_Completed,
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepOptional = (step: number) => {
    //return step === 1;
    return false;
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  //step stepper button event
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  //save inbound delivery
  const handleSetInboundDeliveryValue = (
    newValue: InboundDeliveryDetailsV11Dto
  ) => {
    setInboundDelivery(newValue);
  };
  const notifyUploadCompleted = (message: string) => {
    //reload only the document detail
    if (inboundDelivery) {
      GoodReceiptService.getInboundDeliveryById(id as guid).then((result) => {
        inboundDelivery!.inboundDeliveryDocuments =
          result.inboundDeliveryDocuments;
        setInboundDelivery(inboundDelivery);
        displayNotification(message);
      });
    }
  };
  function showUploadAttemptMessage(message: string) {
    displayNotification(message);
  }

  const handleUpdateInboundDeliveryGeneralDetail = () => {
    if (inboundDelivery) {
      if (!requiredToSaveConsignmentNote) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      else {
        //no value
        if (
          !inboundDelivery.consignmentNote ||
          inboundDelivery.consignmentNote.length <= 0
        ) {
          displayNotification(display_PleaseEnterConsignmentNote);
          return;
        } else if (inboundDelivery?.createdAt.length <= 0) {
          displayNotification(display_PleaseEnterDeliveryDate);
          return;
        }



        GoodReceiptService.updateInboundDelivery(inboundDelivery)
          .then((result) => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          })
          .catch((response) => {
            if (response.Status == "401") {
              handleSignOut();
            } else {
              displayNotification(display_SomethingWentWrong);
            }
          });
      }


    } else {
      displayNotification(display_SomethingWentWrong);
      return;
    }
  };
  const handleNextFromInboundDeliveryItemStep = () => {
    setActiveStep(2);
  };
  const handleNextFromInboundDeliveryAddEditItemStep = () => {
    setActiveStep(1);
  };
  const handleBackFromInboundDeliveryAddEditItemStep = () => {
    setActiveStep(1);
  };
  const handleBackFromAssignmentStep = () => {
    if (assignedItems != null && assignedItems.length > 0) {
      setShowConfirmationForDiscard(true);
    } else {
      setActiveStep(1);
    }
  };

  //post good receipt
  const postGoodReceipt = () => {
    if (debounce) {
      return;
    }

    setDebounce(true);
    const request = ConvertAssigneditemToWarehouseTask();

    if (request.tasks.length <= 0) {
      displayNotification(display_NoTask);
      setDebounce(false);
      return;
    }

    const searchStockRequest: searchStockV11ResquestDto = {
      locationId: locationId!,
      parentElementId: request.tasks[0].dest_hu,
    };

    wmsService
      .findStock(searchStockRequest)
      .then((x) => {
        console.log("search stock", x);
        if (x && x.length > 0) {
          //the newly created HU in this Good Receipt now contain stock
          //this sould not happe by default
          //the only possibility is user had perform Good Receipt already
          //and due to some error they stay on same screen, and try to perform another one
          //consider good receipt success and redirect user to printing instead
          setDebounce(false);
          setActiveStep(3);
        } else {
          GoodReceiptService.createTask(request)
            .then((result) => {
              //og(result);
              //displayNotification(result.status + " " + result.messages);
              setDebounce(false);
              if (result.status === "success") {
                //displayNotification("Transkation abgeschlossen");
                setActiveStep(3);
              } else {
                displayNotification(result.status + " - " + result.messages);
              }
            })
            .catch((response) => {
              setDebounce(false);
              console.log(response);
              if (response.Status == "401") {
                handleSignOut();
              } else {
                displayNotification(display_SomethingWentWrong);
              }
            });
        }
      })
      .catch((e) => {
        console.log("Find stock error", e);
        displayNotification(display_SomethingWentWrong);
      });
  };

  const discardAssignment = () => {
    setAssignedItems([]);
    inboundDelivery?.inboundDeliveryItems.map((x) => {
      if (x.inboundDeliveryItemQuantities != undefined) {
        const filteredOpenQty = x.inboundDeliveryItemQuantities.filter(
          (item) =>
            item.quantityRoleCategory ===
            InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
            item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
        );

        if (filteredOpenQty && filteredOpenQty.length == 1) {
          x.remainingOpenQty = filteredOpenQty[0].quantity;
        }
      }
    });
    setActiveStep(1);
  };

  const handleNextFromHUAssignStep = () => {
    let huWithMultipleAssignedItem: string[] = findHUWithMultipleAssignItem();
    if (
      huWithMultipleAssignedItem != undefined &&
      huWithMultipleAssignedItem.length > 0
    ) {
      displayNotification(
        "Auf diesen Ladungsträgern befinden sich mehrere Positionen. Bitte stellen Sie sicher, dass nur eine Position einem LT zugeordnet ist. <br/><br/>" +
        huWithMultipleAssignedItem.join("<br/>")
      );
    } else {
      showPostGRConfirmation();
    }
  };

  //selected HU
  const [selectedHU, setSelectedHU] = React.useState<HuV11Dto | null>(null);
  function onChangeSelectedHU(seletedItem: HuV11Dto) {
    const index = HUs.findIndex((item) => item.wmsId === seletedItem.wmsId);
    if (index !== -1) {
      setSelectedHU(seletedItem);
    } else {
      displayNotification(display_SomethingWentWrongSelectedHU);
    }
  }

  //post GR
  function showPostGRConfirmation() {
    let underbook: boolean = isDeliveryUnderBook();
    if (underbook) {
      setShowConfirmationText(
        display_ConfirmationToPostGoodReceiptWhenUnderbook
      );
    } else {
      setShowConfirmationText(display_ConfirmationToPostGoodReceipt);
    }
    setShowConfirmation(true);
  }

  const handleConfirmPostGRClick = async () => {
    postGoodReceipt();
    setShowConfirmation(false);
  };

  const handleConfirmDiscardClick = async () => {
    discardAssignment();
    setShowConfirmationForDiscard(false);
  };

  function onAddNewInboundDeliveryItem() {
    setActiveStep(4);
  }

  function onAddEditInboundDeliveryItem() {
    displayNotification("Edit - " + display_NotImplemented);
  }
  function onDeleteInboundDeliveryItem() {
    reloadInboundDelivery();
  }

  function onNewInboundDeliveryItemAdded() {
    reloadInboundDelivery();
  }

  function updateRequiredToSaveConsignmentNote(input: InboundDeliveryDetailsV11Dto) {
    if (input.consignmentNote == undefined || input.consignmentNote.length <= 0) {
      setRequiredToSaveConsignmentNote(true);
    }
    else {
      setRequiredToSaveConsignmentNote(false);
    }
  }


  function reloadInboundDelivery() {
    if (debounce) {
      return;
    }
    setDebounce(true);
    GoodReceiptService.getInboundDeliveryById(id as guid)
      .then((result) => {
        if (result.inboundDeliveryItems != undefined) {
          const sortedItems = [...result.inboundDeliveryItems].sort((a, b) =>
            a.inboundDeliveryItemNumber!.localeCompare(
              b.inboundDeliveryItemNumber!
            )
          );
          result.inboundDeliveryItems = sortedItems;
        }

        updateRequiredToSaveConsignmentNote(result);
        setInboundDelivery(result);
        handleNextFromInboundDeliveryAddEditItemStep();
        showSnackBar(display_ItemAdded, AlertBoxType.success);
        setDebounce(false);
      })
      .catch(() => {
        setDebounce(false);
      });
  }

  function onEditAssignedItems(assignments: ItemAssignment[], action: string) {
    assignments.sort((a, b) => a.huDisplayName.localeCompare(b.huDisplayName));
    setAssignedItems(assignments);
    //displayNotification(action);
  }

  //useEffect(() => {}, [inboundDelivery]);
  useEffect(() => { }, [assignedItems]);
  useEffect(() => { }, [requiredToSaveConsignmentNote]);

  if (!inboundDelivery) {
    return (
      <Page title={display_Item} breadcrumbs={[]} showLoading={true}></Page>
    );
  }

  function ConvertAssigneditemToWarehouseTask(): WarehouseTaskV2RequestDto {
    let tasks: TaskV2Dto[] = [];
    let warehouseTask: WarehouseTaskV2RequestDto = {
      locationId: locationId as string,
      tasks: tasks,
    };

    assignedItems.map((item) => {
      //result += "OrderId: " + item.orderId + ", DestinationHUId: " + item.huId + ", Quantity: " + item.quantity.toString() + "<br/>";
      let tempTask: TaskV2Dto = {
        id: 0,
        type: "1", //why 1?
        movementType: "",
        material: item.item.materialId as string,
        batch: item.item.batch,
        dest_bin: item.binId,
        dest_hu: item.huId,
        dest_hu_name: item.huDisplayName,
        src_bin: "",
        src_hu: "",
        inboundDeliveryItem: item.itemId,
        outboundDeliveryItem: "",
        details: [],
        noSN_tag: true,
        newHUName: "",
        isInboundDeliveryGR: true,
        //processType: "4d00a12a-f389-4ed5-b562-a7cbce554562", //need to call API
        processType: grProcessTypeId,
        inboundDeliveryId: inboundDelivery?.inboundDeliveryId as string,
        outboundDeliveryId: "",
        srcStockType: "",
        dstStockType: "",
        //dstStockType: "a45cc45e-7e94-43f8-a46e-3612ee9d5dd2", //where to get?
        owner: item.item.owner != undefined ? item.item.owner : "",
        buom: item.uom,
        auom: item.uom,
        quantityBuom: item.quantity.toString(),
        quantityAuom: item.quantity.toString(),
        taskStatus: "",
        huTypeId: "",
      };

      warehouseTask.tasks.push(tempTask);
    });

    return warehouseTask;
  }

  function isDeliveryUnderBook(): boolean {
    let totalOpenQty: number = 0;
    inboundDelivery?.inboundDeliveryItems.forEach((item) => {
      const openQty = item.inboundDeliveryItemQuantities?.filter(
        (x) =>
          x.quantityRoleCategory ===
          InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
          x.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
      );

      if (openQty && openQty.length == 1) {
        const qty = openQty[0];
        totalOpenQty += qty.quantity;
      }
    });

    let totalQtyInItemAssigned: number = assignedItems.reduce(
      (sum, individual) => sum + individual.quantity,
      0
    );
    return totalOpenQty != totalQtyInItemAssigned;
  }

  function findHUWithMultipleAssignItem(): string[] {
    var huIds: string[] = assignedItems.map((obj) => obj.huDisplayName);
    var duplicates = Array.from(
      huIds.reduce((acc, v, i, arr) => {
        return arr.indexOf(v) !== i ? acc.add(v) : acc;
      }, new Set())
    );
    return duplicates as string[];
  }

  return (
    <Page
      title={display_GoodReceipt}
      subtitle={inboundDelivery.deliveryNumber}
      breadcrumbs={[
        {
          label: "Home",
          to: "/",
        },
        {
          label: display_GoodReceipt,
          to: `/locations/${locationId}/goodReceipts`,
        },
        {
          label: inboundDelivery.deliveryNumber,
        },
      ]}
    >
      {showPrinterNotConfiguredWarning &&
        (grPrintServerId.length == 0 || grPrinterId.length == 0) ? (
        <div>
          <AlertBox
            alertType={AlertBoxType.info}
            contentText={display_printerNotConfigured}
          />
        </div>
      ) : (
        <div></div>
      )}

      <ConfirmationDialog
        contentText={showConfirmationText}
        onClose={() => {
          setShowConfirmation(false);
        }}
        positiveText="Bestätigen"
        negativeText="Abbrechen"
        visible={showConfirmation}
        onConfirm={async () => {
          await handleConfirmPostGRClick();
        }}
      />

      <ConfirmationDialog
        contentText={display_ConfirmationToDiscard}
        onClose={() => {
          setShowConfirmationForDiscard(false);
        }}
        positiveText="Bestätigen"
        negativeText="Abbrechen"
        visible={showConfirmationForDiscard}
        onConfirm={async () => {
          await handleConfirmDiscardClick();
        }}
      />

      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      <PbCard px={2} pt={2}>
        <CardContent>
          <Box sx={{ width: "100%", opacity: debounce ? "0.5" : "1" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }

                if (index == 0 || index == 1 || index == 2 || index == 3) {
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                } else {
                  return <div></div>;
                }
              })}
            </Stepper>

            {activeStep === 1 ? (
              <React.Fragment></React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <br />
                {activeStep === 0 ? (
                  // <GoodReceiptDocumentListing inboundDeliveryDocuments={hardCodedDocs} />) :
                  <div>
                    <InboundDeliveryDetail
                      inboundDeliveryDetail={inboundDelivery}
                      updateInboundDelivery={handleSetInboundDeliveryValue}
                      notifyUploadCompleted={notifyUploadCompleted}
                      showUploadAttemptMessage={showUploadAttemptMessage}
                    />
                  </div>
                ) : activeStep === 1 ? (
                  <InboundDeliveryItemDetail
                    //items={inboundDelivery}
                    item={inboundDelivery}
                    onAddItem={onAddNewInboundDeliveryItem}
                    onEditItem={onAddEditInboundDeliveryItem}
                    onDeleteItem={onDeleteInboundDeliveryItem}
                  />
                ) : activeStep === 2 ? (
                  <GoodReceiptHUAssign
                    selectedHU={selectedHU}
                    inboundDelivery={inboundDelivery}
                    binId={destinationBinId}
                    hus={HUs}
                    onAddHU={AddHU}
                    onEditHU={EditHU}
                    onDeleteHU={DeleteHU}
                    onChangeSelectedHU={onChangeSelectedHU}
                    assignedItems={assignedItems}
                    onEditAssignedItems={onEditAssignedItems}
                  />
                ) : activeStep === 3 ? (
                  <GoodReceiptPrintComponent
                    task={ConvertAssigneditemToWarehouseTask()}
                  />
                ) : activeStep === 4 ? (
                  <InboundDeliveryItemAddNewItem
                    inboundDelivery={inboundDelivery}
                    onInboundDeliveryItemAdded={onNewInboundDeliveryItemAdded}
                    afsStockTypeId={afsStockTypeId}
                  />
                ) : (
                  // <GoodReceiptHUListing
                  //   binId={destinationBinId}
                  //   hus={HUs}
                  //   onAddHU={AddHU}
                  //   onEditHU={EditHU}
                  //   onDeleteHU={DeleteHU}
                  //   onChangeSelectedHU={onChangeSelectedHU}
                  // />
                  <div></div>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {activeStep === 1 ? (
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      {display_cancel}
                    </Button>
                  ) : activeStep === 2 ? (
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={handleBackFromAssignmentStep}
                      sx={{ mr: 1 }}
                    >
                      {display_cancel}
                    </Button>
                  ) : activeStep === 3 ? (
                    <div></div>
                  ) : activeStep === 4 ? (
                    <Button
                      variant="contained"
                      color="inherit"
                      //onClick={handleBackFromHUAssignStep}
                      onClick={handleBackFromInboundDeliveryAddEditItemStep}
                      sx={{ mr: 1 }}
                    >
                      {display_cancel}
                    </Button>
                  ) : (
                    <div></div>
                  )}

                  <Box sx={{ flex: "1 1 auto" }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}

                  {activeStep === 0 ? (
                    <Button
                      variant="contained"
                      onClick={handleUpdateInboundDeliveryGeneralDetail}
                    >
                      {display_next}
                    </Button>
                  ) : activeStep === 1 ? (
                    <Button
                      variant="contained"
                      onClick={handleNextFromInboundDeliveryItemStep}
                    >
                      {display_next}
                    </Button>
                  ) : activeStep === 2 ? (
                    <Button
                      variant="contained"
                      onClick={handleNextFromHUAssignStep}
                    >
                      {display_post}
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </CardContent>
      </PbCard>

      <Snackbar
        style={{ display: "none" }}
        open={snackBarOpen}
        autoHideDuration={snackBarDuration}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackBarContent}
        </Alert>
      </Snackbar>

      {/* <br />
      <br />
      <div>
        <Typography variant="subtitle1" color="textSecondary">
          (for debugging)
          <br />
          <Typography variant="body2">
            {JSON.stringify(inboundDelivery.inboundDeliveryDocuments)}
          </Typography>
        </Typography>
      </div> */}
    </Page>
  );
}

export default GoodReceiptDetailPage;
