import React, { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Label,
  PropaneSharp,
} from "@mui/icons-material";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { generateRandomGuid, guid } from "types/guid";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { useHandlingUnitService } from "services/HandingUnitService";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { useParams } from "react-router-dom";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huCreateRequestV11Dto } from "interfaces/v11/hu/huCreateRequestV11Dto";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { transportAssignmentV11Detail } from "interfaces/v11/transport/transportV11Detail";
import { searchTransportUnitV11Response } from "interfaces/v11/transport/searchTransportUnitV11Response";
import { AlertBoxType } from "components/platbricks/shared/AlertBox";

interface ShipmentTransportUnitListingProp {
  assignments: searchTransportUnitV11Response[];
  onDeleteAssignment: (id: string) => void;
}

const ShipmentTransportUnitListing: React.FC<
  ShipmentTransportUnitListingProp
> = ({ assignments, onDeleteAssignment }) => {
  const display_NoAssignment: string = "Keine Zuordnungen.";
  const display_totalTransportUnitAdded: string = "Anzahl: ";

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [snackBarDuration, setSnackBarDuration] = useState(1000);
  const [snackBarContent, setSnackBarContent] = useState("default");
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };
  function showSnackBar(content: string, type: AlertBoxType) {
    setSnackBarSeverity(type);
    setSnackBarContent(content);
    setSnackBarOpen(true);
  }

  useEffect(() => {
    //console.log("assignments", assignments);
    showSnackBar("added", AlertBoxType.success);
  }, [assignments]);

  function getSecondaryDisplayText(
    input: searchTransportUnitV11Response
  ): string {
    if (input.linkedHuName != undefined && input.linkedHuName.length > 0) {
      let result: string = "HU: " + input.linkedHuName;
      if (input.linkedHuType != undefined && input.linkedHuType.length > 0) {
        result = result + " (" + input.linkedHuType + ")";
      }
      return result;
    }
    return "";
  }

  const numberToString = (index: number) => {
    const paddedString = String(index).padStart(2, "0");
    return paddedString;
  };

  const handleDelete = (id: string) => {
    onDeleteAssignment(id);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {assignments.length <= 0 ? (
        <div>{display_NoAssignment}</div>
      ) : (
        <div></div>
      )}
      <List>
        {assignments.map((item, index) => (
          // <ListItem key={item.wmsId} button onClick={() => handleEditItemClick(item)}
          <ListItem key={item.id}>
            <ListItemText
              primary={
                numberToString(index + 1) +
                ". " +
                "Transport Unit: " +
                item.transportUnitNumber
              }
              secondary={getSecondaryDisplayText(item)}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => handleDelete(item.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <div hidden={true}>
        <div>{JSON.stringify(assignments)}</div>
        <br />
        <Snackbar
          style={{ display: "none" }}
          open={snackBarOpen}
          autoHideDuration={snackBarDuration}
          onClose={handleSnackBarClose}
        >
          <Alert
            onClose={handleSnackBarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackBarContent}
          </Alert>
        </Snackbar>
      </div>
    </Box>
  );
};

export default React.memo(ShipmentTransportUnitListing);
