import React, { useEffect, useState } from "react";
import { _InboundDeliveryDocumentDetailsV11Dto } from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { WarehouseTaskV2RequestDto } from "interfaces/v2/warehouseTask/warehouseTaskV2RequestDto";
import { useSettingService } from "services/SettingService";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { useParams } from "react-router-dom";
import { usePrintService } from "services/PrintService";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import { PostTranspotStopGoodIssueDebugMessage } from "./ShipmentDetailPage";
import { printConsignmentNoteRequestV11Dto } from "interfaces/general/print/printConsignmentNoteRequestV11Dto";

type ShipmentPrintComponentProp = {
  transportId: string;
  printServerId: string;
  printerId: string;
  goodIssueResults: PostTranspotStopGoodIssueDebugMessage[];
  //printing: boolean;
};

const ShipmentPrintComponent: React.FC<ShipmentPrintComponentProp> = (prop) => {
  const display_PrinterSettingNotConfigured: string =
    "Die Druckereinstellungen sind nicht konfiguriert.  ";
  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";

  // const display_printMsgLine1: string =
  //   "Die Buchung wurde erfolgreich abgeschlossen. ";
  const display_printMsgLine2_failed: string = "0 Frachtbrief wurde gedruckt. ";
  const display_printMsgLine2_success: string =
    "1 Frachtbrief wurde gedruckt. ";
  const display_postGoodIssuedCompleted: string =
    "Alle Transporteinheiten wurden erfolgreich verbucht. ";

  const { locationId, id } = useParams();
  const settingService = useSettingService();
  const printService = usePrintService();

  const [printServer, setPrintServer] = useState("");
  const [printer, setPrinter] = useState("");
  const [printServerId, setPrintServerId] = useState("");
  const [printerId, setPrinterId] = useState("");

  const [printSuccess, setPrintSuccess] = useState(false);

  useEffect(() => {
    settingService
      .getLocationSetting(locationId as string)
      .then((result) => {
        if (result) {
          if (result.shipmentSetting?.printServerId != undefined) {
            setPrintServerId(result.shipmentSetting?.printServerId);
          }
          if (result.shipmentSetting?.printerId != undefined) {
            setPrinterId(result.shipmentSetting?.printerId);
          }
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      })
      .catch(() => {
        displayNotification(display_SomethingWentWrong);
      });
  }, [settingService]);

  const [success, setSuccess] = useState<number>(0);
  const [failed, setFailed] = useState<number>(0);

  function printConsignmentNote() {
    console.log("printConsignmentNote", prop);

    var successStops = prop.goodIssueResults.filter(
      (x) => x.message === "success"
    );
    var failedStops = prop.goodIssueResults.filter(
      (x) => x.message != "success"
    );
    const successCount: number =
      successStops != undefined ? successStops.length : 0;
    const failedCount: number =
      failedStops != undefined ? failedStops.length : 0;
    setSuccess(successCount);
    setFailed(failedCount);

    if (prop.transportId.length <= 0) {
      displayNotification(display_SomethingWentWrong);
      return;
    }

    if (prop.printServerId.length <= 0 || prop.printerId.length <= 0) {
      //displayNotification(display_PrinterSettingNotConfigured);
      return;
    }

    //var successLine: string = "Good Issue ";
    //displayNotification("done");
    let request: printConsignmentNoteRequestV11Dto = {
      transportId: prop.transportId,
      printServerId: prop.printServerId,
      printerId: prop.printerId,
    };

    printService
      .printConsignmentNote(request)
      .then((result) => {
        console.log("printResult", result);
        setPrintSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.errorMessage != undefined && err.errorMessage.length > 0) {
          displayNotification(err.errorMessage);
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      });
  }

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  useEffect(() => {
    printConsignmentNote();
  }, [prop]);

  return (
    <div>
      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />
      <div>
        <AlertBox
          alertType={AlertBoxType.success}
          contentText={display_postGoodIssuedCompleted}
        />
      </div>
      <br />
      <div>
        <div>
          {"Warenausgang erfolgreich für Transporteinheiten: " + success}
        </div>
        <br />
        <div>
          {"Warenausgang nicht erfolgreich für Transporteinheiten: " + failed}
        </div>
        <br />
        <div>
          {printSuccess
            ? display_printMsgLine2_success
            : display_printMsgLine2_failed}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShipmentPrintComponent);
