import Page from "components/platbricks/shared/Page";
import { useParams } from "react-router-dom";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Paper,
  TextField,
  styled,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { PbCard } from "components/platbricks/shared";
import { useTransportService } from "services/TransportService";
import {
  transportStopTime,
  transportV11Detail,
} from "interfaces/v11/transport/transportV11Detail";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import Scanner from "components/platbricks/shared/Scanner";
import { useHandlingUnitService } from "services/HandingUnitService";
import { huSearchRequestV2Dto } from "interfaces/v2/hu/huSearchRequestV2Dto";
import { searchTransportUnitV11Request } from "interfaces/v11/transport/searchTransportUnitV11Request";
import { HuV2Dto } from "interfaces/v2/hu/huV2Dto";
import { searchTransportUnitV11Response } from "interfaces/v11/transport/searchTransportUnitV11Response";
import { transportUnitAssignmentV11Request } from "interfaces/v11/transport/transportUnitAssignmentV11Request";
import ShipmentTransportUnitListing from "./ShipmentTransportUnitListing";
import { ConfirmationDialog } from "components/platbricks/shared/ConfirmationDialog";
import { transportStopPostGoodIssueV11Request } from "interfaces/v11/transport/transportStopPostGoodIssueV11Request";
import { useSettingService } from "services/SettingService";
import { usePrintService } from "services/PrintService";
import ShipmentPrintComponent from "./ShipmentPrintComponent";
import { deleteTransportAssignmentV11Request } from "interfaces/v11/transport/deleteTransportAssignmentV11Request";
import { generateRandomGuid, guid } from "types/guid";

export interface PostTranspotStopGoodIssueDebugMessage {
  transportStopId: string;
  transportStopName: string;
  message: string;
}

const ShipmentDetailPage = () => {
  const maxLength: number = 30;
  const padding: number = 3;
  const { locationId, id } = useParams();
  const display_Shipment: string = "Verladen";

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [snackBarDuration, setSnackBarDuration] = useState(2000);
  const [snackBarContent, setSnackBarContent] = useState("default");
  const handleSnackBarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };
  function showSnackBar(content: string, type: AlertBoxType) {
    setSnackBarSeverity(type);
    setSnackBarContent(content);
    setSnackBarOpen(true);
  }

  const display_PostGoodIssueFailed: string =
    "Warenausgang kann nicht gebucht werden, folgende Transporteinheiten erzeugen Probleme";
  const display_TerminateTransport: string = "Transport abschließen";
  const display_Finding: string = "Finding Transport";
  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";
  const display_TransportUnitAssigned: string = "Transporteinheiten";
  const display_ScanInstruction: string = "HU scannen.";
  const display_ScanHuInstruction: string =
    "HU scannen, um die Transporteinheiten der Verladung hinzuzufügen.";
  const display_totalTransportUnitAdded: string = "Anzahl: ";

  const display_HUNotExitedPlaceHolder: string = "{huNumber}";
  const display_HUNotExisted: string =
    display_HUNotExitedPlaceHolder + " existiert nicht. ";

  const breadcrumbs = [{ label: "Home", to: "/" }, { label: "Verladen" }];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const transportService = useTransportService();
  const huService = useHandlingUnitService();
  const settingService = useSettingService();
  const printService = usePrintService();

  const [debounce, setDebounce] = useState<boolean>(false);
  useEffect(() => {
    //console.log("debounce " + new Date(), debounce);
  }, [debounce]);

  //notification dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  const [transportId, setTransportId] = useState(id);
  const [transport, setTransport] = useState<transportV11Detail | null>(null);
  const [issued, setIssued] = useState<boolean>(false);
  const [transportAssignments, setTransportAssignments] = useState<
    searchTransportUnitV11Response[]
  >([]);
  useEffect(() => {
    if (transportId) {
      loadTransport(transportId);

      settingService
        .getLocationSetting(locationId as string)
        .then((result) => {
          if (result) {
            if (result.shipmentSetting?.printServerId != undefined) {
              setShipmentPrintServerId(result.shipmentSetting?.printServerId);
            }
            if (result.shipmentSetting?.printerId != undefined) {
              setShipmentPrinterId(result.shipmentSetting?.printerId);
            }
          } else {
            displayNotification(display_SomethingWentWrong);
          }
        })
        .catch(() => {
          displayNotification(display_SomethingWentWrong);
        });
    }
  }, [transportService, transportId]);

  function loadTransport(transportId: string) {
    transportService
      .getTransport(transportId)
      .then((result) => {
        setTransport(result);
      })
      .catch((err) => {
        console.log(err);
        displayNotification(display_SomethingWentWrong);
      });
  }

  //printer
  var showPrinterNotConfiguredWarning: boolean = true;
  const display_printerNotConfigured: string =
    "Die Druckereinstellungen sind nicht konfiguriert.  ";
  const [shipmentPrintServerId, setShipmentPrintServerId] = useState("");
  const [shipmentPrinterId, setShipmentPrinterId] = useState("");
  const [giResults, setGiResults] = useState<
    PostTranspotStopGoodIssueDebugMessage[]
  >([]);
  const [printing, setPrinting] = useState<boolean>(false);

  //scan HU
  const [scanningHU, setScanningHU] = useState<boolean>(false);

  const display_submit: string = "Weiter";
  const display_ScanHUInvalid: string = "Scanned Item does not match HU";
  const display_HU: string = "HU";
  const display_ScanHU: string = "Scannen (HU)";
  const display_ScanningHU: string = "Scan abbrechen (HU)";
  const [scannedHU, setScannedHU] = useState<string>("");
  function resetScanHU() {
    setScannedHU("");
  }
  function scanHUStarted() {
    resetScanHU();
    setScanningHU(true);
  }
  function scanHUCompleted(scannedValue: string) {
    setScanningHU(false);
    setSourceText(scannedValue);
    setScannedHU(scannedValue);
  }
  function scanHUCancelled() {
    setScanningHU(false);
  }

  // const [snackBarOpen, setSnackBarOpen] = useState(false);
  // const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  // const [snackBarDuration, setSnackBarDuration] = useState(1000);
  // const [snackBarContent, setSnackBarContent] = useState("default");
  // const handleSnackBarClose = (
  //   event: React.SyntheticEvent | Event,
  //   reason?: string
  // ) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setSnackBarOpen(false);
  // };
  // function showSnackBar(content: string, type: AlertBoxType) {
  //   setSnackBarSeverity(type);
  //   setSnackBarContent(content);
  //   setSnackBarOpen(true);
  // }

  const GOOD_ISSUE_CODE: string = "GOODS_ISSUE";
  const GOOD_ISSUE_COMPLETE: string = "COMPLETED";
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (transport && transport.transportAssignments) {
          if (transport.transportAssignments.length > 0) {
            //transport unit
            const transportUnitIds = transport.transportAssignments.map(
              (item) => item.transportUnitId
            );

            let request: searchTransportUnitV11Request = {
              ids: transportUnitIds,
              locationId: transport.locationId,
              linkedOutboundDeliveryHuIds: [],
              linkedInboundDeliveryHuIds: [],
              linkedHuIds: [],
            };

            await transportService
              .searchTransportUnit(request)
              .then((transportUnitResults) => {
                //console.log("transportUnitResults", transportUnitResults);
                setTransportAssignments(transportUnitResults);
                //showSnackBar("added", AlertBoxType.success);
              })
              .catch((searchTransportUnitErr) => {
                console.log("searchTransportUnitErr", searchTransportUnitErr);
              });
          } else {
            setTransportAssignments([]);
          }
        }

        var hasBeenIssued: boolean = false;
        if (transport && transport.transportStatuses) {
          if (transport.transportStatuses.length > 0) {
            var goodIssueStatusCodes = transport.transportStatuses.filter(
              (x) =>
                x.code === GOOD_ISSUE_CODE && x.value === GOOD_ISSUE_COMPLETE
            );
            if (goodIssueStatusCodes && goodIssueStatusCodes.length > 0) {
              hasBeenIssued = true;
            }
          }
        }
        setIssued(hasBeenIssued);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [transport]);

  useEffect(() => {
    if (scannedHU && scannedHU.length > 0) {
      searchHU(scannedHU);
    }
  }, [scannedHU]);

  const [sourceText, setSourceText] = useState<string>("");
  //const [sourceText2, setSourceText2] = useState<string>("");
  const handleSourceTextChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setSourceText(value);
  };
  // const handleSourceText2Changed = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { name, value } = event.target;
  //   setSourceText2(value);
  // };
  function handleSourceTextKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (event.key === "Enter") {
      setScannedHU(sourceText);
    }
  }
  // const handleSourceTextButtonClick = useCallback(async () => {
  //   setScannedHU(sourceText);
  // }, [sourceText]);

  function handleSourceTextButtonClick() {
    setScannedHU(sourceText);
  }

  function resetScannedHU() {
    setSourceText("");
    setScannedHU("");
  }

  function searchHU(huNumber: string) {
    if (searchHU.length > 0) {
      if (debounce) {
        return;
      }
      setDebounce(true);

      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(huNumber);

      let searchDto: huSearchRequestV2Dto;
      searchDto = {
        name: huNumber,
      };

      huService
        .searchHandlingUnit(searchDto)
        .then((result) => {
          if (result.length == 0) {
            setDebounce(false);
            displayNotification(
              display_HUNotExisted.replace(
                display_HUNotExitedPlaceHolder,
                huNumber
              )
            );
          } else if (result.length == 1) {
            setDebounce(false);
            searchTransportUnitWithHU(result[0]);
          } else {
            setDebounce(false);
            displayNotification("Found More than 1 HU. ");
          }
        })
        .catch((response) => {
          setDebounce(false);
          displayNotification("Something went wrong when search HU");
        });
    }
  }

  function searchTransportUnitWithHU(hu: HuV2Dto) {
    if (debounce) {
      return;
    }
    setDebounce(true);

    let linkedHuIds: string[] = [];
    linkedHuIds.push(hu.wmsId);

    const searchRequest: searchTransportUnitV11Request = {
      ids: [],
      locationId: locationId as string,
      linkedOutboundDeliveryHuIds: [],
      linkedInboundDeliveryHuIds: [],
      linkedHuIds: linkedHuIds,
    };
    transportService
      .searchTransportUnit(searchRequest)
      .then((result) => {
        //console.log(result);
        setDebounce(false);
        if (result && result.length > 0) {
          if (result.length != 1) {
            displayNotification(
              "Es existiert mehr als eine Transporteinheit mit der Nummer " +
                hu.handlingUnit +
                "."
            );
          } else {
            // displayNotification(
            //   "Transport Unit found: " + result[0].transportUnitNumber
            // );
            assignTransportUnit(transport!, result[0]);
          }
        } else {
          displayNotification(
            "Transport Unit linked not found for " + hu.handlingUnit
          );
        }
      })
      .catch((response) => {
        console.log(response);
        setDebounce(false);
        displayNotification(display_SomethingWentWrong);
      });
  }

  function assignTransportUnit(
    transport: transportV11Detail,
    transportUnit: searchTransportUnitV11Response
  ) {
    if (debounce) {
      return;
    }
    setDebounce(true);
    if (
      transport.transportStops == undefined ||
      transport.transportStops.length <= 0
    ) {
      displayNotification(
        "No Transport stop. Cannot perform Transport Unit assignment. "
      );
      setDebounce(false);
      return;
    }

    let transportStopId = transport.transportStops[0].id;

    const request: transportUnitAssignmentV11Request = {
      transportUnitId: transportUnit.id,
      loadingStopId: transportStopId,
    };

    transportService
      .assignTransportUnit(transport.id, request)
      .then((result) => {
        //console.log(result);
        //displayNotification("OK. Reload the Transport. ");
        setDebounce(false);
        resetScannedHU();
        loadTransport(transport.id);
      })
      .catch((err) => {
        setDebounce(false);
        console.log(err);
        if (err.errorMessage) {
          displayNotification(err.errorMessage);
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      });
  }
  function promptConfirmationToPostGoodIssue() {
    //console.log("transport", transport);
    if (
      transport!.transportStops == undefined ||
      transport!.transportStops.length <= 0
    ) {
      displayNotification(
        "No Transport Stop identified. Cannot perform Good Issue. "
      );
      return;
    }
    setShowConfirmationText(
      "Möchten Sie den Warenausgang für alle hinzugefügten Transporteinheiten jetzt buchen?"
    );
    setShowConfirmation(true);
  }
  // function testUpdate() {
  //   postGoodIssueProcessingOnLoadingEndTime();
  // }

  //confirmation dialog
  const [showConfirmationText, setShowConfirmationText] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleConfirmPostGoodIssueClick = async () => {
    postGoodIssue();
  };

  let goodIssueResults: PostTranspotStopGoodIssueDebugMessage[] = [];
  function postGoodIssue() {
    if (debounce) {
      return;
    }
    setDebounce(true);
    if (
      transport!.transportStops == undefined ||
      transport!.transportStops.length <= 0
    ) {
      setDebounce(false);
      displayNotification(
        "No Transport Stop identified. Cannot Post Good Issue. "
      );
      return;
    }

    console.log("tp stop length", transport!.transportStops.length);
    goodIssueResults = [];

    transport!.transportStops.forEach((stop) => {
      //console.log("Good Issue for Stop", stop);
      const request: transportStopPostGoodIssueV11Request = {
        transportStopId: stop.id,
        //transportStopId: "31771497-a7e3-4374-1150-08dbda8bf539",
      };

      let giResult: PostTranspotStopGoodIssueDebugMessage = {
        transportStopId: stop.id,
        transportStopName: stop.transportStopNumber,
        message: "",
      };

      transportService
        .postGoodIssueForTransportStop(request)
        .then((result) => {
          console.log("in result");
          setDebounce(false);
          setShowConfirmation(false);
          // if (
          //   result.errorMessage != undefined &&
          //   result.errorMessage.length > 0
          // ) {
          //   giResult.message = result.errorMessage;
          // } else if (result.message != undefined && result.message.length > 0) {
          //   giResult.message = result.message;
          // } else if (
          //   result.errorMessages != undefined &&
          //   result.errorMessages.length > 0
          // ) {
          //   giResult.message = result.errorMessages.join(" ");
          // } else if (
          //   result.status != undefined &&
          //   result.status.length > 0 &&
          //   result.messages != undefined &&
          //   result.messages.length > 0
          // ) {
          //   if (result.status == "success") {
          //     giResult.message = result.status;
          //   } else if (result.status == "Validation_Failed_Return") {
          //     giResult.message = result.messages.toString();
          //   } else {
          //     giResult.message = result.status;
          //   }
          // } else {
          //   giResult.message = "unhandled scenario";
          // }
          if (result.status == "success") {
            giResult.message = result.status;
          } else if (result.status == "Validation_Failed_Return") {
            if (Array.isArray(result.messages!)) {
              // It's a string array
              giResult.message = result.messages!.join("<br/>");
            } else {
              giResult.message = result.messages!;
            }
          } else {
            giResult.message = result.status!;
          }
          updateGoodIssueResults(giResult);
        })
        .catch((err) => {
          console.log("in err");
          setDebounce(false);
          setShowConfirmation(false);
          console.log(err);
          giResult.message = err;
          updateGoodIssueResults(giResult);
        });
    });

    function updateGoodIssueResults(
      giResult: PostTranspotStopGoodIssueDebugMessage
    ) {
      goodIssueResults.push(giResult);
      if (goodIssueResults.length == transport!.transportStops!.length) {
        console.log("goodIssueResults", goodIssueResults);

        var failedMessage = "";
        var failedStops = goodIssueResults.filter(
          (x) => x.message != "success"
        );
        if (failedStops.length > 0) {
          failedMessage = display_PostGoodIssueFailed + "<br/><br/>";
          failedStops.forEach((x) => {
            failedMessage += x.message + "<br/>";
          });
        }

        // var successMessage = "";
        var successStops = goodIssueResults.filter(
          (x) => x.message === "success"
        );
        // successStops.forEach((x) => {
        //   successMessage +=
        //     "Transport Unit " + x.transportStopName + " - success<br/>";
        // });

        //var finalMessage = successMessage + failedMessage;

        if (successStops.length > 0) {
          //printConsignmentNote();
          postGoodIssueProcessingOnLoadingEndTime();
        }

        if (failedMessage.length > 0) {
          displayNotification(failedMessage);
        }
      }
    }
  }

  function postGoodIssueProcessingOnLoadingEndTime() {
    var hardCodedLoadingEndTimeType =
      getHardCodedLoadingEndTimeTypeConfigured();
    if (hardCodedLoadingEndTimeType.length <= 0) {
      displayNotification("LoadingEndTimeType is not configured properly");
      return;
    }

    var firstTransportStopContainLoadingEndDate =
      IsFirstTransportStopContainLoadingEndDate(hardCodedLoadingEndTimeType);
    if (firstTransportStopContainLoadingEndDate) {
      console.log(
        "Loading End Date found for first transport. No need to create Loading End Date. "
      );
      printConsignmentNote();
    } else {
      console.log(
        "Loading End Date not found for first transport. Create Loading End Date. "
      );
      var target = transport!.transportStops!.at(0)!.transportStopTimes;
      if (target == undefined) {
        target = [];
      }

      var dateNow: Date = new Date();
      let time: transportStopTime = {
        id: generateRandomGuid() as string,
        date: dateNow,
        time: dateNow,
        type: hardCodedLoadingEndTimeType,
        category: "0",
      };
      target.push(time);

      transportService
        .updateTransport(transportId!, transport!)
        .then((x) => {
          console.log("updateTransport then", x);
          printConsignmentNote();
        })
        .catch((y) => {
          console.log("updateTransport catch", y);
          displayNotification(display_SomethingWentWrong);
        });
    }
  }

  // function getDate(now:Date): string {
  //   const year = now.getFullYear();
  //   const month = String(now.getMonth() + 1).padStart(2, "0");
  //   const day = String(now.getDate()).padStart(2, "0");
  //   const hours = String(now.getHours()).padStart(2, "0");
  //   const minutes = String(now.getMinutes()).padStart(2, "0");
  //   const seconds = String(now.getSeconds()).padStart(2, "0");

  //   return `${year}${month}${day}${hours}${minutes}${seconds}`;
  // }

  // function getTime(now:Date): string {
  //   const year = now.getFullYear();
  //   const month = String(now.getMonth() + 1).padStart(2, "0");
  //   const day = String(now.getDate()).padStart(2, "0");
  //   const hours = String(now.getHours()).padStart(2, "0");
  //   const minutes = String(now.getMinutes()).padStart(2, "0");
  //   const seconds = String(now.getSeconds()).padStart(2, "0");

  //   return `${year}${month}${day}${hours}${minutes}${seconds}`;
  // }

  function getHardCodedLoadingEndTimeTypeConfigured(): string {
    const result: string = "";
    const hardCodedLoadingEndTimeType =
      process.env.REACT_APP_TRANSPORTSTOPTIME_LOADINGEND != undefined
        ? process.env.REACT_APP_TRANSPORTSTOPTIME_LOADINGEND
        : "";
    return hardCodedLoadingEndTimeType;
  }

  function IsFirstTransportStopContainLoadingEndDate(
    LOAD_END: string
  ): boolean {
    const result: boolean = false;
    if (
      transport &&
      transport.transportStops &&
      transport.transportStops.length > 0
    ) {
      var targetTransportStop = transport.transportStops.at(0);
      if (
        targetTransportStop!.transportStopTimes &&
        targetTransportStop!.transportStopTimes.length > 0
      ) {
        var existingLoadingEndStopTimes =
          targetTransportStop!.transportStopTimes.filter(
            (x) => x.type === LOAD_END
          );
        if (
          existingLoadingEndStopTimes &&
          existingLoadingEndStopTimes.length > 0
        ) {
          return true;
        }
      }
    }
    return result;
  }

  function UpdateTransportLoadingEndDate() {}

  function printConsignmentNote() {
    setGiResults(goodIssueResults);
    setPrinting(true);
  }

  const [assignmentIdToDelete, setAssignmentIdToDelete] = useState("");
  const [showConfirmDeleteAssignmentText, setShowConfirmDeleteAssignmentText] =
    useState("");
  const [showConfirmDeleteAssignment, setShowConfirmDeleteAssignment] =
    useState(false);
  const handleConfirmDeleteAssignmentClick = async () => {
    confirmDeleteAssignment(assignmentIdToDelete);
  };

  function confirmDeleteAssignment(id: string) {
    if (debounce) {
      return;
    }
    setDebounce(true);

    const request: deleteTransportAssignmentV11Request = {
      transportAssignmentId: id,
    };

    transportService
      .deleteTransportAssignment(request)
      .then((x) => {
        setDebounce(false);
        setShowConfirmDeleteAssignment(false);
        setAssignmentIdToDelete("");
        if (x.message != undefined && x.message.length > 0) {
          displayNotification(x.message);
        } else {
          loadTransport(transport!.id);
        }
      })
      .catch((err) => {
        setDebounce(false);
        setShowConfirmDeleteAssignment(false);
        setAssignmentIdToDelete("");
        if (err.errorMessage) {
          displayNotification(err.errorMessage);
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      });
  }

  function deleteAssignment(id: string) {
    var targetTransportUnits = transportAssignments.filter((x) => x.id === id);
    if (targetTransportUnits != undefined && targetTransportUnits.length == 1) {
      var targetTransportUnit = targetTransportUnits.at(0);

      var targetAssignments = transport?.transportAssignments?.filter(
        (x) => x.transportUnitId === targetTransportUnit?.id
      );
      if (targetAssignments != undefined && targetAssignments.length == 1) {
        var targetAssignment = targetAssignments.at(0);
        setAssignmentIdToDelete(targetAssignment!.id);

        if (
          targetTransportUnit!.linkedOutboundDeliveryHuId != undefined &&
          targetTransportUnit!.linkedOutboundDeliveryHuId.length > 0
        ) {
          setShowConfirmDeleteAssignmentText(
            "This Transport Unit has been issued. Are you sure you want to delete this assignment?"
          );
        } else {
          setShowConfirmDeleteAssignmentText(
            "Are you sure you want to delete this assignment?"
          );
        }

        setShowConfirmDeleteAssignment(true);
      } else {
        displayNotification("Cannot identify assignment. ");
      }
    }
  }

  if (!transport) {
    return (
      <Page title={display_Shipment} breadcrumbs={[]} showLoading={true}>
        <div>{display_Finding}</div>
      </Page>
    );
  }

  return (
    <Page
      title={display_Shipment}
      subtitle={transport.transportNumber}
      breadcrumbs={[
        {
          label: "Home",
          to: "/",
        },
        {
          label: display_Shipment,
          to: `/locations/${locationId}/shipments`,
        },
        {
          label: transport.transportNumber,
        },
      ]}
    >
      <PbCard>
        <CardContent>
          <ConfirmationDialog
            contentText={showConfirmationText}
            onClose={() => {
              setShowConfirmation(false);
            }}
            positiveText="Bestätigen"
            negativeText="Abbrechen"
            visible={showConfirmation}
            onConfirm={async () => {
              await handleConfirmPostGoodIssueClick();
            }}
          />

          <ConfirmationDialog
            contentText={showConfirmDeleteAssignmentText}
            onClose={() => {
              setShowConfirmDeleteAssignment(false);
            }}
            positiveText="Bestätigen"
            negativeText="Abbrechen"
            visible={showConfirmDeleteAssignment}
            onConfirm={async () => {
              await handleConfirmDeleteAssignmentClick();
            }}
          />

          <Snackbar
            style={{ display: "none" }}
            open={snackBarOpen}
            autoHideDuration={snackBarDuration}
            onClose={handleSnackBarClose}
          >
            <Alert
              onClose={handleSnackBarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {snackBarContent}
            </Alert>
          </Snackbar>

          <NotificationDialog
            title="Info"
            contentText={notificationText}
            onClose={() => {
              setShowNotification(false);
            }}
            visible={showNotification}
            onConfirm={() => {
              setShowNotification(false);
            }}
          />

          {showPrinterNotConfiguredWarning &&
          (shipmentPrintServerId.length == 0 ||
            shipmentPrinterId.length == 0) ? (
            <div>
              <AlertBox
                alertType={AlertBoxType.info}
                contentText={display_printerNotConfigured}
              />
            </div>
          ) : (
            <div></div>
          )}
          <br />

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12}>
              <Box>
                <div hidden={printing}>
                  <Grid container xs={12}>
                    <Grid sm={7}>
                      <Item>
                        <div>
                          {display_TransportUnitAssigned}{" "}
                          {transportAssignments != undefined &&
                          transportAssignments.length > 0
                            ? " (" +
                              display_totalTransportUnitAdded +
                              transportAssignments.length +
                              ")"
                            : " "}
                        </div>
                        <div style={{ float: "right" }}></div>
                        <ShipmentTransportUnitListing
                          onDeleteAssignment={deleteAssignment}
                          assignments={transportAssignments}
                        />
                      </Item>
                    </Grid>
                    <Grid sm={1}></Grid>
                    <Grid sm={4}>
                      <div>
                        <div>{display_ScanInstruction}</div>
                        <div
                          style={{
                            border: "1px solid grey",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                        >
                          <div>{display_ScanHuInstruction}</div>
                          <Grid item xs={12} sm={12}>
                            <div style={{ padding: padding }}>
                              <TextField
                                id="sourceHU"
                                name="sourceHU"
                                label={display_HU}
                                variant="outlined"
                                value={sourceText}
                                fullWidth={true}
                                onChange={handleSourceTextChanged}
                                inputProps={{ maxLength }}
                                autoComplete="off"
                                onKeyDown={handleSourceTextKeyDown}
                              />
                            </div>
                            <div style={{ padding: padding }}>
                              <Button
                                variant="contained"
                                fullWidth={true}
                                disabled={debounce}
                                onClick={async () => {
                                  await handleSourceTextButtonClick();
                                }}
                              >
                                <div>
                                  <br />
                                  <br />
                                </div>
                                {display_submit}
                              </Button>
                            </div>
                            <div style={{ padding: padding }}>
                              <Scanner
                                startScan={scanningHU}
                                beforeScanText={display_ScanHU}
                                duringScanText={display_ScanningHU}
                                scanCompleted={scanHUCompleted}
                                scanStarted={scanHUStarted}
                                scanCancelled={scanHUCancelled}
                              />
                            </div>
                          </Grid>
                        </div>
                        <br />
                        <div style={{ padding: padding }}>
                          <Button
                            variant="contained"
                            fullWidth={true}
                            disabled={
                              transport.transportStops == undefined ||
                              transport.transportStops.length <= 0 ||
                              transportAssignments.length <= 0 ||
                              debounce ||
                              issued
                            }
                            onClick={promptConfirmationToPostGoodIssue}
                          >
                            <div>
                              <br />
                              <br />
                            </div>
                            {display_TerminateTransport}
                          </Button>
                          {issued ? (
                            <i style={{ color: "red" }}>
                              Transport bereits gebucht
                            </i>
                          ) : (
                            <i></i>
                          )}

                          {/* <Button
                            variant="contained"
                            fullWidth={true}
                            onClick={testUpdate}
                          >
                            test update
                          </Button> */}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div hidden={!printing}>
                  <Grid container xs={12}>
                    <Grid sm={12}>
                      {printing ? (
                        <ShipmentPrintComponent
                          transportId={transport.id}
                          printServerId={shipmentPrintServerId}
                          printerId={shipmentPrinterId}
                          goodIssueResults={giResults}
                        />
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </PbCard>
    </Page>
  );
};

export default ShipmentDetailPage;
