import React from "react";
import axios from "../utils/axios";
import {
  goodReceiptSetting,
  locationSetting,
  movementSetting,
  pickingSetting,
  shipmentSetting,
} from "interfaces/setting/locationSetting";
import { printServerSearchResponseDto } from "interfaces/general/print/printServerSearchResponseDto";
import { printerSearchRequestDto } from "interfaces/general/print/printerSearchRequestDto";
import { printerSearchResponseDto } from "interfaces/general/print/printerSearchResponseDto";

const { createContext, useContext } = React;

interface ISettingService {
  isLocationSettingOk: (locationId: string) => Promise<locationSetting>;
  getLocationSetting: (locationId: string) => Promise<locationSetting>;

  getDefaultGRSetting: () => goodReceiptSetting;
  getDefaultPickingSetting: () => pickingSetting;
  getDefaultShipmentSetting: () => shipmentSetting;
  getDefaultMovementSetting: () => movementSetting;

  updateLocationSetting: (
    locationId: string,
    setting: locationSetting
  ) => Promise<Boolean>;
}

const SettingServiceContext = createContext({} as ISettingService);

export const SettingServiceProvider: React.FC<{
  children?: React.ReactNode;
  isLocationSettingOk?: any;
  getLocationSetting?: any;

  getDefaultGRSetting?: any;
  getDefaultPickingSetting?: any;
  getDefaultShipmentSetting?: any;
  getDefaultMovementSetting?: any;

  updateLocationSetting?: any;
}> = (props) => {
  function updateSettings(settings: locationSetting[]) {
    window.localStorage.setItem("settings", JSON.stringify(settings));
  }

  function getSettings(): locationSetting[] {
    let storedSettingsString = window.localStorage.getItem("settings");
    if (storedSettingsString == null || storedSettingsString.length <= 0) {
      let defaultSettings: locationSetting[] = [];
      updateSettings(defaultSettings);
      return defaultSettings;
    } else {
      return JSON.parse(storedSettingsString);
    }
  }

  function getDefaultSetting(locationId: string): locationSetting {
    const defaultSetting: locationSetting = {
      locationId: locationId,
      goodReceiptSetting: getDefaultGRSetting(),
      pickingSetting: getDefaultPickingSetting(),
      shipmentSetting: getDefaultShipmentSetting(),
      movementSetting: getDefaultMovementSetting(),
    };
    return defaultSetting;
  }

  function getDefaultGRSetting() {
    const goodReceipSetting: goodReceiptSetting = {
      processTypeId: "",
      processTypeName: "",
      destinationBinId: "",
      destinationBinName: "",
      printServer: "",
      printServerId: "",
      printer: "",
      printerId: "",
    };
    return goodReceipSetting;
  }

  function getDefaultPickingSetting() {
    const pickingSetting: pickingSetting = {
      printServer: "",
      printServerId: "",
      printer: "",
      printerId: "",
    };
    return pickingSetting;
  }

  function getDefaultShipmentSetting() {
    const shipmentSetting: shipmentSetting = {
      printServer: "",
      printServerId: "",
      printer: "",
      printerId: "",
    };
    return shipmentSetting;
  }

  function getDefaultMovementSetting() {
    const movementSetting: movementSetting = {
      printServer: "",
      printServerId: "",
      printer: "",
      printerId: "",
    };
    return movementSetting;
  }

  function getSetting(locationId: string): locationSetting {
    let settings: locationSetting[] = getSettings();
    let targetSettings = settings.filter((x) => x.locationId == locationId);
    if (targetSettings == null || targetSettings.length <= 0) {
      //create default setting
      const defaultSetting: locationSetting = getDefaultSetting(locationId);
      settings.push(defaultSetting);
      updateSettings(settings);
      return defaultSetting;
    } else {
      let targetSetting: locationSetting = targetSettings[0];
      return targetSetting;
    }
  }

  const isLocationSettingOk = async (locationId: string) => {
    let result = getSetting(locationId);

    if (
      result.goodReceiptSetting == null ||
      result.goodReceiptSetting.destinationBinId == undefined ||
      result.goodReceiptSetting.destinationBinId.length <= 0 ||
      result.goodReceiptSetting.destinationBinName == undefined ||
      result.goodReceiptSetting.destinationBinName.length <= 0 ||
      result.goodReceiptSetting.processTypeId == undefined ||
      result.goodReceiptSetting.processTypeId.length <= 0 ||
      result.goodReceiptSetting.processTypeName == undefined ||
      result.goodReceiptSetting.processTypeName.length <= 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const getLocationSetting = async (locationId: string) => {
    let result = getSetting(locationId);
    return result;
  };

  const updateLocationSetting = async (
    locationId: string,
    setting: locationSetting
  ) => {
    let settings: locationSetting[] = getSettings();
    let targetSettings = settings.filter((x) => x.locationId == locationId);
    if (targetSettings == null || targetSettings.length <= 0) {
      settings.push(setting);
      updateSettings(settings);
      return true;
    } else {
      const updatedSettings = settings.map((item) => {
        if (item.locationId === locationId) {
          return setting;
        }
        return item;
      });
      updateSettings(updatedSettings);
      return true;
    }
  };

  const value = {
    isLocationSettingOk: props.isLocationSettingOk || isLocationSettingOk,
    getLocationSetting: props.getLocationSetting || getLocationSetting,

    getDefaultGRSetting: props.getDefaultGRSetting || getDefaultGRSetting,
    getDefaultPickingSetting:
      props.getDefaultPickingSetting || getDefaultPickingSetting,
    getDefaultShipmentSetting:
      props.getDefaultShipmentSetting || getDefaultShipmentSetting,
    getDefaultMovementSetting:
      props.getDefaultMovementSetting || getDefaultMovementSetting,

    updateLocationSetting: props.updateLocationSetting || updateLocationSetting,
  };

  return (
    <SettingServiceContext.Provider value={value}>
      {props.children}
    </SettingServiceContext.Provider>
  );
};

export const useSettingService = () => {
  return useContext(SettingServiceContext);
};
