import React, { useEffect, useState } from "react";
import { _InboundDeliveryDocumentDetailsV11Dto } from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { WarehouseTaskV2RequestDto } from "interfaces/v2/warehouseTask/warehouseTaskV2RequestDto";
import { useSettingService } from "services/SettingService";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { useParams } from "react-router-dom";
import { usePrintService } from "services/PrintService";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useOrderService } from "services/OrderService";
import { orderSearchRequestV11Dto } from "interfaces/v11/order/orderSearchRequestV11Dto";
import { OrderStatusEnum } from "interfaces/v11/order/orderV11Dto";

type GoodReceiptPrintComponentProp = {
  task: WarehouseTaskV2RequestDto;
};

type printItem = {
  id: string;
  index: number;
  name: string;
  orderTaskCompleted: boolean;
  orderTask: string;
  searchOrderRetry: number;
  printCompleted: boolean;
  printStatus: string;
  msg: string;
};

const GoodReceiptPrintComponent: React.FC<GoodReceiptPrintComponentProp> = (
  prop
) => {
  const display_TransactionCompleted: string = "Transaktion abgeschlossen";
  const display_PrinterSettingNotConfigured: string =
    "Printer Setting is not configured";
  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";

  const display_printMsgLine1: string =
    "Die Buchung wurde erfolgreich abgeschlossen. ";
  const display_printMsgLine2: string =
    "Es wurden {number of Label} HU Label gedruckt. ";
  const display_printMsgLine2_var1: string = "{number of Label}";

  const { locationId, id } = useParams();
  const SettingService = useSettingService();
  const PrintService = usePrintService();

  const [printServer, setPrintServer] = useState("");
  const [printer, setPrinter] = useState("");
  const [printServerId, setPrintServerId] = useState("");
  const [printerId, setPrinterId] = useState("");
  const [printItems, setPrintItems] = useState<printItem[]>([]);
  let itemToBeProcess: printItem[] = [];
  let itemStageProcessed: printItem[] = [];
  let itemProcessed: printItem[] = [];

  const maxRetry: number = 60;
  const orderService = useOrderService();

  useEffect(() => {
    SettingService.getLocationSetting(locationId as string)
      .then((result) => {
        if (result) {
          if (
            result.goodReceiptSetting?.printServer != undefined &&
            result.goodReceiptSetting?.printServer.length > 0 &&
            result.goodReceiptSetting?.printer != undefined &&
            result.goodReceiptSetting?.printer.length > 0 &&
            result.goodReceiptSetting?.printServerId != undefined &&
            result.goodReceiptSetting?.printServerId.length > 0 &&
            result.goodReceiptSetting?.printerId != undefined &&
            result.goodReceiptSetting?.printerId.length > 0
          ) {
            const server: string = result.goodReceiptSetting?.printServer;
            const printer: string = result.goodReceiptSetting?.printer;
            const serverId: string = result.goodReceiptSetting?.printServerId;
            const printerId: string = result.goodReceiptSetting?.printerId;

            setPrintServer(server);
            setPrintServerId(serverId);
            setPrinter(printer);
            setPrinterId(printerId);

            if (prop.task != null && prop.task.tasks != null) {
              itemToBeProcess = [];
              itemStageProcessed = [];
              itemProcessed = [];
              setPrintItems([]);

              console.log("FindPutAway Start", new Date());

              itemToBeProcess = getUniqueDestinationHU();
              itemToBeProcess.forEach((x) => {
                //Print(x, serverId, printerId);
                FindPutAwayTask(x, serverId, printerId);
              });
            }
          } else {
            displayNotification(display_PrinterSettingNotConfigured);
          }
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      })
      .catch(() => {
        displayNotification(display_SomethingWentWrong);
      });
  }, [SettingService]);

  function PrintInSequence(
    items: printItem[],
    sequenceNow: number,
    serverId: string,
    printerId: string
  ) {
    if (sequenceNow + 1 > items.length) {
      return;
    }
    const x = items[sequenceNow];
    if (x.orderTaskCompleted) {
      PrintService.printHU({
        handlingUnitId: x.id,
        printServerId: serverId,
        printerId: printerId,
      })
        .then((result) => {
          let msg: string = "";
          msg = JSON.stringify(result);
          x.msg = msg;
          x.printCompleted = true;
          UpdatePrintGlobalParameter(x);
          PrintInSequence(items, sequenceNow + 1, serverId, printerId);
        })
        .catch((result) => {
          let errorMsg: string = "";
          if (result.errorMessage != undefined) {
            errorMsg = result.errorMessage;
          } else {
            errorMsg = JSON.stringify(result);
          }

          x.msg = errorMsg;
          x.printStatus = "failed";
          UpdatePrintGlobalParameter(x);
          PrintInSequence(items, sequenceNow + 1, serverId, printerId);
        });
    } else {
      UpdatePrintGlobalParameter(x);
      PrintInSequence(items, sequenceNow + 1, serverId, printerId);
    }
  }

  function Print(x: printItem, serverId: string, printerId: string) {
    if (x.orderTaskCompleted) {
      PrintService.printHU({
        handlingUnitId: x.id,
        printServerId: serverId,
        printerId: printerId,
      })
        .then((result) => {
          let msg: string = "";
          msg = JSON.stringify(result);
          x.msg = msg;
          x.printCompleted = true;
          UpdatePrintGlobalParameter(x);
        })
        .catch((result) => {
          let errorMsg: string = "";
          if (result.errorMessage != undefined) {
            errorMsg = result.errorMessage;
          } else {
            errorMsg = JSON.stringify(result);
          }

          x.msg = errorMsg;
          x.printStatus = "failed";
          UpdatePrintGlobalParameter(x);
        });
    } else {
      UpdatePrintGlobalParameter(x);
    }
  }

  function UpdatePrintGlobalParameter(x: printItem) {
    itemProcessed.push(x);
    //setPrintItems(itemProcessed);
    //console.log("Print Attemp: ", x);
    if (itemProcessed.length == itemToBeProcess.length) {
      console.log("Total Print Attempt):", itemProcessed);
      setPrintItems(itemProcessed);
    }
  }

  const SkipWaitForTask: boolean = false;

  function FindPutAwayTask(x: printItem, serverId: string, printerId: string) {
    //console.log("FindPutAway:" + x.name, new Date());
    if (x.searchOrderRetry != maxRetry) {
      if (SkipWaitForTask) {
        x.orderTask = "Skip";
        x.orderTaskCompleted = true;
        UpdateSearchOrderGlobalParameter(x, serverId, printerId);
      } else {
        let searchDto: orderSearchRequestV11Dto = {
          page: 0,
          pageSize: 1,
          locationId: locationId!,
          orderStatuses: [OrderStatusEnum.OPEN],
          srcHuIds: [x.id],
        };

        orderService
          .getOrderByHandlingUnitId(searchDto)
          .then((orders) => {
            //console.log(orders);
            if (orders.length <= 0) {
              x.searchOrderRetry += 1;
              FindPutAwayTask(x, serverId, printerId);
            } else if (orders.length > 0) {
              x.orderTask = orders[0].orderNumber;
              x.orderTaskCompleted = true;
              UpdateSearchOrderGlobalParameter(x, serverId, printerId);
            }
          })
          .catch((y) => {
            console.log(y);
            x.searchOrderRetry += 1;
            FindPutAwayTask(x, serverId, printerId);
          });
      }
    } else {
      x.orderTask = "Not found";
      x.orderTaskCompleted = false;
      x.printCompleted = false;
      x.printStatus = "-";
      x.msg = "Failed to find Putaway task after " + maxRetry + " tries";
      UpdateSearchOrderGlobalParameter(x, serverId, printerId);
    }
  }

  function UpdateSearchOrderGlobalParameter(
    x: printItem,
    serverId: string,
    printerId: string
  ) {
    itemStageProcessed.push(x);
    if (itemStageProcessed.length == itemToBeProcess.length) {
      itemStageProcessed.sort((a, b) => a.index - b.index);
      console.log("itemStageProcessed:", itemStageProcessed);
      console.log("FindPutAway Finished", new Date());
      // itemStageProcessed.forEach(async (item) => {
      //   Print(item, serverId, printerId);
      // });
      PrintInSequence(itemStageProcessed, 0, serverId, printerId);
    }
  }

  function getCurrentTime(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  function getUniqueDestinationHU(): printItem[] {
    let result: printItem[] = [];

    prop.task.tasks.map((x) => {
      let id: string = x.dest_hu;
      let name: string = x.dest_hu_name != null ? x.dest_hu_name : "";
      let status: boolean = false;

      let existed = result.filter((x) => x.id === id);
      if (existed == null || existed.length <= 0) {
        let temp: printItem;
        temp = {
          id: id,
          index: result.length + 1,
          name: name,
          orderTaskCompleted: false,
          searchOrderRetry: 0,
          orderTask: "",
          printCompleted: false,
          printStatus: "-",
          msg: "Searching for PutAway task",
        };

        result.push(temp);
      }
    });

    return result;
  }

  return (
    <div>
      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />
      <div>
        <AlertBox
          alertType={AlertBoxType.success}
          contentText={display_TransactionCompleted}
        />
      </div>
      <br />
      <div>
        <div>{display_printMsgLine1}</div>
        <br />
        <div>
          {display_printMsgLine2.replace(
            display_printMsgLine2_var1,
            printItems.filter((x) => x.printCompleted).length.toString()
          )}
        </div>
      </div>
      <div
        style={{
          border: "1px solid grey",
          padding: "5px",
          borderRadius: "5px",
        }}
        hidden={
          process.env.REACT_APP_DEBUGMODE == undefined ||
          process.env.REACT_APP_DEBUGMODE == "0"
        }
      >
        <h4>Printing Details</h4>
        <div>Print Server: {printServer}</div>
        <div>Printer: {printer}</div>
        <br />
        <div>
          {printServer.length > 0 && printer.length > 0 ? (
            <div>
              {printItems.length <= 0 ? (
                <div>Processing. Please wait... </div>
              ) : (
                <div>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell align="left">HU</TableCell>
                          <TableCell align="left">PutAway Task</TableCell>
                          <TableCell align="left">Print Status</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {printItems.map((x) => (
                          <TableRow
                            key={x.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {x.index}
                            </TableCell>
                            <TableCell align="left">{x.name}</TableCell>
                            <TableCell align="left">{x.orderTask}</TableCell>
                            <TableCell align="left">{x.printStatus}</TableCell>
                            <TableCell align="right">{x.msg}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          ) : (
            <div>Printer configuraiton is not valid</div>
          )}
        </div>
        {/* <div>
          {apiResult.map((x) => (
            <div>
              <i>{x}</i>
              <br />
            </div>

          ))}
        </div> */}
      </div>

      {/* <div>
        <Typography variant="body2">
          {JSON.stringify(getUniqueDestinationHU())}
        </Typography>
        <br />
      </div> */}
    </div>
  );
};

export default React.memo(GoodReceiptPrintComponent);
