import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { ChartDataset } from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useAzureAppInsightService } from "services/AzureAppInsightService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin
);

const getAlignment = (context: any) => {
  return context.dataset.data[context.dataIndex] <
    Math.max(...context.dataset.data) / 2
    ? "end"
    : "start";
};

const valueFormatter = (value: any) => {
  if (value >= 1000) return (Math.round(value) / 1000).toLocaleString() + " s";
  return Math.round(value).toLocaleString() + " ms";
};

const getChartDataset = (data: number[]) => {
  return {
    label: "Dataset 1",
    backgroundColor: "#AED6F1",
    borderWidth: 0,
    data: data,
    datalabels: {
      align: getAlignment,
      anchor: "end",
      clamp: false,
      clip: false,
    },
  } as ChartDataset<"bar", number[]>;
};

export const AzureAppInsightChart = ({
  performanceThreshold,
}: {
  performanceThreshold: number | undefined;
}) => {
  const AzureAppInsightService = useAzureAppInsightService();
  const [timerange, setTimerange] = useState("PT24H");
  const [data, setData] = useState<ChartData<"bar"> | undefined>({
    datasets: [getChartDataset([])],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isAzureAppInsightConfigured, setIsAzureAppInsightConfigured] =
    useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    setTimerange(event.target.value as string);
  };

  const fetchChart = useCallback(
    async (timerange: string) => {
      try {
        setIsLoading(true);
        const data = await AzureAppInsightService.getAzureAppInsightChart(
          timerange
        );

        if (data?.length > 0) {
          data.sort((a, b) => (a.duration > b.duration ? -1 : 0));
        }

        setData({
          labels: data.map((v, i) => v.operationDisplayName),
          datasets: [getChartDataset(data.map((v, i) => v.duration))],
        });

        setIsAzureAppInsightConfigured(true);
      } catch (error: any) {
        if (error?.errorCode === "NoAppInsightDefined")
          setIsAzureAppInsightConfigured(false);

        setData(undefined);
      } finally {
        setIsLoading(false);
      }
    },
    [AzureAppInsightService]
  );

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Average Duration",
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            "Average Duration: " + valueFormatter(context.raw),
        },
      },
      datalabels: {
        color: "black",
        formatter: valueFormatter,
      },
      annotation: performanceThreshold
        ? {
            annotations: {
              threshold: {
                type: "line",
                borderColor: "red",
                borderWidth: 2,
                label: {
                  backgroundColor: "red",
                  content: performanceThreshold / 1000 + " sec",
                  display: true,
                },
                scaleID: "x",
                value: performanceThreshold,
              },
            },
          }
        : {},
    },
    scales: {
      x: {
        ticks: {
          callback: valueFormatter,
        },
      },
    },
  };

  useEffect(() => {
    async function initialFetchData() {
      await fetchChart(timerange);
    }
    initialFetchData();
  }, [fetchChart, timerange]);

  return (
    <Fragment>
      {isAzureAppInsightConfigured && (
        <Card>
          <Box sx={{ height: "5px" }}>{isLoading && <LinearProgress />}</Box>
          <CardHeader
            action={
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Timerange</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={timerange}
                  label="Timerange"
                  onChange={handleChange}
                >
                  <MenuItem value="PT1H">Last Hour</MenuItem>
                  <MenuItem value="PT12H">Last 12 Hours</MenuItem>
                  <MenuItem value="PT24H">Last 24 Hours</MenuItem>
                  <MenuItem value="P3D">Last 3 Days</MenuItem>
                  <MenuItem value="P7D">Last 7 Days</MenuItem>
                  <MenuItem value="P30D">Last 30 Days</MenuItem>
                </Select>
              </FormControl>
            }
            title="Performance"
          />
          <CardContent>
            {data ? (
              <Bar options={options} data={data} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "50px",
                }}
              >
                Some problems to load data
              </Box>
            )}
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
};
