import React, { useEffect, useState } from "react";
import { TreeView, TreeItem } from "@mui/lab";
import { Badge, Checkbox, Grid } from "@mui/material";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InventoryIcon from "@mui/icons-material/Inventory";
import { WmsTreeNodeDto } from "interfaces/v1/wmsTree/WmsTreeNodeDto";

// Type definitions for the tree nodes
export enum WmsTreeDisplayType {
    bin = "Bin",
    hu = "HU",
    stock = "Stock",
}

// export interface MaterialInfo {
//   materialNumber: string;
//   materialDescription: string;
//   materialQuantity: string;
//   materialUnit: string;
// }
// export interface TreeNode {
//   wmsId: string;
//   display: string;
//   wmsTreeType: wmsTreeType;
//   children?: TreeNode[];
//   MaterialInfo?: MaterialInfo;
// }

interface WmsTreeDisplayProp {
    treeData: WmsTreeNodeDto[];
    highlightType: string;
    highlightText: string;
}

const WmsTreeDisplay: React.FC<WmsTreeDisplayProp> = (
    prop
) => {

    const getAllChildIds = (node: WmsTreeNodeDto): string[] => {
        let childIds: string[] = [];
        if (node.children) {
            for (const childNode of node.children) {
                childIds.push(childNode.entity.wmsId);
                childIds = [...childIds, ...getAllChildIds(childNode)];
            }
        }
        return childIds;
    };

    const renderTree = (nodes: WmsTreeNodeDto[]) => (
        <>
            {nodes.map((node) => (
                <div
                    key={node.entity.wmsId}
                    style={{ padding: "1%", border: "1px solid grey", borderRadius: "3px", margin: "1%" }}
                >
                    {node.entity.type == WmsTreeDisplayType.bin ?
                        <div>
                            <Badge color="secondary" showZero style={{ width: "100%" }}>
                                <ViewInArIcon />
                                <div
                                    style={{
                                        paddingLeft: "10px",
                                        width: "100%",
                                        textAlign: "left",
                                    }}
                                >
                                    {prop.highlightType == "1" && prop.highlightText.length > 0 && prop.highlightText == node.entity.bin ?
                                        <span style={{ fontWeight: "bolder", backgroundColor: "lightgreen" }}>Lagerplatz: {node.entity.bin}</span>
                                        : <span style={{ fontWeight: "bolder" }}>Lagerplatz: {node.entity.bin}</span>}


                                    <br />
                                    {node.children &&
                                        node.children.length > 0 &&
                                        renderTree(node.children)}
                                </div>
                            </Badge>
                        </div> :
                        node.entity.type == WmsTreeDisplayType.hu ?
                            <div>
                                <Badge color="secondary" showZero style={{ width: "100%" }}>
                                    <InventoryIcon />
                                    <div
                                        style={{
                                            paddingLeft: "10px",
                                            width: "100%",
                                            textAlign: "left",
                                        }}
                                    >
                                        <div style={{ width: "100%", display: "flex" }}>
                                            <div style={{ width: "100%", display: "flex" }}>
                                                <div style={{ width: "50%" }}>
                                                    {prop.highlightType == "2" && prop.highlightText.length > 0 && prop.highlightText == node.entity.handlingUnit ?
                                                        <span style={{ fontWeight: "bolder", backgroundColor: "lightgreen" }}>HU: {node.entity.handlingUnit}</span>
                                                        : <span style={{ fontWeight: "bolder" }}>HU: {node.entity.handlingUnit}</span>}
                                                </div>
                                                <div style={{ width: "50%" }}>Type: {node.entity.handlingUnitType}</div>

                                            </div>


                                        </div>

                                        <br />
                                        {node.children &&
                                            node.children.length > 0 &&
                                            renderTree(node.children)}
                                    </div>
                                </Badge>
                            </div> :
                            node.entity.type == WmsTreeDisplayType.stock ?
                                <div>
                                    <Badge color="secondary" showZero style={{ width: "100%" }}>
                                        <LocalOfferIcon />

                                        <div
                                            style={{
                                                paddingLeft: "10px",
                                                width: "100%",
                                                textAlign: "left",
                                            }}
                                        >
                                            <div style={{ width: "100%", display: "flex" }}>
                                                <div style={{ width: "50%" }}>
                                                    {prop.highlightType == "3" && prop.highlightText.length > 0 && prop.highlightText.includes(node.entity.materials!.material) ?
                                                        <span style={{ fontWeight: "bolder", backgroundColor: "lightgreen" }}>
                                                            Material: {node.entity.materials!.material} {"-"} {node.entity.materials!.name}
                                                        </span>
                                                        : <span style={{ fontWeight: "bolder" }}>Material: {node.entity.materials!.material}{"-"} {node.entity.materials!.name}</span>}


                                                </div>
                                                <div style={{ width: "50%" }}>Menge:  {node.entity.quantityAltUnit} {node.entity.altUnit}</div>

                                            </div>
                                            <div style={{ width: "100%", display: "flex" }}>
                                                <div style={{ width: "50%" }}>ZGS: {node.entity.batch}</div>
                                                <div style={{ width: "50%" }}>Baulos: {node.entity.owner}</div>
                                            </div>

                                            {node.children &&
                                                node.children.length > 0 &&
                                                renderTree(node.children)}
                                        </div>
                                    </Badge>
                                </div> : <div>Unknown Node Type</div>}

                </div>

            ))}
        </>
    );

    return (
        <div>
            {/* {prop.treeData.map((item, index) => (
                // <ListItem key={item.wmsId} button onClick={() => handleEditItemClick(item)}
                // <ListItem key={item.id}>
                //     <ListItemText
                //         primary={
                //             numberToString(index + 1) +
                //             ". " +
                //             "Transport Unit: " +
                //             item.transportUnitNumber
                //         }
                //         secondary={getSecondaryDisplayText(item)}
                //     />
                //     <ListItemSecondaryAction>
                //         <IconButton edge="end" onClick={() => handleDelete(item.id)}>
                //             <DeleteIcon />
                //         </IconButton>
                //     </ListItemSecondaryAction>
                // </ListItem>

                <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {item.entity.type == WmsTreeDisplayType.bin ? <div>
                            <Badge color="secondary" showZero style={{ width: "100%" }}>
                                <ViewInArIcon />
                                <div
                                    style={{
                                        paddingLeft: "10px",
                                        width: "100%",
                                        textAlign: "left",
                                    }}
                                >
                                    Bin: {item.entity.bin}
                                </div>
                            </Badge>
                        </div> :
                            item.entity.type == WmsTreeDisplayType.hu ? <div>
                                <Badge color="secondary" showZero style={{ width: "100%" }}>
                                    <LocalOfferIcon />
                                    <div
                                        style={{
                                            paddingLeft: "10px",
                                            width: "100%",
                                            textAlign: "left",
                                        }}
                                    >
                                        HU: {item.entity.handlingUnit}
                                    </div>
                                </Badge>
                            </div> :
                                item.entity.type == WmsTreeDisplayType.stock ? <div>
                                    <Badge color="secondary" showZero style={{ width: "100%" }}>
                                        <InventoryIcon />
                                        <div
                                            style={{
                                                paddingLeft: "10px",
                                                width: "100%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Material: {item.entity.materials!.material}
                                        </div>
                                    </Badge>
                                </div> :

                                    <div>unknown node type</div>}
                    </div>
                    <br />
                    {item.children.map((node, index) => (
                        <div></div>
                    ))}
                    <br />
                    {index}
                    <br />
                    <div>{JSON.stringify(item)}</div>
                </div>


            ))} */}

            {renderTree(prop.treeData)}
        </div>
    )
};

export default WmsTreeDisplay;
