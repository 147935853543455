export const InventoryDocumentMethodEnum = {
  AD_HOC_PHYSICAL_INV_STORAGE_BIN: "AD_HOC_PHYSICAL_INV_STORAGE_BIN",
  CYCLE_COUNT: "CYCLE_COUNT",
  ADHOC_INVENTORY_MATERIAL_ON_BIN: "ADHOC_INVENTORY_MATERIAL_ON_BIN",
  ADHOC_INVENTORY_CONTAINER_ON_BIN: "ADHOC_INVENTORY_CONTAINER_ON_BIN",
  SCRAPPING: "SCRAPPING"
} as const;

export const InventoryDocumentRecountingDifferenceCheckEnum = {
  TWO_IDENTICAL_COUNTINGS: "TWO_IDENTICAL_COUNTINGS",
  NO_RECOUNTING: "NO_RECOUNTING"
} as const;



export type InventoryDocumentMethodEnum =
  typeof InventoryDocumentMethodEnum[keyof typeof InventoryDocumentMethodEnum];
export type InventoryDocumentRecountingDifferenceCheckEnum =
  typeof InventoryDocumentRecountingDifferenceCheckEnum[keyof typeof InventoryDocumentRecountingDifferenceCheckEnum];
