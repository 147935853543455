import React from "react";
import { guid } from "types/guid";
import { SelectAsyncOption } from "../components/platbricks/shared/SelectAsync";
import axios from "../utils/axios";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huCreateRequestV11Dto } from "interfaces/v11/hu/huCreateRequestV11Dto";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { huSearchRequestV11Dto } from "interfaces/v11/hu/huSearchRequestV11Dto";
import { orderSearchRequestV11Dto } from "interfaces/v11/order/orderSearchRequestV11Dto";
import { orderV11Dto } from "interfaces/v11/order/orderV11Dto";
import { confirmTaskRequestV2Dto } from "interfaces/v2/order/confirmTaskRequestV2Dto";
import { confirmTaskResponseV2Dto } from "interfaces/v2/order/confirmTaskResponseV2Dto";
import { getOrderByQueueNameRequestDto } from "interfaces/v1/order/getOrderByQueueNameRequestDto";
import { getOrderByQueueNameResponseDto } from "interfaces/v1/order/getOrderByQueueNameResponseDto";
import { orderTaskChangeDestinationRequestV11Dto } from "interfaces/v11/order/orderTaskChangeDestinationRequestV11Dto";
import { orderTaskChangeDestinationResponseV11Dto } from "interfaces/v11/order/orderTaskChangeDestinationResponseV11Dto";
import { orderTaskV2Dto } from "interfaces/v2/order/orderTaskV2Dto";
import { orderTaskChangeSourceRequestV11Dto } from "interfaces/v11/order/orderTaskChangeSourceRequestV11Dto";
import { orderTaskChangeSourceResponseV11Dto } from "interfaces/v11/order/orderTaskChangeSourceResponseV11Dto";

const { createContext, useContext } = React;

interface IOrderService {
  getOrderTaskById: (orderTaskId: string) => Promise<orderTaskV2Dto>;

  getOrderByHandlingUnitId: (
    searchDto: orderSearchRequestV11Dto
  ) => Promise<orderV11Dto[]>;

  confirmOrderTask: (
    request: confirmTaskRequestV2Dto
  ) => Promise<confirmTaskResponseV2Dto>;

  getOrderByQueueName: (
    request: getOrderByQueueNameRequestDto
  ) => Promise<getOrderByQueueNameResponseDto[]>;

  changeOrderTaskSourceHu: (
    request: orderTaskChangeSourceRequestV11Dto
  ) => Promise<orderTaskChangeSourceResponseV11Dto>;

  changeOrderTaskDestination: (
    request: orderTaskChangeDestinationRequestV11Dto
  ) => Promise<orderTaskChangeDestinationResponseV11Dto>;
}

const OrderServiceContext = createContext({} as IOrderService);

export const OrderServiceProvider: React.FC<{
  children?: React.ReactNode;
  getOrderTaskById?: any;
  getOrderByHandlingUnitId?: any;
  confirmOrderTask?: any;
  getOrderByQueueName?: any;
  changeOrderTaskSourceHu?: any;
  changeOrderTaskDestination?: any;
}> = (props) => {
  const getOrderTaskById = async (id: string) => {
    const res = await axios.get("/v2/OrderTasks/" + id);
    return res.data;
  };

  const getOrderByHandlingUnitId = async (
    searchDto: orderSearchRequestV11Dto
  ) => {
    const res = await axios.post("/v11/orders/search", searchDto);
    return res.data;
  };

  const confirmOrderTask = async (request: confirmTaskRequestV2Dto) => {
    const res = await axios.put("/v2/wmscore/ConfirmOrderTask", request);
    return res.data;
  };

  const getOrderByQueueName = async (
    request: getOrderByQueueNameRequestDto
  ) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;

    var bodyFormData = new FormData();
    bodyFormData.append("configKey", config!);
    bodyFormData.append("amount", request.amount.toString());
    bodyFormData.append("queueName", request.queueName);

    const response = await axios.post(
      "/orders/GetOrderbyQueueName",
      bodyFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    //console.log(response);
    return response.data;
  };

  const changeOrderTaskSourceHu = async (
    request: orderTaskChangeSourceRequestV11Dto
  ) => {
    const res = await axios.post(
      "/v11/order-tasks/" + request.orderTaskId + "/switch-source-hu",
      request
    );
    console.log(res);
    return res.data;
  };

  const changeOrderTaskDestination = async (
    request: orderTaskChangeDestinationRequestV11Dto
  ) => {
    const res = await axios.post(
      "/v11/order-tasks/change-task-destination?orderTaskId=" +
      request.orderTaskId +
      "&dstBinId=" +
      request.dstBinId +
      "&dstHuId=" +
      request.dstHuId
    );
    console.log(res);
    return res.data;
  };

  const value = {
    getOrderTaskById: props.getOrderTaskById || getOrderTaskById,
    getOrderByHandlingUnitId:
      props.getOrderByHandlingUnitId || getOrderByHandlingUnitId,
    confirmOrderTask: props.confirmOrderTask || confirmOrderTask,
    getOrderByQueueName: props.getOrderByQueueName || getOrderByQueueName,
    changeOrderTaskSourceHu:
      props.changeOrderTaskSourceHu || changeOrderTaskSourceHu,
    changeOrderTaskDestination:
      props.changeOrderTaskDestination || changeOrderTaskDestination,
  };

  return (
    <OrderServiceContext.Provider value={value}>
      {props.children}
    </OrderServiceContext.Provider>
  );
};

export const useOrderService = () => {
  return useContext(OrderServiceContext);
};
