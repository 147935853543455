import { createInvDocRequest } from "interfaces/v1/inventoryDocument/createInvDocRequest";
import axios from "../utils/axios";
import { WmsTreeNodeDto } from "interfaces/v1/wmsTree/WmsTreeNodeDto";
import { getWmsTreeRequestDto } from "interfaces/v1/wmsTree/getWmsTreeRequestDto";
import { searchStockV11ResquestDto } from "interfaces/v11/stock/searchStockV11RequestDto";
import { searchStockV11ResponseDto } from "interfaces/v11/stock/searchStockV11ResponseDto";
import React from "react";
import { createInvDocResponse } from "interfaces/v1/inventoryDocument/createInvDocResponse";
import { activateInvDocRequest } from "interfaces/v1/inventoryDocument/activateInvDocRequest";
import { activateInvDocResponse } from "interfaces/v1/inventoryDocument/activateInvDocResponse";
import { invDocItem, inventoryDocument } from "interfaces/v1/inventoryDocument/inventoryDocument";
import { countDocRequest } from "interfaces/v3/countDoc/countDocRequest";
import { countDocResponse } from "interfaces/v3/countDoc/countDocResponse";
import WmsMaterialService from "./WmsMaterialService";

const { createContext, useContext } = React;

interface IWmsService {
  getWMSTree: (request: getWmsTreeRequestDto) => Promise<WmsTreeNodeDto[]>;

  findStock: (
    request: searchStockV11ResquestDto
  ) => Promise<searchStockV11ResponseDto[]>;

  getInventoryDocumentByBinId: (binId: string) => Promise<string>;
  createInventoryDocument: (request: createInvDocRequest) => Promise<createInvDocResponse>;
  activateInventoryDocument: (invDocId: activateInvDocRequest) => Promise<activateInvDocResponse>;
  getInventoryDocumentByDocId: (docId: string) => Promise<inventoryDocument>;
  countInventoryDocument: (request: countDocRequest) => Promise<countDocResponse>;
}

const WmsServiceContext = createContext({} as IWmsService);

export const WmsServiceProvider: React.FC<{
  children?: React.ReactNode;
  getWMSTree?: any;
  findStock?: any;
  getInventoryDocumentByBinId?: any;
  createInventoryDocument?: any;
  activateInventoryDocument?: any;
  getInventoryDocumentByDocId?: any;
  countInventoryDocument?: any;
}> = (props) => {
  const getWMSTree = async (request: getWmsTreeRequestDto) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;
    const res = await axios.post(
      "/wmscore/GetWMSTree?configKey=" +
      config! +
      "&locationId=" +
      request.locationId,
      request
    );
    return res.data;
  };

  const findStock = async (request: searchStockV11ResquestDto) => {
    const res = await axios.post("v11/stocks/search", request);
    return res.data;
  };

  const getInventoryDocumentByBinId = async (binId: string) => {
    const res = await axios.get("v3/InvDoc/GetInventoryDocumentIdByBinId?binId=" + binId);
    return res.data;
  };

  const createInventoryDocument = async (request: createInvDocRequest) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;
    const response = await axios({
      method: "post",
      url: "/v3/InvDoc/CreateDoc?year=" + request.year + "&method=" + request.method + "&storageBinId=" + request.storageBinId + "&recountingDifferenceCheck=" + request.recountingDifferenceCheck,
      headers: { configKey: config! },
    });

    return response.data;
  };

  const activateInventoryDocument = async (request: activateInvDocRequest) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;

    var bodyFormData = new FormData();

    bodyFormData.append("invDocId", request.invDocId);

    const response = await axios({
      method: "post",
      url: "/InvDoc/ActivateDoc",
      data: bodyFormData,
      headers: { configKey: config!, "Content-Type": "multipart/form-data" },
    });
    return response.data;
  };


  const getInventoryDocumentByDocId = async (docId: string) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;

    return await axios({
      method: "get",
      url: "/InvDoc/GetDocById?invDocId=" + docId,
      headers: { configKey: config!, "Content-Type": "multipart/form-data" },
    }).then(async (res) => {
      let result = res.data;

      // if (result && result.invDocItem) {
      //   result.invDocItem.forEach(async (item: invDocItem) => {
      //     if (item.materialId) {
      //       item.materialInfo = await WmsMaterialService.getMaterialById_v2(
      //         item.materialId
      //       );
      //     }
      //   });
      // }

      return result;
    });
  };

  const countInventoryDocument = async (request: countDocRequest) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;
    const response = await axios({
      method: "post",
      url: "/v3/InvDoc/CountDoc",
      data: request,
      headers: { configKey: config! },
    });

    if (response.data.messages) {
      if (typeof response.data.messages === "string") {
        response.data.strMessages = response.data.messages;
      }
      else if (typeof response.data.messages === "object") {
        response.data.arrMessages = response.data.messages;
      }
    }
    return response.data;
  };

  const value = {
    getWMSTree: props.getWMSTree || getWMSTree,
    findStock: props.findStock || findStock,
    getInventoryDocumentByBinId: props.getInventoryDocumentByBinId || getInventoryDocumentByBinId,
    createInventoryDocument: props.createInventoryDocument || createInventoryDocument,
    activateInventoryDocument: props.activateInventoryDocument || activateInventoryDocument,
    getInventoryDocumentByDocId: props.getInventoryDocumentByDocId || getInventoryDocumentByDocId,
    countInventoryDocument: props.countInventoryDocument || countInventoryDocument
  };

  return (
    <WmsServiceContext.Provider value={value}>
      {props.children}
    </WmsServiceContext.Provider>
  );
};

export const useWmsService = () => {
  return useContext(WmsServiceContext);
};
