import { AzureAppInsightServiceProvider } from "./AzureAppInsightService";
import { CompanyMenuProvider } from "./CompanyMenuService";
import { InboundDeliveryServiceProvider } from "./InboundDeliveryService";
import { GoodReceiptServiceProvider } from "./GoodReceiptService";
import { OutboundDeliveryServiceProvider } from "./OutboundDeliveryService";
import { TransportServiceProvider } from "./TransportService";
import { UserServiceProvider } from "./UserService";
import { AreaServiceProvider } from "./AreaService";
import { WmsProcessTypeServiceProvider } from "./WmsProcessTypeService";
import { BinAreaAllocationServiceProvider } from "./BinAreaAllocationService";
import { BinServiceProvider } from "./BinService";
import { HandlingUnitServiceProvider } from "./HandingUnitService";
import { Settings } from "@mui/icons-material";
import { SettingServiceProvider } from "./SettingService";
import { PrintServiceProvider } from "./PrintService";
import { OrderServiceProvider } from "./OrderService";
import { WmsServiceProvider } from "./WmsService";
import { QueueServiceProvider } from "./QueueService";

export type ServicesProviderProps = {
  children?: React.ReactNode;
};

export const ServicesProvider: React.FC<ServicesProviderProps> = (props) => {
  return (
    <UserServiceProvider>
      <CompanyMenuProvider>
        <SettingServiceProvider>
          <GoodReceiptServiceProvider>
            <WmsServiceProvider>
              <PrintServiceProvider>
                <HandlingUnitServiceProvider>
                  <OrderServiceProvider>
                    <QueueServiceProvider>
                      <BinServiceProvider>
                        <OutboundDeliveryServiceProvider>
                          <TransportServiceProvider>
                            {props.children}
                          </TransportServiceProvider>
                        </OutboundDeliveryServiceProvider>
                      </BinServiceProvider>
                    </QueueServiceProvider>
                  </OrderServiceProvider>
                </HandlingUnitServiceProvider>
              </PrintServiceProvider>
            </WmsServiceProvider>
          </GoodReceiptServiceProvider>
        </SettingServiceProvider>
      </CompanyMenuProvider>
    </UserServiceProvider>
  );
};
