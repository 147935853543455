import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, useTheme } from "@mui/material";

import SignInComponent from "../../components/auth/SignIn";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Sign In" />
        {theme.palette.mode !== "dark" ? (
          <img
            alt="Platbricks"
            src="/static/img/logo/platbricks.png"
            width="100%"
          />
        ) : (
          <img
            alt="Platbricks"
            src="/static/img/logo/platbricks_light.png"
            width="100%"
          />
        )}

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
