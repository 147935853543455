import { guid } from "types/guid";

export interface MenuTreeItemDetailsV11Dto {
  id: guid;
  name: string;
  urlReact: string;
  iconReact?: string;
  locationId?: guid;
  children: MenuTreeItemDetailsV11Dto[];
  // catalogId: guid;
  menuTreeDisplayType: MenuTreeDisplayType;
}

export enum MenuTreeDisplayType {
  Folder,
  Page,
  Separator,
}
