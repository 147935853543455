import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PropaneSharp,
} from "@mui/icons-material";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { generateRandomGuid, guid } from "types/guid";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { useHandlingUnitService } from "services/HandingUnitService";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { useNavigate, useParams } from "react-router-dom";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huCreateRequestV11Dto } from "interfaces/v11/hu/huCreateRequestV11Dto";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { goodReceiptSetting } from "interfaces/setting/locationSetting";
import { useGoodReceiptService } from "services/GoodReceiptService";
import useAuth from "hooks/useAuth";
import { processTypeSearchV11ResponseDto } from "interfaces/v11/processType/processTypeSearchV11ResponseDto";
import { printServerSearchResponseDto } from "interfaces/general/print/printServerSearchResponseDto";
import { usePrintService } from "services/PrintService";
import { printerSearchResponseDto } from "interfaces/general/print/printerSearchResponseDto";

interface GoodReceiptSettingProp {
  setting: goodReceiptSetting;
  onGoodReceiptSettingEdited: (updatedItem: goodReceiptSetting) => void;
}

interface Option {
  label: string;
  value: string;
}

interface Search {
  search: string;
}

const GoodReceiptSettingComponent: React.FC<GoodReceiptSettingProp> = (
  prop
) => {
  const { locationId } = useParams();
  const [setting, setSetting] = useState(prop.setting);
  const padding: number = 3;
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const display_GoodReceipt: string =
    "Parameter einstellen (Wareneingang & Einlagerung)";
  const display_ProcessType: string = "Prozesstyp";
  const display_DestinationBin: string = "Ziellagerplatz";
  const display_PrintServer: string = "Druckserver";
  const display_Printer: string = "Drucker";
  const display_Edit: string = "Bearbeiten";
  const display_Save: string = "Speichern";
  const display_noOptionMsg: string = "keine Optionen vorhanden";

  const display_somethingWentWrongSearchProcess: string =
    "Something went wrong. Could not find Process. ";
  const display_somethingWentWrongSearchBin: string =
    "Something went wrong. Could not find Bin. ";
  const display_CouldNotGetGRProcessType: string =
    "Something went wrong. Could not get GR Process Type. ";
  const display_CouldNotGetPrintServer: string =
    "Something went wrong. Could not get Print Server. ";
  const display_CouldNotGetPrinter: string =
    "Something went wrong. Could not get Printer. ";

  const GoodReceiptService = useGoodReceiptService();
  const huService = useHandlingUnitService();
  const printService = usePrintService();

  //--bin---
  const [searchBinQuery, setSearchBinQuery] = useState<Search>({
    search: "",
  });
  const [binOptions, setBinOptions] = useState<Option[]>([]);
  const [selectedBinOptions, setSelectedBinOptions] = useState<Option | null>(
    null
  );

  const handleSearchDestinationBinInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchBinQuery({ search: event.target.value });
  };

  function convertBinResultToSelectOptions(
    data: binSearchResponseV11Dto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.wmsId,
      label: item.bin,
    }));
  }

  useEffect(() => {
    if (searchBinQuery.search.length >= 3) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(searchBinQuery.search);

      let searchDto: binSearchRequestV11Dto = {
        locationId: locationId! as string,
        BinNames: [],
        binNameContains: searchTexts,
      };
      huService
        .getBin(searchDto)
        .then((result) => {
          setBinOptions(convertBinResultToSelectOptions(result));
        })
        .catch((response) => {
          if (response.Status == "401") {
            handleSignOut();
          } else {
            console.log(display_somethingWentWrongSearchBin);
          }
        });
    }
  }, [searchBinQuery.search]);

  const handleSelectedBinChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      setting.destinationBinId = option.value;
      setting.destinationBinName = option.label;
      setSelectedBinOptions(option);
    }
  };

  //--process---
  const [searchProcessQuery, setSearchProcessQuery] = useState<Search>({
    search: "",
  });
  const [processOption, setProcessOption] = useState<Option[]>([]);
  const [selectedProcessOption, setSelectedProcessOption] =
    useState<Option | null>(null);

  const handleSearchProcessInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchProcessQuery({ search: event.target.value });
  };

  function convertProcessResultToSelectOptions(
    data: processTypeSearchV11ResponseDto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.wmsProcessTypeId,
      label: item.wmsProcessType,
    }));
  }

  useEffect(() => {
    if (searchProcessQuery.search.length >= 3) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(searchProcessQuery.search);

      GoodReceiptService.getGRProcessType({
        locationId: locationId as guid,
        processNames: searchTexts,
        page: 0,
        pageSize: 20,
      })
        .then((result) => {
          setProcessOption(convertProcessResultToSelectOptions(result));
        })
        .catch((response) => {
          if (response.Status == "401") {
            handleSignOut();
          } else {
            console.log(display_CouldNotGetGRProcessType);
          }
        });
    }
  }, [searchProcessQuery.search]);

  const handleSelectedProcessChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      setting.processTypeId = option.value;
      setting.processTypeName = option.label;
      setSelectedProcessOption(option);
    }
  };

  //--print server---
  const [searchPrintServerQuery, setSearchPrintServerQuery] = useState<Search>({
    search: "",
  });
  const [printServerOption, setPrintServerOption] = useState<Option[]>([]);
  const [selectedPrintServerOption, setSelectedPrintServerOption] =
    useState<Option | null>(null);

  const handleSearchPrintServerInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchPrintServerQuery({ search: event.target.value });
  };

  function convertPrintServerResultToSelectOptions(
    data: printServerSearchResponseDto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.deviceId,
      label: item.printerServerName,
    }));
  }

  useEffect(() => {
    if (searchPrintServerQuery.search.length >= 0) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(searchPrintServerQuery.search);

      printService
        .getPrinterServer()
        .then((result) => {
          setPrintServerOption(convertPrintServerResultToSelectOptions(result));
          setPrinterOption([]);
          setSelectedPrinterOption(null);
        })
        .catch(() => {
          console.log(display_CouldNotGetPrintServer);
        });
    }
  }, [searchPrintServerQuery.search]);

  const handleSelectedPrintServerChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null,
    reason: string
  ) => {
    if (reason && reason == "clear") {
      setSelectedPrinterOption(null);
      setSelectedPrintServerOption(null);
    } else if (option) {
      setting.printServerId = option.value;
      setting.printServer = option.label;
      setSelectedPrintServerOption(option);
    }
  };

  //--printer---
  const [searchPrinterQuery, setSearchPrinterQuery] = useState<Search>({
    search: "",
  });
  const [printerOption, setPrinterOption] = useState<Option[]>([]);
  const [selectedPrinterOption, setSelectedPrinterOption] =
    useState<Option | null>(null);

  const handleSearchPrinterInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchPrinterQuery({ search: event.target.value });
  };

  function convertPrinterResultToSelectOptions(
    data: printerSearchResponseDto[]
  ): Option[] {
    const activeStatus: string = "ACTIVE";
    const activePrinter: printerSearchResponseDto[] = data.filter(
      (x) => x.printerStatus == activeStatus
    );
    console.log(activePrinter);

    return activePrinter.map((item) => ({
      value: item.printerId,
      label: item.printerName,
    }));
  }

  useEffect(() => {
    if (selectedPrintServerOption == null) {
      return;
    }
    if (searchPrinterQuery.search.length >= 0) {
      printService
        .getPrinters({
          printServerId: selectedPrintServerOption.value,
        })
        .then((result) => {
          setPrinterOption(convertPrinterResultToSelectOptions(result));
        })
        .catch(() => {
          console.log(display_CouldNotGetPrinter);
        });
    }
  }, [searchPrinterQuery.search]);

  const handleSelectedPrinterChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null,
    reason: string
  ) => {
    if (reason && reason == "clear") {
      setSelectedPrinterOption(null);
    } else if (option) {
      setting.printerId = option.value;
      setting.printer = option.label;
      setSelectedPrinterOption(option);
    }
  };

  //general
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    if (
      setting.processTypeId != null &&
      setting.processTypeId.length > 0 &&
      setting.processTypeName != null &&
      setting.processTypeName.length > 0 &&
      selectedProcessOption == null
    ) {
      setSelectedProcessOption({
        label: setting.processTypeName,
        value: setting.processTypeId,
      });
    }

    if (
      setting.destinationBinId != null &&
      setting.destinationBinId.length > 0 &&
      setting.destinationBinName != null &&
      setting.destinationBinName.length > 0 &&
      selectedBinOptions == null
    ) {
      setSelectedBinOptions({
        label: setting.destinationBinName,
        value: setting.destinationBinId,
      });
    }

    if (
      setting.printServerId != null &&
      setting.printServerId.length > 0 &&
      setting.printServer != null &&
      setting.printServer.length > 0 &&
      selectedPrintServerOption == null
    ) {
      setSelectedPrintServerOption({
        label: setting.printServer,
        value: setting.printServerId,
      });
    }

    if (
      setting.printerId != null &&
      setting.printerId.length > 0 &&
      setting.printer != null &&
      setting.printer.length > 0 &&
      selectedPrinterOption == null
    ) {
      setSelectedPrinterOption({
        label: setting.printer,
        value: setting.printerId,
      });
    }
  }, [setting]);

  // const handleInputPrinterServerChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { name, value } = event.target;
  //   //setting.printServer = value;
  //   setSetting({ ...setting, printServer: value });
  // };

  const handleInputPrinterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    //setting.printer = value;
    setSetting({ ...setting, printer: value });
  };

  function handleSave(): void {
    setting.processTypeId =
      selectedProcessOption != null ? selectedProcessOption.value : "";
    setting.processTypeName =
      selectedProcessOption != null ? selectedProcessOption.label : "";

    setting.destinationBinId =
      selectedBinOptions != null ? selectedBinOptions.value : "";
    setting.destinationBinName =
      selectedBinOptions != null ? selectedBinOptions.label : "";

    setting.printServerId =
      selectedPrintServerOption != null ? selectedPrintServerOption.value : "";
    setting.printServer =
      selectedPrintServerOption != null ? selectedPrintServerOption.label : "";

    setting.printerId =
      selectedPrinterOption != null ? selectedPrinterOption.value : "";
    setting.printer =
      selectedPrinterOption != null ? selectedPrinterOption.label : "";

    console.log(setting);
    prop.onGoodReceiptSettingEdited(setting);
  }

  //   if (
  //     binOptions &&
  //     binOptions.length <= 0 &&
  //     setting?.destinationBinId != undefined &&
  //     setting?.destinationBinId.length > 0 &&
  //     setting?.destinationBinName != undefined &&
  //     setting?.destinationBinName.length > 0
  //   ) {
  //     setSelectedBinOptions({
  //       label: setting.destinationBinName,
  //       value: setting.destinationBinId,
  //     });
  //   }

  return (
    <Box sx={{ width: "100%" }}>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <h4>{display_GoodReceipt}</h4>
            <div style={{ padding: padding }}>
              <Autocomplete
                value={selectedProcessOption}
                onChange={handleSelectedProcessChange}
                aria-required={true}
                options={processOption}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={display_ProcessType}
                    variant="outlined"
                    onChange={handleSearchProcessInputChange}
                  />
                )}
              />
            </div>
            <div style={{ padding: padding }}>
              <Autocomplete
                value={selectedBinOptions}
                onChange={handleSelectedBinChange}
                aria-required={true}
                options={binOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={display_DestinationBin}
                    variant="outlined"
                    onChange={handleSearchDestinationBinInputChange}
                  />
                )}
              />
            </div>
            <div style={{ padding: padding }}>
              <Autocomplete
                noOptionsText={display_noOptionMsg}
                value={selectedPrintServerOption}
                onChange={handleSelectedPrintServerChange}
                aria-required={false}
                options={printServerOption}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={display_PrintServer}
                    variant="outlined"
                    onChange={handleSearchPrintServerInputChange}
                  />
                )}
              />
            </div>
            <div style={{ padding: padding }}>
              <Autocomplete
                noOptionsText={display_noOptionMsg}
                value={selectedPrinterOption}
                onChange={handleSelectedPrinterChange}
                aria-required={false}
                options={printerOption}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={display_Printer}
                    variant="outlined"
                    onChange={handleSearchPrinterInputChange}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Button variant="contained" fullWidth={true} onClick={handleSave}>
              {display_Save}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default React.memo(GoodReceiptSettingComponent);
