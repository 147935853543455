import Page from "components/platbricks/shared/Page";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useCompanyMenuService } from "services/CompanyMenuService";
import { CompanyMenuDetailsV12Dto } from "interfaces/v12/companyMenus/menuTreesDetails/CompanyMenuDetailV12Dto";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, ButtonBase, Card, CardContent, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PbCard } from "components/platbricks/shared";

interface colorCombination {
  index: number;
  defaultColor: string;
  hoverColor: string;
}

const CustomLandings = () => {
  const [companyMenu, setCompanyMenu] =
    useState<CompanyMenuDetailsV12Dto | null>(null);

  const CompanyMenuService = useCompanyMenuService();

  let combination: colorCombination[] = [];
  combination = [
    {
      index: 0,
      defaultColor: "#e2e6ea",
      hoverColor: "#dae0e5",
    },
    {
      index: 1,
      defaultColor: "#e2e6ea",
      hoverColor: "#dae0e5",
    },
    {
      index: 2,
      defaultColor: "#e2e6ea",
      hoverColor: "#dae0e5",
    },
    {
      index: 3,
      defaultColor: "#e2e6ea",
      hoverColor: "#dae0e5",
    },
    {
      index: 4,
      defaultColor: "#e2e6ea",
      hoverColor: "#dae0e5",
    },
    {
      index: 5,
      defaultColor: "#e2e6ea",
      hoverColor: "#dae0e5",
    },
    {
      index: 6,
      defaultColor: "#e2e6ea",
      hoverColor: "#dae0e5",
    },
  ];

  //Side Effect
  useEffect(() => {
    const fetchData = async () => {
      CompanyMenuService.getLocation().then((companyMenu) => {
        if (companyMenu) {
          setCompanyMenu(companyMenu);
        }
      });
    };

    fetchData();
  }, [CompanyMenuService]);

  const [activeCard, setActiveCard] = useState<number | null>(null);

  const handleCardClick = (index: number, path: string) => {
    //setActiveCard(index);
    navigateTo(path);
  };

  const handleCardHover = (index: number) => {
    setActiveCard(index);
  };

  const handleCardHoverExit = () => {
    setActiveCard(null);
  };

  const navigate = useNavigate();
  function navigateTo(path: string) {
    navigate(path);
  }

  if (
    companyMenu == undefined ||
    companyMenu.menuTrees == undefined ||
    companyMenu.menuTrees.length <= 0
  ) {
    return (
      <Page title="Home" breadcrumbs={[{ label: "Home" }]}>
        <div>No Location Info</div>
      </Page>
    );
  } else {
    return (
      <Page title="Home" breadcrumbs={[{ label: "Home" }]}>
        <div>
          {companyMenu.menuTrees.map((x) => {
            return (
              <div>
                <PbCard>
                  <CardContent>
                    <h3>{x.name}</h3>
                    <Grid spacing={3} xs={12} container>
                      {x.children.map((y, index) => {
                        return (
                          <Grid item xs={4}>
                            <Card
                              onMouseEnter={() => handleCardHover(index)}
                              onMouseLeave={handleCardHoverExit}
                              style={{
                                // width: "100%",
                                // height: "100%",
                                backgroundColor:
                                  activeCard === index
                                    ? combination[index].hoverColor
                                    : combination[index].defaultColor,
                                // color:
                                //   activeCard === index
                                //     ? "rgba(0, 0, 0, 0.87)"
                                //     : "rgba(0, 0, 0, 0.87)",
                              }}
                              onClick={() =>
                                handleCardClick(
                                  index,
                                  "/" +
                                    y.urlReact.replace(
                                      "/:locationId/",
                                      "/" + x.locationId + "/"
                                    )
                                )
                              }
                              elevation={activeCard === index ? 4 : index}
                            >
                              <CardContent>
                                <Button
                                  variant="contained"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "transparent",
                                    //  activeCard === index ? "blue" : "inherit",
                                    color: "inherit",
                                    // activeCard === index ? "white" : "inherit",
                                  }}
                                >
                                  <h2>{y.name}</h2>
                                  <br />
                                  {/* <div>
                                description text description textdescription
                                text description text description text
                              </div> */}
                                </Button>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </CardContent>
                </PbCard>
              </div>
            );
          })}
        </div>
      </Page>
    );
  }
};

export default CustomLandings;
