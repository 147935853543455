import React, { useEffect, useState } from "react";
import { TreeView, TreeItem } from "@mui/lab";
import { Alert, Badge, Button, Checkbox, FormControlLabel, Grid, Snackbar, Switch, SwitchProps, TextField, Typography, styled } from "@mui/material";
import { ExpandMore, ChevronRight, Label } from "@mui/icons-material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InventoryIcon from "@mui/icons-material/Inventory";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { WmsTreeNodeDto } from "interfaces/v1/wmsTree/WmsTreeNodeDto";
import WmsTreeDisplay from "components/platbricks/shared/WmsTreeDisplay";
import { invDocItem, inventoryDocument } from "interfaces/v1/inventoryDocument/inventoryDocument";
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    PropaneSharp,
} from "@mui/icons-material";
import { countDocRequest, task } from "interfaces/v3/countDoc/countDocRequest";
import { generateRandomGuid } from "types/guid";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { AlertBoxType } from "components/platbricks/shared/AlertBox";
import AddHuTaskComponent from "./AddHuTaskComponent";
import { useWmsService } from "services/WmsService";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { X } from "react-feather";
import AddMaterialTaskComponent from "./AddMaterialTaskComponent";
import { ConfirmationDialog } from "components/platbricks/shared/ConfirmationDialog";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import NumberOnlyTextField from "components/platbricks/shared/NumberOnlyTextField";
import WmsMaterialService from "services/WmsMaterialService";
import { current } from "@reduxjs/toolkit";

interface CountTaskComponentProp {
    locationId: string;
    invDoc: inventoryDocument;
    countDocRequest: countDocRequest,
    highlightType: string;
    highlightText: string;
    displayNotification: (message: string) => void;
    countDocCompleted: () => void;
}

const CountTaskComponent: React.FC<CountTaskComponentProp> = (
    prop
) => {

    const [debounce, setDebounce] = useState<boolean>(false);
    useEffect(() => {
        //console.log("debounce " + new Date(), debounce);
    }, [debounce]);




    //notificaiton dialog
    const [notificationText, setNotificationText] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    function displayNotification(message: string) {
        setNotificationText(message);
        setShowNotification(true);
    }



    const [countDocs, setCountDocs] = useState<countDocRequest>(prop.countDocRequest);
    function processAndUpdateCountDoc(input: countDocRequest) {
        if (input && input.tasks && input.tasks.length > 0) {
            var newCopy = JSON.parse(JSON.stringify(countDocs));
            newCopy.tasks.forEach(async (t: task) => {
                if (t.type == "Stock" && t.materialDisplay!.length <= 0 && t.wmsMaterialId.length > 0) {
                    var materialInfo = await WmsMaterialService.getMaterialById(
                        t.wmsMaterialId
                    );

                    t.materialDisplay = materialInfo.material + " - " + materialInfo.name;
                }
            });

            setCountDocs(newCopy);
            showSnackBarC("Items Loaded", AlertBoxType.info);
        }
    }

    useEffect(() => {

    }, [countDocs]);

    const wmsService = useWmsService();
    const GoodReceiptService = useGoodReceiptService();
    const display_CouldNotGetStockType: string = "Could not get AFS Stock Type";
    const [afsStockTypeId, setAfsStockTypeId] = useState<string>("");

    useEffect(() => {
        processAndUpdateCountDoc(prop.countDocRequest);

        GoodReceiptService.getWmsStockType({
            locationId: prop.locationId,
            shortName: "AFS",
        })
            .then((result) => {
                setAfsStockTypeId(result.stockTypeId);
            })
            .catch((response) => {
                displayNotification(display_CouldNotGetStockType);
            });

        setCurrentNode(prop.invDoc.binId!);
        setCurrentNodeDisplay(prop.invDoc.binName!);
    }, [GoodReceiptService]);


    const [currentNode, setCurrentNode] = useState<string>("");
    const [currentNodeDisplay, setCurrentNodeDisplay] = useState<string>("");


    // function AddMockItem(type: string) {
    //     if (type === "HU") {
    //         var mockHuTask = GetMockHu();
    //         var copy = countDocs.tasks.slice();
    //         copy.push(mockHuTask);
    //         countDocs.tasks = copy;

    //         var newCopy = JSON.parse(JSON.stringify(countDocs));
    //         setCountDocs(newCopy);
    //         showSnackBar("HU added", AlertBoxType.success);

    //     }
    //     else if (type === "Stock") {
    //         var mockStockTask = GetMockStock();
    //         var copy = countDocs.tasks.slice();
    //         copy.push(mockStockTask);
    //         countDocs.tasks = copy;

    //         var newCopy = JSON.parse(JSON.stringify(countDocs));
    //         setCountDocs(newCopy);

    //         showSnackBar("Stock added", AlertBoxType.success);

    //     }
    //     else {
    //         displayNotification("Invalid type");
    //     }
    // }
    // function GetMockHu() {
    //     let output: task = {
    //         type: "HU",
    //         huNumber: "1001",
    //         huType: "",
    //         invDocItemId: "",
    //         wmsMaterialId: "",
    //         temId: generateRandomGuid().toString(),
    //         stockUsage: "",
    //         owner: "",
    //         specialStockType: "",
    //         specialStockNumber: "",
    //         customerStockItemNumber: 0,
    //         parentElementId: prop.invDoc.binId!,
    //         materialQuantity: 0,
    //         containerQuantity: 0,
    //         bomValue: "",
    //         huCountResult: "1",
    //         batch: "",
    //         invDocId: prop.invDoc.invDocId,
    //         stockType: "",
    //         parentInvDocItemId: "",
    //         huTypeId: "",
    //         method: 0,
    //         serialNumbers: [],
    //     }
    //     return output;
    // }

    // function GetMockStock() {
    //     let output: task = {
    //         type: "Stock",
    //         huNumber: "",
    //         huType: "",
    //         invDocItemId: "",
    //         wmsMaterialId: "f54c539a-21ac-449a-0a99-08db5ab19ded",
    //         temId: generateRandomGuid().toString(),
    //         stockUsage: "",
    //         owner: "",
    //         specialStockType: "",
    //         specialStockNumber: "",
    //         customerStockItemNumber: 0,
    //         parentElementId: prop.invDoc.binId!,
    //         materialQuantity: 8,
    //         containerQuantity: 8,
    //         bomValue: "STK",
    //         huCountResult: "",
    //         batch: "",
    //         invDocId: prop.invDoc.invDocId,
    //         stockType: afsStockTypeId,
    //         parentInvDocItemId: "",
    //         huTypeId: "",
    //         method: 0,
    //         serialNumbers: [],
    //     }
    //     return output;
    // }


    function handleAddHUClick(): void {
        //prop.displayNotification("Click AddHU!");
        //AddMockItem("HU");
        OpenAddHuDialog();
    }

    function handleAddMaterialClick(): void {
        //prop.displayNotification("Click AddMaterial!");
        //AddMockItem("Stock");
        OpenAddMaterialDialog();
    }

    function postCountResult() {
        if (debounce) {
            return;
        }
        setDebounce(true);

        var materialToDeactivate = countDocs.tasks.filter((x) => x.type == "Stock" && x.huCountResult == "2");
        materialToDeactivate.forEach((x) => {
            x.materialQuantity = 0;
        });

        wmsService.countInventoryDocument(countDocs).then((countInventoryDocumentResult) => {
            setDebounce(false);
            //console.log("countInventoryDocumentResult then", countInventoryDocumentResult);
            if (countInventoryDocumentResult.status && countInventoryDocumentResult.status === "success") {
                //displayNotification("Count Doc Success");
                prop.countDocCompleted();
            }
            else if (countInventoryDocumentResult.status && countInventoryDocumentResult.status == "validation_failed") {
                if (countInventoryDocumentResult.arrMessages && countInventoryDocumentResult.arrMessages.length > 0) {
                    var joinedMsgs = countInventoryDocumentResult.arrMessages.join("<br/>")
                    displayNotification(joinedMsgs);
                }
                else if (countInventoryDocumentResult.strMessages && countInventoryDocumentResult.strMessages.length > 0) {
                    //var joinedMsgs = countInventoryDocumentResult.arrMessages.join("<br/>")
                    displayNotification(countInventoryDocumentResult.strMessages);
                }
                else {
                    throw new Error("Unhandled validation failed message");
                }
            }
            else {
                if (countInventoryDocumentResult.messages && countInventoryDocumentResult.messages.length > 0) {
                    throw new Error(countInventoryDocumentResult.messages);
                }
                else {
                    throw new Error("unhandled scenario");
                }
            }
        }).catch((err) => {
            setDebounce(false);
            console.log("countInventoryDocument err", err);
            if (err.errorMessage && err.errorMessage.length > 0) {
                displayNotification(err.errorMessage);
            }
            else if (err && err.length > 0) {
                displayNotification(err);
            }
            else {
                displayNotification("Something went wrong");
            }
        });
    }

    function handlePostClick(): void {
        setShowConfirmationText("Are you sure you want to post the count result?");
        setShowConfirmation(true);
    }


    function handleConfirmClick(): void {
        //var request = prepareDocCountRequest();
        //prop.countDoc(countDocs);
        setShowConfirmation(false);
        postCountResult();
    }

    const [snackBarCOpen, setSnackBarCOpen] = useState(false);
    const [snackBarCSeverity, setSnackBarCSeverity] = useState("success");
    const [snackBarCDuration, setSnackBarCDuration] = useState(1000);
    const [snackBarCContent, setSnackBarCContent] = useState("default");
    const handleSnackBarCClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        // if (reason === "clickaway") {
        //     return;
        // }
        setSnackBarCOpen(false);
    };
    function showSnackBarC(content: string, type: AlertBoxType) {
        setSnackBarCSeverity(type);
        setSnackBarCContent(content);
        setSnackBarCOpen(true);
    }


    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = useState("success");
    const [snackBarDuration, setSnackBarDuration] = useState(3000);
    const [snackBarContent, setSnackBarContent] = useState("default");
    const handleSnackBarClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };
    function showSnackBar(content: string, type: AlertBoxType) {
        setSnackBarSeverity(type);
        setSnackBarContent(content);
        setSnackBarOpen(true);
    }

    const [showAddHUDialog, setShowAddHUDialog] = useState<boolean>(false);
    function OpenAddHuDialog() {
        setShowAddHUDialog(true);
    }
    function CloseAddHuDialog() {
        setShowAddHUDialog(false);
    }
    function HuAdded(task: task) {
        var taskCopies = countDocs.tasks.slice();

        if (task.parentElementId && task.parentElementId.length > 0 && task.parentElementId != prop.invDoc.binId) {
            var parentIndex = taskCopies.findIndex(obj => obj.invDocItemId === task.parentElementId || obj.temId === task.parentElementId);
            //console.log("parentIndex", parentIndex);
            var temp = addAfter(taskCopies, parentIndex + 1, task);
            taskCopies = temp;
        }
        else {
            taskCopies.push(task);
        }

        //console.log(taskCopies);
        var newCopy = JSON.parse(JSON.stringify(countDocs));
        newCopy.tasks = taskCopies;
        setCountDocs(newCopy);
        showSnackBar("HU added", AlertBoxType.success);
        CloseAddHuDialog();
    }

    useEffect(() => {
        // if (currentNode.length > 0 && currentNode != prop.invDoc.binId!) {
        //     var newCopy = JSON.parse(JSON.stringify(countDocs));
        //     var taskOnCurrentNode = newCopy.tasks.filter((n:task) => n.parentElementId == currentNode);

        // }
    }, [currentNode]);


    function addAfter(array: task[], index: number, newItem: task) {
        return [
            ...array.slice(0, index),
            newItem,
            ...array.slice(index)
        ];
    }

    const [showAddMaterialDialog, setShowAddMaterialDialog] = useState<boolean>(false);
    function OpenAddMaterialDialog() {
        setShowAddMaterialDialog(true);
    }
    function CloseAddMaterialDialog() {
        setShowAddMaterialDialog(false);
    }
    function MaterialAdded(task: task) {
        var taskCopies = countDocs.tasks.slice();
        //taskCopies.push(task);

        if (task.parentElementId && task.parentElementId.length > 0 && task.parentElementId != prop.invDoc.binId) {
            var parentIndex = taskCopies.findIndex(obj => obj.invDocItemId === task.parentElementId || obj.temId === task.parentElementId);
            //console.log("parentIndex", parentIndex);
            var temp = addAfter(taskCopies, parentIndex + 1, task);
            taskCopies = temp;
        }
        else {
            taskCopies.push(task);
        }
        //countDocs.tasks = copy;

        //console.log("countDocs copy", countDocs);
        var newCopy = JSON.parse(JSON.stringify(countDocs));
        newCopy.tasks = taskCopies;
        setCountDocs(newCopy);
        showSnackBar("Material added", AlertBoxType.success);
        CloseAddMaterialDialog();
    }

    function ToggleFound(id: string) {
        //prop.displayNotification("To remove " + id);

        var existingTasks = countDocs.tasks.slice();
        var invDocItemWithSameInvDocItemId = existingTasks.filter((x) => x.invDocItemId === id);
        if (invDocItemWithSameInvDocItemId && invDocItemWithSameInvDocItemId.length == 1) {
            var entryToUpdate = invDocItemWithSameInvDocItemId.at(0);
            var originalValue = entryToUpdate!.huCountResult;
            var UpdateToFound: boolean = originalValue == "1" ? false : true;
            if (!UpdateToFound) {
                entryToUpdate!.huCountResult = "2";
            }
            else {
                entryToUpdate!.huCountResult = "1";
            }

            var newCopy = JSON.parse(JSON.stringify(countDocs));
            newCopy.tasks = existingTasks;
            //if (!UpdateToFound) {
            toggleSubsequentItem(newCopy.tasks, id, UpdateToFound);
            //}

            setCountDocs(newCopy);
            //console.log("2newCopy", newCopy);
            //showSnackBar("2item removed", AlertBoxType.success);

        }
        else {
            var invDocItemWithSameTempId = existingTasks.filter((x) => x.temId === id);
            if (invDocItemWithSameTempId && invDocItemWithSameTempId.length == 1) {

                //invDocItemWithSameTempId.forEach((item) => { item.huCountResult = "2"; });

                var entryToUpdate = invDocItemWithSameTempId.at(0);
                var originalValue = entryToUpdate!.huCountResult;
                var UpdateToFound: boolean = originalValue == "1" ? false : true;
                if (!UpdateToFound) {
                    entryToUpdate!.huCountResult = "2";
                }
                else {
                    entryToUpdate!.huCountResult = "1";
                }

                var newCopy = JSON.parse(JSON.stringify(countDocs));
                newCopy.tasks = existingTasks;
                //if (!UpdateToFound) {
                toggleSubsequentItem(newCopy.tasks, id, UpdateToFound);
                //}
                setCountDocs(newCopy);
                //console.log("2newCopy", newCopy);
            }
            else {
                displayNotification("Error. Failed to identify Item to process. ");
            }
        }
    }

    function toggleSubsequentItem(existingTasks: task[], id: string, found: boolean) {
        //var existingTasks = countDocs.tasks.slice();
        //var existingTasks = countDocs.tasks;
        var existingMaterialTaskBelongToId = existingTasks.filter((x) => x.type == "Stock" && x.parentElementId == id);
        if (existingMaterialTaskBelongToId && existingMaterialTaskBelongToId.length > 0) {
            existingMaterialTaskBelongToId.forEach((y) => {
                //y.materialQuantity = 0;
                y.huCountResult = found ? "1" : "2";
            });
        }

        var existingHuTasksBelongsToId = existingTasks.filter((x) => x.type == "HU" && x.parentElementId == id);
        if (existingHuTasksBelongsToId && existingHuTasksBelongsToId.length > 0) {
            existingHuTasksBelongsToId.forEach((y) => {
                toggleSubsequentItem(existingTasks, y.invDocItemId.length > 0 ? y.invDocItemId : y.temId, found);
                y.huCountResult = found ? "1" : "2";
            });
        }
    }

    function ToggleFound_v2(id: string, value: string) {
        //prop.displayNotification("To remove " + id);

        var existingTasks = countDocs.tasks.slice();
        var invDocItemWithSameInvDocItemId = existingTasks.filter((x) => x.invDocItemId === id);
        if (invDocItemWithSameInvDocItemId && invDocItemWithSameInvDocItemId.length == 1) {
            var entryToUpdate = invDocItemWithSameInvDocItemId.at(0);
            entryToUpdate!.huCountResult = value;

            var newCopy = JSON.parse(JSON.stringify(countDocs));
            newCopy.tasks = existingTasks;
            setCountDocs(newCopy);
            //console.log("2newCopy", newCopy);
            //showSnackBar("2item removed", AlertBoxType.success);

        }
        else {
            var invDocItemWithSameTempId = existingTasks.filter((x) => x.temId === id);
            if (invDocItemWithSameTempId && invDocItemWithSameTempId.length == 1) {

                //invDocItemWithSameTempId.forEach((item) => { item.huCountResult = "2"; });

                var entryToUpdate = invDocItemWithSameTempId.at(0);
                entryToUpdate!.huCountResult = value;

                var newCopy = JSON.parse(JSON.stringify(countDocs));
                newCopy.tasks = existingTasks;
                setCountDocs(newCopy);
                //console.log("2newCopy", newCopy);
            }
            else {
                displayNotification("Error. Failed to identify Item to process. ");
            }
        }
    }

    function RemoveItem(id: string) {
        //prop.displayNotification("To remove " + id);
        var existingTasks = countDocs.tasks.slice();
        var invDocItemWithSameTempId = existingTasks.filter((x) => x.temId === id);
        if (invDocItemWithSameTempId && invDocItemWithSameTempId.length == 1) {

            var newTasks = existingTasks.filter((x) => x.temId != id);
            var newCopy = JSON.parse(JSON.stringify(countDocs));
            newCopy.tasks = newTasks;
            setCountDocs(newCopy);
            //console.log("2newCopy", newCopy);
            //showSnackBar("2item removed", AlertBoxType.success);


        }
        else {
            displayNotification("Cannot find newly added task to remove. <br/> Existing task populate from server cannot be remove. Please set to missing instead. ");
        }
    }

    function EditQuantity(id: string) {
        displayNotification("Not implemented yet");
        return;

        console.log("EditQuantity", id);

        var existingTasks = countDocs.tasks.slice();
        var invDocItemWithSameInvDocItemId = existingTasks.filter((x) => x.invDocItemId === id);
        if (invDocItemWithSameInvDocItemId && invDocItemWithSameInvDocItemId.length == 1) {
            var entryToUpdate = invDocItemWithSameInvDocItemId.at(0);
            entryToUpdate!.materialQuantity = 0;
            entryToUpdate!.containerQuantity = 0;
            var newCopy = JSON.parse(JSON.stringify(countDocs));
            newCopy.tasks = existingTasks;
            setCountDocs(newCopy);
            console.log("2newCopy", newCopy);
            //showSnackBar("2item removed", AlertBoxType.success);

        }
        else {
            var invDocItemWithSameTempId = existingTasks.filter((x) => x.temId === id);
            if (invDocItemWithSameTempId && invDocItemWithSameTempId.length == 1) {
                var entryToUpdate = invDocItemWithSameTempId.at(0);
                entryToUpdate!.materialQuantity = 0;
                entryToUpdate!.containerQuantity = 0;

                var newCopy = JSON.parse(JSON.stringify(countDocs));
                newCopy.tasks = existingTasks;
                setCountDocs(newCopy);
                console.log("2newCopy", newCopy);
            }
            else {
                displayNotification("Error. Failed to identify Item to process. ");
            }
        }
    }

    function EditQuantity_v2(id: string, newQuantity: number) {

        var existingTasks = countDocs.tasks.slice();
        var invDocItemWithSameInvDocItemId = existingTasks.filter((x) => x.invDocItemId === id);
        if (invDocItemWithSameInvDocItemId && invDocItemWithSameInvDocItemId.length == 1) {
            var entryToUpdate = invDocItemWithSameInvDocItemId.at(0);
            entryToUpdate!.materialQuantity = newQuantity;
            //entryToUpdate!.containerQuantity = 0;
            var newCopy = JSON.parse(JSON.stringify(countDocs));
            newCopy.tasks = existingTasks;
            setCountDocs(newCopy);
            //console.log("2newCopy", newCopy);
            //showSnackBar("2item removed", AlertBoxType.success);

        }
        else {
            var invDocItemWithSameTempId = existingTasks.filter((x) => x.temId === id);
            if (invDocItemWithSameTempId && invDocItemWithSameTempId.length == 1) {
                var entryToUpdate = invDocItemWithSameTempId.at(0);
                entryToUpdate!.materialQuantity = newQuantity;
                //entryToUpdate!.containerQuantity = 0;

                var newCopy = JSON.parse(JSON.stringify(countDocs));
                newCopy.tasks = existingTasks;
                setCountDocs(newCopy);
                //console.log("2newCopy", newCopy);
            }
            else {
                displayNotification("Error. Failed to identify Item to process. ");
            }
        }
    }

    const allowRemoveLocalItem: boolean = false;
    const showParentTemId: boolean = false;

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    function getHuDisplay(input: task): string {
        return "HU " + input.huNumber + " " + (input.huType.length > 0 ? "(" + input.huType + ")" : "");
    }

    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    //confirmation dialog
    const [showConfirmationText, setShowConfirmationText] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationForDiscard, setShowConfirmationForDiscard] =
        useState(false);

    return (
        <div>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={snackBarDuration}
                onClose={handleSnackBarClose}
            >
                <Alert
                    onClose={handleSnackBarClose}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    {snackBarContent}
                </Alert>
            </Snackbar>

            <div hidden={true}>
                <Snackbar
                    open={snackBarCOpen}
                    autoHideDuration={snackBarCDuration}
                    onClose={handleSnackBarCClose}
                >
                    <Alert
                        onClose={handleSnackBarCClose}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        {snackBarCContent}
                    </Alert>
                </Snackbar>
            </div>


            <NotificationDialog
                title="Info"
                contentText={notificationText}
                onClose={() => {
                    setShowNotification(false);
                }}
                visible={showNotification}
                onConfirm={() => {
                    setShowNotification(false);
                }}
            />

            <Grid container xs={12} sm={12}>

                {/* <Button onClick={(e) => {
                    setCurrentNode(prop.invDoc.binId!);
                    setCurrentNodeDisplay(prop.invDoc.binName!);
                }}>Reset</Button>
                <div>Current Node: {currentNodeDisplay}</div> */}
                <Grid container xs={12} sm={12}>
                    <Grid container spacing={3} >

                        <Grid item xs={12} sm={9}>
                            {/* <Grid item xs={12} sm={9} style={{ maxHeight: "80%", overflowX: "hidden", overflowY: "auto" }}> */}
                            {countDocs != null && countDocs.tasks.length > 0 ? (
                                <div >
                                    {countDocs.tasks.map((item, index) => {
                                        //{countDocs.tasks.filter((f) => f.parentElementId == currentNode || f.parentElementId == "").map((item, index) => {
                                        return (
                                            <Grid item xs={12} sm={12}>
                                                <div
                                                    key={item.invDocItemId}
                                                    style={{ padding: "1%", border: "1px solid grey", borderRadius: "3px", margin: "1%" }}
                                                >
                                                    <div>

                                                        <Grid container xs={12} sm={12} style={{ textAlign: "left" }}>
                                                            {/* <Grid xs={12} sm={1}>
                                                                <Badge color="secondary" showZero style={{ width: "100%" }}>
                                                                    {item.type == "Bin" ? <ViewInArIcon /> : item.type == "HU" ? <InventoryIcon /> : item.type == "Stock" ? <LocalOfferIcon /> : <div>Invalid Type</div>}
                                                                </Badge>
                                                            </Grid>
                                                            <Grid xs={12} sm={4}>
                                                                Type: {item.type}
                                                            </Grid> */}

                                                            {item.type == "Bin" ?
                                                                <Grid xs={12} sm={12}>
                                                                    <Grid xs={12} sm={2}>
                                                                        <Badge color="secondary" showZero style={{ width: "100%" }}>
                                                                            <ViewInArIcon />Bin
                                                                        </Badge>
                                                                    </Grid>
                                                                    <Grid xs={12} sm={5}></Grid>
                                                                    <Grid xs={12} sm={5}></Grid>


                                                                </Grid>
                                                                : item.type == "HU" ?
                                                                    <Grid container xs={12} sm={12}>
                                                                        <Grid xs={12} sm={8}>
                                                                            <Badge color="secondary" showZero style={{ width: "100%" }}>
                                                                                <InventoryIcon />
                                                                                {showParentTemId ? <i>{item.parentElementId}</i> : <i></i>}
                                                                                {getHuDisplay(item)}


                                                                                {item.huCountResult != "2" ?
                                                                                    <Badge showZero>
                                                                                        <CheckIcon color="success" />
                                                                                    </Badge>
                                                                                    :
                                                                                    <Badge showZero>
                                                                                        <ClearIcon color="error" />
                                                                                    </Badge>
                                                                                }


                                                                            </Badge>
                                                                            {/* <Button fullWidth={true} variant="contained"
                                                                                onClick={(e) => {
                                                                                    setCurrentNode(item.invDocItemId.length ? item.invDocItemId : item.temId);
                                                                                    setCurrentNodeDisplay(item.huNumber);
                                                                                }

                                                                                }
                                                                            >Drill in</Button> */}

                                                                        </Grid>
                                                                        <Grid xs={12} sm={4}>
                                                                            {item.parentName && item.parentName.length > 0 ?
                                                                                <i> (Gehört zu : {item.parentName})</i>
                                                                                :
                                                                                <i></i>
                                                                            }
                                                                            <FormControlLabel style={{ float: "right" }}
                                                                                control={<IOSSwitch sx={{ m: 1 }} defaultChecked={item.huCountResult != "2"} />}
                                                                                label="Gefunden" onChange={(e) =>
                                                                                    ToggleFound(item.invDocItemId.length > 0 ? item.invDocItemId : item.temId)} />

                                                                        </Grid>

                                                                        {/* <Grid xs={12} sm={5}>
                                                                            <Button
                                                                                fullWidth={true}
                                                                                style={{ margin: "2px" }}
                                                                                color="success"
                                                                                variant="contained"
                                                                                onClick={(e) =>
                                                                                    ToggleFound_v2(item.invDocItemId.length > 0 ? item.invDocItemId : item.temId, "1")}>
                                                                                <CheckIcon />
                                                                                Found
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid xs={12} sm={5}>
                                                                            <Button
                                                                                fullWidth={true}
                                                                                style={{ margin: "2px" }}
                                                                                color="error"
                                                                                variant="contained"
                                                                                onClick={(e) =>
                                                                                    ToggleFound_v2(item.invDocItemId.length > 0 ? item.invDocItemId : item.temId, "2")}>
                                                                                <ClearIcon />
                                                                                Missing
                                                                            </Button>
                                                                        </Grid> */}
                                                                        {/* <Grid xs={12} sm={5}>
                                                                            <FormControlLabel control={<Switch defaultChecked={item.huCountResult != "2"} />}
                                                                                label="Found" onChange={(e) =>
                                                                                    ToggleFound(item.invDocItemId.length > 0 ? item.invDocItemId : item.temId)} />

                                                                        </Grid> */}


                                                                        {allowRemoveLocalItem ?
                                                                            <Button
                                                                                fullWidth={true}
                                                                                color="error"
                                                                                style={{ margin: "2px" }}
                                                                                onClick={(e) =>
                                                                                    RemoveItem(item.temId)}
                                                                                disabled={item.temId.length <= 0}
                                                                                variant="contained"
                                                                            >
                                                                                Remove
                                                                            </Button> : <div></div>}

                                                                    </Grid>
                                                                    : item.type == "Stock" ?
                                                                        <Grid container xs={12} sm={12}>
                                                                            {/* <Grid xs={12} sm={2}>
                                                                                
                                                                            </Grid> */}
                                                                            <Grid container xs={12} sm={8}>
                                                                                <Badge color="secondary" showZero style={{ width: "100%" }}>
                                                                                    <LocalOfferIcon />
                                                                                    {showParentTemId ? <i>{item.parentElementId}</i> : <i></i>}
                                                                                    Material: {item.materialDisplay}
                                                                                    {item.huCountResult != "2" ?
                                                                                        <Badge showZero>
                                                                                            <CheckIcon color="success" />
                                                                                        </Badge>
                                                                                        :
                                                                                        <Badge showZero>
                                                                                            <ClearIcon color="error" />
                                                                                        </Badge>
                                                                                    }

                                                                                </Badge>

                                                                                {/* <Grid xs={12} sm={2}>
                                                                                    <Badge color="secondary" showZero style={{ width: "100%" }}>
                                                                                        <LocalOfferIcon />Stock
                                                                                    </Badge>
                                                                                </Grid>
                                                                                <Grid xs={12} sm={10}>
                                                                                    Material: {item.materialDisplay}
                                                                                </Grid> */}
                                                                            </Grid>
                                                                            <Grid xs={12} sm={4}>
                                                                                {item.parentName && item.parentName.length > 0 ?
                                                                                    <i> (Gehört zu :: {item.parentName})</i>
                                                                                    :
                                                                                    <i></i>
                                                                                }

                                                                            </Grid>
                                                                            {/* <Grid xs={12} sm={4}>
                                                                                Quantity: {item.materialQuantity}
                                                                                {" (" + item.bomValue + ")"}
                                                                            </Grid> */}
                                                                            <Grid xs={12} sm={4}>
                                                                                Zeichnungsstand: {item.batch}
                                                                            </Grid>
                                                                            <Grid xs={12} sm={4}>
                                                                                Baulos: {item.owner}
                                                                            </Grid>
                                                                            <br /><br />
                                                                            <Grid container xs={12} sm={12}>
                                                                                {/* <TextField label={"Menge"} value={item.materialQuantity}
                                                                                    onChange={function (newValue: string): void {
                                                                                        EditQuantity_v2(item.invDocItemId.length > 0 ? item.invDocItemId : item.temId, newValue);
                                                                                }} /> */}
                                                                                <Grid xs={12} sm={4}>
                                                                                    <NumberOnlyTextField
                                                                                        disabled={false}
                                                                                        label={"Menge"}
                                                                                        value={item.materialQuantity}
                                                                                        minValue={0}
                                                                                        maxValue={9999999}
                                                                                        onChanged={function (newValue: number): void {
                                                                                            EditQuantity_v2(item.invDocItemId.length > 0 ? item.invDocItemId : item.temId, newValue);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid xs={12} sm={2}>
                                                                                    <TextField label={"Mengeneinheit"} value={item.bomValue} disabled={true} />
                                                                                </Grid>


                                                                            </Grid>


                                                                            {/* <Grid xs={12} sm={5}>
                                                                                <Button
                                                                                    fullWidth={true}
                                                                                    style={{ margin: "2px" }}
                                                                                    onClick={(e) =>
                                                                                        EditQuantity(item.invDocItemId.length > 0 ? item.invDocItemId : item.temId)}
                                                                                    variant="contained"
                                                                                >
                                                                                    <EditIcon />
                                                                                    Edit Quantity
                                                                                </Button>
                                                                            </Grid> */}

                                                                            {allowRemoveLocalItem ?
                                                                                <Button
                                                                                    fullWidth={true}
                                                                                    color="error"
                                                                                    style={{ margin: "2px" }}
                                                                                    onClick={(e) =>
                                                                                        RemoveItem(item.temId)}
                                                                                    disabled={item.temId.length <= 0}
                                                                                    variant="contained"
                                                                                >
                                                                                    Remove
                                                                                </Button> : <div></div>}




                                                                        </Grid>
                                                                        : <Grid xs={12} sm={12}>Invalid Type</Grid>}

                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Grid>);
                                    })}
                                </div>
                            )
                                : <div>
                                </div>}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <div>
                                <Button
                                    variant="contained"
                                    style={{ margin: "2px" }}
                                    fullWidth={true}
                                    onClick={handleAddHUClick}
                                    disabled={debounce}
                                    startIcon={<AddIcon />}>
                                    <div>
                                        <br />
                                        <br />
                                    </div>
                                    HU hinzufügen</Button>
                                <Button
                                    variant="contained"
                                    style={{ margin: "2px" }}
                                    fullWidth={true}
                                    onClick={handleAddMaterialClick}
                                    disabled={debounce}
                                    startIcon={<AddIcon />}>
                                    <div>
                                        <br />
                                        <br />
                                    </div>
                                    Material hinzufügen</Button>
                                <br />
                                <br />
                                <Button
                                    color="success"
                                    variant="contained"
                                    style={{ margin: "2px" }}
                                    fullWidth={true}
                                    onClick={handlePostClick}
                                    disabled={debounce}
                                >
                                    <div>
                                        <br />
                                        <br />
                                    </div>
                                    <SaveAltIcon />
                                    Sichern
                                </Button>
                            </div>
                        </Grid>


                    </Grid>
                </Grid>

                <div>
                    <AddHuTaskComponent locationId={prop.locationId}
                        showAddHUDialog={showAddHUDialog}
                        title={"Add HU"}
                        Close={CloseAddHuDialog}
                        HuTaskAdded={HuAdded}
                        displayNotification={displayNotification}
                        invDocId={prop.invDoc.invDocId!}
                        binId={prop.invDoc.binId!}
                        binName={prop.invDoc.binName!}
                        existingTasks={countDocs.tasks} />
                </div>

                <div>
                    <AddMaterialTaskComponent locationId={prop.locationId}
                        showAddMaterialDialog={showAddMaterialDialog}
                        title={"Add Material"}
                        Close={CloseAddMaterialDialog}
                        MaterialTaskAdded={MaterialAdded}
                        displayNotification={displayNotification}
                        invDocId={prop.invDoc.invDocId!}
                        binId={prop.invDoc.binId!}
                        binName={prop.invDoc.binName!}
                        stockTypeId={afsStockTypeId}
                        existingTasks={countDocs.tasks}
                    />
                </div>

                <div>

                    <ConfirmationDialog
                        contentText={showConfirmationText}
                        onClose={() => {
                            setShowConfirmation(false);
                        }}
                        positiveText="Bestätigen"
                        negativeText="Abbrechen"
                        visible={showConfirmation}
                        onConfirm={async () => {
                            await handleConfirmClick();
                        }}
                    />
                </div>

                {/* <div>
                    {JSON.stringify(countDocs)}
                </div> */}
            </Grid>

            {/* <WmsTreeDisplay treeData={prop.treeData} highlightText={prop.highlightText} highlightType={prop.highlightType} />
            <br /> */}
        </div >
    )
};

export default CountTaskComponent;
