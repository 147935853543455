import {
  CompanyMenuDetailsV11Dto,
  locationV2Dto,
} from "interfaces/v11/companyMenus/menuTreesDetails/CompanyMenuDetailV11Dto";
import {
  MenuTreeDisplayType,
  MenuTreeItemDetailsV11Dto,
} from "interfaces/v11/companyMenus/menuTreesDetails/MenuTreeItemDetailsV11Dto";
import React from "react";
import { generateRandomGuid, guid } from "types/guid";
import axios from "../utils/axios";

const { createContext, useContext } = React;

interface ICustomerMenuService {
  getCompanyMenusById: (
    companyMenuId: guid
  ) => Promise<CompanyMenuDetailsV11Dto>;

  getLocation: () => Promise<CompanyMenuDetailsV11Dto>;
}

const assignKeyToChildren = (children: MenuTreeItemDetailsV11Dto[]) => {
  children.forEach((child) => {
    child.id = generateRandomGuid();
    assignKeyToChildren(child.children);
  });
};

const CompanyMenuServiceContext = createContext({} as ICustomerMenuService);

export type CompanyMenuProviderProps = {
  children?: React.ReactNode;
  getCompanyMenusById?: any;
  getLocation?: any;
};
export const CompanyMenuProvider: React.FC<CompanyMenuProviderProps> = (
  props
) => {
  const getCompanyMenusById = (companyMenuId: string) => {
    return (
      axios
        //.get<CompanyMenuDetailsV12Dto>(`/v11/location/${companyMenuId}`)
        .get<CompanyMenuDetailsV11Dto>(`/v11/company-menu/company-menu`)
        .then(async (res) => {
          let companyMenu: CompanyMenuDetailsV11Dto;
          companyMenu = res.data;
          return companyMenu;
        })
    );
  };

  const getLocation = () => {
    return (
      axios
        //.get<CompanyMenuDetailsV12Dto>(`/v11/location/${companyMenuId}`)
        .get<locationV2Dto[]>(`/v2/Locations`)
        .then(async (res) => {
          //hard code logic
          let rastattLocation = res.data?.filter(
            (x) => x.name.toUpperCase() == "RASTATT"
          );
          if (rastattLocation != null && rastattLocation.length == 1) {
            //hard code logic to show RASTATT only
            res.data = rastattLocation;
          }

          let menuTree: MenuTreeItemDetailsV11Dto[];
          menuTree = [];

          let menu: CompanyMenuDetailsV11Dto = {
            companyMenuId: generateRandomGuid(),
            name: "",
            menuTrees: menuTree,
            createdById: generateRandomGuid(),
            createdAt: "",
            changedById: generateRandomGuid(),
            changedAt: "",
          };

          res.data?.forEach((location) => {
            let loc: MenuTreeItemDetailsV11Dto = {
              locationId: location.locationId as guid,
              id: location.locationId as guid,
              name: location.name,
              iconReact: "",
              urlReact: "#",
              menuTreeDisplayType: MenuTreeDisplayType.Folder,
              children: [],
            };
            let grReceipt: MenuTreeItemDetailsV11Dto = {
              id: generateRandomGuid(),
              name: "Wareneingang",
              urlReact: "locations/:locationId/goodReceipts",
              menuTreeDisplayType: MenuTreeDisplayType.Page,
              children: [],
              locationId: location.locationId as guid,
            };
            loc.children.push(grReceipt);

            let putAway: MenuTreeItemDetailsV11Dto = {
              id: generateRandomGuid(),
              name: "Einlagerung",
              urlReact: "locations/:locationId/putAways",
              menuTreeDisplayType: MenuTreeDisplayType.Page,
              children: [],
              locationId: location.locationId as guid,
            };
            loc.children.push(putAway);

            let pickings: MenuTreeItemDetailsV11Dto = {
              id: generateRandomGuid(),
              name: "Kommissionierung",
              urlReact: "locations/:locationId/pickings",
              menuTreeDisplayType: MenuTreeDisplayType.Page,
              children: [],
              locationId: location.locationId as guid,
            };
            loc.children.push(pickings);

            let shipments: MenuTreeItemDetailsV11Dto = {
              id: generateRandomGuid(),
              name: "Verladen",
              urlReact: "locations/:locationId/shipments",
              menuTreeDisplayType: MenuTreeDisplayType.Page,
              children: [],
              locationId: location.locationId as guid,
            };
            loc.children.push(shipments);

            let movements: MenuTreeItemDetailsV11Dto = {
              id: generateRandomGuid(),
              name: "Umlagerung",
              urlReact: "locations/:locationId/movements",
              menuTreeDisplayType: MenuTreeDisplayType.Page,
              children: [],
              locationId: location.locationId as guid,
            };
            loc.children.push(movements);

            let stockCountings: MenuTreeItemDetailsV11Dto = {
              id: generateRandomGuid(),
              name: "Inventur",
              urlReact: "locations/:locationId/stockCountings",
              menuTreeDisplayType: MenuTreeDisplayType.Page,
              children: [],
              locationId: location.locationId as guid,
            };
            loc.children.push(stockCountings);

            let settings: MenuTreeItemDetailsV11Dto = {
              id: generateRandomGuid(),
              name: "Einstellungen",
              urlReact: "locations/:locationId/settings",
              menuTreeDisplayType: MenuTreeDisplayType.Page,
              children: [],
              locationId: location.locationId as guid,
            };
            loc.children.push(settings);

            menu.menuTrees.push(loc);
          });

          return menu;
        })
    );
  };

  const value = {
    getCompanyMenusById: props.getCompanyMenusById || getCompanyMenusById,
    getLocation: props.getLocation || getLocation,
  };

  return (
    <CompanyMenuServiceContext.Provider value={value}>
      {props.children}
    </CompanyMenuServiceContext.Provider>
  );
};

export const useCompanyMenuService = () => {
  return useContext(CompanyMenuServiceContext);
};
