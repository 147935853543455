import React from "react";
import TextField from "@mui/material/TextField";

export interface NumberOnlyTextFieldProp {
  label: string;
  value: number;
  minValue: number;
  maxValue: number;
  disabled: boolean;
  onChanged: (newValue: number) => void;
}

const NumberOnlyTextField: React.FC<NumberOnlyTextFieldProp> = ({
  label,
  value,
  minValue,
  maxValue,
  disabled,
  onChanged,
}) => {
  const isNumber = (input: string) => {
    return !isNaN(Number(input));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredValue = event.target.value;
    if (isNumber(enteredValue)) {
      const enteredValueAsNumber: number = Number(enteredValue);
      onChanged(enteredValueAsNumber);
    }
  };

  return (
    <TextField
      label={label}
      type="number"
      inputProps={{
        pattern: "[0-9]*", // Regular expression to restrict the input to numbers only
        minValue,
        maxValue,
      }}
      disabled={disabled}
      fullWidth={true}
      value={value}
      onChange={handleInputChange}
    />
  );
};

export default NumberOnlyTextField;
