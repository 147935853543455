import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huSearchResponseV11Dto } from "interfaces/v11/hu/huSearchResponseV11Dto";
import { WMSMaterialV11Dto } from "interfaces/v11/materialDetail/wmsMaterialDetailsV11Dto";

export interface WmsAutoCompleteOption {
    value: string;
    label: string;
    additionalAttribute1?: string;
    additionalAttribute2?: string;

    binDetail?: binSearchResponseV11Dto;
    huDetail?: huSearchResponseV11Dto;
    materialDetail?: WMSMaterialV11Dto;
}

export interface WmsAutoCompleteSearch {
    search: string;
}

export enum WmsAutoCompleteType {
    BIN = "Bin",
    HU = "HU",
    MATERIAL = "Material",
    HUTYPE = "HU Type",
    UNKNOWN = "Unknown"
}