import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

export interface ScannerProp {
  startScan: boolean;
  beforeScanText: string;
  duringScanText: string;
  scanStarted: () => void;
  scanCompleted: (scannedValue: string) => void;
  scanCancelled: () => void;
}

const Scanner: React.FC<ScannerProp> = (prop) => {
  const scanButtonRef = useRef<HTMLButtonElement>(null);
  const scanTextRef = useRef<HTMLInputElement>(null);
  const [showScanner, setShowScanner] = useState<boolean>(false);
  const [scannedValue, setScannedValue] = useState<string>("");

  function handleScanSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const value = scannedValue;
    StopScanning();
    prop.scanCompleted(value);
  }
  function handleScanKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && scanButtonRef.current) {
      scanButtonRef.current.click();
    }
  }
  function StartScanning() {
    prop.scanStarted();
    setScannedValue("");
    setShowScanner(true);
    if (scanTextRef.current) {
      scanTextRef.current?.focus();
    }
  }
  function StopScanning() {
    setShowScanner(false);
    prop.scanCancelled();
  }
  function handleScanBlur(event: React.FocusEvent<HTMLInputElement>) {
    StopScanning();
  }
  const performScan = () => {
    StartScanning();
  };

  // useEffect(() => {
  //   if (prop.startScan) {
  //     StartScanning();
  //   }
  // }, [scanButtonRef, scanTextRef]);

  useEffect(() => {
    //console.log("Scanner scanning " + new Date(), prop.startScan);
    if (prop.startScan) {
      //console.log("Start Scanning");
      StartScanning();
    }
  }, [prop.startScan]);


  return (
    <div>
      <div hidden={showScanner}>
        <Button onClick={performScan} variant="contained" fullWidth={true}>
          <div>
            <br />
            <br />
          </div>
          {prop.beforeScanText}
        </Button>
      </div>
      <div hidden={!showScanner}>
        <Button
          onClick={() => {
            setShowScanner(false);
          }}
          disabled={!showScanner}
          variant="contained"
          fullWidth={true}
        >
          <div>
            <br />
            <br />
          </div>
          {prop.duringScanText}
        </Button>
      </div>

      <div style={{ overflow: "hidden", position: "relative" }}>
        <div
          style={{
            border: "1px solid black",
            position: "absolute",
            right: "-500px",
          }}
        >
          <i>This technically should be invisible to user</i>
          <form onSubmit={handleScanSubmit}>
            <input
              type="text"
              value={scannedValue}
              onChange={(event) => setScannedValue(event.target.value)}
              onKeyDown={handleScanKeyDown}
              onBlur={handleScanBlur}
              ref={scanTextRef}
            />
            <button type="button" ref={scanButtonRef}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Scanner;
