import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  Grid,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import { PbCard } from "components/platbricks/shared";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import Page from "components/platbricks/shared/Page";
import {
  goodReceiptSetting,
  locationSetting,
  movementSetting,
  pickingSetting,
  shipmentSetting,
} from "interfaces/setting/locationSetting";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHandlingUnitService } from "services/HandingUnitService";
import { useSettingService } from "services/SettingService";
import GoodReceiptSettingComponent from "./GoodReceipt/GoodReceiptSettingComponent";
import GoodReceiptSettingDisplayComponent from "./GoodReceipt/GoodReceiptSettingDisplayComponent";
import PickingSettingDisplayComponent from "./Picking/PickingSettingDisplayComponent";
import PickingSettingComponent from "./Picking/PickingSettingComponent";
import ShipmentSettingDisplayComponent from "./Shipment/ShipmentSettingDisplayComponent";
import ShipmentSettingComponent from "./Shipment/ShipmentSettingComponent";
import MovementSettingDisplayComponent from "./Movement/MovementSettingDisplayComponent";
import MovementSettingComponent from "./Movement/MovementSettingComponent";
const breadcrumbs = [{ label: "Home", to: "/" }, { label: "Einstellungen" }];

interface Option {
  label: string;
  value: string;
}

interface Search {
  search: string;
}

const SettingPage = () => {
  const { locationId } = useParams();

  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";
  const display_noOptionMsg: string = "keine Optionen vorhanden";

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  //setting
  const display_settingNotConfigured: string =
    "Good Receipt Setting is not configured properly";

  //get setting
  const SettingService = useSettingService();
  const [setting, setSetting] = useState<locationSetting | null>(null);
  const [grSettingWarning, setGrSettingWarning] = useState(false);
  useEffect(() => {
    SettingService.isLocationSettingOk(locationId as string)
      .then((result) => {
        if (result) {
          setGrSettingWarning(false);
        } else {
          setGrSettingWarning(true);
        }
      })
      .catch(() => {
        console.log(display_SomethingWentWrong);
      });

    SettingService.getLocationSetting(locationId as string)
      .then((result) => {
        console.log(result);
        if (result.goodReceiptSetting == undefined) {
          result.goodReceiptSetting = SettingService.getDefaultGRSetting();
        }
        if (result.pickingSetting == undefined) {
          result.pickingSetting = SettingService.getDefaultPickingSetting();
        }
        if (result.shipmentSetting == undefined) {
          result.shipmentSetting = SettingService.getDefaultShipmentSetting();
        }
        if (result.movementSetting == undefined) {
          result.movementSetting = SettingService.getDefaultMovementSetting();
        }
        setSetting(result);
      })
      .catch(() => {
        console.log(display_SomethingWentWrong);
      });
  }, [SettingService]);

  //gr and putaway setting
  const [editGRSetting, setEditGRSetting] = useState(false);
  function onGoodReceiptSettingEdited(newSetting: goodReceiptSetting) {
    setSetting((prevLocationSetting) => {
      if (prevLocationSetting) {
        return {
          ...prevLocationSetting,
          goodReceiptSetting: newSetting,
        };
      }
      return prevLocationSetting;
    });

    if (setting != undefined) {
      let newEntries: locationSetting = setting;
      newEntries.goodReceiptSetting = newSetting;
      SettingService.updateLocationSetting(locationId as string, newEntries)
        .then((result) => {
          setEditGRSetting(false);
          displayNotification("Saved");
        })
        .catch(() => {
          setEditGRSetting(false);
          displayNotification(display_SomethingWentWrong);
        });

      SettingService.isLocationSettingOk(locationId as string)
        .then((result) => {
          if (result) {
            setGrSettingWarning(false);
          } else {
            setGrSettingWarning(true);
          }
        })
        .catch(() => {
          console.log(display_SomethingWentWrong);
        });
    }
  }

  function onPerformEditGRSetting() {
    setEditGRSetting(true);
  }

  //picking setting
  const [editPickingSetting, setEditPickingSetting] = useState(false);
  function onPickingSettingEdited(newSetting: pickingSetting) {
    setSetting((prevLocationSetting) => {
      if (prevLocationSetting) {
        return {
          ...prevLocationSetting,
          pickingSetting: newSetting,
        };
      }
      return prevLocationSetting;
    });

    if (setting != undefined) {
      let newEntries: locationSetting = setting;
      newEntries.pickingSetting = newSetting;
      SettingService.updateLocationSetting(locationId as string, newEntries)
        .then((result) => {
          setEditPickingSetting(false);
          displayNotification("Saved");
        })
        .catch(() => {
          setEditPickingSetting(false);
          displayNotification(display_SomethingWentWrong);
        });
    }
  }

  function onPerformEditPickingSetting() {
    setEditPickingSetting(true);
  }

  //shipment setting
  const [editShipmentSetting, setEditShipmentSetting] = useState(false);
  function onShipmentSettingEdited(newSetting: shipmentSetting) {
    setSetting((prevLocationSetting) => {
      if (prevLocationSetting) {
        return {
          ...prevLocationSetting,
          shipmentSetting: newSetting,
        };
      }
      return prevLocationSetting;
    });

    if (setting != undefined) {
      let newEntries: locationSetting = setting;
      newEntries.shipmentSetting = newSetting;
      SettingService.updateLocationSetting(locationId as string, newEntries)
        .then((result) => {
          setEditShipmentSetting(false);
          displayNotification("Saved");
        })
        .catch(() => {
          setEditShipmentSetting(false);
          displayNotification(display_SomethingWentWrong);
        });
    }
  }

  function onPerformEditShipmentSetting() {
    setEditShipmentSetting(true);
  }

  //Movement setting
  const [editMovementSetting, setEditMovementSetting] = useState(false);
  function onMovementSettingEdited(newSetting: movementSetting) {
    setSetting((prevLocationSetting) => {
      if (prevLocationSetting) {
        return {
          ...prevLocationSetting,
          movementSetting: newSetting,
        };
      }
      return prevLocationSetting;
    });

    if (setting != undefined) {
      let newEntries: locationSetting = setting;
      newEntries.movementSetting = newSetting;
      SettingService.updateLocationSetting(locationId as string, newEntries)
        .then((result) => {
          setEditMovementSetting(false);
          displayNotification("Saved");
        })
        .catch(() => {
          setEditMovementSetting(false);
          displayNotification(display_SomethingWentWrong);
        });
    }
  }

  function onPerformEditMovementSetting() {
    setEditMovementSetting(true);
  }

  //generic
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(5),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  if (
    setting == undefined ||
    setting.goodReceiptSetting == undefined ||
    setting.pickingSetting == undefined ||
    setting.shipmentSetting == undefined ||
    setting.movementSetting == undefined
  ) {
    return <div>{display_SomethingWentWrong}</div>;
  }


  return (
    <Page title="Einstellungen" breadcrumbs={breadcrumbs}>
      <PbCard>
        <CardContent>
          {grSettingWarning ? (
            <AlertBox
              alertType={AlertBoxType.warning}
              contentText={display_settingNotConfigured}
            />
          ) : (
            <div></div>
          )}

          <NotificationDialog
            title="Info"
            contentText={notificationText}
            onClose={() => {
              setShowNotification(false);
            }}
            visible={showNotification}
            onConfirm={() => {
              setShowNotification(false);
            }}
          />

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12}>
              <Item>
                <div>
                  <div hidden={editGRSetting}>
                    <GoodReceiptSettingDisplayComponent
                      setting={setting?.goodReceiptSetting}
                      performEdit={onPerformEditGRSetting}
                    />
                  </div>
                  <div hidden={!editGRSetting}>
                    <GoodReceiptSettingComponent
                      setting={setting?.goodReceiptSetting}
                      onGoodReceiptSettingEdited={onGoodReceiptSettingEdited}
                    />
                  </div>
                </div>
              </Item>
              <Item>
                <div>
                  <div hidden={editPickingSetting}>
                    <PickingSettingDisplayComponent
                      setting={setting?.pickingSetting}
                      performEdit={onPerformEditPickingSetting}
                    />
                  </div>
                  <div hidden={!editPickingSetting}>
                    <PickingSettingComponent
                      setting={setting?.pickingSetting}
                      onPickingSettingEdited={onPickingSettingEdited}
                    />
                  </div>
                </div>
              </Item>
              <Item>
                <div>
                  <div hidden={editShipmentSetting}>
                    <ShipmentSettingDisplayComponent
                      setting={setting?.shipmentSetting}
                      performEdit={onPerformEditShipmentSetting}
                    />
                  </div>
                  <div hidden={!editShipmentSetting}>
                    <ShipmentSettingComponent
                      setting={setting?.shipmentSetting}
                      onPickingSettingEdited={onShipmentSettingEdited}
                    />
                  </div>
                </div>
              </Item>
              <Item>
                <div>
                  <div hidden={editMovementSetting}>
                    <MovementSettingDisplayComponent
                      setting={setting?.movementSetting}
                      performEdit={onPerformEditMovementSetting}
                    />
                  </div>
                  <div hidden={!editMovementSetting}>
                    <MovementSettingComponent
                      setting={setting?.movementSetting}
                      onMovementSettingEdited={onMovementSettingEdited}
                    />
                  </div>
                </div>
              </Item>
            </Grid>
          </Grid>
        </CardContent>
      </PbCard>
    </Page>
  );
};

export default SettingPage;
