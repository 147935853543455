import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PropaneSharp,
} from "@mui/icons-material";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { generateRandomGuid, guid } from "types/guid";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { useHandlingUnitService } from "services/HandingUnitService";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { useParams } from "react-router-dom";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huCreateRequestV11Dto } from "interfaces/v11/hu/huCreateRequestV11Dto";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import NumberOnlyTextField from "components/platbricks/shared/NumberOnlyTextField";
import { width } from "@mui/system";

interface GoodReceiptHUListingProp {
  selectedHU: HuV11Dto | null;
  binId: string;
  hus: HuV11Dto[];
  deliveryReferenceContainerSize: string;
  deliveryReferenceContainerNumber: string;
  onAddHU: (item: HuV11Dto) => void;
  onEditHU: (id: guid, updatedItem: HuV11Dto) => void;
  onDeleteHU: (id: guid) => void;
  onChangeSelectedHU: (selectedItem: HuV11Dto) => void;
}

interface Option {
  label: string;
  value: string;
}

interface Search {
  search: string;
}

const GoodReceiptHUListing: React.FC<GoodReceiptHUListingProp> = ({
  selectedHU,
  binId,
  hus,
  deliveryReferenceContainerSize,
  deliveryReferenceContainerNumber,
  onAddHU,
  onEditHU,
  onDeleteHU,
  onChangeSelectedHU,
}) => {
  // const [name, setName] = useState('');
  // const [description, setDescription] = useState('');
  //const [value, setValue] = useState<Option | null>(null);
  const { locationId } = useParams();

  const display_noOptionMsg: string = "keine Optionen vorhanden";
  const reservedPlaceHolder: string = "{%!Hu@}";
  const display_subtitle: string = "Ladungsträger";

  const display_totalHuAdded: string = "Anzahl LT: ";
  const display_new: string = "Neu";
  const display_newHU: string = "Neuer LT";
  const display_noHU: string = "Kein Ladungsträger vorhanden.";
  const display_HUType: string = "LT Typ";

  const display_HU: string = "Ladungsträger";
  const display_somethingWentWrongSearchBin: string =
    "Something went wrong. Could not find Bin. ";
  const display_somethingWentWrongSearchHUType: string =
    "Something went wrong. Could not find HU Type. ";
  const display_confirm: string = "Bestätigen";
  const display_cancel: string = "Abbrechen";
  const display_pleaseSelectBin: string = "Please select Bin";
  const display_pleaseSelectHUType: string = "Please select LT Typ";
  const display_somethingWentWrongWhenCreateHU: string =
    "Something went wrong when create HU";
  const display_huAdded: string =
    "Ladungträger " + reservedPlaceHolder + " wurde hinzugefügt.";

  const [binOptions, setBinOptions] = useState<Option[]>([]);
  const [huTypeOptions, setHuTypeOptions] = useState<Option[]>([]);
  const [selectedHUTypeOptions, setSelectedHUTypeOptions] =
    useState<Option | null>(null);
  const [selectedBinOptions, setSelectedBinOptions] = useState<Option | null>(
    null
  );

  const huService = useHandlingUnitService();
  const [searchHuTypeQuery, setSearchHuTypeQuery] = useState<Search>({
    search: "",
  });
  const [searchBinQuery, setSearchBinQuery] = useState<Search>({ search: "" });

  const defaultHU = {
    wmsId: "",
    binIsEmpty: false,
    changedAt: "",
    changedBy: "",
    deliveryReference: "",
    deliveryItemReference: "",
    goodsReceivedAt: "",
    handlingUnit: "",
    handlingUnitType: "",
    inventoryBan: false,
    locationId: "",
    parentElement: "",
    putAwayBan: false,
    removalBan: false,
    rootElement: "",
    serialNumbersAssigned: false,
    stockCategory: "",
    stockUsage: "",
    type: "",
    verification: [],
    serialNumbers: [],
    binAreaAllocations: [],
    rowVersion: "",
    volumeUnit: "",
    massUnit: "",
    lengthUnit: "",
    closed: "",
    avlQtyAuom: 0,
    avlQtyBuom: 0,
    incomingOpenTasks: 0,
    incomingInactiveTasks: 0,
    outgoingOpenTasks: 0,
    outgoingInactiveTasks: 0,
    loadingCapacity: 0,
    loadingVolume: 0,
    loadingWeight: 0,
    huInMovementByTasks: 0,
    huAnyInferiorInMovementByTasks: 0,
    huDeleteAutomaticallyWhenEmpty: "",
    behaviourIfEmptyDuringAddTasksStockOut: "",
    updateBehaviour: "",
    consumedCapacity: 0,
    consumedVolume: 0,
    consumedWeight: 0,
    processingIndicator: "",

    assignedItems: [],
  };

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  //confirmation dialog
  const [showAddHUDialog, setShowAddHUDialog] = useState(false);
  const handleAddHUClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason && reason == "backdropClick") return;
    setShowAddHUDialog(false);
  };

  const [hu, setHU] = useState<HuV11Dto>(defaultHU);
  const [selectedItemId, setSelectedItemId] = useState<guid | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  //const [BinId, setBinId] = useState("");
  //const [BinName, setBinName] = useState("");
  const [HUTypeId, setHUTypeId] = useState("");
  const [HUType, setHUType] = useState("");
  const [quantityToCreate, setQuantityToCreate] = useState<number>(1);
  const minValue: number = 1;
  const maxValue: number = 30;
  const display_Quantity: string =
    "Menge (" + minValue + " - " + maxValue + ")";
  const [HuId, setHuId] = useState<guid>(generateRandomGuid());
  const [HuName, setHuName] = useState("");

  // function convertBinResultToSelectOptions(
  //   data: binSearchResponseV11Dto[]
  // ): Option[] {
  //   return data.map((item) => ({
  //     value: item.wmsId,
  //     label: item.bin,
  //   }));
  // }

  function convertHuTypeResultToSelectOptions(
    data: huTypeSearchResponseV11Dto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.handlingUnitTypeId,
      label: item.name,
    }));
  }

  //handleQuantityToCreateInputChange
  const handleSearchHuTypeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchHuTypeQuery({ search: event.target.value });
  };
  useEffect(() => {
    if (searchHuTypeQuery.search.length >= 3) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(searchHuTypeQuery.search);

      let searchDto: huTypeSearchRequestV11Dto;
      searchDto = {
        page: 0,
        pageSize: 20,
        nameContains: [],
      };
      searchDto.nameContains = searchTexts;

      huService
        .getHandlingUnitType(searchDto)
        .then((result) => {
          setHuTypeOptions(convertHuTypeResultToSelectOptions(result));
        })
        .catch(() => console.log(display_somethingWentWrongSearchHUType));
    }
  }, [searchHuTypeQuery.search]);

  // const handleBinChange = (
  //   event: React.ChangeEvent<{}>,
  //   option: Option | null
  // ) => {
  //   if (option) {
  //     setBinId(option.value);
  //     setBinName(option.label);
  //     setSelectedBinOptions(option);
  //   } else {
  //     setBinId("");
  //     setBinName("");
  //     setSelectedBinOptions(null);
  //   }
  // };

  const handleHuTypeChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      setHUTypeId(option.value);
      setHUType(option.label);
      setSelectedHUTypeOptions(option);
    } else {
      setHUTypeId("");
      setHUType("");
      setSelectedHUTypeOptions(null);
    }
  };

  const [debounce, setDebounce] = useState<boolean>(false);
  useEffect(() => {
    //console.log("debounce " + new Date(), debounce);
  }, [debounce]);

  const handleAdd = () => {
    //onAdd(hu);
    if (debounce) {
      return;
    }

    setDebounce(true);

    if (binId.length <= 0) {
      displayNotification(display_pleaseSelectBin);
      setDebounce(false);
      return;
    }

    if (HUType == undefined || HUType.length <= 0) {
      displayNotification(display_pleaseSelectHUType);
      setDebounce(false);
      return;
    }

    if (quantityToCreate < minValue || quantityToCreate > maxValue) {
      // displayNotification(
      //   "Quantity value allowed are between " + minValue + " to " + maxValue
      // );
      displayNotification("Bitte geben Sie eine Menge bis maximal 30 ein. ");
      setDebounce(false);
      return;
    }
    let createHURequest: huCreateRequestV11Dto = {
      locationId: locationId as string,
      handlingUnitType: HUType,
      parentElement: binId,
      rootElement: binId,
      type: 1,
    };

    setDebounce(false);
    CreateHUInSequence(createHURequest, quantityToCreate);

    // huService
    //   .createHU(createHURequest)
    //   .then((result) => {
    //     //alert(result.wmsId);
    //     setShowAddHUDialog(false);
    //     displayNotification(
    //       display_huAdded.replace(reservedPlaceHolder, result.handlingUnit)
    //     );
    //     setIsAdding(false);
    //     setHU(defaultHU);
    //     onAddHU(result);
    //     setDebounce(false);
    //   })
    //   .catch(() => {
    //     displayNotification(display_somethingWentWrongWhenCreateHU);
    //     setIsAdding(false);
    //     setHU(defaultHU);
    //     setDebounce(false);
    //   });
  };

  let createHUSequence: huCreateRequestV11Dto[] = [];
  function CreateHUInSequence(
    createHURequest: huCreateRequestV11Dto,
    totalQuantityToCreate: number
  ) {
    setDebounce(true);
    huService
      .createHU(createHURequest)
      .then((result) => {
        onAddHU(result);
        UpdateCreateHUSequence(createHURequest, totalQuantityToCreate);
      })
      .catch((err) => {
        console.log(err);
        UpdateCreateHUSequence(createHURequest, totalQuantityToCreate);
      });
  }

  function UpdateCreateHUSequence(
    createHURequest: huCreateRequestV11Dto,
    totalQuantityToCreate: number
  ) {
    createHUSequence.push(createHURequest);
    if (createHUSequence.length == totalQuantityToCreate) {
      //sequence completed
      setDebounce(false);
    } else {
      CreateHUInSequence(createHURequest, totalQuantityToCreate);
    }
  }

  const handleEdit = () => {
    if (selectedItemId !== null) {
      alert(selectedItemId);
      //onEdit(selectedItemId, { HuId: generateRandomGuid(), BinId: generateRandomGuid(), HUTypeId:generateRandomGuid(), HUType:HUType, BinName:BinName, HuName:HuName });
      setHU(defaultHU);
      setSelectedItemId(null);
      setIsEditing(false);
    }
  };

  const handleDelete = (id: guid) => {
    onDeleteHU(id);
  };

  const handleCancel = () => {
    setSelectedItemId(null);
    setHU(defaultHU);
    setIsAdding(false);
    setIsEditing(false);
    setSelectedBinOptions(null);
    setSelectedHUTypeOptions(null);
  };

  const handleAddItemClick = () => {
    //setIsAdding(true);
    //setBinName("");
    setHuName("");
    setHUType("");

    let a: Option;
    a = {
      label: "",
      value: "",
    };
    let b: Option[];
    b = [];
    b.push(a);
    setBinOptions(b);
    setSelectedBinOptions(a);

    let x: Option;
    x = {
      label: "",
      value: "",
    };
    let y: Option[];
    y = [];
    y.push(x);
    setHuTypeOptions(y);
    setSelectedHUTypeOptions(x);

    setShowAddHUDialog(true);
  };

  //data change event
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setHU({ ...hu, [name]: value });
  };

  const [showAddEditHUMaterial, setShowAddEditHUMaterial] = useState(false);
  const handleAddEditHUMaterialClose = async (
    event: React.SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason && reason == "backdropClick") return;
    setShowAddEditHUMaterial(false);
  };
  const handleAddEditHUMaterialCancel = (
    event: React.SyntheticEvent<Element, Event> | null,
    reason: string
  ) => {
    setShowAddEditHUMaterial(false);
  };
  const handleSelectedHUClick = (item: HuV11Dto) => {
    setSelectedItemId(item.wmsId as guid);
    onChangeSelectedHU(item);
  };

  // useEffect(() => {

  // }, [selectedItemId]);

  const numberToString = (index: number) => {
    const paddedString = String(index).padStart(2, "0");
    return paddedString;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      <Grid container xs={12} sm={12}>
        <Grid sm={6}>
          <Typography variant="subtitle2" gutterBottom>
            {display_subtitle}
          </Typography>
        </Grid>
        <Grid sm={6}>
          <Box>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddItemClick}
              disabled={debounce}
            >
              {display_newHU}
            </Button>
          </Box>
          {/* <Grid container xs={12} sm={12}>
            <Grid sm={2}></Grid>
            <Grid sm={4}>
              <Box></Box>
            </Grid>
            <Grid sm={1}></Grid>
            <Grid sm={2}>
              <Typography variant="subtitle2" align="left">
                {"LT-type: "} <br />
                {"LT-Menge: "}
              </Typography>
            </Grid>
            <Grid sm={2}>
              <Typography variant="subtitle2" align="left">
                {deliveryReferenceContainerSize} <br />
                {deliveryReferenceContainerNumber}
              </Typography>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>

      {hus.length <= 0 ? <div>{display_noHU}</div> : <div></div>}
      <List>
        {hus.map((item, index) => (
          // <ListItem key={item.wmsId} button onClick={() => handleEditItemClick(item)}
          <ListItem
            key={item.wmsId}
            button
            selected={selectedHU != null && selectedHU.wmsId === item.wmsId}
            // style={{
            //   backgroundColor:
            //     selectedHU != null && selectedHU.wmsId === item.wmsId
            //       ? "#376fd0"
            //       : "",
            //   color:
            //     selectedHU != null && selectedHU.wmsId === item.wmsId
            //       ? "white"
            //       : "",
            // }}
            onClick={() => handleSelectedHUClick(item)}
          //onSelect={() => handleSelectedHUClick(item)}
          >
            <ListItemText
              primary={
                numberToString(index + 1) +
                ". " +
                item.handlingUnit +
                " (" +
                item.handlingUnitType +
                ")"
              }
            //secondary={selectedHU != null ? selectedHU.wmsId : "ds"}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => handleDelete(item.wmsId as guid)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <div style={{ float: "right" }}>
        {hus != undefined && hus.length > 0
          ? " (" + display_totalHuAdded + hus.length + ")"
          : " "}
      </div>

      {isAdding && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            {display_new}
          </Typography>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={7}>
              <Autocomplete
                value={selectedBinOptions}
                onChange={handleBinChange}
                options={binOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bin"
                    variant="outlined"
                    onChange={handleSearchDestinationBinInputChange}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={7}>
              <Autocomplete
                noOptionsText={display_noOptionMsg}
                value={selectedHUTypeOptions}
                onChange={handleHuTypeChange}
                options={huTypeOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={display_HUType}
                    variant="outlined"
                    onChange={handleSearchHuTypeInputChange}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* <TextField label="BinId" value={hu.BinId} onChange={(e) => setBinId(e.target.value)} fullWidth />
          <TextField label="HuId" value={hu.HuId} onChange={(e) => setHuId(e.target.value)} fullWidth />
          <TextField label="HUType" value={hu.HUType} onChange={(e) => setHUType(e.target.value)} fullWidth /> */}

          {/* <TextField label="HUType" value={HUType} onChange={(e) => setHUType(e.target.value)} fullWidth /> */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={handleCancel} sx={{ mr: 1 }}>
              {display_cancel}
            </Button>
            <Button variant="contained" onClick={handleAdd}>
              {display_new}
            </Button>
          </Box>
        </Box>
      )}

      <Dialog
        onClose={handleAddHUClose}
        aria-labelledby="simple-dialog-title"
        open={showAddHUDialog}
        hideBackdrop={false}
        PaperProps={{
          style: {
            minWidth: "50%",
          },
        }}
      >
        <DialogTitle>{display_HU}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>{contentText}</DialogContentText> */}
          <Box sx={{ mt: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  noOptionsText={display_noOptionMsg}
                  value={selectedHUTypeOptions}
                  onChange={handleHuTypeChange}
                  options={huTypeOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={display_HUType}
                      variant="outlined"
                      onChange={handleSearchHuTypeInputChange}
                    />
                  )}
                />
                <br />
                <NumberOnlyTextField
                  disabled={false}
                  label={display_Quantity}
                  value={quantityToCreate}
                  minValue={minValue}
                  maxValue={maxValue}
                  onChanged={function (newValue: number): void {
                    setQuantityToCreate(newValue);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowAddHUDialog(false);
            }}
          >
            {display_cancel}
          </Button>
          <Button onClick={handleAdd}>{display_confirm}</Button>
        </DialogActions>
      </Dialog>

      {isEditing && <div>Editing</div>}
    </Box>
  );
};

export default React.memo(GoodReceiptHUListing);
