import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PropaneSharp,
} from "@mui/icons-material";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { generateRandomGuid, guid } from "types/guid";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { useHandlingUnitService } from "services/HandingUnitService";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { useNavigate, useParams } from "react-router-dom";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huCreateRequestV11Dto } from "interfaces/v11/hu/huCreateRequestV11Dto";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import {
  goodReceiptSetting,
  pickingSetting,
} from "interfaces/setting/locationSetting";
import { useGoodReceiptService } from "services/GoodReceiptService";
import useAuth from "hooks/useAuth";
import { processTypeSearchV11ResponseDto } from "interfaces/v11/processType/processTypeSearchV11ResponseDto";
import { printServerSearchResponseDto } from "interfaces/general/print/printServerSearchResponseDto";
import { usePrintService } from "services/PrintService";
import { printerSearchResponseDto } from "interfaces/general/print/printerSearchResponseDto";

interface PickingSettingProp {
  setting: pickingSetting;
  onPickingSettingEdited: (updatedItem: pickingSetting) => void;
}

interface Option {
  label: string;
  value: string;
}

interface Search {
  search: string;
}

const PickingSettingComponent: React.FC<PickingSettingProp> = (prop) => {
  const { locationId } = useParams();
  const [setting, setSetting] = useState(prop.setting);
  const padding: number = 3;
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const display_Picking: string = "Parameter einstellen (Kommissionierung)";

  const display_PrintServer: string = "Druckserver";
  const display_Printer: string = "Drucker";
  const display_Edit: string = "Bearbeiten";
  const display_Save: string = "Speichern";
  const display_noOptionMsg: string = "keine Optionen vorhanden";

  const display_CouldNotGetPrintServer: string =
    "Something went wrong. Could not get Print Server. ";
  const display_CouldNotGetPrinter: string =
    "Something went wrong. Could not get Printer. ";

  const printService = usePrintService();

  //--print server---
  const [searchPrintServerQuery, setSearchPrintServerQuery] = useState<Search>({
    search: "",
  });
  const [printServerOption, setPrintServerOption] = useState<Option[]>([]);
  const [selectedPrintServerOption, setSelectedPrintServerOption] =
    useState<Option | null>(null);

  const handleSearchPrintServerInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchPrintServerQuery({ search: event.target.value });
  };

  function convertPrintServerResultToSelectOptions(
    data: printServerSearchResponseDto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.deviceId,
      label: item.printerServerName,
    }));
  }

  useEffect(() => {
    if (searchPrintServerQuery.search.length >= 0) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(searchPrintServerQuery.search);

      printService
        .getPrinterServer()
        .then((result) => {
          setPrintServerOption(convertPrintServerResultToSelectOptions(result));
          setPrinterOption([]);
          setSelectedPrinterOption(null);
        })
        .catch(() => {
          console.log(display_CouldNotGetPrintServer);
        });
    }
  }, [searchPrintServerQuery.search]);

  const handleSelectedPrintServerChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null,
    reason: string
  ) => {
    if (reason && reason == "clear") {
      setSelectedPrinterOption(null);
      setSelectedPrintServerOption(null);
    } else if (option) {
      setting.printServerId = option.value;
      setting.printServer = option.label;
      setSelectedPrintServerOption(option);
    }
  };

  //--printer---
  const [searchPrinterQuery, setSearchPrinterQuery] = useState<Search>({
    search: "",
  });
  const [printerOption, setPrinterOption] = useState<Option[]>([]);
  const [selectedPrinterOption, setSelectedPrinterOption] =
    useState<Option | null>(null);

  const handleSearchPrinterInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchPrinterQuery({ search: event.target.value });
  };

  function convertPrinterResultToSelectOptions(
    data: printerSearchResponseDto[]
  ): Option[] {
    const activeStatus: string = "ACTIVE";
    const activePrinter: printerSearchResponseDto[] = data.filter(
      (x) => x.printerStatus == activeStatus
    );
    console.log(activePrinter);

    return activePrinter.map((item) => ({
      value: item.printerId,
      label: item.printerName,
    }));
  }

  useEffect(() => {
    if (selectedPrintServerOption == null) {
      return;
    }
    if (searchPrinterQuery.search.length >= 0) {
      printService
        .getPrinters({
          printServerId: selectedPrintServerOption.value,
        })
        .then((result) => {
          setPrinterOption(convertPrinterResultToSelectOptions(result));
        })
        .catch(() => {
          console.log(display_CouldNotGetPrinter);
        });
    }
  }, [searchPrinterQuery.search]);

  const handleSelectedPrinterChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null,
    reason: string
  ) => {
    if (reason && reason == "clear") {
      setSelectedPrinterOption(null);
    } else if (option) {
      setting.printerId = option.value;
      setting.printer = option.label;
      setSelectedPrinterOption(option);
    }
  };

  //general
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    if (
      setting.printServerId != null &&
      setting.printServerId.length > 0 &&
      setting.printServer != null &&
      setting.printServer.length > 0 &&
      selectedPrintServerOption == null
    ) {
      setSelectedPrintServerOption({
        label: setting.printServer,
        value: setting.printServerId,
      });
    }

    if (
      setting.printerId != null &&
      setting.printerId.length > 0 &&
      setting.printer != null &&
      setting.printer.length > 0 &&
      selectedPrinterOption == null
    ) {
      setSelectedPrinterOption({
        label: setting.printer,
        value: setting.printerId,
      });
    }
  }, [setting]);

  // const handleInputPrinterServerChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { name, value } = event.target;
  //   //setting.printServer = value;
  //   setSetting({ ...setting, printServer: value });
  // };

  const handleInputPrinterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    //setting.printer = value;
    setSetting({ ...setting, printer: value });
  };

  function handleSave(): void {
    setting.printServerId =
      selectedPrintServerOption != null ? selectedPrintServerOption.value : "";
    setting.printServer =
      selectedPrintServerOption != null ? selectedPrintServerOption.label : "";

    setting.printerId =
      selectedPrinterOption != null ? selectedPrinterOption.value : "";
    setting.printer =
      selectedPrinterOption != null ? selectedPrinterOption.label : "";

    console.log(setting);
    prop.onPickingSettingEdited(setting);
  }

  return (
    <Box sx={{ width: "100%" }}>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <h4>{display_Picking}</h4>
            <div style={{ padding: padding }}>
              <Autocomplete
                noOptionsText={display_noOptionMsg}
                value={selectedPrintServerOption}
                onChange={handleSelectedPrintServerChange}
                aria-required={false}
                options={printServerOption}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={display_PrintServer}
                    variant="outlined"
                    onChange={handleSearchPrintServerInputChange}
                  />
                )}
              />
            </div>
            <div style={{ padding: padding }}>
              <Autocomplete
                noOptionsText={display_noOptionMsg}
                value={selectedPrinterOption}
                onChange={handleSelectedPrinterChange}
                aria-required={false}
                options={printerOption}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={display_Printer}
                    variant="outlined"
                    onChange={handleSearchPrinterInputChange}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Button variant="contained" fullWidth={true} onClick={handleSave}>
              {display_Save}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default React.memo(PickingSettingComponent);
