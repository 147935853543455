import { AzureAppInsightChartResultsV12Dto } from "interfaces/v12/azureAppInsight/azureAppInsightChart/AzureAppInsightChartResultsV12Dto";
import { AzureAppInsightTimePerformanceChartResultsV12Dto } from "interfaces/v12/azureAppInsight/azureAppInsightChart/AzureAppInsightTimePerformanceChartResultsV12Dto";
import { AzureAppInsightTimeStatChartResultsV12Dto } from "interfaces/v12/azureAppInsight/azureAppInsightChart/AzureAppInsightTimeStatChartResultsV12Dto";
import { AzureAppInsightCreateEditV12Dto } from "interfaces/v12/azureAppInsight/azureAppInsightCreate/azureAppInsightCreateV12Dto";
import { AzureAppInsightDetailsV12Dto } from "interfaces/v12/azureAppInsight/azureAppInsightDetails/azureAppInsightDetailsV12Dto";
import { AzureAppInsightSearchV12Dto } from "interfaces/v12/azureAppInsight/azureAppInsightDetails/azureAppInsightSearchV12Dto";
import React from "react";
import { guid } from "../types/guid";
import axios from "../utils/axios";

const { createContext, useContext } = React;

interface IAzureAppInsightService {
  searchAzureAppInsights: (
    searchDto: AzureAppInsightSearchV12Dto
  ) => Promise<AzureAppInsightDetailsV12Dto[]>;
  countAzureAppInsights: (searchDto: any) => Promise<number>;
  getAzureAppInsightById: (
    azureAppInsightId: guid
  ) => Promise<AzureAppInsightDetailsV12Dto>;
  getFirstAvailableAzureAppInsight: () => Promise<AzureAppInsightDetailsV12Dto>;
  createAzureAppInsight: (
    azureAppInsight: AzureAppInsightCreateEditV12Dto
  ) => Promise<string>;
  updateAzureAppInsight: (
    id: string,
    azureAppInsight: AzureAppInsightCreateEditV12Dto
  ) => Promise<string>;
  deleteAzureAppInsight: (id: string) => void;
  getAzureAppInsightChart: (
    timerange: string
  ) => Promise<AzureAppInsightChartResultsV12Dto[]>;
  getAzureAppInsightTimePerformanceChart: (
    timerange: string,
    timegrain: string
  ) => Promise<AzureAppInsightTimePerformanceChartResultsV12Dto[]>;
  getAzureAppInsightTimeStatChart: (
    timerange: string,
    timegrain: string
  ) => Promise<AzureAppInsightTimeStatChartResultsV12Dto[]>;
  getAzureAppInsightYearToDateAvailabilityChart: () => Promise<
    AzureAppInsightTimeStatChartResultsV12Dto[]
  >;
}

const AzureAppInsightServiceContext = createContext(
  {} as IAzureAppInsightService
);

export type AzureAppInsightServiceProviderProps = {
  children?: React.ReactNode;
  searchAzureAppInsights?: any;
  countAzureAppInsights?: any;
  getAzureAppInsightById?: any;
  createAzureAppInsight?: any;
  updateAzureAppInsight?: any;
  deleteAzureAppInsight?: any;
  getAzureAppInsightChart?: any;
  getFirstAvailableAzureAppInsight?: any;
  getAzureAppInsightTimePerformanceChart?: any;
  getAzureAppInsightTimeStatChart?: any;
  getAzureAppInsightYearToDateAvailabilityChart?: any;
};
export const AzureAppInsightServiceProvider: React.FC<
  AzureAppInsightServiceProviderProps
> = (props) => {
  const searchAzureAppInsights = (searchDto: AzureAppInsightSearchV12Dto) => {
    return axios
      .post("/v12/azure-appinsights/search", searchDto)
      .then((res) => {
        return res.data.data;
      });
  };

  const countAzureAppInsights = (searchDto: any) => {
    return axios
      .post("/v12/azure-appinsights/count", searchDto)
      .then((res) => res.data);
  };

  const getAzureAppInsightById = (azureAppInsightId: guid) => {
    return axios
      .get<AzureAppInsightDetailsV12Dto>(
        "/v12/azure-appinsights/" + azureAppInsightId
      )
      .then(async (res) => {
        let result = res.data;
        return result;
      });
  };

  const getFirstAvailableAzureAppInsight = () => {
    return axios
      .get<AzureAppInsightDetailsV12Dto>(
        "/v12/azure-appinsights/appInsightDashboard"
      )
      .then(async (res) => {
        let result = res.data;
        return result;
      });
  };

  const updateAzureAppInsight = (
    id: string,
    azureAppInsight: AzureAppInsightCreateEditV12Dto
  ) => {
    return axios
      .put(`/v12/azure-appinsights/${id}`, azureAppInsight)
      .then(async (res) => {
        let result = res.data;
        return result;
      });
  };

  const createAzureAppInsight = (
    azureAppInsight: AzureAppInsightCreateEditV12Dto
  ) => {
    return axios
      .post("/v12/azure-appinsights/", azureAppInsight)
      .then(async (res) => {
        let result = res.data;
        return result.id;
      });
  };

  const deleteAzureAppInsight = async (id: string) => {
    await axios.delete(`/v12/azure-appinsights/${id}`);
  };

  const getAzureAppInsightChart = async (timerange: string) => {
    const response = await axios.get<AzureAppInsightChartResultsV12Dto>(
      `/v12/azure-appinsights/dashboard-chart?timerange=${timerange}`
    );

    return response.data;
  };

  const getAzureAppInsightTimePerformanceChart = async (
    timerange: string,
    timegrain: string
  ) => {
    const response =
      await axios.get<AzureAppInsightTimePerformanceChartResultsV12Dto>(
        `/v12/azure-appinsights/dashboard-chart/time-performance?timerange=${timerange}&timegrain=${timegrain}`
      );

    return response.data;
  };

  const getAzureAppInsightTimeStatChart = async (
    timerange: string,
    timegrain: string
  ) => {
    const response =
      await axios.get<AzureAppInsightTimePerformanceChartResultsV12Dto>(
        `/v12/azure-appinsights/dashboard-chart/time-stat?timerange=${timerange}&timegrain=${timegrain}`
      );

    return response.data;
  };

  const getAzureAppInsightYearToDateAvailabilityChart = async () => {
    const response =
      await axios.get<AzureAppInsightTimePerformanceChartResultsV12Dto>(
        `/v12/azure-appinsights/dashboard-chart/year-to-date-availability`
      );

    return response.data;
  };

  const value = {
    searchAzureAppInsights:
      props.searchAzureAppInsights || searchAzureAppInsights,
    countAzureAppInsights: props.countAzureAppInsights || countAzureAppInsights,
    getAzureAppInsightById:
      props.getAzureAppInsightById || getAzureAppInsightById,
    createAzureAppInsight: props.createAzureAppInsight || createAzureAppInsight,
    updateAzureAppInsight: props.updateAzureAppInsight || updateAzureAppInsight,
    deleteAzureAppInsight: props.deleteAzureAppInsight || deleteAzureAppInsight,
    getAzureAppInsightChart:
      props.getAzureAppInsightChart || getAzureAppInsightChart,
    getAzureAppInsightTimePerformanceChart:
      props.getAzureAppInsightTimePerformanceChart ||
      getAzureAppInsightTimePerformanceChart,
    getAzureAppInsightTimeStatChart:
      props.getAzureAppInsightTimeStatChart || getAzureAppInsightTimeStatChart,
    getAzureAppInsightYearToDateAvailabilityChart:
      props.getAzureAppInsightYearToDateAvailabilityChart ||
      getAzureAppInsightYearToDateAvailabilityChart,
    getFirstAvailableAzureAppInsight:
      props.getFirstAvailableAzureAppInsight ||
      getFirstAvailableAzureAppInsight,
  };

  return (
    <AzureAppInsightServiceContext.Provider value={value}>
      {props.children}
    </AzureAppInsightServiceContext.Provider>
  );
};

export const useAzureAppInsightService = () => {
  return useContext(AzureAppInsightServiceContext);
};
