import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getByParameters as getUsersByParameters } from "../../services/UserService";
import { guid } from "../../types/guid";

export interface UserCacheObject {
  id: guid;
  userName: string;
  status: "success" | "pending" | "fetching" | "error";
}

const initialState: {
  data: { [key: guid]: UserCacheObject };
} = { data: {} };

let timer: any = null;

const performFetch = createAsyncThunk(
  "userCache/performFetch",
  async (_: any, thunk: any) => {
    console.log("perform fetch called");
    const userCacheObjects: UserCacheObject[] = Object.values(
      thunk.getState().userCache.data
    );
    let ids = userCacheObjects
      .filter((x) => x.status === "pending")
      .map((x) => x.id);
    if (ids.length > 0) {
      thunk.dispatch(setUsersFetchingState(ids));
      const response = await getUsersByParameters(ids, 1, ids.length);
      thunk.dispatch(addUsers(response.data));
    }
  }
);

export const queueIds = createAsyncThunk(
  "userCache/queueIds",
  async (ids: guid[], thunk: any) => {
    const userCacheState = thunk.getState().userCache;
    ids = ids.filter((x) => !userCacheState.data[x]);
    if (ids.length > 0) {
      ids.forEach((id) => thunk.dispatch(setUserPendingState(id)));
      timer && clearTimeout(timer);
      timer = setTimeout(async () => {
        thunk.dispatch(performFetch({}));
        //const response = await fetchUsers(ids);
        //thunk.dispatch(addUsers(response.data));
      }, 500);
      //return response.data;
    }
  }
);

// A mock function to mimic making an async request for data
export function fetchUsers(ids: guid[]) {
  console.log("fetching ids: ", ids);
  return new Promise<{ data: any }>((resolve) => {
    const users: any = [];
    ids.forEach(
      (x) =>
        (users[x] = {
          id: x,
          userName: "Random User " + Math.floor(Math.random() * 100),
        })
    );
    const usersArray = ids.map((x) => ({
      id: x,
      userName: "Random User " + Math.floor(Math.random() * 100),
    }));
    setTimeout(() => resolve({ data: usersArray }), 500);
  });
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getUsersAsync = createAsyncThunk(
  "userCache/getUsers",
  async (ids: guid[], thunk: any) => {
    const userCacheState = thunk.getState().userCache;
    ids = ids.filter((x) => !userCacheState[x]);
    if (ids.length > 0) {
      ids.forEach((id) => thunk.dispatch(setUserPendingState(id)));
      const response = await fetchUsers(ids);
      return response.data;
    }
  }
);

export const userCacheSlice = createSlice({
  name: "userCache",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addUsers: (
      state,
      action: PayloadAction<{ id: guid; forename: string; surname: string }[]>
    ) => {
      action.payload.forEach((user) => {
        state.data[user.id] = {
          id: user.id,
          userName: user.forename + " " + user.surname,
          status: "success",
        };
      });
    },
    setUserPendingState: (state, action: PayloadAction<guid>) => {
      state.data[action.payload] = {
        id: action.payload,
        status: "pending",
        userName: "...",
      };
    },
    setUsersFetchingState: (state, action: PayloadAction<guid[]>) => {
      action.payload.forEach((id) => {
        state.data[id] = {
          id,
          status: "fetching",
          userName: "...",
        };
      });
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending, (state, action) => {
        //state.status = "loading";
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        action.payload.forEach((user: UserCacheObject) => {
          state.data[user.id] = user;
        });
      });
  },
});

export const { setUserPendingState, setUsersFetchingState, addUsers } =
  userCacheSlice.actions;
export default userCacheSlice.reducer;
