import React, { useCallback, useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  _InboundDeliveryDocumentDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { generateRandomGuid, guid } from "types/guid";
import {
  Box,
  Button,
  CardContent,
  Grid,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { huSearchRequestV11Dto } from "interfaces/v11/hu/huSearchRequestV11Dto";
import { useHandlingUnitService } from "services/HandingUnitService";
import { useOrderService } from "services/OrderService";
import { orderSearchRequestV11Dto } from "interfaces/v11/order/orderSearchRequestV11Dto";
import useAuth from "hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import {
  OrderStatusEnum,
  orderTaskDto,
  orderV11Dto,
} from "interfaces/v11/order/orderV11Dto";
import { useBinService } from "services/BinService";
import { confirmTaskRequestV2Dto } from "interfaces/v2/order/confirmTaskRequestV2Dto";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import { fontSize } from "@mui/system";
import WmsTreeViewWithCheckbox, {
  wmsTreeType,
} from "components/platbricks/shared/WmsTreeViewWithCheckbox";
import Scanner from "components/platbricks/shared/Scanner";
import { PbCard } from "components/platbricks/shared/PbCard";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { huSearchRequestV2Dto } from "interfaces/v2/hu/huSearchRequestV2Dto";
import {
  TaskV2Dto,
  WarehouseTaskV2RequestDto,
} from "interfaces/v2/warehouseTask/warehouseTaskV2RequestDto";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { useWmsService } from "services/WmsService";
import { getWmsTreeRequestDto } from "interfaces/v1/wmsTree/getWmsTreeRequestDto";
import { WmsTreeNodeDto } from "interfaces/v1/wmsTree/WmsTreeNodeDto";
import { useSettingService } from "services/SettingService";
import { usePrintService } from "services/PrintService";

interface scannedWms {
  wmsId: string;
  displayName: string;
  wmsType: string;
  rootWmsId: string;
}

const MovementComponent: React.FC = () => {
  const { locationId } = useParams();
  const huService = useHandlingUnitService();
  const goodReceiptService = useGoodReceiptService();
  const wmsService = useWmsService();

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  //generic setting
  const maxLength: number = 30;
  const skipTreeView: boolean = true;
  //if set to false, then root element will be use. It will render everything start from root bin for selection
  const scannedWmsIdAsDestinationRoot: boolean = true;
  const allowSelectBin: boolean = false;

  const printService = usePrintService();
  const settingService = useSettingService();
  var showPrinterNotConfiguredWarning: boolean = true;
  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";
  const display_printerNotConfigured: string =
    "Die Druckereinstellungen sind nicht konfiguriert. Es wird kein HU-Label gedruckt. ";
  const [printServerId, setPrintServerId] = useState("");
  const [printerId, setPrinterId] = useState("");
  useEffect(() => {
    settingService
      .getLocationSetting(locationId as string)
      .then((result) => {
        if (result) {
          if (result.movementSetting?.printServerId != undefined) {
            setPrintServerId(result.movementSetting?.printServerId);
          }
          if (result.movementSetting?.printerId != undefined) {
            setPrinterId(result.movementSetting?.printerId);
          }
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      })
      .catch(() => {
        displayNotification(display_SomethingWentWrong);
      });
  }, [settingService]);

  const [debounce, setDebounce] = useState<boolean>(false);
  useEffect(() => {
    //console.log("debounce " + new Date(), debounce);
  }, [debounce]);

  //scan message
  const display_NotBinOrHuPlaceHolder: string = "{value}";
  const display_NotBinOrHU: string = "{value} is neither Bin or HU. ";

  //scan source
  const [scanningSource, setScanningSource] = useState<boolean>(true);
  const [scanningDestination, setScanningDestination] =
    useState<boolean>(false);

  const display_Scan: string = "Scannen";
  const display_Scanning: string = "Scan abbrechen";
  const display_ScanSourceInstruction: string = "Lagerplatz oder HU scannen";
  const [scannedWmsSource, setScannedWmsSource] = useState<scannedWms | null>(
    null
  );
  function resetScanWmsSource() {
    setSourceText("");
    setDestinationText("");
    setScanningSource(true);
    setScannedWmsSource(null);
    setWmsTree([]);
    setSelectedWmsItems([]);
    setConfirmSelectedItemsForMovement(false);
    setScannedWmsDestination(null);
    setConfirmSelectedDestinationForMovement(false);
  }
  function scanWmsSourceStarted() {
    resetScanWmsSource();
    setScanningSource(true);
  }
  function scanWmsSourceCompleted(scannedValue: string) {
    setScanningSource(false);
    searchBin(scannedValue, true);
  }
  function scanWmsSourceCancelled() {
    setScanningSource(false);
  }

  function searchBin(scannedValue: string, IsSource: boolean) {
    if (scannedValue.length >= 0) {
      if (debounce) {
        return;
      }
      setDebounce(true);

      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(scannedValue);

      let searchDto: binSearchRequestV11Dto = {
        locationId: locationId! as string,
        BinNames: searchTexts,
        binNameContains: [],
      };
      huService
        .getBin(searchDto)
        .then((result) => {
          setDebounce(false);
          if (result.length == 0) {
            searchHU(scannedValue, IsSource);
          } else if (result.length == 1) {
            //displayNotification("Found Bin. ");

            if (IsSource) {
              const scanneditem: scannedWms = {
                wmsId: result[0].wmsId,
                displayName: result[0].bin,
                wmsType: wmsTreeType.bin,
                rootWmsId: result[0].rootElement,
              };

              if (skipTreeView) {
                let selectedWmsIds: string[] = [];
                selectedWmsIds.push(result[0].wmsId);
                setSelectedWmsItems(selectedWmsIds);
                setScannedWmsSource(scanneditem);
                setConfirmSelectedItemsForMovement(true);
                setScanningDestination(true);
                //setScanningDestination(true);
              } else {
                GetSetTreeView(scanneditem, true);
              }
            } else {
              setScannedWmsDestination({
                wmsId: result[0].wmsId,
                displayName: result[0].bin,
                wmsType: wmsTreeType.bin,
                rootWmsId: result[0].rootElement,
              });
              //setConfirmSelectedDestinationForMovement(true);
              createTask({
                wmsId: result[0].wmsId,
                displayName: result[0].bin,
                wmsType: wmsTreeType.bin,
                rootWmsId: result[0].rootElement,
              });
            }
          } else {
            setDebounce(false);
            displayNotification("Found More than 1 Bin. ");
          }
        })
        .catch((response) => {
          displayNotification("Something went wrong when search for Bin. ");
        });
    }
  }

  function searchHU(scannedValue: string, IsSource: boolean) {
    if (scannedValue.length > 0) {
      if (debounce) {
        return;
      }
      setDebounce(true);

      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(scannedValue);

      let searchDto: huSearchRequestV2Dto;
      searchDto = {
        name: scannedValue,
      };

      huService
        .searchHandlingUnit(searchDto)
        .then((result) => {
          setDebounce(false);
          if (result.length == 0) {
            displayNotification(
              display_NotBinOrHU.replace(
                display_NotBinOrHuPlaceHolder,
                scannedValue
              )
            );
          } else if (result.length == 1) {
            //displayNotification("Found HU. ");

            if (IsSource) {
              const scanneditem: scannedWms = {
                wmsId: result[0].wmsId,
                displayName: result[0].handlingUnit,
                wmsType: wmsTreeType.hu,
                rootWmsId: result[0].rootElement,
              };

              if (skipTreeView) {
                let selectedWmsIds: string[] = [];
                selectedWmsIds.push(result[0].wmsId);
                setSelectedWmsItems(selectedWmsIds);
                setScannedWmsSource(scanneditem);
                setConfirmSelectedItemsForMovement(true);
                setScanningDestination(true);
                //setScanningDestination(true);
              } else {
                GetSetTreeView(scanneditem, false);
              }
            } else {
              setScannedWmsDestination({
                wmsId: result[0].wmsId,
                displayName: result[0].handlingUnit,
                wmsType: wmsTreeType.hu,
                rootWmsId: result[0].rootElement,
              });
              //setConfirmSelectedDestinationForMovement(true);
              createTask({
                wmsId: result[0].wmsId,
                displayName: result[0].handlingUnit,
                wmsType: wmsTreeType.hu,
                rootWmsId: result[0].rootElement,
              });
            }
          } else {
            displayNotification("Found More than 1 HU. ");
          }
        })
        .catch((response) => {
          setDebounce(false);
          displayNotification("Something went wrong when search HU");
        });
    }
  }

  function GetSetTreeView(scannedWmsSource: scannedWms, IsBin: boolean) {
    let searchBinIds: string[];
    searchBinIds = [];
    searchBinIds.push(scannedWmsSource.rootWmsId);

    const request: getWmsTreeRequestDto = {
      locationId: locationId as string,
      BinIds: searchBinIds,
    };

    wmsService
      .getWMSTree(request)
      .then((result) => {
        console.log(result);
        //alert("got result");
        setWmsTree(result);
        setScannedWmsSource(scannedWmsSource);
        //setWmsTree(result);
      })
      .catch((error) => {
        //console.log(error);
        //alert("got error");
        displayNotification("Could not get items in the target Bin");
      });
  }

  const display_sourceHU: string = "Lagerplatz oder HU";
  const display_search: string = "Weiter";
  const [sourceText, setSourceText] = useState<string>("");
  const handleSourceTextChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setSourceText(value);
  };
  function handleSourceTextKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (event.key === "Enter") {
      searchBin(sourceText, true);
    }
  }
  const handleSourceTextButtonClick = useCallback(async () => {
    searchBin(sourceText, true);
  }, [sourceText]);

  //select source item
  const display_Move: string = "Umlagern";
  const display_cancel: string = "Abbrechen";
  const display_MoveInventoryInstruction: string = "Bestand umlagern";
  const [wmsTree, setWmsTree] = useState<WmsTreeNodeDto[]>([]);
  const [selectedWmsItems, setSelectedWmsItems] = useState<string[]>([]);
  const [confirmSelectedItemsForMovement, setConfirmSelectedItemsForMovement] =
    useState<boolean>(false);
  function onChangeSelectedWmsId(selectedWmsIds: string[]) {
    setSelectedWmsItems(selectedWmsIds);
  }
  const ConfirmedMoveButtonClick = () => {
    setConfirmSelectedItemsForMovement(true);
  };
  const BackToScanningButtonClick = () => {
    resetScanWmsSource();
  };

  //select destination
  const padding: number = 3;
  const display_ContainerDisplayName: string = "Name";
  const display_ContainerType: string = "Type";
  const display_confirmDestinationBin: string = "Neues Ziel bestätigen";
  const display_SelectDestinationInstruction: string = "Ziel scannen";
  const [scannedWmsDestination, setScannedWmsDestination] =
    useState<scannedWms | null>(null);
  const [
    confirmSelectedDestinationForMovement,
    setConfirmSelectedDestinationForMovement,
  ] = useState<boolean>(false);

  //useEffect(() => {}, [scanningDestination]);

  function resetScanDestination() {
    setScannedWmsDestination(null);
    setScanningDestination(true);
  }
  function scanWmsDestinationStarted() {
    resetScanDestination();
    setScanningDestination(true);
  }
  function scanWmsDestinationCancelled() {
    setScanningDestination(false);
  }
  function scanWmsDestinationCompleted(scannedValue: string) {
    setScanningDestination(false);
    searchBin(scannedValue, false);
  }
  const ConfirmedDestinationButtonClick = () => {
    setConfirmSelectedDestinationForMovement(true);
  };
  const BackToSelectInventoryClick = () => {
    if (skipTreeView) {
      resetScanWmsSource();
    } else {
      setConfirmSelectedItemsForMovement(false);
    }
  };

  const display_destinationBin: string = "Ziel";
  const [destinationText, setDestinationText] = useState<string>("");
  const handleDestinationTextChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setDestinationText(value);
  };
  function handleDestinationTextKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (event.key === "Enter") {
      searchBin(destinationText, false);
    }
  }
  const handleDestinationTextButtonClick = useCallback(async () => {
    searchBin(destinationText, false);
  }, [destinationText]);

  //confirm
  const display_NoTask: string = "keine Buchung möglich.";
  const display_close: string = "beenden";
  const display_MovementSuccesful: string = "Umlagerung erfolgt";
  const navigate = useNavigate();
  const CloseClick = () => {
    setConfirmSelectedItemsForMovement(false);
    navigate("/index");
  };

  function ConvertSelectedItemsToWarehouseTask(
    destination: scannedWms
  ): WarehouseTaskV2RequestDto {
    let tasks: TaskV2Dto[] = [];
    let warehouseTask: WarehouseTaskV2RequestDto = {
      locationId: locationId as string,
      tasks: tasks,
    };

    const processTypeId =
      process.env.REACT_APP_MOVEMENT_PROCESS_TYPE_ID != undefined
        ? process.env.REACT_APP_MOVEMENT_PROCESS_TYPE_ID
        : "";

    selectedWmsItems.map((item) => {
      //result += "OrderId: " + item.orderId + ", DestinationHUId: " + item.huId + ", Quantity: " + item.quantity.toString() + "<br/>";
      let tempTask: TaskV2Dto = {
        id: 0,
        type: "3", //why 1?
        movementType: "",
        material: "",
        batch: "",
        dest_bin:
          destination.wmsType == wmsTreeType.bin ? destination?.wmsId : "",
        dest_hu:
          destination?.wmsType == wmsTreeType.hu ? destination?.wmsId : "",
        dest_hu_name: "",
        src_bin: scannedWmsSource?.rootWmsId!,
        src_hu:
          scannedWmsSource?.wmsType == wmsTreeType.hu
            ? scannedWmsSource?.wmsId
            : "",
        inboundDeliveryItem: "",
        outboundDeliveryItem: "",
        details: [],
        noSN_tag: true,
        newHUName: "",
        isInboundDeliveryGR: true,
        //processType: "4d00a12a-f389-4ed5-b562-a7cbce554562", //need to call API
        processType: processTypeId,
        inboundDeliveryId: "",
        outboundDeliveryId: "",
        srcStockType: "",
        dstStockType: "",
        //dstStockType: "a45cc45e-7e94-43f8-a46e-3612ee9d5dd2", //where to get?
        owner: "",
        buom: "",
        auom: "",
        quantityBuom: "",
        quantityAuom: "",
        taskStatus: "",
        huTypeId: "",
      };

      warehouseTask.tasks.push(tempTask);
    });

    return warehouseTask;
  }

  function createTask(destination: scannedWms) {
    if (debounce) {
      return;
    }
    setDebounce(true);

    const request = ConvertSelectedItemsToWarehouseTask(destination);

    if (request.tasks.length <= 0) {
      displayNotification(display_NoTask);
      setDebounce(false);
      return;
    }

    goodReceiptService
      .createTask(request)
      .then((result) => {
        setDebounce(false);
        if (result.status === "success") {
          //setConfirmSelectedDestinationForMovement(true);
          //displayNotification("Transkation abgeschlossen");
          //navigate("/movement");
          if (printServerId.length > 0 && printerId.length > 0) {
            printHULabel(request.tasks[0].dest_hu.length > 0 ? request.tasks[0].dest_hu : request.tasks[0].src_hu, printServerId, printerId);
          }
          resetScanWmsSource();
        } else {
          displayNotification(result.status + " - " + result.messages);
        }
      })
      .catch((response) => {
        setDebounce(false);
        console.log(response);
        displayNotification("Something went wrong when create task");
      });
  }

  function printHULabel(huId: string, printServerId: string, printerId: string) {
    console.log(
      "huId: " +
      huId +
      ", printServerId: " +
      printServerId +
      ", printerId: " +
      printerId
    );
    if (huId.length > 0 && printServerId.length > 0 && printerId.length > 0) {
      printService
        .printHU({
          handlingUnitId: huId,
          printServerId: printServerId,
          printerId: printerId,
        })
        .then((result) => {
          let msg: string = "";
          msg = JSON.stringify(result);
          console.log("Print request result: ", msg);
        })
        .catch((result) => {
          let errorMsg: string = "";
          if (result.errorMessage != undefined) {
            errorMsg = result.errorMessage;
          } else {
            errorMsg = JSON.stringify(result);
          }

          console.log("Print request failed: ", errorMsg);
        });
    }
  }

  return (
    <div>
      {showPrinterNotConfiguredWarning &&
        (printServerId.length == 0 || printerId.length == 0) ? (
        <div>
          <AlertBox
            alertType={AlertBoxType.info}
            contentText={display_printerNotConfigured}
          />
        </div>
      ) : (
        <div></div>
      )}

      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: scannedWmsSource != null ? "none" : "" }}
        >
          <div hidden={scannedWmsSource != null}>
            <div style={{ padding: padding }}>
              <AlertBox
                alertType={AlertBoxType.info}
                contentText={display_ScanSourceInstruction}
              />
            </div>
            <div style={{ padding: padding }}>
              <TextField
                id="sourceBin"
                name="sourceBin"
                label={display_sourceHU}
                variant="outlined"
                value={sourceText}
                fullWidth={true}
                onChange={handleSourceTextChanged}
                inputProps={{ maxLength }}
                autoComplete="off"
                onKeyDown={handleSourceTextKeyDown}
              />
            </div>
            <div style={{ padding: padding }}>
              <Button
                variant="contained"
                fullWidth={true}
                disabled={debounce}
                onClick={async () => {
                  await handleSourceTextButtonClick();
                }}
              >
                <div>
                  <br />
                  <br />
                </div>
                {display_search}
              </Button>
            </div>
            <div style={{ padding: padding }}>
              <Scanner
                startScan={scanningSource}
                beforeScanText={display_Scan}
                duringScanText={display_Scanning}
                scanCompleted={scanWmsSourceCompleted}
                scanStarted={scanWmsSourceStarted}
                scanCancelled={scanWmsSourceCancelled}
              />
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display:
              scannedWmsSource == null || confirmSelectedItemsForMovement
                ? "none"
                : "",
          }}
        >
          <div
            hidden={scannedWmsSource == null || confirmSelectedItemsForMovement}
          >
            <Grid item xs={12} sm={12}>
              <div>
                <AlertBox
                  alertType={AlertBoxType.info}
                  contentText={display_MoveInventoryInstruction}
                />
              </div>
              <div>
                <WmsTreeViewWithCheckbox
                  selectedWmsIds={selectedWmsItems}
                  onChange={onChangeSelectedWmsId}
                  treeData={wmsTree}
                  allowSelectBin={allowSelectBin}
                  allowSelectHU={false}
                  allowSelectStock={false}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box
                sx={{ width: "100%" }}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  disabled={debounce}
                  onClick={BackToScanningButtonClick}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  {display_cancel}
                </Button>
                <Button
                  onClick={ConfirmedMoveButtonClick}
                  variant="contained"
                  sx={{ mr: 1 }}
                  disabled={selectedWmsItems.length <= 0 || debounce}
                >
                  {display_Move}
                </Button>
              </Box>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display:
              !confirmSelectedItemsForMovement ||
                confirmSelectedDestinationForMovement
                ? "none"
                : "",
          }}
        >
          <div
            hidden={
              !confirmSelectedItemsForMovement ||
              confirmSelectedDestinationForMovement
            }
          >
            <Grid item xs={12} sm={12}>
              <div style={{ padding: padding }}>
                <AlertBox
                  alertType={AlertBoxType.info}
                  contentText={display_SelectDestinationInstruction}
                />
              </div>
              <div style={{ padding: padding }}>
                <TextField
                  id="destinationBin"
                  name="destinationBin"
                  label={display_destinationBin}
                  variant="outlined"
                  value={destinationText}
                  fullWidth={true}
                  onChange={handleDestinationTextChanged}
                  inputProps={{ maxLength }}
                  autoComplete="off"
                  onKeyDown={handleDestinationTextKeyDown}
                />
              </div>
              <div style={{ padding: padding }}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  disabled={debounce}
                  onClick={async () => {
                    await handleDestinationTextButtonClick();
                  }}
                >
                  <div>
                    <br />
                    <br />
                  </div>
                  {display_search}
                </Button>
              </div>
              <Scanner
                startScan={scanningDestination}
                beforeScanText={display_Scan}
                duringScanText={display_Scanning}
                scanCompleted={scanWmsDestinationCompleted}
                scanStarted={scanWmsDestinationStarted}
                scanCancelled={scanWmsDestinationCancelled}
              />
              <br />
              <br />
              {/* <div hidden={scannedWmsDestination == null}> */}
              <div hidden={true}>
                <div style={{ padding: padding }} hidden={false}>
                  <TextField
                    label={display_ContainerDisplayName}
                    variant="outlined"
                    value={scannedWmsDestination?.displayName}
                    aria-required={true}
                    disabled={true}
                    fullWidth={true}
                  />
                </div>
                <div style={{ padding: padding }} hidden={false}>
                  <TextField
                    label={display_ContainerType}
                    variant="outlined"
                    value={scannedWmsDestination?.wmsType}
                    aria-required={true}
                    disabled={true}
                    fullWidth={true}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div hidden={true}>
                <Box
                  sx={{ width: "100%" }}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    disabled={debounce}
                    onClick={BackToSelectInventoryClick}
                    variant="contained"
                    sx={{ mr: 1 }}
                  >
                    {display_cancel}
                  </Button>
                  <Button
                    onClick={ConfirmedDestinationButtonClick}
                    variant="contained"
                    sx={{ mr: 1 }}
                    disabled={scannedWmsDestination == null || debounce}
                  >
                    {display_confirmDestinationBin}
                  </Button>
                </Box>
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: !confirmSelectedDestinationForMovement ? "none" : "",
          }}
        >
          <div hidden={!confirmSelectedDestinationForMovement}>
            <AlertBox
              alertType={AlertBoxType.success}
              contentText={display_MovementSuccesful}
            />
            <Box
              sx={{ width: "100%" }}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                onClick={CloseClick}
                variant="contained"
                sx={{ mr: 1 }}
                disabled={scannedWmsDestination == null || debounce}
              >
                {display_close}
              </Button>
            </Box>
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          border: "1px solid grey",
          padding: "5px",
          borderRadius: "5px",
        }}
        hidden={true}
      >
        <div>Debug</div>
        <div>{JSON.stringify(scannedWmsSource)}</div>
        <div>{JSON.stringify(selectedWmsItems)}</div>
        <div>{JSON.stringify(scannedWmsDestination)}</div>
      </div>
    </div>
  );
};

export default React.memo(MovementComponent);
