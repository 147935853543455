import Icon from "@mui/material/Icon";
import { CompanyMenuDetailsV12Dto } from "interfaces/v12/companyMenus/menuTreesDetails/CompanyMenuDetailV12Dto";
import { MenuTreeItemDetailsv12Dto } from "interfaces/v12/companyMenus/menuTreesDetails/MenuTreeItemDetailsv12Dto";
import { useEffect, useState } from "react";
import { useCompanyMenuService } from "services/CompanyMenuService";
import { generateRandomGuid, guid } from "types/guid";
import { blueGrey } from "@mui/material/colors";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { SidebarItemsType } from "types/sidebar";

//Private function
const mapReactIcon = (icon: string | undefined) => {
  let iconName = icon ? icon : "panorama_fish_eye";
  return <Icon sx={{ color: blueGrey[400] }}>{iconName}</Icon>;
};

//Map Location
const getSection = (element: MenuTreeItemDetailsv12Dto) => {
  const sideItemsType = {
    id: element.id,
    href: element.urlReact?.replaceAll(":locationId", element.locationId || ""),
    title: element.name,
    icon: mapReactIcon(element.iconReact),
    children: getChildrenSections(element.children),
  } as SidebarItemsType;

  return sideItemsType;
};

const getChildrenSections = (children: MenuTreeItemDetailsv12Dto[]) => {
  const sideItemsTypes = [] as SidebarItemsType[];

  if (children) {
    children.forEach((element) => {
      const obj = {
        id: element.id,
        href: element.urlReact?.replaceAll(
          ":locationId",
          element.locationId || ""
        ),
        title: element.name,
        icon: mapReactIcon(element.iconReact),
        children: getChildrenSections(element.children),
      } as SidebarItemsType;

      sideItemsTypes.push(obj);
    });
  }

  return sideItemsTypes;
};

const useCompanyMenu = (companyMenuId: guid) => {
  const [companyMenu, setCompanyMenu] =
    useState<CompanyMenuDetailsV12Dto | null>(null);

  let dashboardItems: {
    id: string;
    title: string;
    pages: SidebarItemsType[];
  }[] = [];

  const CompanyMenuService = useCompanyMenuService();

  //Side Effect
  useEffect(() => {
    // const fetchData = async () => {
    //   CompanyMenuService.getCompanyMenusById(companyMenuId).then(
    //     (companyMenu) => {
    //       if (companyMenu) {
    //         setCompanyMenu(companyMenu);
    //       }
    //     }
    //   );
    // };

    const fetchData2 = async () => {
      CompanyMenuService.getLocation().then(
        (companyMenu) => {
          if (companyMenu) {
            setCompanyMenu(companyMenu);
          }
        }
      );
    };

    //fetchData();
    fetchData2();
  }, [CompanyMenuService, companyMenuId]);
  //}, []);
  if (companyMenu) {
    companyMenu.menuTrees.forEach((element) => {
      const locationId = element.locationId;
      const isTenant = locationId !== "" ? true : false;

      if (isTenant) {
        let locationMenuItem = dashboardItems.find(
          (x) => x.title === "Locations"
        );
        if (!locationMenuItem) {
          locationMenuItem = {
            id: generateRandomGuid(),
            title: "Locations",
            pages: [getSection(element)],
          };

          dashboardItems.push(locationMenuItem);
        } else {
          const sideItemsType = getSection(element);
          locationMenuItem.pages.push(sideItemsType);
        }
      } else {
        let generalMenuItem = dashboardItems.find((x) => x.title === "General");
        if (!generalMenuItem) {
          const sideItemsTypes = [] as SidebarItemsType[];

          const sideItemsType = getSection(element);
          sideItemsTypes.push(sideItemsType);

          generalMenuItem = {
            id: generateRandomGuid(),
            title: "General",
            pages: sideItemsTypes,
          };

          dashboardItems.push(generalMenuItem);
        } else {
          const sideItemsType = getSection(element);
          generalMenuItem.pages.push(sideItemsType);
        }
      }
    });
  }
  dashboardItems.forEach((item) => {
    item.pages = removeEmptyPages(item.pages);
  });
  dashboardItems = dashboardItems.filter((item) => item.pages.length > 0);
  return dashboardItems;
};

const removeEmptyPages = (pages: SidebarItemsType[]): SidebarItemsType[] => {
  pages.forEach((page) => {
    page.children = removeEmptyPages(page.children);
  });
  return pages.filter((page) => {
    if (!page.href) {
      return false;
    }
    if (page.href === "#" && page.children.length === 0) {
      return false;
    }
    return true;
  });
};

export default useCompanyMenu;
