import { useCallback } from "react";
import { useSelector } from "react-redux";
import { queueIds } from "../redux/slices/userCache";
import { RootState } from "../redux/store";
import { guid } from "../types/guid";
import useAppDispatch from "./useAppDispatch";

const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

const useUserCache = () => {
  const userCache = useSelector((state: RootState) => state.userCache);
  const dispatch = useAppDispatch();
  const getUserById = useCallback(
    (id: guid) => {
      if (id === EMPTY_GUID) {
        return { userName: "" };
      }
      if (userCache.data[id]) {
        return userCache.data[id];
      } else {
        dispatch(queueIds([id]));
        return { userName: "loading..." };
      }
    },
    [userCache]
  );
  return [getUserById];
};
export default useUserCache;
