import React, { useState } from "react";
import {
  _InboundDeliveryDocumentDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { Box, Button, Grid, Paper, TextField, styled } from "@mui/material";

interface targetOrderTask {
  orderId: string;
  orderTaskId: string;
  orderNumber: string;
  orderTaskNumber: string;

  srcBinId: string;
  srcBinName: string;

  dstBinId: string;
  dstBinName: string;

  srcHUId: string;
  srcHUName: string;

  dstHUId: string;
  dstHUName: string;
}

interface OrderDetailComponent {
  detail: targetOrderTask;
}

const OrderDetailComponent: React.FC<OrderDetailComponent> = (prop) => {
  const [targetTask, setTargetTask] = useState<targetOrderTask>(prop.detail);

  const IsDebug: boolean = false;
  const padding: number = 3;
  const display_detail: string = "Details";

  const display_OrderId: string = "OrderId";
  const display_OrderNo: string = "Auftrag";

  const display_OrderTaskId: string = "OrderTaskId";
  const display_OrderTaskNo: string = "OrderTaskNo";

  const display_SrcHUId: string = "srcHuId";
  const display_SrcHUName: string = "HU";

  const display_SrcBinId: string = "Src Bin Id";
  const display_SrcBin: string = "Von Platz";

  const display_DstBinId: string = "Dst Bin Id";
  const display_DstBin: string = "Ziel Platz";

  return (
    <Box sx={{ width: "100%" }}>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <div style={{ padding: padding }} hidden={!IsDebug}>
              <TextField
                label={display_OrderId}
                variant="outlined"
                value={targetTask.orderId}
                aria-required={true}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }} hidden={!IsDebug}>
              <TextField
                label={display_OrderNo}
                variant="outlined"
                value={targetTask.orderNumber}
                aria-required={true}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }} hidden={!IsDebug}>
              <TextField
                label={display_OrderTaskId}
                variant="outlined"
                value={targetTask.orderTaskId}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }} hidden={!IsDebug}>
              <TextField
                label={display_OrderTaskNo}
                variant="outlined"
                value={targetTask.orderTaskNumber}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }} hidden={!IsDebug}>
              <TextField
                label={display_SrcHUId}
                variant="outlined"
                value={targetTask.srcHUId}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }}>
              <TextField
                label={display_SrcHUName}
                variant="outlined"
                value={targetTask.srcHUName}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }} hidden={!IsDebug}>
              <TextField
                label={display_SrcBinId}
                variant="outlined"
                value={targetTask.srcBinId}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }}>
              <TextField
                label={display_SrcBin}
                variant="outlined"
                value={targetTask.srcBinName}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }} hidden={!IsDebug}>
              <TextField
                label={display_DstBinId}
                variant="outlined"
                value={targetTask.dstBinId}
                disabled={true}
                fullWidth={true}
              />
            </div>
            <div style={{ padding: padding }}>
              <TextField
                label={display_DstBin}
                variant="outlined"
                value={targetTask.dstBinName}
                disabled={true}
                fullWidth={true}
                InputProps={{
                  style: { fontWeight: "900", fontSize: "large" },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default React.memo(OrderDetailComponent);
