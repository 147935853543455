import { Autocomplete, TextField } from "@mui/material";
import { WmsAutoCompleteOption, WmsAutoCompleteSearch, WmsAutoCompleteType } from "interfaces/general/util/AutoCompleteOption";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huSearchRequestV11Dto } from "interfaces/v11/hu/huSearchRequestV11Dto";
import { huSearchResponseV11Dto } from "interfaces/v11/hu/huSearchResponseV11Dto";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { WMSMaterialV11Dto } from "interfaces/v11/materialDetail/wmsMaterialDetailsV11Dto";
import { wmsMaterialSearchV11Dto } from "interfaces/v11/materialDetail/wmsMaterialSearchV11Dto";
import React, { useEffect, useState } from "react";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { useHandlingUnitService } from "services/HandingUnitService";
import Scanner from "./Scanner";



interface WmsAutoCompleteProp {
    locationId: string;
    populateLength: number;
    displayLabel: string;
    autoCompleteType: WmsAutoCompleteType;
    selectedOption: (WmsAutoCompleteOption | null);
    optionSelected: (option: WmsAutoCompleteOption | null) => void;
    notifyMessage: (message: string) => void;
    withScanner: boolean;
}

const WmsAutoComplete: React.FC<WmsAutoCompleteProp> = (
    prop
) => {
    const huService = useHandlingUnitService();
    const goodReceiptService = useGoodReceiptService();

    const display_noOptionMsg: string = "keine Optionen vorhanden";

    const [locationId, setLocationId] = useState<string>(prop.locationId);
    const [displayLabel, setDisplayLabel] = useState<string>(prop.displayLabel);
    const [autoCompleteType, setAutoCompleteType] = useState<WmsAutoCompleteType>(prop.autoCompleteType);
    const [selectedOption, setSelectedOption] = useState<WmsAutoCompleteOption | null>(prop.selectedOption);
    const [availableOption, setAvailableOption] = useState<WmsAutoCompleteOption[]>([]);
    const [searchAvailableOptionQuery, setSearchAvailableOptionQuery] = useState<WmsAutoCompleteSearch>({
        search: "",
    });

    const handleSelectedOptionChange = (
        event: React.ChangeEvent<{}>,
        option: WmsAutoCompleteOption | null
    ) => {
        if (option) {
            setSelectedOption(option);
            prop.optionSelected(option);
        } else {
            setSelectedOption(null);
            prop.optionSelected(null);
        }
    };

    const handleSearchOptionInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchAvailableOptionQuery({ search: event.target.value });
    };

    useEffect(() => {
        if (searchAvailableOptionQuery.search.length >= prop.populateLength) {
            if (autoCompleteType == WmsAutoCompleteType.BIN) {
                let searchTexts: string[];
                searchTexts = [];
                searchTexts.push(searchAvailableOptionQuery.search);
                searchAvailableOptionByBin(searchTexts);
            }
            else if (autoCompleteType == WmsAutoCompleteType.HU) {
                searchAvailableOptionByHU(searchAvailableOptionQuery.search);
            }
            else if (autoCompleteType == WmsAutoCompleteType.MATERIAL) {
                let searchTexts: string[];
                searchTexts = [];
                searchTexts.push(searchAvailableOptionQuery.search);
                searchAvailableOptionByMaterial(searchTexts);
            }
            else if (autoCompleteType == WmsAutoCompleteType.HUTYPE) {
                let searchTexts: string[];
                searchTexts = [];
                searchTexts.push(searchAvailableOptionQuery.search);
                searchAvailableOptionByHuType(searchTexts);
            }
            else {
                prop.notifyMessage("WmsAutoCompleteType invalid");
            }
        }
    }, [searchAvailableOptionQuery.search]);

    function searchAvailableOptionByBin(searchTexts: string[]) {
        let searchDto: binSearchRequestV11Dto = {
            locationId: locationId! as string,
            BinNames: [],
            binNameContains: searchTexts,
        };
        huService
            .getBin(searchDto)
            .then((result) => {
                setAvailableOption(convertBinResultToSelectOptions(result));
            })
            .catch((response) => {
                console.log("Something went wrong when search bin");
            });
    }

    function getSelectedOptionByBin(searchText: string) {
        let searchDto: binSearchRequestV11Dto = {
            locationId: locationId! as string,
            BinNames: [],
            binNameContains: [],
        };
        searchDto.BinNames.push(searchText);

        huService.getBin(searchDto)
            .then((results) => {
                if (results && results.length == 1) {
                    var option: WmsAutoCompleteOption = convertBinResultToSelectOptions(results)[0];
                    setSelectedOption(option);
                }
                else {
                    prop.notifyMessage("Not a valid Bin");
                }
            })
            .catch(() => console.log("Something went wrong when search Bin"));
    }

    useEffect(() => {
        //console.log("selectedOption", selectedOption);
    }, [selectedOption]);

    //prop.selectedOption


    function searchAvailableOptionByHU(searchText: string) {
        let searchDto: huSearchRequestV11Dto = {
            locationId: locationId! as string,
            handlingUnitNumberContains: searchText,
            allowMoreThanOneParameter: true,
        };
        huService
            .searchHandlingUnitV11(searchDto)
            .then((result) => {
                setAvailableOption(convertHuResultToSelectOptions(result));
            })
            .catch((response) => {
                console.log("Something went wrong when search HU");
            });
    }

    function getSelectedOptionByHU(searchText: string) {
        let searchDto: huSearchRequestV11Dto = {
            locationId: locationId! as string,
            handlingUnitNumberContains: searchText,
            allowMoreThanOneParameter: true,
        };

        huService.searchHandlingUnitV11(searchDto)
            .then((results) => {
                if (results && results.length == 1) {
                    var option: WmsAutoCompleteOption = convertHuResultToSelectOptions(results)[0];
                    setSelectedOption(option);
                }
                else {
                    prop.notifyMessage("Not a valid HU");
                }
            })
            .catch(() => console.log("Something went wrong when search HU"));
    }

    function searchAvailableOptionByMaterial(searchTexts: string[]) {
        let searchDto: wmsMaterialSearchV11Dto;
        searchDto = {
            materialAndNumberContains: [],
            page: 0,
            pageSize: 20,
        };
        searchDto.materialAndNumberContains = searchTexts;

        goodReceiptService.searchWmsMaterials(searchDto)
            .then((result) => {
                setAvailableOption(convertWmsMaterialResultToSelectOptions(result));
            })
            .catch(() => console.log("Something went wrong when search material"));
    }

    function getSelectedOptionByMaterial(searchText: string) {
        let searchDto: wmsMaterialSearchV11Dto;
        searchDto = {
            materials: [],
            page: 0,
            pageSize: 20,
        };
        searchDto.materials!.push(searchText);

        goodReceiptService.searchWmsMaterials(searchDto)
            .then((results) => {
                if (results && results.length == 1) {
                    var result = results[0];
                    var option: WmsAutoCompleteOption = convertWmsMaterialResultToSelectOptions(results)[0];
                    setSelectedOption(option);
                }
                else {
                    prop.notifyMessage("Not a valid Material");
                }
            })
            .catch(() => console.log("Something went wrong when search material"));
    }

    function searchAvailableOptionByHuType(searchTexts: string[]) {
        let searchDto: huTypeSearchRequestV11Dto;
        searchDto = {
            page: 0,
            pageSize: 20,
            nameContains: [],
        };
        searchDto.nameContains = searchTexts;

        huService
            .getHandlingUnitType(searchDto)
            .then((result) => {
                setAvailableOption(convertHuTypeResultToSelectOptions(result));
            })
            .catch(() => console.log("Something went wrong when search hu type"));
    }

    function convertBinResultToSelectOptions(
        data: binSearchResponseV11Dto[]
    ): WmsAutoCompleteOption[] {
        return data.map((item) => ({
            value: item.wmsId,
            label: item.bin,
            type: WmsAutoCompleteType.BIN,
            binDetail: item
        }));
    }

    function convertHuResultToSelectOptions(
        data: huSearchResponseV11Dto[]
    ): WmsAutoCompleteOption[] {
        return data.map((item) => ({
            value: item.wmsId,
            label: item.handlingUnit,
            additionalAttribute1: item.rootElement!,
            type: WmsAutoCompleteType.HU,
            huDetail: item
        }));
    }

    function convertWmsMaterialResultToSelectOptions(
        data: WMSMaterialV11Dto[]
    ): WmsAutoCompleteOption[] {
        return data.map((item) => ({
            value: item.materialId,
            label: item.material + " - " + item.name,
            type: WmsAutoCompleteType.MATERIAL,
            materialDetail: item
        }));
    }

    function convertHuTypeResultToSelectOptions(
        data: huTypeSearchResponseV11Dto[]
    ): WmsAutoCompleteOption[] {
        return data.map((item) => ({
            value: item.handlingUnitTypeId,
            label: item.name,
        }));
    }


    //scan material
    const display_Scan: string = "Scannen";
    const display_Scanning: string = "Scan abbrechen";

    const [scanning, setScanning] = useState<boolean>(true);
    const [scannedValue, setScannedValue] = useState<string>(
        ""
    );
    function resetScan() {
        setSelectedOption(null);
        setScannedValue("");
        setScanning(true);
    }
    function scanStarted() {
        resetScan();
        setScanning(true);
    }
    function scanCompleted(scannedValue: string) {
        setScanning(false);
        setScannedValue(scannedValue);
    }
    function scanCancelled() {
        setScanning(false);
    }
    useEffect(() => {
        if (scannedValue && scannedValue.length > 0) {
            if (autoCompleteType == WmsAutoCompleteType.BIN) {
                getSelectedOptionByBin(scannedValue);
            }
            else if (autoCompleteType == WmsAutoCompleteType.HU) {
                getSelectedOptionByHU(scannedValue);
            }
            else if (autoCompleteType == WmsAutoCompleteType.MATERIAL) {
                getSelectedOptionByMaterial(scannedValue);
            }
            else if (autoCompleteType == WmsAutoCompleteType.HUTYPE) {
                prop.notifyMessage("Scanner (WmsAutoCompleteType.HUTYPE) not supported");
            }
            else {
                prop.notifyMessage("WmsAutoCompleteType invalid");
            }
        }
        else {
            setSelectedOption(null);
        }
    }, [scannedValue]);

    // useEffect(() => {
    //     if (prop.showAddMaterialDialog && !scanningMaterial) {
    //         setScanningMaterial(true);
    //     }
    // }, [prop.]);

    return (
        <div>
            <Autocomplete
                noOptionsText={display_noOptionMsg}
                value={selectedOption}
                onChange={handleSelectedOptionChange}
                options={availableOption}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={displayLabel}
                        variant="outlined"
                        onChange={handleSearchOptionInputChange}
                    />
                )}
            />
            {prop.withScanner ?
                <Scanner
                    startScan={scanning}
                    beforeScanText={display_Scan}
                    duringScanText={display_Scanning}
                    scanCompleted={scanCompleted}
                    scanStarted={scanStarted}
                    scanCancelled={scanCancelled}
                />
                : <div></div>}
        </div>

    );
};

export default WmsAutoComplete;
