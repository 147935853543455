import { OutboundDeliveryDetailsV12Dto } from "interfaces/v12/outboundDelivery/outboundDeliveryDetails/outboundDeliveryDetailsV12Dto";
import { OutboundDeliverySearchV12Dto } from "interfaces/v12/outboundDelivery/outboundDeliverySearch/outboundDeliverySearchRequestV12Dto";
import React from "react";
import { guid } from "types/guid";
import { SelectAsyncOption } from "../components/platbricks/shared/SelectAsync";
import axios from "../utils/axios";
import CarrierConfigurationService from "./CarrierConfigurationService";
import LocationTransferService from "./LocationTransferService";
import WmsMaterialService from "./WmsMaterialService";
import WmsStockTypeService from "./WmsStockTypeService";
import { outboundDeliveryV2Dto } from "interfaces/v2/outboundDelivery/outboundDeliveryV2Dto";

const { createContext, useContext } = React;

interface IOutboundDeliveryService {
  getOutboundDeliveryById: (
    outboundDeliveryId: string
  ) => Promise<outboundDeliveryV2Dto>;
}

const OutboundDeliveryServiceContext = createContext(
  {} as IOutboundDeliveryService
);

export const OutboundDeliveryServiceProvider: React.FC<{
  children?: React.ReactNode;
  getOutboundDeliveryById?: any;
}> = (props) => {
  const getOutboundDeliveryById = async (outboundDeliveryId: string) => {
    const res = await axios.get("/v2/OutboundDeliveries/" + outboundDeliveryId);
    return res.data;

    // return await axios
    //   .get<outboundDeliveryV2Dto>(
    //     "/v2/OutboundDeliveries/" + outboundDeliveryId
    //   )
    //   .then(async (res) => {
    //     let result = res.data;
    //     return result;
    //   });
  };

  const value = {
    getOutboundDeliveryById:
      props.getOutboundDeliveryById || getOutboundDeliveryById,
  };

  return (
    <OutboundDeliveryServiceContext.Provider value={value}>
      {props.children}
    </OutboundDeliveryServiceContext.Provider>
  );
};

export const useOutboundDeliveryService = () => {
  return useContext(OutboundDeliveryServiceContext);
};
