import React, { useCallback, useEffect, useState } from "react";
import { TreeView, TreeItem } from "@mui/lab";
import { Badge, Button, CardContent, Checkbox, Grid, Paper, Typography, styled } from "@mui/material";
import { ExpandMore, ChevronRight, Label } from "@mui/icons-material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InventoryIcon from "@mui/icons-material/Inventory";
import { WmsTreeNodeDto } from "interfaces/v1/wmsTree/WmsTreeNodeDto";
import WmsTreeDisplay from "components/platbricks/shared/WmsTreeDisplay";
import { invDocItem, inventoryDocument } from "interfaces/v1/inventoryDocument/inventoryDocument";
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    PropaneSharp,
} from "@mui/icons-material";
import { useWmsService } from "services/WmsService";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { useParams } from "react-router-dom";
import CountTaskComponent from "./componnet/CountTaskComponent";
import { Page, PbCard } from "components/platbricks/shared";
import { useHandlingUnitService } from "services/HandingUnitService";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { countDocRequest, task } from "interfaces/v3/countDoc/countDocRequest";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import WmsMaterialService from "services/WmsMaterialService";
import { ConfirmationDialog } from "components/platbricks/shared/ConfirmationDialog";

// interface InventoryDocumentComponentProp {
//     invDoc: inventoryDocument | null;
// }

const StockCountingDetailPage = () => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    }));
    const breadcrumbs = [{ label: "Home", to: "/" }, { label: "Inventur" }];

    //const wmsMaterialService = usematerial
    const wmsService = useWmsService();
    const huService = useHandlingUnitService();
    const { locationId, id } = useParams();

    const [invDocId, setInvDocId] = useState(id);
    const [inventoryDocumentId, setInventoryDocumentId] = useState(id);

    const [countCompleted, setCountCompleted] = useState<boolean>(false);
    const [invDoc, setInvDoc] = useState<inventoryDocument | null>(null);
    const [binInfo, setBinInfo] = useState<binSearchResponseV11Dto | null>(null);

    const display_Inventory: string = "Inventur";
    const display_Finding: string = "Finding";

    //notificaiton dialog
    const [notificationText, setNotificationText] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    function displayNotification(message: string) {
        setNotificationText(message);
        setShowNotification(true);
    }

    const [debounce, setDebounce] = useState<boolean>(false);
    useEffect(() => {
        //console.log("debounce " + new Date(), debounce);
    }, [debounce]);


    function getBin(binId: string) {
        huService.getBinById(binId).then((binResult) => {
            setDebounce(false);
            setBinInfo(binResult);
        })
            .catch((err) => {
                setDebounce(false);
                //console.log("getBinById err", err);
                displayNotification("Failed to get Bin detail. " + err);
            });
    }

    // const fetchInventoryDocumentByDocId = useCallback(async () => {
    //     // let response = await fetch('api/data/' + userId)
    //     // response = await response.json()
    //     // dataSet(response)
    //     await getAndSetInventoryDocumentByDocId();
    // }, [id])

    var tempInventoryDocumentHolder: inventoryDocument | null = null;

    // async function getInventoryDocumentByDocId() {
    //     return await wmsService.getInventoryDocumentByDocId(invDocId as string)
    //         .then((getInventoryDocumentByDocIdResult) => {
    //             console.log("getInventoryDocumentByDocId", JSON.stringify(getInventoryDocumentByDocIdResult));

    //         })
    //         .catch((err) => {
    //             setDebounce(false);
    //             console.log("getInventoryDocumentByDocId err", err);
    //             displayNotification("Get Inventory Document failed. ");
    //         });
    // }

    function getAndSetInventoryDocumentByDocId() {
        tempInventoryDocumentHolder = null;
        return wmsService.getInventoryDocumentByDocId(invDocId as string)
            .then((getInventoryDocumentByDocIdResult) => {
                //console.log("getInventoryDocumentByDocIdResult", JSON.stringify(getInventoryDocumentByDocIdResult));
                tempInventoryDocumentHolder = getInventoryDocumentByDocIdResult;
                return huService.getBinById(getInventoryDocumentByDocIdResult.storageBinId!);
            })
            .then((getBinByIdResult) => {
                setDebounce(false);
                setBinInfo(getBinByIdResult);
                setInvDoc(tempInventoryDocumentHolder);

                //console.log("tempInventoryDocumentHolder", JSON.stringify(tempInventoryDocumentHolder));
                var tempCountRequest = prepareCountRequest(tempInventoryDocumentHolder!, getBinByIdResult.bin);
                setCountDocRequest(tempCountRequest);
            })
            .catch((err) => {
                setDebounce(false);
                console.log("getInventoryDocumentByDocId err", err);
                displayNotification("Get Inventory Document failed. ");
            });
    }


    function countDocCompleted() {
        //displayNotification("Count Doc Completed");
        //getAndSetInventoryDocumentByDocId();
        setCountCompleted(true);
    }

    const [countDocRequest, setCountDocRequest] = useState<countDocRequest>(getDefaultDocCountRequest());


    useEffect(() => {
        //getAndSetInventoryDocumentByDocId();
        //fetchInventoryDocumentByDocId();
        // (async () => {
        //     await getAndSetInventoryDocumentByDocId();
        // })();

        // async function fetch() {
        //     return wmsService.getInventoryDocumentByDocId(invDocId as string);
        // }
        // fetch().then((result) => console.log("async fetch", JSON.stringify(result)));

        getAndSetInventoryDocumentByDocId();
    }, [invDocId, wmsService]);
    useEffect(() => {

    }, [binInfo]);
    useEffect(() => {
        // if (invDoc != null && binInfo != null) {
        //     var tempCountRequest = prepareCountRequest(invDoc, binInfo.bin);
        //     setCountDocRequest(tempCountRequest);
        // }
    }, [invDoc, binInfo]);
    // useEffect(() => {
    // }, [countDocRequest]);




    function getDefaultDocCountRequest() {
        let invDocIds: string[];
        invDocIds = [];

        let countTasks: task[];
        countTasks = [];

        let output: countDocRequest = {
            locationId: "",
            invDocIds: invDocIds,
            tasks: countTasks,
        }

        return output;
    }

    function prepareCountRequest(input: inventoryDocument, binName: string): countDocRequest {

        let invDocIds: string[];
        invDocIds = [];
        invDocIds.push(input.invDocId);

        let countTasks: task[];
        countTasks = [];
        //console.log("prepareCountRequest input", input);
        input.invDocItem.forEach((x) => {
            //console.log("prepareCountRequest stringify input.invDocItem", JSON.stringify(x));
            var tempTask = convertInvDocItemToTask(x, binName, input.invDocItem);
            countTasks.push(tempTask);
        });

        //countTasks.sort(x => x.type);
        //countTasks.sort((a, b) => a.type.localeCompare(b.type) || a.huNumber.localeCompare(b.huNumber) || a.wmsMaterialId.localeCompare(b.wmsMaterialId));
        //countTasks.sort((a, b) => a.parentInvDocItemId.localeCompare(b.parentInvDocItemId));
        countTasks.sort((a, b) => a.orderKey!.localeCompare(b.orderKey!));


        let output: countDocRequest = {
            locationId: input.locationId,
            invDocIds: invDocIds,
            tasks: countTasks,
        }

        return output;
    }

    const defaultCountResult: string = "1"; //2 - missing, 1 - found
    function convertInvDocItemToTask(input: invDocItem, binName: string, availableInput: invDocItem[]): task {

        var parentDisplayName: string = "";
        if (input.parentInvDocItemId && input.parentInvDocItemId.length > 0) {
            var matchedParents = availableInput.filter((x) => x.invDocItemId === input.parentInvDocItemId);
            if (matchedParents && matchedParents.length == 1 && matchedParents[0].handlingUnit) {
                parentDisplayName = matchedParents[0].handlingUnit;
            }
        }
        else {
            parentDisplayName = binName;
        }

        var orderKey: string = (input.type == "BIN" ? invDoc?.binName! : input.type == "HU" ? input.handlingUnit! : "Stock");
        console.log("orderKey", orderKey);
        if (input.parentInvDocItem) {
            var temp = getParentNodeName(orderKey, input.parentInvDocItem.invDocItemId, availableInput);
            orderKey = temp;
        }


        let output: task = {
            type: input.type,
            huNumber: input.handlingUnit ? input.handlingUnit : "",
            huType: input.handlingUnitType ? input.handlingUnitType : "",
            invDocItemId: input.invDocItemId,
            wmsMaterialId: input.materialId,
            materialDisplay: "",
            temId: "",
            //stockUsage: input.stockUsage ? input.stockUsage : "", //?
            stockUsage: input.stockUsage === "UNSPECIFIED" ? "0" : input.stockUsage,
            owner: input.owner,
            specialStockType: input.specialStock ? input.specialStock : "",
            specialStockNumber: input.specialStockNumber ? input.specialStockNumber : "",
            customerStockItemNumber: input.customerStockItemNumber,
            parentElementId: input.parentInvDocItemId ? input.parentInvDocItemId : "",
            materialQuantity: input.bookQtyAuom,
            containerQuantity: 0,
            bomValue: input.bookAuom,
            huCountResult: defaultCountResult,
            batch: input.batch,
            invDocId: input.invDocId,
            stockType: input.stockTypeId,
            parentInvDocItemId: input.parentInvDocItemId ? input.parentInvDocItemId : "",
            huTypeId: "",
            method: 0, //?
            serialNumbers: [], //?

            parentName: parentDisplayName,
            orderKey: orderKey
        }

        return output;
    }

    function getParentNodeName(orderKey: string, parentNodeId: string, tasks: invDocItem[]): string {
        var targetParentNodes = tasks.filter((x) => x.invDocItemId == parentNodeId);
        if (targetParentNodes && targetParentNodes.length > 0) {
            var targetParentNode = targetParentNodes[0];
            orderKey = (targetParentNode.type == "HU" ? targetParentNode.handlingUnit : invDoc!.binName) + "-" + orderKey;
            if (targetParentNode.parentInvDocItem) {
                orderKey += getParentNodeName(orderKey, targetParentNode.parentInvDocItem.invDocItemId, tasks);
            }
        }

        return orderKey;
    }

    if (!invDoc || !binInfo) {
        return (
            <Page title={display_Inventory} breadcrumbs={[]} showLoading={true}>
                <div>{display_Finding}</div>
            </Page>
        );
    }

    return (
        <Page title={display_Inventory}
            subtitle={invDoc.invDocNumber}
            breadcrumbs={[
                {
                    label: "Home",
                    to: "/",
                },
                {
                    label: display_Inventory,
                    to: `/locations/${locationId}/stockCountings`,
                },
                {
                    label: invDoc.invDocNumber,
                },
            ]}
        >
            <PbCard>
                <CardContent>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid xs={12}>
                            <Item>
                                <div>
                                    <NotificationDialog
                                        title="Info"
                                        contentText={notificationText}
                                        onClose={() => {
                                            setShowNotification(false);
                                        }}
                                        visible={showNotification}
                                        onConfirm={() => {
                                            setShowNotification(false);
                                        }}
                                    />

                                    <div hidden={countCompleted}>
                                        <Grid container xs={12} sm={12}>
                                            <Grid container xs={12} sm={12}>
                                                <Grid item xs={12} sm={3}>
                                                    <Typography variant="subtitle1" color="textPrimary">
                                                        Inventurbeleg
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <Typography variant="subtitle1" color="textPrimary">
                                                        {invDoc.invDocNumber}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <Typography variant="subtitle1" color="textPrimary">
                                                        Lagerplatz
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <Typography variant="subtitle1" color="textPrimary">
                                                        {binInfo.bin}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <CountTaskComponent invDoc={invDoc} highlightText={""} highlightType={""}
                                            displayNotification={displayNotification} locationId={locationId!} countDocCompleted={countDocCompleted}
                                            countDocRequest={countDocRequest} />
                                        <br />
                                    </div>
                                    <div hidden={!countCompleted}>
                                        <AlertBox alertType={AlertBoxType.success} contentText="Beleg gebucht" />
                                    </div>


                                </div>
                            </Item>
                        </Grid>
                    </Grid>

                    {/* <div>
                        debug<br />
                        {JSON.stringify(invDoc)}
                    </div> */}
                </CardContent>
            </PbCard>
        </Page>


    )
};

export default StockCountingDetailPage;
