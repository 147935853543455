import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { _InboundDeliveryItemDetailsV11Dto } from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { generateRandomGuid } from "types/guid";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  InboundDeliveryItemQuantityRoleCategoryEnum,
  InboundDeliveryItemQuantityRoleEnum,
} from "interfaces/enums/InboundDeliveryEnums";

interface InboundDeliveryItemDetailProp {
  items: _InboundDeliveryItemDetailsV11Dto[];
  onChangeSelectedItem: (
    selectedItem: _InboundDeliveryItemDetailsV11Dto
  ) => void;
  showRemainingQty: boolean;
}

const InboundDeliveryItemListing: React.FC<InboundDeliveryItemDetailProp> = (
  prop
) => {
  const display_subtitle: string = "Position auswählen";
  const display_QuantityRequested: string = "Angefragte Menge";
  const display_QuantityOpen: string = "Offene Menge";
  const display_QuantityToPost: string = "Zu buchende Menge";
  const display_NoOpenGRQuantity: string = "No Open GR Quantity";
  const display_RemainingOpenQty: string = "Verbleibende offene Menge";
  const display_new: string = "Neu";
  const display_NoItem: string = "Keine Positionen vorhanden";
  const display_Owner: string = "Baulos";
  const display_Batch_Short: string = "ZGS";

  const handleSelectedItemClick = (item: _InboundDeliveryItemDetailsV11Dto) => {
    prop.onChangeSelectedItem(item);
    //setSelectedItemId(item.wmsId);
  };

  const handleDeleteItem = (id: string) => {
    alert(id);
  };

  function Line2Display(input: _InboundDeliveryItemDetailsV11Dto): string {
    let qtys = input.inboundDeliveryItemQuantities;
    if (qtys) {
      const filteredRequestedQty = qtys.filter(
        (item) =>
          item.quantityRoleCategory ===
            InboundDeliveryItemQuantityRoleCategoryEnum.REQUESTED &&
          item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
      );

      let result: string = "";
      if (filteredRequestedQty && filteredRequestedQty.length == 1) {
        const qty = filteredRequestedQty[0];
        result =
          display_QuantityRequested +
          ": " +
          qty.quantity.toString() +
          " " +
          qty.unitOfMeasurement;
      }

      const filteredOpenQty = qtys.filter(
        (item) =>
          item.quantityRoleCategory ===
            InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
          item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
      );

      if (filteredOpenQty && filteredOpenQty.length == 1) {
        const qty = filteredOpenQty[0];
        result +=
          "\n| " +
          display_QuantityOpen +
          ": " +
          qty.quantity.toString() +
          " " +
          qty.unitOfMeasurement;
      }

      if (prop.showRemainingQty) {
        result +=
          " | " + display_RemainingOpenQty + ": " + input.remainingOpenQty;
      }
      return result;
    }
    return display_NoOpenGRQuantity;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="subtitle2" gutterBottom>
        {display_subtitle}
      </Typography>
      {prop.items.length <= 0 ? (
        <i>{display_NoItem}</i>
      ) : (
        <List>
          {prop.items.map((item) => (
            <ListItem
              key={item.inboundDeliveryItemId}
              button
              onClick={() => handleSelectedItemClick(item)}
            >
              <ListItemText
                primary={
                  item.inboundDeliveryItemNumber +
                  " : " +
                  item.material?.material +
                  " - " +
                  item.material?.name
                }
                secondary={Line2Display(item)}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default React.memo(InboundDeliveryItemListing);
