import { Grid } from "@mui/material";
import Page from "components/platbricks/shared/Page";
import { AzureAppInsightDetailsV12Dto } from "interfaces/v12/azureAppInsight/azureAppInsightDetails/azureAppInsightDetailsV12Dto";
import { useCallback, useEffect, useState } from "react";
import { useAzureAppInsightService } from "services/AzureAppInsightService";
import { AzureAppInsightAvailabilityYearToDateChart } from "./components/AzureAppInsightAvailabilityYearToDateChart";
import { AzureAppInsightChart } from "./components/AzureAppInsightChart";
import { AzureAppInsightTimePerformanceChart } from "./components/AzureAppInsightTimePerformanceChart";
import { AzureAppInsightTimeStatChart } from "./components/AzureAppInsightTimeStatChart";

const DefaultDashboard = () => {
  const AzureAppInsightService = useAzureAppInsightService();
  const [azureAppInsight, setAzureAppInsight] =
    useState<AzureAppInsightDetailsV12Dto | null>(null);
  const [isAzureAppInsightConfigured, setIsAzureAppInsightConfigured] =
    useState<boolean>(false);

  const fetchAppInsight = useCallback(async () => {
    try {
      const data =
        await AzureAppInsightService.getFirstAvailableAzureAppInsight();

      setAzureAppInsight(data);

      setIsAzureAppInsightConfigured(true);
    } catch (error: any) {
      if (error?.errorCode === "NoAppInsightDefined")
        setIsAzureAppInsightConfigured(false);

      setAzureAppInsight(null);
    } finally {
    }
  }, [AzureAppInsightService]);

  useEffect(() => {
    async function initialFetchData() {
      await fetchAppInsight();
    }
    initialFetchData();
  }, [fetchAppInsight]);

  return (
    <Page title="Dashboard" breadcrumbs={[{ label: "Dashboard" }]}>
      {isAzureAppInsightConfigured && (
        <Grid
          container
          rowSpacing={4}
          columnSpacing={4}
          justifyContent="flex-start"
        >
          <Grid item xs={6}>
            <AzureAppInsightChart
              performanceThreshold={azureAppInsight?.performanceThreshold}
            />
          </Grid>
          <Grid item xs={6}>
            <AzureAppInsightTimeStatChart
              availabilityThreshold={azureAppInsight?.availabilityThreshold}
            />
          </Grid>
          <Grid item xs={8}>
            <AzureAppInsightTimePerformanceChart
              performanceThreshold={azureAppInsight?.performanceThreshold}
            />
          </Grid>
          <Grid item xs={4}>
            <AzureAppInsightAvailabilityYearToDateChart />
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

export default DefaultDashboard;
