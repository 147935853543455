import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  CardContent,
  Grid,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import Page from "components/platbricks/shared/Page";
import { useNavigate, useParams } from "react-router-dom";
import { PbCard } from "components/platbricks/shared";
import { generateRandomGuid } from "types/guid";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import OrderDetailComponent from "./component/OrderDetailComponent";
import { border } from "polished";
import PutAwayComponent from "./component/PutAwayComponent";

const breadcrumbs = [{ label: "Home", to: "/" }, { label: "Einlagerung" }];
function PutAwayPage() {
  const { locationId } = useParams();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Page title="Einlagerung" breadcrumbs={breadcrumbs}>
      <PbCard>
        <CardContent>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12}>
              <Item>
                <PutAwayComponent />
              </Item>
            </Grid>
          </Grid>
        </CardContent>
      </PbCard>
    </Page>
  );
}

export default PutAwayPage;
