import React from "react";
import axios from "../utils/axios";
import { locationSetting } from "interfaces/setting/locationSetting";
import { printServerSearchResponseDto } from "interfaces/general/print/printServerSearchResponseDto";
import { printerSearchResponseDto } from "interfaces/general/print/printerSearchResponseDto";
import { printerSearchRequestDto } from "interfaces/general/print/printerSearchRequestDto";
import { database } from "faker";
import { printHURequestDto } from "interfaces/general/print/printHURequestDto";
import { printHUResponseDto } from "interfaces/general/print/printHUResponseDto";
import { printConsignmentNoteRequestV11Dto } from "interfaces/general/print/printConsignmentNoteRequestV11Dto";
import { printConsignmentNoteResponseV11Dto } from "interfaces/general/print/printConsignmentNoteResponseV11Dto";

const { createContext, useContext } = React;

interface IPrintService {
  getPrinterServer: () => Promise<printServerSearchResponseDto[]>;
  getPrinters: (
    searchDto: printerSearchRequestDto
  ) => Promise<printerSearchResponseDto[]>;

  printHU: (printRequest: printHURequestDto) => Promise<printHUResponseDto>;

  printShippingLabel: (
    printRequest: printHURequestDto
  ) => Promise<printHUResponseDto>;

  printConsignmentNote: (
    printRequest: printConsignmentNoteRequestV11Dto
  ) => Promise<printConsignmentNoteResponseV11Dto>;
}

const PrintServiceContext = createContext({} as IPrintService);

export const PrintServiceProvider: React.FC<{
  children?: React.ReactNode;
  getPrinterServer?: any;
  getPrinters?: any;
  printHU?: any;
  printShippingLabel?: any;
  printConsignmentNote?: any;
}> = (props) => {
  const getPrinterServer = async () => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;

    const response = await axios({
      method: "get",
      url: "/print/GetPrintServers",
      headers: { configKey: config! },
    });
    return response.data;
  };

  const getPrinters = async (searchDto: printerSearchRequestDto) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;

    var bodyFormData = new FormData();
    bodyFormData.append("printServerId", searchDto.printServerId);

    const response = await axios({
      method: "get",
      url: "/print/GetPrinters?printServerId=" + searchDto.printServerId,
      data: bodyFormData,
      headers: { configKey: config!, "Content-Type": "multipart/form-data" },
    });
    return response.data;
  };

  const printHU = async (printRequest: printHURequestDto) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;

    var bodyFormData = new FormData();
    bodyFormData.append("handlingUnitId", printRequest.handlingUnitId);
    bodyFormData.append("printServerId", printRequest.printServerId);
    bodyFormData.append("printerId", printRequest.printerId);

    const response = await axios({
      method: "post",
      url: "/print/hulabel",
      data: bodyFormData,
      headers: { configKey: config!, "Content-Type": "multipart/form-data" },
    });
    return response.data;
  };

  // const printShippingLabel = async (printRequest: printHURequestDto) => {
  //   var config = process.env.REACT_APP_LOGIN_CONFIG;

  //   var bodyFormData = new FormData();
  //   bodyFormData.append("handlingUnitId", printRequest.handlingUnitId);
  //   bodyFormData.append("printServerId", printRequest.printServerId);
  //   bodyFormData.append("printerId", printRequest.printerId);

  //   const response = await axios({
  //     method: "post",
  //     url: "/print/ShippingLabel",
  //     data: bodyFormData,
  //     headers: { configKey: config!, "Content-Type": "multipart/form-data" },
  //   });
  //   return response.data;
  // };

  const printShippingLabel = async (printRequest: printHURequestDto) => {
    return axios
      .post(
        "/v11/Print/ShippingLabelByHandlingUnitId?handlingUnitId=" +
          printRequest.handlingUnitId +
          "&printServerId=" +
          printRequest.printServerId +
          "&printerId=" +
          printRequest.printerId
      )
      .then((res) => {
        return res.data;
      });
  };

  const printConsignmentNote = async (
    printRequest: printConsignmentNoteRequestV11Dto
  ) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;

    var bodyFormData = new FormData();
    bodyFormData.append("transportId", printRequest.transportId);
    bodyFormData.append("printServerId", printRequest.printServerId);
    bodyFormData.append("printerId", printRequest.printerId);

    const response = await axios({
      method: "post",
      url: "/print/ConsignmentNoteTransport",
      data: bodyFormData,
      headers: { configKey: config!, "Content-Type": "multipart/form-data" },
    });
    return response.data;
  };

  const value = {
    getPrinterServer: props.getPrinterServer || getPrinterServer,
    getPrinters: props.getPrinters || getPrinters,
    printHU: props.printHU || printHU,
    printShippingLabel: props.printShippingLabel || printShippingLabel,
    printConsignmentNote: props.printConsignmentNote || printConsignmentNote,
  };

  return (
    <PrintServiceContext.Provider value={value}>
      {props.children}
    </PrintServiceContext.Provider>
  );
};

export const usePrintService = () => {
  return useContext(PrintServiceContext);
};
