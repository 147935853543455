import Page from "components/platbricks/shared/Page";
import { useNavigate, useParams } from "react-router-dom";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import React, { useEffect, useState } from "react";
import {
  Button,
  CardContent,
  Collapse,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import { PbCard } from "components/platbricks/shared";
import Grid from "@mui/material/Unstable_Grid2";
import GoodReceiptSearchComponent from "./component/GoodReceiptSearchComponent";
import { useSettingService } from "services/SettingService";
import { locationSetting } from "interfaces/setting/locationSetting";

const breadcrumbs = [{ label: "Home", to: "/" }, { label: "Wareneingang" }];

const GoodReceiptPage = () => {
  const navigate = useNavigate();
  const { locationId } = useParams();

  //setting
  const display_settingNotConfigured: string =
    "Setting is not configured properly";
  const SettingService = useSettingService();
  const [setting, setSetting] = useState<locationSetting | null>(null);
  useEffect(() => {
    if (setting) {
      console.log(setting);
    }
  }, [setting]);

  function navigateToSetting() {
    let path = "/locations/" + locationId + "/settings";
    navigate(path);
  }

  function CheckSetting(): void {
    SettingService.isLocationSettingOk(locationId as string)
      .then((result) => {
        if (result) {
          console.log("Good Receipt Setting OK");
        } else {
          navigateToSetting();
        }
      })
      .catch(() => {
        alert("Ein Fehler ist aufgetreten.");
      });
  }

  CheckSetting();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [goodReceiptInfo, setGoodReceiptInfo] = useState({
    orderNo: "",
    deliveryNo: "",
    deliveryDate: new Date(),
    messageType: AlertBoxType.info,
    message: "Geben Sie eine Anliefernummer ein, um fortzufahren",
    orderIsValid: false,
  });

  return (
    <Page title="Wareneingang" breadcrumbs={breadcrumbs}>
      <PbCard>
        <CardContent>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12}>
              <Item>
                <GoodReceiptSearchComponent />
              </Item>
            </Grid>
          </Grid>
        </CardContent>
      </PbCard>
    </Page>
  );
};

export default GoodReceiptPage;
