import React, { useEffect, useState } from "react";
import { TreeView, TreeItem } from "@mui/lab";
import { Alert, Autocomplete, Badge, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography } from "@mui/material";
import { ExpandMore, ChevronRight, Label } from "@mui/icons-material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InventoryIcon from "@mui/icons-material/Inventory";
import { WmsTreeNodeDto } from "interfaces/v1/wmsTree/WmsTreeNodeDto";
import WmsTreeDisplay from "components/platbricks/shared/WmsTreeDisplay";
import { invDocItem, inventoryDocument } from "interfaces/v1/inventoryDocument/inventoryDocument";
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    PropaneSharp,
} from "@mui/icons-material";
import { countDocRequest, task } from "interfaces/v3/countDoc/countDocRequest";
import { generateRandomGuid } from "types/guid";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { AlertBoxType } from "components/platbricks/shared/AlertBox";
import WmsAutoComplete from "components/platbricks/shared/WmsAutoComplete";
import { WmsAutoCompleteOption, WmsAutoCompleteSearch, WmsAutoCompleteType } from "interfaces/general/util/AutoCompleteOption";
import NumberOnlyTextField from "components/platbricks/shared/NumberOnlyTextField";
import { batchSearchV11ResponseDto } from "interfaces/v11/batch/batchSearchV11Response";
import { batchSearchV11RequestDto } from "interfaces/v11/batch/batchSearchV11RequestDto";
import { ownerSearchResponseDto } from "interfaces/v1/owner/ownerSearchResponseDto";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import Scanner from "components/platbricks/shared/Scanner";
import { wmsMaterialSearchV11Dto } from "interfaces/v11/materialDetail/wmsMaterialSearchV11Dto";

interface AddMaterialTaskComponentProp {
    locationId: string;
    invDocId: string;
    binId: string;
    binName: string;
    stockTypeId: string;
    showAddMaterialDialog: boolean;
    title: string;
    existingTasks: task[];
    Close: () => void;
    MaterialTaskAdded: (addedTask: task) => void;
    displayNotification: (message: string) => void;
}

const AddMaterialTaskComponent: React.FC<AddMaterialTaskComponentProp> = (
    prop
) => {

    const GoodReceiptService = useGoodReceiptService();
    const [debounce, setDebounce] = useState<boolean>(false);
    useEffect(() => {
        //console.log("debounce " + new Date(), debounce);
    }, [debounce]);
    // useEffect(() => {
    //     if (prop.showAddMaterialDialog && !scanningMaterial) {
    //         setScanningMaterial(true);
    //     }
    // }, [prop.showAddMaterialDialog]);

    //notificaiton dialog
    const [notificationText, setNotificationText] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    function displayNotification(message: string) {
        setNotificationText(message);
        setShowNotification(true);
    }

    const display_noOptionMsg: string = "keine Optionen vorhanden";
    const display_confirm_AddToBin: string = "Add To Bin";
    const display_confirm_AddToHu: string = "Add To HU";
    const display_cancel: string = "Abbrechen";

    const display_MaterialNumber: string = "Materialnummer";
    const display_Quantity: string =
        "Menge";
    const [quantity, setQuantity] = useState<number>(0);
    // const [newMaterialNumber, setNewMaterialNumber] = useState<string>("");
    // const handleNewMaterialNumberChange = (materialNumber: string) => {
    //     setNewMaterialNumber(materialNumber);
    // }

    function reset() {
        //setNewMaterialNumber("");
        setSelectedWmsAutoCompleteOption(null);
        setSelectedBatchOption(null);
        setSelectedOwnerOption(null);
        setSelectedParentHu("");
        setSelectedParentHuDisplay("");
        setQuantity(0);
        setUnit("");

        setScannedParentValue("");
        //setScannedMaterialValue("");
    }

    const display_ParentHU: string = "Parent HU";
    const [selectedParentHu, setSelectedParentHu] = useState<string>("");
    const [selectedParentHuDisplay, setSelectedParentHuDisplay] = useState<string>("");
    const handleselectedParentHuChange = (event: SelectChangeEvent) => {
        setSelectedParentHu(event.target.value as string);
        setSelectedParentHuDisplay(event.target.name as string);
    };

    const handleAddToBin = () => {
        //onAdd(hu);
        if (debounce) {
            return;
        }
        setDebounce(true);

        var requiredParentHU: boolean = false;
        if (!validationPass(requiredParentHU)) {
            setDebounce(false);
            return;
        }

        var batchvalue: string = selectedBatchOption ? selectedBatchOption.label : "";
        var ownerValue: string = selectedOwnerOption ? selectedOwnerOption.label : "";
        var quantityFound: number = quantity;

        var newTask = CreateTaskBasedOnValue(selectedWmsAutoCompleteOption!, quantityFound, batchvalue, ownerValue, prop.binId, prop.binName);
        setDebounce(false);
        prop.MaterialTaskAdded(newTask);
        reset();

    };

    const handleAddToHu = () => {
        //onAdd(hu);
        if (debounce) {
            return;
        }
        setDebounce(true);

        var requiredParentHU: boolean = true;
        if (!validationPass(requiredParentHU)) {
            setDebounce(false);
            return;
        }

        var batchvalue: string = selectedBatchOption ? selectedBatchOption.label : "";
        var ownerValue: string = selectedOwnerOption ? selectedOwnerOption.label : "";
        var quantityFound: number = quantity;

        var newTask = CreateTaskBasedOnValue(selectedWmsAutoCompleteOption!, quantityFound, batchvalue, ownerValue, selectedParentHu, selectedParentHuDisplay);
        setDebounce(false);
        prop.MaterialTaskAdded(newTask);
        reset();

    };

    function validationPass(requiredParentHU: boolean): boolean {

        if (selectedWmsAutoCompleteOption == null) {
            displayNotification("Please select Material");
            return false;
        }

        if (requiredParentHU) {
            if (selectedParentHu.length <= 0) {
                displayNotification("Please select Parent HU");
                return false;
            }
        }

        return true;
    }

    const defaultCountResult: string = "1"; //2 - missing, 1 - found
    function CreateTaskBasedOnValue(selectedMaterial: WmsAutoCompleteOption,
        materialQuantity: number, batch: string, owner: string,
        parentElementId: string, parentElementDisplay: string): task {
        let output: task = {
            type: "Stock",
            huNumber: "",
            huType: "",
            invDocItemId: "",
            wmsMaterialId: selectedMaterial.materialDetail!.materialId,
            materialDisplay: selectedMaterial.materialDetail!.material + " - " + selectedMaterial.materialDetail!.name,
            temId: generateRandomGuid().toString(),
            stockUsage: "",
            owner: owner,
            specialStockType: "",
            specialStockNumber: "",
            customerStockItemNumber: 0,
            parentElementId: parentElementId,
            materialQuantity: materialQuantity,
            containerQuantity: 0,
            bomValue: selectedMaterial.materialDetail!.baseUnit,
            huCountResult: defaultCountResult,
            batch: batch,
            invDocId: prop.invDocId,
            stockType: prop.stockTypeId,
            parentInvDocItemId: "",
            huTypeId: "",
            method: 0,
            serialNumbers: [],

            parentName: parentElementDisplay
        }
        return output;
    }


    const [selectedWmsAutoCompleteOption, setSelectedWmsAutoCompleteOption] = useState<WmsAutoCompleteOption | null>(null);
    function autoCompleteOptionSelected(selectedAutoCompleteOption: WmsAutoCompleteOption | null) {
        setSelectedWmsAutoCompleteOption(selectedAutoCompleteOption);
        if (selectedAutoCompleteOption && selectedAutoCompleteOption.materialDetail != undefined) {
            setUnit(selectedAutoCompleteOption.materialDetail.baseUnit);
        }
    }
    useEffect(() => {
        //console.log("selectedWmsAutoCompleteOption", selectedWmsAutoCompleteOption);
    }, [selectedWmsAutoCompleteOption]);

    //-- unit
    const [unit, setUnit] = useState<string>("");

    //--batch
    const display_Batch: string = "Zeichnungsstand";
    const display_somethingWentWrongGetBatch: string =
        "Something went wrong. Could not find Batch. ";
    const [searchBatchQuery, setSearchBatchQuery] = useState<WmsAutoCompleteSearch>({
        search: "",
    });
    const [batchOption, setBatchOption] = useState<WmsAutoCompleteOption[]>([]);
    const [selectedBatchOption, setSelectedBatchOption] = useState<WmsAutoCompleteOption | null>(
        null
    );

    const handleSearchBatchInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchBatchQuery({ search: event.target.value });
    };

    function convertBatchResultToSelectOptions(
        data: batchSearchV11ResponseDto[]
    ): WmsAutoCompleteOption[] {
        return data.map((item) => ({
            value: item.name,
            label: item.name,
        }));
    }
    useEffect(() => {
        if (searchBatchQuery.search.length >= 1) {
            if (selectedWmsAutoCompleteOption == null) {
                prop.displayNotification("Please select material first");
                return;
            }

            let materialIds: string[];
            materialIds = [];
            materialIds.push(selectedWmsAutoCompleteOption.value);

            let searchTexts: string[];
            searchTexts = [];
            searchTexts.push(searchBatchQuery.search);

            let searchDto: batchSearchV11RequestDto;
            searchDto = {
                page: 0,
                pageSize: 20,
                materialIds: [],
                nameContains: [],
            };

            searchDto.materialIds = materialIds;
            searchDto.nameContains = searchTexts;

            GoodReceiptService.getBatch(searchDto)
                .then((result) => {
                    //g(result);
                    if (result != null) {
                        //let temp: batchSearchV11ResponseDto[] = [];
                        //temp.push(result);
                        //console.log(convertBatchResultToSelectOptions(temp));
                        setBatchOption(convertBatchResultToSelectOptions(result));
                    }
                })
                .catch(() => {
                    console.log(display_somethingWentWrongGetBatch);
                });
        }
    }, [searchBatchQuery.search]);
    const handleSelectedBatchChange = (
        event: React.ChangeEvent<{}>,
        option: WmsAutoCompleteOption | null
    ) => {
        if (option) {
            setSelectedBatchOption(option);
        }
    };



    //--owner
    const display_Owner: string = "Baulos";
    const display_somethingWentWrongGetOwner: string =
        "Something went wrong. Could not get Owner. ";
    const [searchOwnerQuery, setSearchOwnerQuery] = useState<WmsAutoCompleteSearch>({
        search: "",
    });
    const [ownerOption, setOwnerOption] = useState<WmsAutoCompleteOption[]>([]);
    const [selectedOwnerOption, setSelectedOwnerOption] = useState<WmsAutoCompleteOption | null>(
        null
    );

    const handleSearchOwnerInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchOwnerQuery({ search: event.target.value });
    };

    function convertOwnerResultToSelectOptions(
        data: ownerSearchResponseDto[]
    ): WmsAutoCompleteOption[] {
        return data.map((item) => ({
            value: item.ownerId,
            label: item.name,
        }));
    }

    useEffect(() => {
        if (searchOwnerQuery.search.length >= 0) {
            GoodReceiptService.getOwner({
                locationId: prop.locationId!,
                ownerName: searchOwnerQuery.search,
            })
                .then((result) => {
                    setOwnerOption(convertOwnerResultToSelectOptions(result));
                    //setOwnerOption([]);
                    //setSelectedOwnerOption(null);
                })
                .catch(() => {
                    console.log(display_somethingWentWrongGetOwner);
                });
        }
    }, [searchOwnerQuery.search]);

    const handleSelectedOwnerChange = (
        event: React.ChangeEvent<{}>,
        option: WmsAutoCompleteOption | null
    ) => {
        if (option) {
            setSelectedOwnerOption(option);
        }
    };

    const handleDialogClose = (
        event: React.SyntheticEvent<Element, Event>,
        reason: string
    ) => {
        if (reason && reason == "backdropClick") return;
        reset();
        prop.Close();
    };

    const display_Scan: string = "Scannen";
    const display_Scanning: string = "Scan abbrechen";

    //scan parent
    const [scanningParent, setScanningParent] = useState<boolean>(false);
    const display_ScanParentInstruction: string = "Lagerplatz oder HU scannen";
    const [scannedParentValue, setScannedParentValue] = useState<string>(
        ""
    );
    function resetScanParent() {
        setScanningParent(true);
        setScannedParentValue("");
    }
    function scanParentStarted() {
        resetScanParent();
        setScanningParent(true);
    }
    function scanParentCompleted(scannedValue: string) {
        setScanningParent(false);
        setScannedParentValue(scannedValue);
    }
    function scanParentCancelled() {
        setScanningParent(false);
    }
    useEffect(() => {
        if (scannedParentValue && scannedParentValue.length > 0) {
            var matchedOptions = prop.existingTasks.filter((x) => x.type == "HU" && x.huNumber === scannedParentValue);
            if (matchedOptions && matchedOptions.length == 1) {
                setSelectedParentHu(matchedOptions[0].invDocItemId.length > 0 ? matchedOptions[0].invDocItemId : matchedOptions[0].temId)
            }
            else {
                displayNotification("Scan value is not a valid HU within this Bin. ");
            }
        }
        else {
            setScannedParentValue("");
        }
    }, [scannedParentValue]);



    // //scan material
    // const [scanningMaterial, setScanningMaterial] = useState<boolean>(true);
    // const display_ScanMaterialInstruction: string = "Lagerplatz oder HU scannen";
    // const [scannedMaterialValue, setScannedMaterialValue] = useState<string>(
    //     ""
    // );
    // function resetScanMaterial() {
    //     setSelectedWmsAutoCompleteOption(null);
    //     setScanningMaterial(true);
    //     setScannedMaterialValue("");
    // }
    // function ScanMaterialStarted() {
    //     resetScanMaterial();
    //     setScanningMaterial(true);
    // }
    // function ScanMaterialCompleted(scannedValue: string) {
    //     setScanningMaterial(false);
    //     setScannedMaterialValue(scannedValue);
    // }
    // function ScanMaterialCancelled() {
    //     setScanningMaterial(false);
    // }
    // useEffect(() => {
    //     if (scannedMaterialValue && scannedMaterialValue.length > 0) {
    //         //displayNotification(scannedMaterialValue);
    //         //setSearchBatchQuery({ search: scannedMaterialValue });
    //         let searchDto: wmsMaterialSearchV11Dto;
    //         searchDto = {
    //             materials: [],
    //             page: 0,
    //             pageSize: 20,
    //         };
    //         searchDto.materials?.push(scannedMaterialValue);

    //         GoodReceiptService.searchWmsMaterials(searchDto)
    //             .then((results) => {
    //                 if (results && results.length == 1) {
    //                     var temp: WmsAutoCompleteOption = {
    //                         label: results[0].material + " - " + results[0].name,
    //                         value: results[0].materialId,
    //                         materialDetail: results[0]
    //                     }
    //                     //displayNotification("material found");
    //                     autoCompleteOptionSelected(temp);
    //                 }
    //                 else {
    //                     displayNotification("Not a valid material");
    //                 }

    //             })
    //             .catch(() =>
    //                 console.log("Something went wrong when search material"));

    //     }
    //     else {
    //         setSelectedWmsAutoCompleteOption(null);
    //     }
    // }, [scannedMaterialValue]);

    return (
        <Dialog
            onClose={handleDialogClose}
            aria-labelledby="simple-dialog-title"
            open={prop.showAddMaterialDialog}
            hideBackdrop={false}
            PaperProps={{
                style: {
                    minWidth: "50%",
                },
            }}
        >
            <DialogTitle>{prop.title}</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>{contentText}</DialogContentText> */}
                <Box sx={{ mt: 4 }}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12}>
                            <WmsAutoComplete
                                locationId={prop.locationId}
                                populateLength={3}
                                displayLabel={display_MaterialNumber}
                                autoCompleteType={WmsAutoCompleteType.MATERIAL}
                                selectedOption={selectedWmsAutoCompleteOption}
                                optionSelected={autoCompleteOptionSelected}
                                notifyMessage={prop.displayNotification}
                                withScanner={true}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={12}>
                            <Scanner
                                startScan={scanningMaterial}
                                beforeScanText={display_Scan}
                                duringScanText={display_Scanning}
                                scanCompleted={ScanMaterialCompleted}
                                scanStarted={ScanMaterialStarted}
                                scanCancelled={ScanMaterialCancelled}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label={"Mengeneinheit"}
                                value={unit}
                                disabled={true}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <NumberOnlyTextField
                                disabled={selectedWmsAutoCompleteOption == null}
                                label={display_Quantity}
                                value={quantity}
                                minValue={0}
                                maxValue={9999999}
                                onChanged={function (newValue: number): void {
                                    setQuantity(newValue);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Autocomplete
                                noOptionsText={display_noOptionMsg}
                                disabled={selectedWmsAutoCompleteOption == null}
                                value={selectedBatchOption}
                                onChange={handleSelectedBatchChange}
                                aria-required={false}
                                options={batchOption}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={display_Batch}
                                        variant="outlined"
                                        onChange={handleSearchBatchInputChange}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Autocomplete
                                noOptionsText={display_noOptionMsg}
                                disabled={selectedWmsAutoCompleteOption == null}
                                value={selectedOwnerOption}
                                onChange={handleSelectedOwnerChange}
                                aria-required={false}
                                options={ownerOption}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={display_Owner}
                                        variant="outlined"
                                        onChange={handleSearchOwnerInputChange}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{display_ParentHU}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedParentHu}
                                    label={display_ParentHU}
                                    onChange={handleselectedParentHuChange}
                                >
                                    {/* <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem> */}
                                    {prop.existingTasks.filter((x) => x.type == "HU").map((task) => (
                                        <MenuItem value={task.invDocItemId.length > 0 ? task.invDocItemId : task.temId}>{task.huNumber}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Scanner
                                startScan={scanningParent}
                                beforeScanText={display_Scan}
                                duringScanText={display_Scanning}
                                scanCompleted={scanParentCompleted}
                                scanStarted={scanParentStarted}
                                scanCancelled={scanParentCancelled}
                            />
                        </Grid>

                        <NotificationDialog
                            title="Info"
                            contentText={notificationText}
                            onClose={() => {
                                setShowNotification(false);
                            }}
                            visible={showNotification}
                            onConfirm={() => {
                                setShowNotification(false);
                            }}
                        />

                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        reset();
                        prop.Close();
                    }}
                >
                    {display_cancel}
                </Button>
                <Button onClick={handleAddToBin}>{display_confirm_AddToBin}</Button>
                <Button onClick={handleAddToHu}>{display_confirm_AddToHu}</Button>
            </DialogActions>
        </Dialog>
    )
};

export default AddMaterialTaskComponent;
