import React, { useEffect, useState } from "react";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryDocumentDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { guid } from "types/guid";
import { Box, Grid, Paper, Typography, styled } from "@mui/material";
import GoodReceiptHUListing from "./GoodReceiptHUListing";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import GoodReceiptHUListingForAssignment from "./GoodReceiptHUListingForAssignment";
import GoodReceiptItemAssigned from "./GoodReceiptItemAssigned";
import { ItemAssignment } from "interfaces/v11/goodReceiptDto/ItemAssignment";
import { useHandlingUnitService } from "services/HandingUnitService";
import { width } from "@mui/system";
import { AlertBox } from "components/platbricks/shared/AlertBox";
import {
  InboundDeliveryItemQuantityRoleCategoryEnum,
  InboundDeliveryItemQuantityRoleEnum,
} from "interfaces/enums/InboundDeliveryEnums";

interface GoodReceiptHUAssignProp {
  selectedHU: HuV11Dto | null;
  inboundDelivery: InboundDeliveryDetailsV11Dto;
  binId: string;
  hus: HuV11Dto[];
  onAddHU: (item: HuV11Dto) => void;
  onEditHU: (id: guid, updatedItem: HuV11Dto) => void;
  onDeleteHU: (
    id: guid,
    newAssigneditems: ItemAssignment[],
    newInboundDelivery: InboundDeliveryDetailsV11Dto
  ) => void;
  onChangeSelectedHU: (selectedItem: HuV11Dto) => void;

  assignedItems: ItemAssignment[];
  onEditAssignedItems: (
    assignedItems: ItemAssignment[],
    action: string
  ) => void;
}

interface Option {
  label: string;
  value: string;
}

interface Search {
  search: string;
}

const GoodReceiptHUAssign: React.FC<GoodReceiptHUAssignProp> = (prop) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const display_subtitle: string = "Zuordnung";
  const display_somethingWentWrongHU: string =
    "Something went wrong. Could not identify selected HU. ";

  const [inboundDelivery, setInboundDelivery] = useState(prop.inboundDelivery);
  const [assignedItems, setAssignedItems] = useState(prop.assignedItems);

  function onEditAssignedItems(assignments: ItemAssignment[], action: string) {
    setAssignedItems(assignments);
    prop.onEditAssignedItems(assignments, action);
  }

  function onDeleteHU(id: guid) {
    var assignedItemAfterRemoval: ItemAssignment[] = assignedItems.filter(
      (x) => x.huId != id
    );
    let newAssignedItems = JSON.parse(JSON.stringify(assignedItemAfterRemoval));
    setAssignedItems([...newAssignedItems]);

    const itemAssginedToHU: ItemAssignment[] = assignedItems.filter(
      (x) => x.huId == id
    );
    if (itemAssginedToHU && itemAssginedToHU.length > 0) {
      itemAssginedToHU.forEach((x) => {
        var targetInboundDeliveryItems =
          inboundDelivery?.inboundDeliveryItems.filter(
            (y) => y.inboundDeliveryItemId === x.itemId
          );

        if (
          targetInboundDeliveryItems &&
          targetInboundDeliveryItems.length > 0
        ) {
          var targetInboundDeliveryItem = targetInboundDeliveryItems.at(0);
          targetInboundDeliveryItem!.remainingOpenQty! += x.quantity;
        }
      });
    }

    let newInboundDelivery = JSON.parse(JSON.stringify(inboundDelivery));
    setInboundDelivery(newInboundDelivery);
    setSelectedHU(null);

    prop.onDeleteHU(id, newAssignedItems, newInboundDelivery);
  }

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  const [selectedHU, setSelectedHU] = React.useState<HuV11Dto | null>(
    prop.selectedHU
  );

  const huService = useHandlingUnitService();
  const [searchHuTypeQuery, setSearchHuTypeQuery] = useState<Search>({
    search: "",
  });
  const [searchBinQuery, setSearchBinQuery] = useState<Search>({ search: "" });

  const defaultHU = {
    wmsId: "",
    binIsEmpty: false,
    changedAt: "",
    changedBy: "",
    deliveryReference: "",
    deliveryItemReference: "",
    goodsReceivedAt: "",
    handlingUnit: "",
    handlingUnitType: "",
    inventoryBan: false,
    locationId: "",
    parentElement: "",
    putAwayBan: false,
    removalBan: false,
    rootElement: "",
    serialNumbersAssigned: false,
    stockCategory: "",
    stockUsage: "",
    type: "",
    verification: [],
    serialNumbers: [],
    binAreaAllocations: [],
    rowVersion: "",
    volumeUnit: "",
    massUnit: "",
    lengthUnit: "",
    closed: "",
    avlQtyAuom: 0,
    avlQtyBuom: 0,
    incomingOpenTasks: 0,
    incomingInactiveTasks: 0,
    outgoingOpenTasks: 0,
    outgoingInactiveTasks: 0,
    loadingCapacity: 0,
    loadingVolume: 0,
    loadingWeight: 0,
    huInMovementByTasks: 0,
    huAnyInferiorInMovementByTasks: 0,
    huDeleteAutomaticallyWhenEmpty: "",
    behaviourIfEmptyDuringAddTasksStockOut: "",
    updateBehaviour: "",
    consumedCapacity: 0,
    consumedVolume: 0,
    consumedWeight: 0,
    processingIndicator: "",

    assignedItems: [],
  };

  function onChangeSelectedHUForAssignment(seletedItem: HuV11Dto) {
    const index = prop.hus.findIndex(
      (item) => item.wmsId === seletedItem.wmsId
    );
    if (index !== -1) {
      setSelectedHU(seletedItem);
      prop.onChangeSelectedHU(seletedItem);
    } else {
      displayNotification(display_somethingWentWrongHU);
    }
  }

  function getReferenceValueByReferenceType(referenceType: string) {
    let result: string = "-";
    var references = inboundDelivery.inboundDeliveryReferences;
    if (references && references.length > 0) {
      var containerSizeReference = references.filter(
        (x) => x.referenceType === referenceType
      );
      if (containerSizeReference) {
        result = containerSizeReference[0].reference;
      }
    }
    return result;
  }

  function getDeliveryReferenceContainerSize() {
    let result: string = getReferenceValueByReferenceType("CONTAINER_SIZE");
    return result;
  }

  function getDeliveryReferenceContainerNumber() {
    let result: string = getReferenceValueByReferenceType("CONTAINER_NUMBER");
    return result;
  }

  function getContainerDisplay() {
    let result =
      "( LT-Typ: " +
      getDeliveryReferenceContainerSize() +
      ", LT-Menge: " +
      getDeliveryReferenceContainerNumber() +
      " )";

    return result;
  }

  useEffect(() => { }, [assignedItems]);

  return (
    <Box>
      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      <Typography variant="subtitle2" gutterBottom>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            {display_subtitle}
          </Grid>
          <Grid item xs={12} sm={10}>
            <div>
              <table
                style={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  fontSize: "small",
                  fontStyle: "italic",
                }}
              >
                <tr>
                  <td width={70}>LT-Typ</td>
                  <td style={{ color: "grey" }} width={100}>
                    {getDeliveryReferenceContainerSize()}
                  </td>
                  <td width={70}>LT-Menge</td>
                  <td style={{ color: "grey" }} width={100}>
                    {getDeliveryReferenceContainerNumber()}
                  </td>
                </tr>

                {/* <tr>
                  <td width={70}>LT-Menge</td>
                  <td width={100}>{getDeliveryReferenceContainerNumber()}</td>
                </tr> */}
              </table>
            </div>
          </Grid>
        </Grid>
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Item>
            <GoodReceiptHUListing
              selectedHU={selectedHU}
              binId={prop.binId}
              hus={prop.hus}
              onAddHU={prop.onAddHU}
              onEditHU={prop.onEditHU}
              onDeleteHU={onDeleteHU}
              onChangeSelectedHU={onChangeSelectedHUForAssignment}
              deliveryReferenceContainerSize={getDeliveryReferenceContainerSize()}
              deliveryReferenceContainerNumber={getDeliveryReferenceContainerNumber()}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Item>
            <GoodReceiptItemAssigned
              selectedHU={selectedHU}
              items={inboundDelivery.inboundDeliveryItems}
              assignedItems={assignedItems}
              onEditAssignedItems={onEditAssignedItems}
            />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(GoodReceiptHUAssign);
