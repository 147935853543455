import { createContext, ReactNode, useEffect, useReducer } from "react";

import { JWTContextType, ActionMap, AuthState, AuthUser } from "../types/auth";

import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.tsx` file.

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    user: AuthUser;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/v12/auth/account");
          const user = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email: string, password: string) => {
    var config = process.env.REACT_APP_LOGIN_CONFIG;
    var bodyFormData = new FormData();
    bodyFormData.append("configKey", config!);
    bodyFormData.append("loginMethod", "1");
    bodyFormData.append("loginKeyNumber", email);
    bodyFormData.append("loginVerification", password);

    const response = await axios({
      method: "post",
      url: "/login/AuthenticateUser",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    var accessToken = response.data.jwt;
    setSession(accessToken);
    // const userResponse = await axios.get("v11/accounts/account");
    // const user = userResponse.data;
    const user = {
      Forename: "default",
      Surname: "default",
      Locale: "",
      UserName: email,
      EmailAlternate: email,
      Email: email,
      PhoneNumber: "",
      TimeZoneInfoId: "",
      MenuId: "",
    };

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });

    // await axios({
    //   method: "post",
    //   url: "/login/AuthenticateUser",
    //   data: bodyFormData,
    //   headers: { "Content-Type": "multipart/form-data" },
    // })
    // .then(function (response) {
    //   //handle success
    //   console.log(response);
    //   var accessToken = response.data.jwt;
    //   setSession(accessToken);
    //   const userResponse = await axios.get("/v12/auth/account");
    // })
    // .catch(function (response) {
    //   //handle error
    //   console.log(response);
    // });

    // const response = await axios.post("/v12/auth/sign-in", {
    //   email,
    //   password,
    // });
    // var accessToken = response.data.jwt;
    // setSession(accessToken);
    // const userResponse = await axios.get("/v12/auth/account");
    // const user = userResponse.data;
    // dispatch({
    //   type: SIGN_IN,
    //   payload: {
    //     user,
    //   },
    // });
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email: string) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
