import { BinDetailsV12Dto } from "interfaces/v12/bin/binDetailsV12Dto";
import React from "react";
import { guid } from "types/guid";
import axios from "../utils/axios";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";

const { createContext, useContext } = React;

interface IBinService {
  getBinById: (wmsId: guid) => Promise<BinDetailsV12Dto>;
  getBinByIdV11: (wmsId: string) => Promise<binSearchResponseV11Dto>;
}

const BinServiceContext = createContext({} as IBinService);

export const BinServiceProvider: React.FC<{
  children?: React.ReactNode;
  getBinById?: any;
  getBinByIdV11?: any;
}> = (props) => {
  const getBinById = async (wmsId: guid) => {
    return await axios
      .get<BinDetailsV12Dto>("/v12/bins/" + wmsId)
      .then(async (res) => {
        return res.data;
      });
  };

  const getBinByIdV11 = async (wmsId: guid) => {
    const res = await axios.get("/v11/bins/" + wmsId);
    return res.data;
  };

  const value = {
    getBinById: props.getBinById || getBinById,
    getBinByIdV11: props.getBinByIdV11 || getBinByIdV11,
  };

  return (
    <BinServiceContext.Provider value={value}>
      {props.children}
    </BinServiceContext.Provider>
  );
};

export const useBinService = () => {
  return useContext(BinServiceContext);
};
