import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const NotificationDialog = ({
  onClose,
  visible,
  title,
  contentText,
  onConfirm,
}: {
  onClose: () => void;
  visible: boolean;
  title?: string;
  contentText: string;
  onConfirm: () => void;
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={visible}
      PaperProps={{
        style: {
          minWidth: "50%",
        },
      }}
    >
      <DialogTitle id="simple-dialog-title">
        {title ?? "Confirmation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText.split("<br/>").map((item) => (
            // <ListItem key={item.wmsId} button onClick={() => handleEditItemClick(item)}
            <div>
              {item}
              <br />
            </div>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
