import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { generateRandomGuid } from "types/guid";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  InboundDeliveryItemQuantityRoleCategoryEnum,
  InboundDeliveryItemQuantityRoleEnum,
} from "interfaces/enums/InboundDeliveryEnums";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { ConfirmationDialog } from "components/platbricks/shared/ConfirmationDialog";

interface InboundDeliveryItemDetailProp {
  //items: _InboundDeliveryItemDetailsV11Dto[];
  item: InboundDeliveryDetailsV11Dto;
  onAddItem: () => void;
  onEditItem: (item: _InboundDeliveryItemDetailsV11Dto) => void;
  onDeleteItem: (id: string) => void;
}

const InboundDeliveryItemDetail: React.FC<InboundDeliveryItemDetailProp> = (
  prop
) => {
  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";
  const display_subtitle: string = "Position(en)";
  const display_QuantityRequested: string = "Angefragte Menge";
  const display_QuantityOpen: string = "Offene Menge";
  const display_NoOpenGRQuantity: string = "No Open GR Quantity";
  const display_new: string = "Neu";
  const display_NoItem: string = "Keine Positionen vorhanden";
  const display_Owner: string = "Baulos";
  const display_Batch_Short: string = "ZGS";
  const display_Classification: string = "Materialklassifizierung";
  const display_ConfirmToDelete: string =
    "Are you sure you want to delete this item?";

  //console.log("InboundDeliveryItemListing", prop);

  const GoodReceiptService = useGoodReceiptService();
  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  const [delivery, setDelivery] = useState(prop.item);
  const [deliveryItems, setDeliveryItems] = useState(
    prop.item.inboundDeliveryItems
  );

  const addNewitem = async () => {
    prop.onAddItem();
  };

  const edititem = async () => {
    prop.onAddItem();
  };
  const handleEditItemClick = (item: _InboundDeliveryItemDetailsV11Dto) => {
    prop.onEditItem(item);
  };

  function Line2Display(input: _InboundDeliveryItemDetailsV11Dto): string {
    let qtys = input.inboundDeliveryItemQuantities;
    if (qtys) {
      const filteredRequestedQty = qtys.filter(
        (item) =>
          item.quantityRoleCategory ===
            InboundDeliveryItemQuantityRoleCategoryEnum.REQUESTED &&
          item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
      );

      let result: string = "";
      if (filteredRequestedQty && filteredRequestedQty.length == 1) {
        const qty = filteredRequestedQty[0];
        result =
          display_QuantityRequested +
          ": " +
          qty.quantity.toString() +
          " " +
          qty.unitOfMeasurement;
      }

      const filteredOpenQty = qtys.filter(
        (item) =>
          item.quantityRoleCategory ===
            InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
          item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
      );

      if (filteredOpenQty && filteredOpenQty.length == 1) {
        const qty = filteredOpenQty[0];
        result +=
          "\n| " +
          display_QuantityOpen +
          ": " +
          qty.quantity.toString() +
          " " +
          qty.unitOfMeasurement;
      }

      return result;
    }
    return display_NoOpenGRQuantity;
  }

  const [itemToDelete, setItemToDelete] = useState("");
  const handleDeleteItemClick = (id: string) => {
    //DeleteItem(id);
    setItemToDelete(id);
    DeleteItem(id);
    //setShowConfirmation(true);
  };
  //confirmation dialog
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleConfirmDeleteClick = async () => {
    setShowConfirmation(false);
    //DeleteItem();
  };
  const handleCancelDelete = async () => {
    setShowConfirmation(false);
    setItemToDelete("");
  };
  function DeleteItem(id: string) {
    //let id: string = itemToDelete;
    if (id.length <= 0) {
      displayNotification("Could not identify item to delete");
    } else {
      let temp: InboundDeliveryDetailsV11Dto = JSON.parse(
        JSON.stringify(prop.item)
      );
      const newInboundDeliveryItemList: _InboundDeliveryItemDetailsV11Dto[] =
        temp.inboundDeliveryItems.filter((x) => x.inboundDeliveryItemId !== id);

      temp.inboundDeliveryItems = newInboundDeliveryItemList;

      //call web service
      GoodReceiptService.updateInboundDelivery(temp)
        .then((result) => {
          {
            setItemToDelete("");
            setDeliveryItems(newInboundDeliveryItemList);
            prop.onDeleteItem(id);
          }
        })
        .catch(() => {
          displayNotification(display_SomethingWentWrong);
        });
    }
  }

  useEffect(() => {}, [deliveryItems]);

  return (
    <Box sx={{ width: "100%" }}>
      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />
      <ConfirmationDialog
        contentText={"Are you sure you want to delete this item?"}
        onClose={async () => {
          await handleCancelDelete();
        }}
        positiveText="Bestätigen"
        negativeText="Abbrechen"
        visible={showConfirmation}
        onConfirm={async () => {
          await handleConfirmDeleteClick();
        }}
      />
      <Typography variant="subtitle2" gutterBottom>
        {display_subtitle}
      </Typography>
      <Grid xs={12}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={addNewitem}
        >
          {display_new}
        </Button>
      </Grid>

      {deliveryItems.length <= 0 ? (
        <i>{display_NoItem}</i>
      ) : (
        <List>
          {deliveryItems.map((item) => (
            <ListItem
              key={item.inboundDeliveryItemId}
              button
              onClick={() => handleEditItemClick(item)}
            >
              <ListItemText
                primary={
                  item.inboundDeliveryItemNumber +
                  " : " +
                  item.material?.material +
                  " - " +
                  item.material?.name +
                  " | " +
                  display_Batch_Short +
                  ": " +
                  item.batch +
                  " | " +
                  display_Owner +
                  ": " +
                  (item.owner == undefined ? "none" : item.owner) +
                  " | " +
                  display_Classification +
                  ": " +
                  (item.material && item.material.classification
                    ? item.material.classification
                    : "")
                }
                secondary={Line2Display(item)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() =>
                    handleDeleteItemClick(item.inboundDeliveryItemId)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default React.memo(InboundDeliveryItemDetail);
