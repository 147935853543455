import { WMSMaterialDetailsV12Dto } from "interfaces/v12/materialDetail/wmsMaterialDetailsV12Dto";
import axios from "../utils/axios";

interface IWmsMaterialService {
  getMaterialById: (materialId: string) => Promise<WMSMaterialDetailsV12Dto>;

  getMaterialById_v2: (materialId: string) => Promise<WMSMaterialDetailsV12Dto>;
}

const WmsMaterialService: IWmsMaterialService = {
  getMaterialById: async (materialId: string) => {
    const res = await axios.get(`/v11/materials/${materialId}`);
    return res.data;
  },

  getMaterialById_v2: async (materialId: string) => {
    const res = await axios.get(`/v2/materials/${materialId}`);
    return res.data;
  },
};
export default WmsMaterialService;
