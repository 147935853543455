import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Grid, TextField, Typography, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "components/platbricks/shared/ConfirmationDialog";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { useParams, useNavigate } from "react-router-dom";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { InboundDeliveryDetailsV11Dto } from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { guid } from "types/guid";
import useAuth from "hooks/useAuth";
import { useSettingService } from "services/SettingService";
import { locationSetting } from "interfaces/setting/locationSetting";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import AddIcon from "@mui/icons-material/Add";
import { InboundDeliveryCreateV2Response } from "interfaces/v2/inboundDelivery/inboundDeliveryCreate/inboudDeliveryCreateV2Response";
import { InboundDeliveryCreateV11Response } from "interfaces/v11/inboundDelivery/inboundDeliveryCreate/inboudDeliveryCreateV11Response";

function GoodReceiptSearchComponent() {
  const maxLength: number = 30;
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { signOut } = useAuth();

  //message
  const display_NewInboundDelivery: string = "Neue Anlieferung anlegen";
  const display_SearchInboundDelivery: string = "Weiter";
  const display_EnterOrderNoToContinue: string =
    "Geben Sie eine Liefernummer ein um fortzufahren.";
  const display_somethingWentWrong: string = "Ein Fehler ist aufgetreten.";
  const display_confirmTextToCreateDelivery: string =
    "Es wurde keine Anlieferung mit der Nummer {inboundDeliveryNumber} gefunden.\n\nSoll diese jetzt angelegt werden?";
  const display_orderNoDisplay: string = "Anlieferung";
  //

  const searchRef = useRef<HTMLButtonElement>(null);
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && searchRef.current) {
      searchRef.current.click();
    }
  }

  const [goodReceipt, setGoodReceipt] = useState({
    orderNo: "",
    deliveryNo: "",
    deliveryDate: new Date(),
  });

  const [debounce, setDebounce] = useState<boolean>(false);

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  //confirmation dialog
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmatioNText, setConfirmationText] = useState(
    display_confirmTextToCreateDelivery
  );
  const handleConfirmCreateInboundDeliveryClick = async () => {
    const useV11: boolean = true;
    createInboundDelivery(useV11);
  };

  function createInboundDelivery(WithDeliveryNumber: boolean) {
    if (debounce) {
      return;
    }

    setDebounce(true);

    if (WithDeliveryNumber) {
      GoodReceiptService.createInboundDeliveryV2({
        LocationId: locationId as string,
        deliveryNumber: goodReceipt.orderNo,
      }).then((result) => {
        handleConfirmCreateInboundDeliveryV2Response(result);
        setDebounce(false);
      });
    } else {
      GoodReceiptService.createInboundDeliveryV11({
        LocationId: locationId as string,
      }).then((result) => {
        handleConfirmCreateInboundDeliveryV11Response(result);
        setDebounce(false);
      });
    }
  }

  function handleConfirmCreateInboundDeliveryV2Response(
    response: InboundDeliveryCreateV2Response
  ) {
    if (response.message != undefined) {
      //v2 positive
      navigateToTargetInbouldDelivery(response.message);
    } else if (response.errorMessage != undefined) {
      displayNotification(response.errorMessage);
    } else {
      displayNotification("Unhandled exception");
    }
  }

  function handleConfirmCreateInboundDeliveryV11Response(
    response: InboundDeliveryCreateV11Response
  ) {
    if (response.id != undefined) {
      //v11 positive
      navigateToTargetInbouldDelivery(response.id);
    } else if (response.errorMessage != undefined) {
      displayNotification(response.errorMessage);
    } else {
      displayNotification("Unhandled exception");
    }
  }

  function navigateToTargetInbouldDelivery(id: guid | undefined) {
    let path = "/locations/" + locationId + "/goodReceipts/" + id;
    navigate(path);
  }

  //api call
  const GoodReceiptService = useGoodReceiptService();
  const [inboundDeliveries, setInboundDeliveries] = useState<
    InboundDeliveryDetailsV11Dto[] | null
  >(null);

  //data change event
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setGoodReceipt({ ...goodReceipt, [name]: value });
  };
  const handleDateChange = (date: Date | null) => {
    if (date != null) {
      setGoodReceipt({ ...goodReceipt, deliveryDate: date });
    }
  };

  const handleSearchInbondDeliveryButtonClick = useCallback(async () => {
    if (debounce) {
      return;
    }
    setDebounce(true);
    if (goodReceipt.orderNo.length <= 0) {
      displayNotification(display_EnterOrderNoToContinue);
      setDebounce(false);
      return;
    }
    let deliveryNumbers: string[];
    deliveryNumbers = [];
    if (goodReceipt.orderNo.length > 0) {
      deliveryNumbers.push(goodReceipt.orderNo);
    }

    GoodReceiptService.searchInboundDeliveries({
      locationId: locationId as guid,
      includeAllChildren: true,
      page: 0,
      pageSize: 1,
      deliveryNumbers: deliveryNumbers,
    })
      .then((inboundDeliveries) => {
        setDebounce(false);
        setInboundDeliveries(inboundDeliveries);
      })
      .catch((response) => {
        setDebounce(false);
        console.log(response);
        if (response.Status == "401") {
          handleSignOut();
        } else {
          displayNotification(display_somethingWentWrong);
        }
      });
  }, [goodReceipt]);

  const handleCreateInbondDeliveryButtonClick = useCallback(async () => {
    const useV11: boolean = false;
    createInboundDelivery(useV11);
  }, [goodReceipt]);

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    //console.log("debounce " + new Date(), debounce);
  }, [debounce]);

  useEffect(() => {
    if (inboundDeliveries) {
      if (inboundDeliveries.length > 0) {
        let id = inboundDeliveries.at(0)?.inboundDeliveryId;
        let status = inboundDeliveries.at(0)?.status;
        if (status == "OPEN") {
          navigateToTargetInbouldDelivery(id);
        } else {
          displayNotification(
            "The inbound delivery is " +
              status +
              ". Cannot perform Good Receipt. "
          );
        }
      } else {
        let msg = display_confirmTextToCreateDelivery.replace(
          "{inboundDeliveryNumber}",
          goodReceipt.orderNo
        );
        setConfirmationText(msg);
        setShowConfirmation(true);
      }
    }
  }, [inboundDeliveries]);

  return (
    <div>
      <ConfirmationDialog
        contentText={confirmatioNText}
        onClose={() => {
          setShowConfirmation(false);
        }}
        positiveText="Bestätigen"
        negativeText="Abbrechen"
        visible={showConfirmation}
        onConfirm={async () => {
          await handleConfirmCreateInboundDeliveryClick();
        }}
      />

      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div hidden={false}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={async () => {
                await handleCreateInbondDeliveryButtonClick();
              }}
              disabled={debounce}
              startIcon={<AddIcon />}
            >
              <div>
                <br />
                <br />
              </div>
              {display_NewInboundDelivery}
            </Button>
            <br />
            <br />
            <br />
          </div>
        </Grid>

        <Grid item xs={12} sm={12}>
          <div
            style={{
              border: "1px solid grey",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Grid item xs={12} sm={12}>
              <AlertBox
                alertType={AlertBoxType.info}
                contentText={display_EnterOrderNoToContinue}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="orderNo"
                name="orderNo"
                label={display_orderNoDisplay}
                variant="outlined"
                value={goodReceipt.orderNo}
                fullWidth={true}
                onChange={handleInputChange}
                inputProps={{ maxLength }}
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                ref={searchRef}
                variant="contained"
                fullWidth={true}
                disabled={debounce}
                onClick={async () => {
                  await handleSearchInbondDeliveryButtonClick();
                }}
              >
                <div>
                  <br />
                </div>
                {display_SearchInboundDelivery}
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default GoodReceiptSearchComponent;
