import React from "react";
import axios from "../utils/axios";
import { printerSearchRequestDto } from "interfaces/general/print/printerSearchRequestDto";
import { QueueDto } from "interfaces/v2/queue/QueueDto";

const { createContext, useContext } = React;

interface IQueueService {
  getQueue: () => Promise<QueueDto[]>;
}

const QueueServiceContext = createContext({} as IQueueService);

export const QueueServiceProvider: React.FC<{
  children?: React.ReactNode;
  getQueue?: any;
}> = (props) => {
  const getQueue = async () => {
    const res = await axios.get("/v2/queues/GetQueueByUser");
    return res.data;
  };

  const value = {
    getQueue: props.getQueue || getQueue,
  };

  return (
    <QueueServiceContext.Provider value={value}>
      {props.children}
    </QueueServiceContext.Provider>
  );
};

export const useQueueService = () => {
  return useContext(QueueServiceContext);
};
