import React from "react";
import { guid } from "types/guid";
import { SelectAsyncOption } from "../components/platbricks/shared/SelectAsync";
import axios from "../utils/axios";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huCreateRequestV11Dto } from "interfaces/v11/hu/huCreateRequestV11Dto";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { huSearchRequestV11Dto } from "interfaces/v11/hu/huSearchRequestV11Dto";
import { huSearchRequestV2Dto } from "interfaces/v2/hu/huSearchRequestV2Dto";
import { HuV2Dto } from "interfaces/v2/hu/huV2Dto";
import { huSearchResponseV11Dto } from "interfaces/v11/hu/huSearchResponseV11Dto";

const { createContext, useContext } = React;

interface IHandlingUnitService {
  getBin: (
    searchDto: binSearchRequestV11Dto
  ) => Promise<binSearchResponseV11Dto[]>;

  getBinById: (
    binId: string
  ) => Promise<binSearchResponseV11Dto>;

  getHandlingUnitType: (
    searchDto: huTypeSearchRequestV11Dto
  ) => Promise<huTypeSearchResponseV11Dto[]>;

  searchHandlingUnit: (searchDto: huSearchRequestV2Dto) => Promise<HuV2Dto[]>;

  searchHandlingUnitV11: (
    searchDto: huSearchRequestV11Dto
  ) => Promise<huSearchResponseV11Dto[]>;

  getHandlingUnit: (id: string) => Promise<HuV2Dto[]>;

  createHU: (createDto: huCreateRequestV11Dto) => Promise<HuV11Dto>;
}

const HandlingUnitServiceContext = createContext({} as IHandlingUnitService);

export const HandlingUnitServiceProvider: React.FC<{
  children?: React.ReactNode;
  getBin?: any;
  getBinById?: any;
  getHandlingUnitType?: any;
  createHU?: any;
  searchHandlingUnit?: any;
  searchHandlingUnitV11?: any;
  getHandlingUnit?: any;
}> = (props) => {
  const getBin = async (searchDto: binSearchRequestV11Dto) => {
    const res = await axios.post("/v11/bins/search", searchDto);
    return res.data;
  };

  const getBinById = async (binId: string) => {
    const res = await axios.get("/v11/bins/" + binId);
    return res.data;
  };

  const getHandlingUnitType = async (searchDto: huTypeSearchRequestV11Dto) => {
    const res = await axios.post("/v11/handling-unit-types/search", searchDto);
    return res.data;
  };

  const createHU = async (createDto: huCreateRequestV11Dto) => {
    const res = await axios.post("/v11/HandlingUnits", createDto);
    //const res = await axios.post("/v11/HandlingUnits/create-lite", createDto);
    return res.data;
  };

  const searchHandlingUnit = async (searchDto: huSearchRequestV2Dto) => {
    //const res = await axios.post("/v11/HandlingUnits/search", searchDto);
    const res = await axios.get("/v2/HandlingUnits?name=" + searchDto.name);
    return res.data;
  };

  const searchHandlingUnitV11 = async (searchDto: huSearchRequestV11Dto) => {
    const res = await axios.post("/v11/HandlingUnits/search", searchDto);
    //const res = await axios.get("/v2/HandlingUnits?name=" + searchDto.name);
    return res.data;
  };

  const getHandlingUnit = async (id: string) => {
    const res = await axios.get("/v2/HandlingUnits/" + id);
    return res.data;
  };

  const value = {
    getBin: props.getBin || getBin,
    getBinById: props.getBinById || getBinById,
    getHandlingUnitType: props.getHandlingUnitType || getHandlingUnitType,
    createHU: props.createHU || createHU,
    searchHandlingUnit: props.searchHandlingUnit || searchHandlingUnit,
    searchHandlingUnitV11: props.searchHandlingUnitV11 || searchHandlingUnitV11,
    getHandlingUnit: props.getHandlingUnit || getHandlingUnit,
  };

  return (
    <HandlingUnitServiceContext.Provider value={value}>
      {props.children}
    </HandlingUnitServiceContext.Provider>
  );
};

export const useHandlingUnitService = () => {
  return useContext(HandlingUnitServiceContext);
};
