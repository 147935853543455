import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export const ConfirmationDialog = ({
  onClose,
  visible,
  title,
  contentText,
  positiveText,
  negativeText,
  onConfirm,
}: {
  onClose: () => void;
  visible: boolean;
  title?: string;
  contentText: string;
  positiveText: string;
  negativeText: string;
  onConfirm: () => void;
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={visible}
    >
      <DialogTitle id="simple-dialog-title">
        {title ?? "Bestätigung"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText.split("\n").map((x) => (
            <div>
              {x}
              <br />
            </div>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{negativeText}</Button>
        <Button onClick={onConfirm}>{positiveText}</Button>
      </DialogActions>
    </Dialog>
  );
};
