import { Box, CardContent, Grid, Paper, Toolbar, styled } from "@mui/material";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import Page from "components/platbricks/shared/Page";
import { useParams } from "react-router-dom";
import MovementComponent from "./component/MovementComponent";
import { PbCard } from "components/platbricks/shared";

const breadcrumbs = [{ label: "Home", to: "/" }, { label: "Umlagerung" }];
const MovementPage = () => {
  const { locationId } = useParams();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Page title="Umlagerung" breadcrumbs={breadcrumbs}>
      <PbCard>
        <CardContent>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12}>
              <Item>
                <MovementComponent />
                {/* <AlertBox
                  alertType={AlertBoxType.info}
                  contentText="This page is still in development"
                /> */}
              </Item>
            </Grid>
          </Grid>
        </CardContent>
      </PbCard>
    </Page>
  );
};

export default MovementPage;
