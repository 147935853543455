import React from "react";
import axios from "../utils/axios";
import { searchTransportV11Request } from "interfaces/v11/transport/searchTransportV11Request";
import { searchTransportV11Response } from "interfaces/v11/transport/searchTransportV11Response";
import { createTransportV11Request } from "interfaces/v11/transport/createTransportV11Request";
import { createTransportV11Response } from "interfaces/v11/transport/createTransportV11Response";
import { transportV11Detail } from "interfaces/v11/transport/transportV11Detail";
import { searchTransportUnitV11Request } from "interfaces/v11/transport/searchTransportUnitV11Request";
import { searchTransportUnitV11Response } from "interfaces/v11/transport/searchTransportUnitV11Response";
import { transportUnitAssignmentV11Request } from "interfaces/v11/transport/transportUnitAssignmentV11Request";
import { useHandlingUnitService } from "./HandingUnitService";
import HandlingUnitServiceNonContext from "./HandingUnitServiceNonContext";
import { transportStopPostGoodIssueV11Request } from "interfaces/v11/transport/transportStopPostGoodIssueV11Request";
import { transportStopPostGoodIssueV11Response } from "interfaces/v11/transport/transportStopPostGoodIssueV11Response";
import { deleteTransportAssignmentV11Request } from "interfaces/v11/transport/deleteTransportAssignmentV11Request";
import { deleteTransportAssignmentV11Response } from "interfaces/v11/transport/deleteTransportAssignmentV11Response";
import { updateTransportV11Response } from "interfaces/v11/transport/updateTransportV11Response";

const { createContext, useContext } = React;

interface ITransportService {
  searchTransports: (
    searchDto: searchTransportV11Request
  ) => Promise<searchTransportV11Response[]>;

  createTransport: (
    request: createTransportV11Request
  ) => Promise<createTransportV11Response>;

  updateTransport: (
    transportId: string,
    request: transportV11Detail
  ) => Promise<updateTransportV11Response>;

  getTransport: (id: string) => Promise<transportV11Detail>;

  searchTransportUnit: (
    searchDto: searchTransportUnitV11Request
  ) => Promise<searchTransportUnitV11Response[]>;

  assignTransportUnit: (
    transportId: string,
    request: transportUnitAssignmentV11Request
  ) => Promise<string>;

  postGoodIssueForTransportStop: (
    request: transportStopPostGoodIssueV11Request
  ) => Promise<transportStopPostGoodIssueV11Response>;

  deleteTransportAssignment: (
    request: deleteTransportAssignmentV11Request
  ) => Promise<deleteTransportAssignmentV11Response>;
}

const TransportServiceContext = createContext({} as ITransportService);

export type TransportServiceProviderProps = {
  children?: React.ReactNode;
  searchTransports?: any;
  createTransport?: any;
  updateTransport?: any;
  getTransport?: any;
  searchTransportUnit?: any;
  assignTransportUnit?: any;
  postGoodIssueForTransportStop?: any;
  deleteTransportAssignment?: any;
};
export const TransportServiceProvider: React.FC<
  TransportServiceProviderProps
> = (props) => {
  const searchTransports = (searchDto: searchTransportV11Request) => {
    return axios.post("/v11/transports/search", searchDto).then((res) => {
      return res.data;
    });
  };

  const updateTransport = (
    transportId: string,
    request: transportV11Detail
  ) => {
    console.log("updateTransport service transportId", transportId);
    console.log("updateTransport service request", request);
    return axios.put("/v11/transports/" + transportId, request).then((res) => {
      console.log("updateTransport service res", res);
      return res.data;
    });
  };

  const createTransport = (request: createTransportV11Request) => {
    return axios.post("/v11/transports", request).then((res) => {
      return res.data;
    });
  };

  const getTransport = (id: string) => {
    return axios.get("/v11/transports/" + id).then((res) => {
      return res.data;
    });
  };

  const searchTransportUnit = async (
    searchDto: searchTransportUnitV11Request
  ) => {
    //var huService = useHandlingUnitService();
    return await axios
      .post("/v11/transport-units/search", searchDto)
      .then(async (res) => {
        var transportUnits: searchTransportUnitV11Response[] = res.data;
        if (transportUnits != undefined && transportUnits.length > 0) {
          await transportUnits.forEach(async (x) => {
            if (x.linkedHuId && x.linkedHuId.length > 0) {
              var huResponse =
                await HandlingUnitServiceNonContext.getHandlingUnit(
                  x.linkedHuId
                );
              //console.log("huResponse", huResponse);

              if (huResponse && huResponse.length == 1) {
                x.linkedHuName = huResponse[0].handlingUnit;
                x.linkedHuType = huResponse[0].handlingUnitType;
              }
            }
          });
        }

        //console.log("transportUnit To Return: ", await transportUnits);
        return await transportUnits;
      });
  };

  const assignTransportUnit = (
    transportId: string,
    request: transportUnitAssignmentV11Request
  ) => {
    return axios
      .post(
        "/v11/transports/" + transportId + "/transport-assignments",
        request
      )
      .then((res) => {
        return res;
      });
  };

  const postGoodIssueForTransportStop = (
    request: transportStopPostGoodIssueV11Request
  ) => {
    return axios
      .post(
        "/v11/transports/stops/" + request.transportStopId + "/post-goods-issue"
      )
      .then((res) => {
        return res.data;
      });
  };

  const deleteTransportAssignment = (
    request: deleteTransportAssignmentV11Request
  ) => {
    return axios
      .delete(
        "/v11/transports/transport-assignments/" + request.transportAssignmentId
      )
      .then((res) => {
        return res.data;
      });
  };

  const value = {
    searchTransports: props.searchTransports || searchTransports,
    createTransport: props.createTransport || createTransport,
    updateTransport: props.updateTransport || updateTransport,
    getTransport: props.getTransport || getTransport,
    searchTransportUnit: props.searchTransportUnit || searchTransportUnit,
    assignTransportUnit: props.assignTransportUnit || assignTransportUnit,
    postGoodIssueForTransportStop:
      props.postGoodIssueForTransportStop || postGoodIssueForTransportStop,
    deleteTransportAssignment:
      props.deleteTransportAssignment || deleteTransportAssignment,
  };

  return (
    <TransportServiceContext.Provider value={value}>
      {props.children}
    </TransportServiceContext.Provider>
  );
};

export const useTransportService = () => {
  return useContext(TransportServiceContext);
};
