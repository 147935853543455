export type guid = string & { isGuid: true };

export const guid = (guid: string): guid => {
  return guid as guid; // maybe add validation that the parameter is an actual guid ?
};

export const generateRandomGuid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  const randomGuiStr =
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4();

  return guid(randomGuiStr);
};
