import { Box, CardContent, Grid, Paper, Toolbar, styled } from "@mui/material";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import Page from "components/platbricks/shared/Page";
import { useParams } from "react-router-dom";
import { PbCard } from "components/platbricks/shared";
import StockCountingIndexComponent from "./index/StockCountingIndexComponent";

const breadcrumbs = [
  { label: "Home", to: "/" },
  { label: "Inventur" },
];
const StockCountingPage = () => {
  const { locationId } = useParams();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <Page title="Inventur" breadcrumbs={breadcrumbs}>
      <PbCard>
        <CardContent>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12}>
              <Item>
                <StockCountingIndexComponent />
              </Item>
            </Grid>
          </Grid>
        </CardContent>
      </PbCard>
    </Page>
    // <Page title="Inventur" breadcrumbs={breadcrumbs}>
    //   <AlertBox alertType={AlertBoxType.info} contentText="This page is still in development" />
    // </Page>
  );
};

export default StockCountingPage;
