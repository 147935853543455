import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { ChartDataset } from "chart.js/auto";
import "chartjs-adapter-luxon";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useAzureAppInsightService } from "services/AzureAppInsightService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin
);

const getChartDataset = (data: number[]) => {
  return {
    label: "Availability",
    data: data,
  } as ChartDataset<"bar", number[]>;
};

export const AzureAppInsightTimeStatChart = ({
  availabilityThreshold,
}: {
  availabilityThreshold: number | undefined;
}) => {
  const AzureAppInsightService = useAzureAppInsightService();
  const [timerange, setTimerange] = useState("P12M");
  const [timegrain, setTimegrain] = useState("month");
  const [yAxisMin, setYAxisMin] = useState(95);

  const [data, setData] = useState<ChartData<"bar"> | undefined>({
    datasets: [getChartDataset([])],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isAzureAppInsightConfigured, setIsAzureAppInsightConfigured] =
    useState<boolean>(false);

  const getTimegrain = useCallback((timerange: string) => {
    switch (timerange) {
      case "PT24H":
        return "1h";
      case "P14D":
        return "1d";
      case "P13W":
        return "7d";
      case "P12M":
        return "month";
      case "P2Y":
        return "year";
      default:
        return "1d";
    }
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setTimerange(event.target.value as string);
    setTimegrain(getTimegrain(event.target.value as string));
  };

  const getDateLabel = useCallback(
    (date: string) => {
      switch (timegrain) {
        case "1h":
          return new Date(date).toLocaleString("de-DE", {
            hour: "numeric",
          });
        case "7d":
        case "1d":
          return new Date(date).toLocaleString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });

        case "month":
          return new Date(date).toLocaleString("de-DE", {
            year: "2-digit",
            month: "long",
          });
        default:
          return date;
      }
    },
    [timegrain]
  );

  const fetchChart = useCallback(
    async (timerange: string) => {
      try {
        setIsLoading(true);
        const data =
          await AzureAppInsightService.getAzureAppInsightTimeStatChart(
            timerange,
            timegrain
          );

        // get lower axis chart value
        if (data) {
          const lowestValue = Math.min(
            ...data.map((v, i) => v.averagePercentage)
          );
          if (lowestValue >= 95) {
            setYAxisMin(95);
          } else {
            const minimum = lowestValue - 2;
            setYAxisMin(minimum <= 0 ? 0 : minimum);
          }
        }

        setData({
          labels: data.map((v, i) => getDateLabel(v.date)),
          datasets: [getChartDataset(data.map((v, i) => v.averagePercentage))],
        });

        setIsAzureAppInsightConfigured(true);
      } catch (error: any) {
        if (error?.errorCode === "NoAppInsightDefined")
          setIsAzureAppInsightConfigured(false);

        setData(undefined);
      } finally {
        setIsLoading(false);
      }
    },
    [AzureAppInsightService, timegrain, getDateLabel]
  );

  const colorize = () => {
    return (ctx: any) => {
      const v = ctx.parsed?.y;
      return v >= 99.5 ? "#AEF1AF" : "#F5B7B1";
    };
  };

  const options: ChartOptions<"bar"> = {
    elements: {
      bar: {
        backgroundColor: colorize(),
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Average Duration",
      },
      tooltip: {
        callbacks: {
          label: (context: any) =>
            "Availability: " + context.raw.toFixed(2) + "%",
        },
      },
      datalabels: {
        color: "black",
        display: "auto",
        formatter: function (value, context) {
          return value.toFixed(2) + "%";
        },
        clamp: true,
      },
      annotation: availabilityThreshold
        ? {
            annotations: {
              threshold: {
                type: "line",
                borderColor: "red",
                borderWidth: 2,
                label: {
                  backgroundColor: "red",
                  content: availabilityThreshold + "%",
                  display: true,
                },
                scaleID: "y",
                value: availabilityThreshold,
              },
            },
          }
        : {},
    },
    scales: {
      y: {
        min: yAxisMin.toFixed(),
        max: 100,
        ticks: {
          callback: function (value, index, ticks) {
            return value.toLocaleString() + "%";
          },
        },
      },
    },
  };

  useEffect(() => {
    async function initialFetchData() {
      await fetchChart(timerange);
    }
    initialFetchData();
  }, [fetchChart, timerange]);

  return (
    <Fragment>
      {isAzureAppInsightConfigured && (
        <Card>
          <Box sx={{ height: "5px" }}>{isLoading && <LinearProgress />}</Box>
          <CardHeader
            action={
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Aggregate by
                </InputLabel>
                <Select
                  sx={{ minWidth: "100px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={timerange}
                  label="Aggregate by"
                  onChange={handleChange}
                >
                  <MenuItem value="PT24H">Hour</MenuItem>
                  <MenuItem value="P14D">Day</MenuItem>
                  <MenuItem value="P13W">Week</MenuItem>
                  <MenuItem value="P12M">Month</MenuItem>
                  <MenuItem value="P2Y">Year</MenuItem>
                </Select>
              </FormControl>
            }
            title="Availability"
          />
          <CardContent>
            {data ? (
              <Bar options={options} data={data} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "50px",
                }}
              >
                Some problems to load data
              </Box>
            )}
          </CardContent>
        </Card>
      )}
    </Fragment>
  );
};
