import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from "@mui/material";
import useUserCache from "hooks/useUserCache";
import { InboundDeliveryDetailsV11Dto } from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { InboundDeliveryDocumentUploadV11Request } from "interfaces/v11/inboundDelivery/inboundDeliveryDocument/inboundDeliveryDocumentUploadV11Request";
import { generateRandomGuid, guid } from "types/guid";
import Camera from "components/platbricks/shared/Camera";
import { Box, maxWidth } from "@mui/system";
import {
  Download as DownloadIcon
} from "@mui/icons-material";

type InboundDeliveryDetailProps = {
  inboundDeliveryDetail: InboundDeliveryDetailsV11Dto;
  updateInboundDelivery: (value: any) => void;
  notifyUploadCompleted: (message: string) => void;
  showUploadAttemptMessage: (message: string) => void;
};

const InboundDeliveryDetail: React.FC<InboundDeliveryDetailProps> = (prop) => {
  const [inboundDelivery, setInboundDelivery] =
    useState<InboundDeliveryDetailsV11Dto>(prop.inboundDeliveryDetail);
  const [getUserById] = useUserCache();
  const maxLength: number = 30;

  const display_confirm: string = "Bestätigen";
  const display_cancel: string = "Abbrechen";
  const reservedPlaceHolder: string = "{%!Hu@}";
  const display_deliveryNumber: string = "Lieferscheinnummer";
  const display_deliveryDate: string = "Lieferdatum";

  //data change event
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    //setInboundDelivery({ ...inboundDelivery, [name]: value });
    prop.updateInboundDelivery({ ...inboundDelivery, [name]: value });
  };
  const handleDateChange = (date: Date | null) => {
    if (date != null) {
      //setInboundDelivery({ ...inboundDelivery, changedAt: date.toString() });
      prop.updateInboundDelivery({
        ...inboundDelivery,
        changedAt: date.toString(),
      });
    }
  };

  const GoodReceiptService = useGoodReceiptService();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != undefined && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
    else {
      prop.showUploadAttemptMessage("Error in retrieving file");
    }
  };
  const downloadFile = (fileName: string, url: string) => {
    const newTab = window.open(url, '_blank');
  };

  const [debounce, setDebounce] = useState<boolean>(false);
  useEffect(() => {
  }, [debounce]);

  useEffect(() => {
  }, [selectedFile]);
  const handleUpload = () => {
    if (selectedFile) {
      upload(selectedFile);
    }
    else {
      prop.showUploadAttemptMessage("Please select a file");
    }
  };

  function upload(file: File) {
    if (file) {
      if (debounce) {
        return;
      }
      setDebounce(true);
      //prop.showUploadAttempMessage("Uploading.. ");
      const formData = new FormData();
      formData.append("file", file);

      var fileName: string = generateRandomGuid().toString() + "_" + file.name;
      let request: InboundDeliveryDocumentUploadV11Request = {
        InboundDeliveryId: prop.inboundDeliveryDetail.inboundDeliveryId,
        type: "1",
        fileName: fileName,
        file: file,
      };
      GoodReceiptService.uploadDocument(request)
        .then((x) => {
          setDebounce(false);
          prop.notifyUploadCompleted("File uploaded as " + fileName + " completed. ");
          setSelectedFile(null);

          if (showCameraDialog) {
            setShowCameraDialog(false);
          }

        })
        .catch((error) => {
          setDebounce(false);
          console.log(error);
          prop.showUploadAttemptMessage(error);

          if (showCameraDialog) {
            setShowCameraDialog(false);
          }
        });
    }
    else {
      prop.showUploadAttemptMessage("Please select a file");
    }
  }


  const handleOpenCamera = () => {
    //prop.showUploadAttempMessage("Camera function not implemented yet.");
    setShowCameraDialog(true);
  };

  const mobileButtonRef = useRef<HTMLInputElement>(null);
  const handleOpenMobileCamera = () => {
    if (mobileButtonRef && mobileButtonRef.current) {
      mobileButtonRef.current.click();
    }
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };


  const display_openCamera: string = "Kamera öffnen";
  const display_upload: string = "Hochladen";
  const [showCameraDialog, setShowCameraDialog] = useState(false);
  const handleShowCameraDialogClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason && reason == "backdropClick") return;
    setShowCameraDialog(false);
  };
  const handleCaptureClick = () => {
    setShowCameraDialog(false);
  };
  useEffect(() => {
  }, [showCameraDialog]);

  function notifyImageCaptured(dataUrl: string) {


    urltoFile(dataUrl, "Capture.png", "image/png")
      .then(function (file) {
        console.log(file);
        //setSelectedFile(file);
        upload(file);
        //prop.showUploadAttempMessage("image url: " + dataUrl);
      })
      .catch((err) => {
        console.log(err);
        setShowCameraDialog(false);
        prop.showUploadAttemptMessage(err);
      });
  }
  function notifyCaptureCancel() {
    setShowCameraDialog(false);
  }

  // return a promise that resolves with a File instance
  function urltoFile(url: string, filename: string, mimeType: string) {
    if (url.startsWith('data:')) {
      var arr = url.split(','),
        mime = arr![0]!.match(/:(.*?);/)![1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then(res => res.arrayBuffer())
      .then(buf => new File([buf], filename, { type: mimeType }));
  }

  const [imageObject, setImageObject] = useState("");
  const handleFileInput = useRef(null);
  const handleImageChange = (event: any) => {
    upload(event.target.files[0]);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <TextField
          name="consignmentNote"
          label={display_deliveryNumber}
          variant="outlined"
          value={inboundDelivery?.consignmentNote}
          fullWidth={true}
          onChange={handleInputChange}
          inputProps={{ maxLength }}
          autoComplete="off"
          disabled={inboundDelivery?.consignmentNote?.length > 0}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <MobileDatePicker
          value={inboundDelivery?.createdAt}
          label={display_deliveryDate}
          onChange={handleDateChange}
          renderInput={(props) => <TextField fullWidth={true} {...props} />}
          disabled={inboundDelivery?.createdAt?.length > 0}
          inputFormat="dd.MM.yyyy"
        />
      </Grid>


      <Grid item xs={12} sm={12}>
        <Grid container xs={12}>
          <Grid sm={6}>
            <TextField
              name="document"
              type="file"
              variant="outlined"
              onChange={handleFileChange}
              autoComplete="off"
              fullWidth={true}
            />
          </Grid>
          <Grid item sm={2}>
            <div style={{ padding: "2px" }}>
              <Button
                variant="contained" disabled={selectedFile == null}
                onClick={handleUpload} fullWidth={true}>{display_upload}
              </Button>
            </div>

          </Grid>
          <Grid item sm={2}>
            <div style={{ padding: "2px" }}>
              <Button
                variant="contained"
                onClick={handleOpenCamera} fullWidth={true}>{display_openCamera}</Button>

            </div>

          </Grid>
          {/* <Grid item sm={2}>
            <div hidden={true} style={{ padding: "2px" }}>
              <Button
                variant="contained"
                onClick={handleOpenMobileCamera} fullWidth={true}>Mobile Camera</Button>

              <input
                ref={mobileButtonRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleImageChange}
              />
              <img src={imageObject} />
            </div>

          </Grid> */}
          <Grid item sm={10}>
            <div>
              <br />
              <List style={{ overflowY: "scroll", maxHeight: "30vh", padding: "4px" }}>
                {inboundDelivery.inboundDeliveryDocuments.map((item) => (
                  <ListItem
                    key={item.inboundDeliveryDocumentId}
                  //onClick={() => downloadFile(item.name, item.blobStorage)}
                  >
                    <ListItemText
                      primary={item.name}
                    //secondary={"Uploaded at: " + item.createdAt}
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => downloadFile(item.name, item.blobStorage)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
        </Grid>


        <Dialog
          onClose={handleShowCameraDialogClose}
          aria-labelledby="simple-dialog-title"
          open={showCameraDialog}
          hideBackdrop={false}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle>Capture</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>{contentText}</DialogContentText> */}
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div> <Camera showCameraControl={true} notifyCaptureCancel={notifyCaptureCancel} notifyImageCaptured={notifyImageCaptured}                  //capturing={showCameraDialog}
                  ></Camera></div>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          {/* <DialogActions>
            <Button
              onClick={() => {
                setShowCameraDialog(false);
              }}
            >
              {display_cancel}
            </Button>
            <Button onClick={handleCaptureClick} disabled={selectedFile == null}>{display_confirm}</Button>
          </DialogActions> */}
        </Dialog>
      </Grid>
    </Grid >
  );
};

export default React.memo(InboundDeliveryDetail);
