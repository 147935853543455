import Alert from "@mui/material/Alert";

export const AlertBox = ({
  alertType,
  contentText,
}: {
  alertType: AlertBoxType;
  contentText: string;
}) => {
  if (contentText.length > 0) {
    return <Alert severity={alertType}>{contentText}</Alert>;
  } else {
    return <div></div>;
  }
};

export enum AlertBoxType {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}
