import { HuV2Dto } from "interfaces/v2/hu/huV2Dto";
import axios from "../utils/axios";

interface IHandlingUnitServiceNonContext {
  getHandlingUnit: (Id: string) => Promise<HuV2Dto[]>;
}

const HandlingUnitServiceNonContext: IHandlingUnitServiceNonContext = {
  getHandlingUnit: async (id: string) => {
    // .get<CompanyMenuDetailsV12Dto>(`/v12/company-menus/${companyMenuId}`)
    const res = await axios.get("/v2/HandlingUnits/" + id);
    return res.data;
  },
};
export default HandlingUnitServiceNonContext;
