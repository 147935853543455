import React, { useEffect, useState } from "react";
import { TreeView, TreeItem } from "@mui/lab";
import { Alert, Autocomplete, Badge, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography } from "@mui/material";
import { ExpandMore, ChevronRight, Label } from "@mui/icons-material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InventoryIcon from "@mui/icons-material/Inventory";
import { WmsTreeNodeDto } from "interfaces/v1/wmsTree/WmsTreeNodeDto";
import WmsTreeDisplay from "components/platbricks/shared/WmsTreeDisplay";
import { invDocItem, inventoryDocument } from "interfaces/v1/inventoryDocument/inventoryDocument";
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    PropaneSharp,
} from "@mui/icons-material";
import { countDocRequest, task } from "interfaces/v3/countDoc/countDocRequest";
import { generateRandomGuid } from "types/guid";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { AlertBoxType } from "components/platbricks/shared/AlertBox";
import WmsAutoComplete from "components/platbricks/shared/WmsAutoComplete";
import { WmsAutoCompleteOption, WmsAutoCompleteType } from "interfaces/general/util/AutoCompleteOption";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import Scanner from "components/platbricks/shared/Scanner";

interface AddHuTaskComponentProp {
    locationId: string;
    invDocId: string;
    binId: string;
    binName: string;
    showAddHUDialog: boolean;
    title: string;
    existingTasks: task[];
    Close: () => void;
    HuTaskAdded: (addedTask: task) => void;
    displayNotification: (message: string) => void;
}

const AddHuTaskComponent: React.FC<AddHuTaskComponentProp> = (
    prop
) => {

    const [debounce, setDebounce] = useState<boolean>(false);
    useEffect(() => {
    }, [debounce]);
    useEffect(() => {
        if (prop.showAddHUDialog && !scanningHu) {
            setScanningHu(true);
        }
    }, [prop.showAddHUDialog]);

    //notificaiton dialog
    const [notificationText, setNotificationText] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    function displayNotification(message: string) {
        setNotificationText(message);
        setShowNotification(true);
    }

    function reset() {
        setNewHuNumber("");
        //setParentHuNumber("");
        setSelectedParentHu("");
        setSelectedParentHuDisplay("");
        setSelectedWmsAutoCompleteOption(null);
        setScannedParentValue("");
        setScannedHuValue("");
    }
    const display_noOptionMsg: string = "keine Optionen vorhanden";
    const display_confirm_AddToBin: string = "Add To Bin";
    const display_confirm_AddToHu: string = "Add to HU";
    const display_cancel: string = "Abbrechen";
    const display_HUType: string = "LT Typ";
    const [selectedWmsAutoCompleteOption, setSelectedWmsAutoCompleteOption] = useState<WmsAutoCompleteOption | null>(null);
    function autoCompleteOptionSelected(selectedAutoCompleteOption: WmsAutoCompleteOption | null) {
        setSelectedWmsAutoCompleteOption(selectedAutoCompleteOption);
    }
    useEffect(() => {

    }, [selectedWmsAutoCompleteOption]);

    const display_HuNumber: string = "Hu Number";
    const [newHuNumber, setNewHuNumber] = useState<string>("");
    const handleHuNumberChange = (huNumber: string) => {
        setNewHuNumber(huNumber);
    }

    const display_ParentHU: string = "Parent HU";
    // const [parentHuNumber, setParentHuNumber] = useState<string>("");
    // const handleParentHuNumberChange = (newParentHuNumber: string) => {
    //     setParentHuNumber(newParentHuNumber);
    // }

    const [selectedParentHu, setSelectedParentHu] = useState<string>("");
    const [selectedParentHuDisplay, setSelectedParentHuDisplay] = useState<string>("");
    const handleSelectedParentHuChange = (event: SelectChangeEvent) => {
        //console.log("selected parent HU change event", event.target);
        setSelectedParentHu(event.target.value as string);
        setSelectedParentHuDisplay(event.target.name as string);
    };

    const handleAddToBin = () => {
        //onAdd(hu);
        if (debounce) {
            return;
        }
        setDebounce(true);

        var requiredParentHU: boolean = false;
        if (!validationPass(requiredParentHU)) {
            setDebounce(false);
            return;
        }


        //var newTask = CreateTaskBasedOnValue(newHuNumber, selectedWmsAutoCompleteOption.label);
        var newTask = CreateTaskBasedOnValue(newHuNumber, selectedWmsAutoCompleteOption ? selectedWmsAutoCompleteOption.label : "", prop.binId, prop.binName);
        //prop.HuTaskAdded(GetMockHu());
        setDebounce(false);
        prop.HuTaskAdded(newTask);
        reset();

    };

    const handleAddToHu = () => {
        //onAdd(hu);
        if (debounce) {
            return;
        }
        setDebounce(true);

        var requiredParentHU: boolean = true;
        if (!validationPass(requiredParentHU)) {
            setDebounce(false);
            return;
        }


        //var newTask = CreateTaskBasedOnValue(newHuNumber, selectedWmsAutoCompleteOption.label);
        var newTask = CreateTaskBasedOnValue(newHuNumber, selectedWmsAutoCompleteOption ? selectedWmsAutoCompleteOption.label : "", selectedParentHu, selectedParentHuDisplay);
        //prop.HuTaskAdded(GetMockHu());
        setDebounce(false);
        prop.HuTaskAdded(newTask);
        reset();

    };

    function validationPass(requiredParentHU: boolean): boolean {
        if (newHuNumber.length <= 0) {
            displayNotification("Please enter Hu number");
            return false;
        }

        var existingHuWithSameHuNumber = prop.existingTasks.filter((x) => x.type == "HU" && x.huNumber == newHuNumber);
        if (existingHuWithSameHuNumber && existingHuWithSameHuNumber.length > 0) {
            displayNotification("Hu " + newHuNumber + " already existed");
            return false;
        }

        if (requiredParentHU && selectedParentHu.length <= 0) {
            displayNotification("Please select Parent Hu");
            return false;
        }

        return true;
    }

    function convertTaskToAvailableHuSelection(inputs: task[]): WmsAutoCompleteOption[] {

        var result: WmsAutoCompleteOption[];
        result = [];

        var huTasks = inputs.filter((x) => x.type == "HU");
        if (huTasks && huTasks.length > 0) {
            huTasks.forEach((x) => {
                result.push({
                    label: x.huNumber,
                    value: x.invDocItemId.length > 0 ? x.invDocItemId : x.temId
                });
            });
        }

        return result;
    }

    // function convertTaskToAvailableMenuItems(inputs: task[]): MenuItem[] {

    //     var result: MenuItem[];
    //     result = [];

    //     var huTasks = inputs.filter((x) => x.type == "HU");
    //     if (huTasks && huTasks.length > 0) {
    //         huTasks.forEach((x) => {
    //             result.push({
    //                 label: x.huNumber,
    //                 value: x.invDocItemId.length > 0 ? x.invDocItemId : x.temId
    //             });
    //         });
    //     }

    //     return result;
    // }

    const defaultCountResult: string = "1"; //2 - missing, 1 - found
    function CreateTaskBasedOnValue(huNumber: string, huType: string, parentElementId: string, parentNameDisplay: string): task {
        let output: task = {
            type: "HU",
            huNumber: huNumber,
            huType: huType,
            invDocItemId: "",
            wmsMaterialId: "",
            temId: generateRandomGuid().toString(),
            stockUsage: "",
            owner: "",
            specialStockType: "",
            specialStockNumber: "",
            customerStockItemNumber: 0,
            parentElementId: parentElementId,
            materialQuantity: 0,
            containerQuantity: 0,
            bomValue: "",
            huCountResult: defaultCountResult,
            batch: "",
            invDocId: prop.invDocId,
            stockType: "",
            parentInvDocItemId: "",
            huTypeId: "",
            method: 0,
            serialNumbers: [],

            parentName: parentNameDisplay
        }
        return output;
    }

    const handleDialogClose = (
        event: React.SyntheticEvent<Element, Event>,
        reason: string
    ) => {
        if (reason && reason == "backdropClick") return;
        reset();
        prop.Close();
    };

    const display_Scan: string = "Scannen";
    const display_Scanning: string = "Scan abbrechen";

    //scan parent
    const [scanningParent, setScanningParent] = useState<boolean>(false);
    const display_ScanParentInstruction: string = "Lagerplatz oder HU scannen";
    const [scannedParentValue, setScannedParentValue] = useState<string>(
        ""
    );
    function resetScanParent() {
        setScanningParent(true);
        setScannedParentValue("");
    }
    function scanParentStarted() {
        resetScanParent();
        setScanningParent(true);
    }
    function scanParentCompleted(scannedValue: string) {
        setScanningParent(false);
        setScannedParentValue(scannedValue);
    }
    function scanParentCancelled() {
        setScanningParent(false);
    }
    useEffect(() => {
        if (scannedParentValue && scannedParentValue.length > 0) {
            var matchedOptions = prop.existingTasks.filter((x) => x.type == "HU" && x.huNumber === scannedParentValue);
            if (matchedOptions && matchedOptions.length == 1) {
                setSelectedParentHu(matchedOptions[0].invDocItemId.length > 0 ? matchedOptions[0].invDocItemId : matchedOptions[0].temId)
            }
            else {
                displayNotification("Scan value is not a valid HU within this Bin. ");
            }
        }
        else {
            setScannedParentValue("");
        }
    }, [scannedParentValue]);




    //scan hu
    const [scanningHu, setScanningHu] = useState<boolean>(true);
    const display_ScanHuInstruction: string = "Lagerplatz oder HU scannen";
    const [scannedHuValue, setScannedHuValue] = useState<string>(
        ""
    );
    function resetScanHu() {
        setScanningHu(true);
        setScannedHuValue("");
    }
    function scanHuStarted() {
        resetScanHu();
        setScanningHu(true);
    }
    function scanHuCompleted(scannedValue: string) {
        setScanningHu(false);
        setScannedHuValue(scannedValue);
    }
    function scanHuCancelled() {
        setScanningHu(false);
    }
    useEffect(() => {
        setNewHuNumber(scannedHuValue);
    }, [scannedHuValue]);

    return (
        <Dialog
            onClose={handleDialogClose}
            aria-labelledby="simple-dialog-title"
            open={prop.showAddHUDialog}
            hideBackdrop={false}
            PaperProps={{
                style: {
                    minWidth: "50%",
                },
            }}
        >
            <DialogTitle>{prop.title}</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>{contentText}</DialogContentText> */}
                <Box sx={{ mt: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                label={display_HuNumber}
                                value={newHuNumber}
                                onChange={(e) => setNewHuNumber(e.target.value)}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Scanner
                                startScan={scanningHu}
                                beforeScanText={display_Scan}
                                duringScanText={display_Scanning}
                                scanCompleted={scanHuCompleted}
                                scanStarted={scanHuStarted}
                                scanCancelled={scanHuCancelled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <WmsAutoComplete
                                locationId={prop.locationId}
                                populateLength={3}
                                displayLabel={display_HUType}
                                autoCompleteType={WmsAutoCompleteType.HUTYPE}
                                selectedOption={selectedWmsAutoCompleteOption}
                                optionSelected={autoCompleteOptionSelected}
                                notifyMessage={prop.displayNotification}
                                withScanner={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{display_ParentHU}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedParentHu}
                                    label={display_ParentHU}
                                    onChange={handleSelectedParentHuChange}
                                >
                                    {/* <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem> */}
                                    {prop.existingTasks.filter((x) => x.type == "HU").map((task) => (
                                        <MenuItem
                                            //key={task.invDocItemId.length > 0 ? task.invDocItemId : task.temId}
                                            //value={task.huNumber}
                                            value={task.invDocItemId.length > 0 ? task.invDocItemId : task.temId}
                                        >{task.huNumber}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Scanner
                                startScan={scanningParent}
                                beforeScanText={display_Scan}
                                duringScanText={display_Scanning}
                                scanCompleted={scanParentCompleted}
                                scanStarted={scanParentStarted}
                                scanCancelled={scanParentCancelled}
                            />
                        </Grid>


                        <NotificationDialog
                            title="Info"
                            contentText={notificationText}
                            onClose={() => {
                                setShowNotification(false);
                            }}
                            visible={showNotification}
                            onConfirm={() => {
                                setShowNotification(false);
                            }}
                        />
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        reset();
                        prop.Close();
                    }}
                >
                    {display_cancel}
                </Button>
                <Button onClick={handleAddToBin}>{display_confirm_AddToBin}</Button>
                <Button onClick={handleAddToHu}>{display_confirm_AddToHu}</Button>
            </DialogActions>
        </Dialog>
    )
};

export default AddHuTaskComponent;
