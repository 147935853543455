import { Button, Grid, TextField } from "@mui/material";
import { ConfirmationDialog } from "components/platbricks/shared/ConfirmationDialog";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
//import useAuth from "hooks/useAuth";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import { useTransportService } from "services/TransportService";
import { searchTransportV11Request } from "interfaces/v11/transport/searchTransportV11Request";
import { searchTransportV11Response } from "interfaces/v11/transport/searchTransportV11Response";
import { createTransportV11Response } from "interfaces/v11/transport/createTransportV11Response";
import useAuth from "hooks/useAuth";
import { transportStop } from "interfaces/v11/transport/createTransportV11Request";

function ShipmentSearchComponent() {
  const maxLength: number = 30;
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { signOut } = useAuth();

  const transportService = useTransportService();

  const display_ConfirmTextToCreateTranspot: string =
    "Verladung {transportNumber} existiert nicht. Soll eine Verladung mit dieser Nummer angelegt werden?";
  const display_NewTransport: string = "Neuer Transport";
  const display_OpenExistingTransport: string = "Transport öffnen";
  const display_EnterTransportNoToContinue: string =
    "Geben Sie eine Verladung/Transport Nummer ein, um fortzufahren.";
  const display_TransportNo: string = "Verladung";
  const display_SearchTransport: string = "Search";
  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";

  const [debounce, setDebounce] = useState<boolean>(false);
  useEffect(() => {
    //console.log("debounce " + new Date(), debounce);
  }, [debounce]);

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  //confirmation dialog
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmatioNText, setConfirmationText] = useState(
    display_ConfirmTextToCreateTranspot
  );
  const handleConfirmCreateTransportClick = async () => {
    createTransport(true);
  };

  function createTransport(WithTransportNumber: boolean) {
    if (debounce) {
      return;
    }

    setDebounce(true);

    let newTransportNumber: string = "";
    if (WithTransportNumber) {
      newTransportNumber = transportNumber;
    }

    const defaultTransportStop: transportStop = {
      transportStopNumber: "1",
      transportStopIndex: 0,
    };
    const defaultTransportStops: transportStop[] = [];
    defaultTransportStops.push(defaultTransportStop);

    transportService
      .createTransport({
        locationId: locationId as string,
        transportNumber: transportNumber,
        transportStops: defaultTransportStops,
      })
      .then((result) => {
        //handleConfirmCreateInboundDeliveryV2Response(result);
        setDebounce(false);
        handleConfirmCreateTransportResponse(result);
      })
      .catch((err) => {
        console.log(err);
        setDebounce(false);
        displayNotification(display_SomethingWentWrong);
      });
  }

  function handleConfirmCreateTransportResponse(
    response: createTransportV11Response
  ) {
    if (response.id != null && response.id.length > 0) {
      //displayNotification(response.id);
      navigateToTargetTransport(response.id);
    } else {
      displayNotification(display_SomethingWentWrong);
    }
  }

  const [transportNumber, setTransportNumber] = useState("");
  const [transports, setTransports] = useState<
    searchTransportV11Response[] | null
  >(null);
  const handleCreateTransportButtonClick = useCallback(async () => {
    // displayNotification(
    //   "Create transport without specifying Transport Number is not supported yet. "
    // );
    createTransport(false);
  }, [transportNumber]);

  const searchTransportNumberRef = useRef<HTMLButtonElement>(null);
  function handleTransportNumberKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (event.key === "Enter" && searchTransportNumberRef.current) {
      searchTransportNumberRef.current.click();
    }
  }

  const handleSearchTransportNumberButtonClick = useCallback(async () => {
    if (debounce) {
      return;
    }
    setDebounce(true);
    if (transportNumber.length <= 0) {
      displayNotification(display_EnterTransportNoToContinue);
      setDebounce(false);
      return;
    }

    let locationIds: string[] = [];
    locationIds.push(locationId as string);
    let transportNumbers: string[] = [];
    transportNumbers.push(transportNumber);

    const searchRequest: searchTransportV11Request = {
      locationIds: locationIds,
      transportNumber: transportNumbers,
    };
    transportService
      .searchTransports(searchRequest)
      .then((result) => {
        console.log(result);
        setDebounce(false);
        setTransports(result);
      })
      .catch((response) => {
        console.log(response);
        setDebounce(false);
        if (response.Status == "401") {
          handleSignOut();
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      });
  }, [transportNumber]);

  useEffect(() => {
    if (transports) {
      if (transports.length == 0) {
        let msg = display_ConfirmTextToCreateTranspot.replace(
          "{transportNumber}",
          transportNumber
        );
        setConfirmationText(msg);
        setShowConfirmation(true);

        // displayNotification(
        //   "Transpot does not exist"
        // );
      } else if (transports.length == 1) {
        let id = transports.at(0)!.id;
        //displayNotification("transport " + transportNumber + " found - " + id);
        // let status = transports.at(0)?.;
        // if (status == "OPEN") {
        //   navigateToTargetInbouldDelivery(id);
        // } else {
        //   displayNotification(
        //     "The inbound delivery is " +
        //       status +
        //       ". Cannot perform Good Receipt. "
        //   );
        // }
        navigateToTargetTransport(id);
      } else {
        displayNotification(
          "Something went wrong. More than 1 transport found"
        );
      }
    }
  }, [transports]);

  function navigateToTargetTransport(id: string) {
    let path = "/locations/" + locationId + "/shipments/" + id;
    navigate(path);
  }

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleTransportNumberInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setTransportNumber(value);
  };

  return (
    <div>
      <ConfirmationDialog
        contentText={confirmatioNText}
        onClose={() => {
          setShowConfirmation(false);
        }}
        positiveText="Bestätigen"
        negativeText="Abbrechen"
        visible={showConfirmation}
        onConfirm={async () => {
          await handleConfirmCreateTransportClick();
        }}
      />

      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div hidden={false}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={async () => {
                await handleCreateTransportButtonClick();
              }}
              disabled={debounce}
              startIcon={<AddIcon />}
            >
              <div>
                <br />
                <br />
              </div>
              {display_NewTransport}
            </Button>
            <br />
            <br />
            <br />
          </div>
        </Grid>

        <Grid item xs={12} sm={12}>
          <div
            style={{
              border: "1px solid grey",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Grid item xs={12} sm={12}>
              <AlertBox
                alertType={AlertBoxType.info}
                contentText={display_EnterTransportNoToContinue}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="transportNo"
                name="transportNo"
                label={display_TransportNo}
                variant="outlined"
                value={transportNumber}
                fullWidth={true}
                onChange={handleTransportNumberInputChange}
                inputProps={{ maxLength }}
                autoComplete="off"
                onKeyDown={handleTransportNumberKeyDown}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                ref={searchTransportNumberRef}
                variant="contained"
                fullWidth={true}
                disabled={debounce}
                onClick={async () => {
                  await handleSearchTransportNumberButtonClick();
                }}
              >
                <div>
                  <br />
                </div>
                {display_OpenExistingTransport}
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ShipmentSearchComponent;
