import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import { useGoodReceiptService } from "services/GoodReceiptService";
import { WMSMaterialV11Dto } from "interfaces/v11/materialDetail/wmsMaterialDetailsV11Dto";
import { wmsMaterialSearchV11Dto } from "interfaces/v11/materialDetail/wmsMaterialSearchV11Dto";
import { useParams } from "react-router-dom";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { generateRandomGuid, guid } from "types/guid";
import { ownerSearchResponseDto } from "interfaces/v1/owner/ownerSearchResponseDto";
import { batchSearchV11ResponseDto } from "interfaces/v11/batch/batchSearchV11Response";
import { batchSearchV11RequestDto } from "interfaces/v11/batch/batchSearchV11RequestDto";
import { number } from "yup";
import { ConfirmationDialog } from "components/platbricks/shared/ConfirmationDialog";
import { color } from "@mui/system";
import { Opacity } from "@mui/icons-material";

interface GoodReceiptAddNewItemProp {
  inboundDelivery: InboundDeliveryDetailsV11Dto;
  onInboundDeliveryItemAdded: () => void;
  afsStockTypeId: string;
}

interface material {
  materialNumber: string;
  quantity: number;
  batch: string;
  owner: string;
}

interface Option {
  label: string;
  value: string;
}

interface Search {
  search: string;
}

const InboundDeliveryItemAddNewItem: React.FC<GoodReceiptAddNewItemProp> = (
  prop
) => {
  const { locationId } = useParams();

  const display_noOptionMsg: string = "keine Optionen vorhanden";
  const reservedPlaceHolder: string = "{%!Hu@}";
  const display_confirm: string = "Bestätigen";
  const display_cancel: string = "Abbrechen";
  const display_save: string = "Speichern";

  const display_InvalidMaterialNumber: string = "Invalid Material Number";
  const display_PleaseSelectMaterial: string =
    "Please select Material to continue";
  const display_PleaseEnterQuantity: string =
    "Bitte Menge eingeben, um Buchung abzuschliessen.";
  const display_somethingWentWrongSearchMaterial: string =
    "Something went wrong. Could not find Material. ";
  const display_somethingWentWrongGetBatch: string =
    "Something went wrong. Could not find Batch. ";
  const display_somethingWentWrongCreateBatch: string =
    "Something went wrong. Could not create Batch. ";
  const display_somethingWentWrongGetOwner: string =
    "Something went wrong. Could not get Owner. ";
  const display_PleaseEnterBatch: string = "Bitte Zeichnungsstand eingeben";

  const display_AddNewItem: string = "Neue Anlieferposition hinzufügen";
  const display_MaterialNumber: string = "Materialnummer";
  const display_Scan: string = "Scannen";
  const display_CancelScan: string = "Scan abbrechen";
  const display_BaseUnit: string = "Mengeneinheit";
  const display_Quantity: string = "Menge";
  const display_Batch: string = "Zeichnungsstand";
  const display_Owner: string = "Baulos";
  const display_Add: string = "Hinzufügen";
  //Hinzufügen​

  const display_NoSelectedHU: string = "Bitte Ladungsträger auswählen";
  const display_NoSelectedMaterial: string = "Bitte Material auswählen";

  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";

  const display_MaterialRecorded: string = "Material erfassen";
  const display_new: string = "Neu";
  const display_HU: string = "Ladungsträger";
  const display_somethingWentWrongSearchBin: string =
    "Something went wrong. Could not find Bin. ";
  const display_somethingWentWrongSearchHUType: string =
    "Something went wrong. Could not find HU Type. ";

  const GoodReceiptService = useGoodReceiptService();
  const [material, setMaterial] = useState({
    materialId: "",
    quantity: 0,
    batch: "",
    owner: "",
  });

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  const [showScanMaterial, setShowScanMaterial] = useState(false);
  const handleScanMaterialClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason && reason == "backdropClick") return;
    setShowScanMaterial(false);
  };
  function onScanned(materialNumber: string) {
    if (materialNumber.length > 0) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(materialNumber);

      let searchDto: wmsMaterialSearchV11Dto;
      searchDto = {
        materialAndNumberContains: [],
        page: 0,
        pageSize: 20,
      };
      searchDto.materialAndNumberContains = searchTexts;

      GoodReceiptService.searchWmsMaterials(searchDto)
        .then((result) => {
          if (result.length == 1) {
            const option = convertWmsMaterialResultToSelectOptions(result);
            setMaterialOption(option);
            setSelectedMaterial(result);
            setSelectedMaterialOption(option[0]);
            setMaterial({
              ...material,
              materialId: option[0].value,
            });
            setShowScanMaterial(false);
          } else {
            setShowScanMaterial(false);
            displayNotification(display_InvalidMaterialNumber);
          }
        })
        .catch(() => {
          setShowScanMaterial(false);
          displayNotification(display_InvalidMaterialNumber);
        });
    } else {
      setShowScanMaterial(false);
      displayNotification(display_InvalidMaterialNumber);
    }
  }

  const [debounce, setDebounce] = useState<boolean>(false);
  useEffect(() => {
    console.log("debounce " + new Date(), debounce);
  }, [debounce]);

  function CreateMaterial(confirmedCreateMaterialWithoutBatch: boolean) {
    if (debounce) {
      return;
    }

    setDebounce(true);

    if (material.materialId.length <= 0) {
      displayNotification(display_PleaseSelectMaterial);
      setDebounce(false);
      return;
    }

    if (material.quantity <= 0) {
      displayNotification(display_PleaseEnterQuantity);
      setDebounce(false);
      return;
    }

    if (selectedMaterial == undefined || selectedMaterial.length <= 0) {
      displayNotification("Cannot get material detail. ");
      setDebounce(false);
      return;
    }

    if (material.batch.length <= 0) {
      if (!confirmedCreateMaterialWithoutBatch) {
        setShowConfirmation(true);
        setDebounce(false);
        return;
      }
      //displayNotification(display_PleaseEnterBatch);
    }

    const uom: string = selectedMaterial[0].baseUnit;

    let maxNumber: number = 0;
    prop.inboundDelivery.inboundDeliveryItems.forEach((x) => {
      let tempDeliveryItemNumber: string =
        x.inboundDeliveryItemNumber != undefined
          ? x.inboundDeliveryItemNumber
          : "";

      let tempDeliveryItemNumberAsFloat: number = parseFloat(
        tempDeliveryItemNumber
      );

      if (
        !isNaN(tempDeliveryItemNumberAsFloat) &&
        isFinite(tempDeliveryItemNumberAsFloat)
      ) {
        if (tempDeliveryItemNumberAsFloat > maxNumber) {
          maxNumber = tempDeliveryItemNumberAsFloat;
        }
      }
    });

    let deliveryItemNumber: string = "";
    if (maxNumber == 0) {
      let newLength = prop.inboundDelivery.inboundDeliveryItems.length + 1;
      deliveryItemNumber = (newLength * 10).toString();
    } else {
      deliveryItemNumber = (maxNumber + 10).toString();
    }

    let createItemRequest: _InboundDeliveryItemDetailsV11Dto = {
      inboundDeliveryItemId: generateRandomGuid(),
      inboundDeliveryItemNumber: deliveryItemNumber,
      batch: material.batch,
      itemType: "UNSPECIFIED",
      materialId: material.materialId as guid,
      owner: material.owner,
      stockUsage: "UNSPECIFIED",
      stockTypeId: prop.afsStockTypeId as guid,
      inboundDeliveryItemQuantities: [
        {
          inboundDeliveryItemQuantityId: generateRandomGuid(),
          quantityRoleCategory: "REQUESTED",
          quantityRole: "GR",
          unitOfMeasurement: uom,
          quantity: material.quantity,
        },
      ],
    };

    setDebounce(false);
    CreateItem(createItemRequest);
  }

  const handleButtonClick = useCallback(async () => {
    CreateMaterial(false);
  }, [material]);

  function CreateItem(request: _InboundDeliveryItemDetailsV11Dto) {
    if (debounce) {
      return;
    }
    setDebounce(true);
    console.log(request);
    let temp = JSON.parse(JSON.stringify(prop.inboundDelivery));
    //let temp = { ...prop.inboundDelivery };
    temp.inboundDeliveryItems.push(request);
    //call web service
    GoodReceiptService.updateInboundDelivery(temp)
      .then((result) => {
        //prop.inboundDelivery.inboundDeliveryItems.push(request);
        {
          setDebounce(false);
          prop.onInboundDeliveryItemAdded();
        }
      })
      .catch(() => {
        displayNotification(display_SomethingWentWrong);
        setDebounce(false);
      });
  }

  const [selectedMaterial, setSelectedMaterial] = useState<WMSMaterialV11Dto[]>(
    []
  );
  const [materialOption, setMaterialOption] = useState<Option[]>([]);
  const [selectedMaterialOption, setSelectedMaterialOption] =
    useState<Option | null>(null);
  const [searchMaterialQuery, setSearchMaterialQuery] = useState<Search>({
    search: "",
  });
  const handleSearchMaterialInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchMaterialQuery({ search: event.target.value });
  };
  useEffect(() => {
    if (searchMaterialQuery.search.length >= 3) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(searchMaterialQuery.search);

      let searchDto: wmsMaterialSearchV11Dto;
      searchDto = {
        materialAndNumberContains: [],
        page: 0,
        pageSize: 20,
      };
      searchDto.materialAndNumberContains = searchTexts;

      GoodReceiptService.searchWmsMaterials(searchDto)
        .then((result) => {
          setMaterialOption(convertWmsMaterialResultToSelectOptions(result));
          setSelectedMaterial(result);
        })
        .catch(() => console.log(display_somethingWentWrongSearchMaterial));
    }
  }, [searchMaterialQuery.search]);

  function convertWmsMaterialResultToSelectOptions(
    data: WMSMaterialV11Dto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.materialId,
      label: item.material + " - " + item.name,
    }));
  }

  const handleMaterialChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      setSelectedMaterialOption(option);
      setMaterial({
        ...material,
        materialId: option.value,
      });

      let temp: WMSMaterialV11Dto[] = JSON.parse(
        JSON.stringify(selectedMaterial)
      );
      const newSelectedMaterial: WMSMaterialV11Dto[] = temp.filter(
        (item) => item.materialId === option.value
      );
      setSelectedMaterial(newSelectedMaterial);
    } else {
      setSelectedMaterialOption(null);
      setMaterial({
        ...material,
        materialId: "",
      });
      setSelectedMaterial([]);
    }
  };

  //data change event
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setMaterial({ ...material, [name]: value });
  };

  const handleNumericInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const inputValue = Number(event.target.value);
    if (!isNaN(inputValue)) {
      setMaterial({ ...material, [name]: value });
    }
  };

  //----------------
  const [inputValue, setInputValue] = useState("");
  const searchRef = useRef<HTMLButtonElement>(null);
  const focusRef = useRef<HTMLButtonElement>(null);
  const searchTextRef = useRef<HTMLInputElement>(null);
  const [refAquired, setRefAquired] = useState(false);

  const searchMaterialButtnRef = useRef<HTMLButtonElement>(null);
  const searchMaterialTextRef = useRef<HTMLInputElement>(null);
  const [scannedMaterialNumber, setScannedMaterialNumber] = useState("");
  function handleScanMaterialSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (scannedMaterialNumber.length > 0) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(scannedMaterialNumber);

      let searchDto: wmsMaterialSearchV11Dto;
      searchDto = {
        materialAndNumberContains: [],
        page: 0,
        pageSize: 20,
      };
      searchDto.materialAndNumberContains = searchTexts;

      GoodReceiptService.searchWmsMaterials(searchDto)
        .then((result) => {
          if (result.length == 1) {
            const option = convertWmsMaterialResultToSelectOptions(result);
            setMaterialOption(option);
            setSelectedMaterial(result);
            setSelectedMaterialOption(option[0]);
            setMaterial({
              ...material,
              materialId: option[0].value,
            });
            StopScanning();
          } else {
            StopScanning();
            displayNotification(display_InvalidMaterialNumber);
          }
        })
        .catch(() => {
          StopScanning();
          displayNotification(display_InvalidMaterialNumber);
        });
    } else {
      StopScanning();
      displayNotification(display_InvalidMaterialNumber);
    }
  }
  function handleScanMaterialKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (event.key === "Enter" && searchMaterialButtnRef.current) {
      searchMaterialButtnRef.current.click();
    }
  }

  function StartScanning() {
    setMaterialOption([]);
    setSelectedMaterial([]);
    setSelectedMaterialOption(null);
    setMaterial({
      ...material,
      materialId: "",
    });
    setShowScanMaterial(true);
    setScannedMaterialNumber("");
    if (searchMaterialTextRef.current) {
      searchMaterialTextRef.current?.focus();
    }
  }
  function StopScanning() {
    setShowScanMaterial(false);
    setScannedMaterialNumber("");
  }

  function handleScanMaterialTextBlur(
    event: React.FocusEvent<HTMLInputElement>
  ) {
    StopScanning();
  }

  const performScan = () => {
    //displayNotification("This function is not implemented yet");
    StartScanning();
  };

  //--batch
  const [searchBatchQuery, setSearchBatchQuery] = useState<Search>({
    search: "",
  });
  const [batchOption, setBatchOption] = useState<Option[]>([]);
  const [selectedBatchOption, setSelectedBatchOption] = useState<Option | null>(
    null
  );

  const handleSearchBatchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchBatchQuery({ search: event.target.value });
  };

  function convertBatchResultToSelectOptions(
    data: batchSearchV11ResponseDto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.name,
      label: item.name,
    }));
  }

  useEffect(() => {
    if (searchBatchQuery.search.length >= 1) {
      if (selectedMaterial == null) {
        displayNotification("Please select material first");
        return;
      }

      let materialIds: string[];
      materialIds = [];
      materialIds.push(selectedMaterial[0].materialId);

      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(searchBatchQuery.search);

      let searchDto: batchSearchV11RequestDto;
      searchDto = {
        page: 0,
        pageSize: 20,
        materialIds: [],
        nameContains: [],
      };

      searchDto.materialIds = materialIds;
      searchDto.nameContains = searchTexts;

      GoodReceiptService.getBatch(searchDto)
        .then((result) => {
          console.log(result);
          if (result != null) {
            //let temp: batchSearchV11ResponseDto[] = [];
            //temp.push(result);
            //console.log(convertBatchResultToSelectOptions(temp));
            setBatchOption(convertBatchResultToSelectOptions(result));
          }
        })
        .catch(() => {
          console.log(display_somethingWentWrongGetBatch);
        });
    }
  }, [searchBatchQuery.search]);

  const handleSelectedBatchChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      setSelectedBatchOption(option);
      setMaterial({ ...material, batch: option.label });
    }
  };

  //--owner
  const [searchOwnerQuery, setSearchOwnerQuery] = useState<Search>({
    search: "",
  });
  const [ownerOption, setOwnerOption] = useState<Option[]>([]);
  const [selectedOwnerOption, setSelectedOwnerOption] = useState<Option | null>(
    null
  );

  const handleSearchOwnerInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchOwnerQuery({ search: event.target.value });
  };

  function convertOwnerResultToSelectOptions(
    data: ownerSearchResponseDto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.ownerId,
      label: item.name,
    }));
  }

  useEffect(() => {
    if (searchOwnerQuery.search.length >= 0) {
      GoodReceiptService.getOwner({
        locationId: locationId!,
        ownerName: searchOwnerQuery.search,
      })
        .then((result) => {
          setOwnerOption(convertOwnerResultToSelectOptions(result));
          //setOwnerOption([]);
          //setSelectedOwnerOption(null);
        })
        .catch(() => {
          console.log(display_somethingWentWrongGetOwner);
        });
    }
  }, [searchOwnerQuery.search]);

  const handleSelectedOwnerChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      setSelectedOwnerOption(option);
      setMaterial({ ...material, owner: option.label });
    }
  };

  //----------------
  //confirmation dialog
  const display_confirmTextToCreateMaterialWithoutBatch =
    "Kein Zeichnungsstand eingetragen. Möchten Sie fortfahren?";
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmatioNText, setConfirmationText] = useState(
    display_confirmTextToCreateMaterialWithoutBatch
  );
  const handleConfirmCreateMaterialWithoutBatch = async () => {
    CreateMaterial(true);
  };

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{
        opacity: debounce ? "0.5" : "1",
      }}
    >
      <ConfirmationDialog
        contentText={confirmatioNText}
        onClose={() => {
          setShowConfirmation(false);
        }}
        positiveText="Ja"
        negativeText="Nein"
        visible={showConfirmation}
        onConfirm={async () => {
          await handleConfirmCreateMaterialWithoutBatch();
        }}
      />

      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      <Typography variant="subtitle2" gutterBottom>
        {display_AddNewItem}
      </Typography>
      <div>{showScanMaterial}</div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7}>
          <Autocomplete
            noOptionsText={display_noOptionMsg}
            value={selectedMaterialOption}
            onChange={handleMaterialChange}
            options={materialOption}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={display_MaterialNumber}
                variant="outlined"
                onChange={handleSearchMaterialInputChange}
              />
            )}
          />

          <div hidden={showScanMaterial}>
            <Button onClick={performScan} variant="contained">
              {display_Scan}
            </Button>
          </div>
          <div hidden={!showScanMaterial}>
            <Button
              onClick={() => {
                setShowScanMaterial(false);
              }}
              disabled={!showScanMaterial}
              variant="contained"
            >
              {display_CancelScan}
            </Button>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={7} hidden={!scan}>
                    <div>Scannnnn</div>
                </Grid> */}
        <Grid item xs={12} sm={7}>
          <TextField
            name="baseUnit"
            label={display_BaseUnit}
            variant="outlined"
            value={
              selectedMaterial.length == 1 ? selectedMaterial[0].baseUnit : ""
            }
            autoComplete="off"
            fullWidth={true}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            disabled={selectedMaterial == null || selectedMaterial.length != 1}
            name="quantity"
            label={display_Quantity}
            variant="outlined"
            value={material.quantity}
            autoComplete="off"
            fullWidth={true}
            required={true}
            onChange={handleNumericInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          {/* <TextField
            name="batch"
            label={display_Batch}
            variant="outlined"
            value={material.batch}
            autoComplete="off"
            fullWidth={true}
            required={true}
            hidden={false}
            onChange={handleInputChange}
          /> */}
          <Autocomplete
            noOptionsText={display_noOptionMsg}
            disabled={selectedMaterial == null || selectedMaterial.length != 1}
            value={selectedBatchOption}
            onChange={handleSelectedBatchChange}
            aria-required={false}
            options={batchOption}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={display_Batch}
                variant="outlined"
                onChange={handleSearchBatchInputChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Autocomplete
            noOptionsText={display_noOptionMsg}
            disabled={selectedMaterial == null || selectedMaterial.length != 1}
            value={selectedOwnerOption}
            onChange={handleSelectedOwnerChange}
            aria-required={false}
            options={ownerOption}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={display_Owner}
                variant="outlined"
                onChange={handleSearchOwnerInputChange}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={7}>
          <Button
            variant="contained"
            fullWidth={true}
            onClick={async () => {
              await handleButtonClick();
            }}
            disabled={
              selectedMaterial == null ||
              selectedMaterial.length != 1 ||
              debounce
            }
          >
            {display_Add}
          </Button>
        </Grid>
      </Grid>

      <div style={{ overflow: "hidden", position: "relative" }}>
        <div
          style={{
            border: "1px solid black",
            position: "absolute",
            right: "-500px",
          }}
        >
          <i>This technically should be invisible to user</i>
          <form onSubmit={handleScanMaterialSubmit}>
            <input
              type="text"
              value={scannedMaterialNumber}
              onChange={(event) => setScannedMaterialNumber(event.target.value)}
              onKeyDown={handleScanMaterialKeyDown}
              onBlur={handleScanMaterialTextBlur}
              ref={searchMaterialTextRef}
            />
            <button type="button" ref={searchMaterialButtnRef}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </Grid>
  );
};

export default React.memo(InboundDeliveryItemAddNewItem);
