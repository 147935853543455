import { Box, Button, Grid, Paper, styled } from "@mui/material";
import { flexbox, height } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

type CameraProp = {
  showCameraControl: boolean; //not showing camera control = open camera instantly
  notifyImageCaptured: (dataUrl: string) => void;
  notifyCaptureCancel: () => void;
};

const Camera: React.FC<CameraProp> = (prop) => {
  const display_capture: string = "Erfassen";
  const display_retake: string = "Wiederholen";
  const display_confirm: string = "Bestätigen";
  const display_cancel: string = "Abbrechen";
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  //function Camera() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [capturing, setCapturing] = useState<boolean>(true);

  const [imageUrl, setImageUrl] = useState<string>("");
  const openCamera = async () => {
    setCapturing(true);
  };

  function confirmCapturedImage() {
    if (imageUrl && imageUrl.length > 0) {
      prop.notifyImageCaptured(imageUrl);
    }
    else {
      alert("Please capture an image. ");
    }
  }

  useEffect(() => {
    if (capturing) {
      startCapturing();
    }
    else {
      stopCapturing();
    }
  }, [capturing]);

  async function startCapturing() {
    try {
      if (imageUrl.length > 0) {
        setImageUrl("");
      }
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
      setStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.hidden = false;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  }

  async function stopCapturing() {
    try {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop(); // Stop each track in the stream
        });
        setStream(null); // Clear the stream state
        if (videoRef.current) {
          videoRef.current.hidden = true;
        }
      }
    } catch (error) {
      console.error("Error closing camera:", error);
    }
  }

  const stopCamera = async () => {
    setCapturing(false);
  };

  const cancelCamera = async () => {
    setCapturing(false);
    prop.notifyCaptureCancel();
  };

  const capturePicture = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (context) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageDataUrl = canvas.toDataURL("image/png");
        setImageUrl(imageDataUrl);
      }
      stopCamera();
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "80%" }}>
        <video ref={videoRef} autoPlay width={"100%"} />
        <canvas ref={canvasRef} style={{ display: "none" }} width={"100%"} />
        {imageUrl.length > 0 ? <img src={imageUrl} width={"100%"} /> : <div></div>}
      </div>
      <div style={{ width: "20%" }}>
        <div style={{ padding: "4px" }} hidden={imageUrl.length <= 0}><Button variant="contained" onClick={confirmCapturedImage} fullWidth={true}>{display_confirm}</Button></div>
        <div style={{ padding: "4px" }} hidden={capturing}><Button variant="contained" onClick={openCamera} fullWidth={true}>{display_retake}</Button></div>
        <div style={{ padding: "4px" }} hidden={!capturing}><Button variant="contained" onClick={capturePicture} fullWidth={true}>{display_capture}</Button></div>

        <div style={{ padding: "4px" }}><Button variant="contained" onClick={cancelCamera} fullWidth={true}>{display_cancel}</Button></div>
      </div>
    </div>
  );
}

export default Camera;
