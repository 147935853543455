import React, { useEffect, useState } from "react";
import { TreeView, TreeItem } from "@mui/lab";
import { Badge, Checkbox, Grid } from "@mui/material";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InventoryIcon from "@mui/icons-material/Inventory";
import { WmsTreeNodeDto } from "interfaces/v1/wmsTree/WmsTreeNodeDto";

// Type definitions for the tree nodes
export enum wmsTreeType {
  bin = "Bin",
  hu = "HU",
  stock = "Stock",
}

// export interface MaterialInfo {
//   materialNumber: string;
//   materialDescription: string;
//   materialQuantity: string;
//   materialUnit: string;
// }
// export interface TreeNode {
//   wmsId: string;
//   display: string;
//   wmsTreeType: wmsTreeType;
//   children?: TreeNode[];
//   MaterialInfo?: MaterialInfo;
// }

interface WmsTreeViewWithCheckboxProp {
  treeData: WmsTreeNodeDto[];
  selectedWmsIds: string[];
  onChange: (selectedWmsIds: string[]) => void;
  allowSelectBin: boolean;
  allowSelectHU: boolean;
  allowSelectStock: boolean;
}

const WmsTreeViewWithCheckbox: React.FC<WmsTreeViewWithCheckboxProp> = (
  prop
) => {
  const [checked, setChecked] = useState<string[]>(prop.selectedWmsIds);
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    prop.onChange(checked);
  }, [checked]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    nodeId: string
  ) => {
    if (event.target.checked) {
      setChecked((prevChecked) => [...prevChecked, nodeId]);
      checkChildren(nodeId);
    } else {
      setChecked((prevChecked) => prevChecked.filter((id) => id !== nodeId));
      uncheckChildren(nodeId);
    }
  };

  const checkChildren = (nodeId: string) => {
    const node = findNodeById(prop.treeData, nodeId);
    if (node) {
      const childIds = getAllChildIds(node);
      setChecked((prevChecked) => [...prevChecked, ...childIds]);
    }
  };

  const uncheckChildren = (nodeId: string) => {
    const node = findNodeById(prop.treeData, nodeId);
    if (node) {
      const childIds = getAllChildIds(node);
      setChecked((prevChecked) =>
        prevChecked.filter((id) => !childIds.includes(id))
      );
    }
  };

  const findNodeById = (
    nodes: WmsTreeNodeDto[],
    nodeId: string
  ): WmsTreeNodeDto | undefined => {
    for (const node of nodes) {
      if (node.entity.wmsId === nodeId) {
        return node;
      }
      if (node.children) {
        const foundNode = findNodeById(node.children, nodeId);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return undefined;
  };

  const getAllChildIds = (node: WmsTreeNodeDto): string[] => {
    let childIds: string[] = [];
    if (node.children) {
      for (const childNode of node.children) {
        childIds.push(childNode.entity.wmsId);
        childIds = [...childIds, ...getAllChildIds(childNode)];
      }
    }
    return childIds;
  };

  const renderTree = (nodes: WmsTreeNodeDto[]) => (
    <>
      {nodes.map((node) => (
        <TreeItem
          key={node.entity.wmsId}
          nodeId={node.entity.wmsId}
          label={
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {prop.allowSelectBin ? (
                  <Checkbox
                    checked={checked.includes(node.entity.wmsId)}
                    onChange={(event) =>
                      handleCheckboxChange(event, node.entity.wmsId)
                    }
                  />
                ) : node.entity.type == wmsTreeType.bin ? (
                  <div></div>
                ) : (
                  <Checkbox
                    checked={checked.includes(node.entity.wmsId)}
                    onChange={(event) =>
                      handleCheckboxChange(event, node.entity.wmsId)
                    }
                  />
                )}

                <span style={{ marginLeft: "8px", width: "100%" }}>
                  {/* <Badge
                    badgeContent={node.wmsTreeType}
                    color="primary"
                    style={{ marginRight: "20px" }}
                  >
                    <div>{node.display}</div>
                  </Badge> */}
                  {node.entity.type == wmsTreeType.bin ? (
                    <Badge color="secondary" showZero style={{ width: "100%" }}>
                      <ViewInArIcon />
                      <div
                        style={{
                          paddingLeft: "10px",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        Lagerplatz: {node.entity.bin}
                      </div>
                    </Badge>
                  ) : node.entity.type == wmsTreeType.hu ? (
                    <Badge color="secondary" showZero style={{ width: "100%" }}>
                      <InventoryIcon />
                      <div
                        style={{
                          paddingLeft: "10px",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        HU: {node.entity.handlingUnit}
                      </div>
                    </Badge>
                  ) : node.entity.type == wmsTreeType.stock ? (
                    <div>
                      <Badge
                        color="secondary"
                        showZero
                        style={{ width: "100%", padding: "3px" }}
                      >
                        <LocalOfferIcon />
                        <div
                          style={{
                            paddingLeft: "10px",
                            padding: "3px",
                            width: "100%",
                          }}
                        >
                          <Grid
                            container
                            spacing={3}
                            justifyContent="flex-start"
                          >
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ textAlign: "left" }}
                            >
                              <div style={{ paddingLeft: "5px" }}>
                                Material: {node.entity!.materials!.material}{" "}
                                <br />
                                <b>{node.entity!.materials!.name} </b>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              style={{ textAlign: "left" }}
                            >
                              {node.entity!.quantityAltUnit}{" "}
                              {node.entity!.altUnit}
                            </Grid>
                          </Grid>
                        </div>
                      </Badge>
                    </div>
                  ) : (
                    <div>Unable to identify node type</div>
                  )}
                </span>
              </div>
            </div>
          }
        >
          {node.children &&
            node.children.length > 0 &&
            renderTree(node.children)}
        </TreeItem>
      ))}
    </>
  );

  return (
    <div>
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
      >
        {renderTree(prop.treeData)}
      </TreeView>
    </div>
  );
};

export default WmsTreeViewWithCheckbox;
