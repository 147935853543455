import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PropaneSharp,
} from "@mui/icons-material";
import {
  InboundDeliveryDetailsV11Dto,
  _InboundDeliveryItemDetailsV11Dto,
} from "interfaces/v11/inboundDelivery/inboundDeliveryDetails/inboundDeliveryDetailsV11Dto";
import { generateRandomGuid, guid } from "types/guid";
import { HuV11Dto } from "interfaces/v11/hu/huV11Dto";
import { useHandlingUnitService } from "services/HandingUnitService";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { binSearchRequestV11Dto } from "interfaces/v11/bin/binSearch/binSearchRequestV11Dto";
import { useParams } from "react-router-dom";
import { binSearchResponseV11Dto } from "interfaces/v11/bin/binSearch/binSearchResponseV11Dto";
import { huCreateRequestV11Dto } from "interfaces/v11/hu/huCreateRequestV11Dto";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import InboundDeliveryItemListing from "./InboundDeliveryItemListing";
import {
  InboundDeliveryItemQuantityRoleCategoryEnum,
  InboundDeliveryItemQuantityRoleEnum,
} from "interfaces/enums/InboundDeliveryEnums";
import { ItemAssignment } from "interfaces/v11/goodReceiptDto/ItemAssignment";
import navItems from "components/sidebar/dashboardItems";

interface GoodReceiptItemAssignedProp {
  selectedHU: HuV11Dto | null;
  items: _InboundDeliveryItemDetailsV11Dto[];
  assignedItems: ItemAssignment[];
  onEditAssignedItems: (
    assignedItems: ItemAssignment[],
    action: string
  ) => void;
}

const GoodReceiptItemAssigned: React.FC<GoodReceiptItemAssignedProp> = (
  prop
) => {
  const reservedPlaceHolder: string = "{%!Hu@}";
  const display_confirm: string = "Bestätigen";
  const display_cancel: string = "Abbrechen";
  const display_save: string = "Speichern";

  const display_MaterialRecordedFor: string = "Material hinzufügen zu ";
  const display_noOptionMsg: string = "keine Optionen vorhanden";
  const display_NoSelectedHU: string = "Bitte Ladungsträger auswählen";
  const display_NoSelectedMaterial: string = "Bitte Material auswählen";
  const display_PleaseEnterQuantity: string =
    "Bitte Menge eingeben, um Buchung abzuschliessen.";
  const display_QtyToPostMoreThanOpenQty: string =
    "Die eingegebene Menge überschreitet die verbleibende offene Menge. Menge bitte anpassen.";
  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";
  const display_ItemAdded: string = "Position wurde hinzugefügt.";
  const display_Updated: string = "aktualisiert";
  const display_Deleted: string = "gelöscht";
  const display_somethingWentWrongSearchMaterial: string =
    "Something went wrong. Could not find Material. ";
  const display_MaterialRecorded: string = "Position auswählen";
  //gelöscht
  //
  //

  const display_SelectedHU: string = "Ausgewählter LT: ";
  const display_NoItemRecorded: string = "Keine Position erfasst.";
  const display_QuantityRequested: string = "Angefragte Menge";
  const display_QuantityOpen: string = "Offene Menge";
  const display_QuantityToPost: string = "Zu buchende Menge";
  const display_RemainingOpenQty: string = "Verbleibende offene Menge";

  const display_Owner: string = "Baulos";
  const display_Type: string = "Bestandsart";
  //Zu buchende Menge

  //const [hu, setHU] = useState<HuV11Dto>(defaultHU);
  const [selectedHU, setSelectedHU] = useState(prop.selectedHU);
  const [availableItem, setAvailableItem] = useState(prop.items);
  //const [itemAssigned, setItemAssigned] = useState(prop.assignedItems);

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  const handleEditItemClick = (selectedItem: ItemAssignment) => {
    //displayNotification("To Change Quantity for " + item.orderId);
    setSelectedMaterial(null);
    setSelectedAssignedItem(selectedItem);
    setSelectedMaterialQuantity(selectedItem.quantity);

    const targetAvailableItems = availableItem.filter(
      (x) => x.inboundDeliveryItemId == selectedItem.itemId
    );
    if (targetAvailableItems != undefined && targetAvailableItems.length == 1) {
      const targetAvailableItem = targetAvailableItems[0];
      let qtys = targetAvailableItem.inboundDeliveryItemQuantities;
      if (qtys) {
        const filteredOpenQty = qtys.filter(
          (item) =>
            item.quantityRoleCategory ===
              InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
            item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
        );
        if (filteredOpenQty && filteredOpenQty.length == 1) {
          const qty = filteredOpenQty[0];
          setOpenQty(qty.quantity);
          setUOM(qty.unitOfMeasurement);
        }

        const remainingOpenQtyTemp: number =
          targetAvailableItem.remainingOpenQty != null
            ? targetAvailableItem.remainingOpenQty
            : 0;
        setRemainingQuantity(remainingOpenQtyTemp);
        setSelectedMaterialQuantity(remainingOpenQtyTemp);

        const filteredRequestedQty = qtys.filter(
          (item) =>
            item.quantityRoleCategory ===
              InboundDeliveryItemQuantityRoleCategoryEnum.REQUESTED &&
            item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
        );
        if (filteredRequestedQty && filteredRequestedQty.length == 1) {
          const qty = filteredRequestedQty[0];
          setRequestedQty(qty.quantity);
        }
      }
    }

    setShowEditItemDialog(true);
  };
  const handleAddItemClick = () => {
    setSelectedAssignedItem(null);
    setSelectedMaterial(null);
    setSelectedMaterialQuantity(0);
    setShowAddItemDialog(true);
  };
  const handleDeleteItemClick = (item: ItemAssignment) => {
    handleDelete(item);
  };
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [selectedAssignedItem, setSelectedAssignedItem] =
    useState<ItemAssignment | null>(null);

  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const [showEditItemDialog, setShowEditItemDialog] = useState(false);
  const handleConfirmShowAddItemDialogClick = async () => {
    alert("1");
  };
  const handleAddItemDialogClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason && reason == "backdropClick") return;
    setShowAddItemDialog(false);
  };
  const handleEditItemDialogClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason && reason == "backdropClick") return;
    setShowEditItemDialog(false);
  };

  const handleAddItem = () => {
    if (selectedHU == null) {
      displayNotification(display_NoSelectedHU);
      return;
    }
    if (selectedMaterial == null) {
      displayNotification(display_NoSelectedMaterial);
      return;
    }
    if (selectedMaterialQuantity <= 0) {
      displayNotification(display_PleaseEnterQuantity);
      return;
    }

    if (selectedMaterialQuantity > remainingQuantity) {
      displayNotification(display_QtyToPostMoreThanOpenQty);
      return;
    }

    const targetItem = availableItem.filter(
      (x) => x.inboundDeliveryItemId == selectedMaterial.inboundDeliveryItemId
    );
    if (targetItem != null) {
      targetItem[0].remainingOpenQty =
        targetItem[0].remainingOpenQty != null
          ? targetItem[0].remainingOpenQty
          : 0;
      targetItem[0].remainingOpenQty -= selectedMaterialQuantity;
    }

    const newItem: ItemAssignment = {
      orderId: generateRandomGuid(),
      itemId: selectedMaterial.inboundDeliveryItemId,
      binId: selectedHU.parentElement,
      huId: selectedHU.wmsId,
      huDisplayName:
        selectedHU.handlingUnit + " (" + selectedHU.handlingUnitType + ")",
      item: selectedMaterial,
      quantity: selectedMaterialQuantity,
      uom: uom,
      stockTypeId: "",
      stockTypeDisplay: "AFS",
      zgs: selectedMaterial.batch,
      requestedQuantity: requestedQuantity,
      openQuantity: openQuantity,
    };
    let temp = JSON.parse(JSON.stringify(prop.assignedItems));
    temp.push(newItem);
    setShowAddItemDialog(false);
    prop.onEditAssignedItems(temp, display_ItemAdded);
    //setItemAssigned(temp);
  };

  const handleEditItem = () => {
    if (selectedHU == null) {
      displayNotification(display_NoSelectedHU);
      return;
    }
    if (selectedAssignedItem == null) {
      displayNotification(display_SomethingWentWrong);
      return;
    }
    if (selectedAssignedItem.quantity <= 0) {
      displayNotification(display_PleaseEnterQuantity);
      return;
    }
    // Find the index of the item to be updated
    let temp: ItemAssignment[] = JSON.parse(JSON.stringify(prop.assignedItems));
    const index = temp.findIndex(
      (x) => x.orderId === selectedAssignedItem?.orderId
    );

    if (index !== -1) {
      if (temp != undefined && selectedAssignedItem != undefined) {
        temp[index] = selectedAssignedItem;

        //total qty consumed for selected delivery item
        let totalConsumed: number = 0;
        temp.map((t) => {
          totalConsumed += t.quantity;
        });
        //alert(totalConsumed);

        const targetAvailableItems = availableItem.filter(
          (x) => x.inboundDeliveryItemId == selectedAssignedItem.itemId
        );
        if (targetAvailableItems != null) {
          const targetAvailableItem = targetAvailableItems[0];
          if (targetAvailableItem.inboundDeliveryItemQuantities) {
            const filteredOpenQty =
              targetAvailableItem.inboundDeliveryItemQuantities.filter(
                (item) =>
                  item.quantityRoleCategory ===
                    InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
                  item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
              );

            let openQty: number = 0;
            if (filteredOpenQty && filteredOpenQty.length == 1) {
              openQty = filteredOpenQty[0].quantity;
              targetAvailableItem.remainingOpenQty = openQty - totalConsumed;
            }
          }
        }

        setSelectedAssignedItem(null);
        setShowEditItemDialog(false);
        prop.onEditAssignedItems(temp, display_Updated);
        //setItemAssigned(temp);
      }
    } else {
      displayNotification(display_SomethingWentWrong);
    }
  };

  //handleDelete
  const handleDelete = (deletedItem: ItemAssignment) => {
    let temp: ItemAssignment[] = JSON.parse(JSON.stringify(prop.assignedItems));

    const newItemAssigned: ItemAssignment[] = temp.filter(
      (item) => item.orderId !== deletedItem.orderId
    );

    const targetItem = availableItem.filter(
      (x) => x.inboundDeliveryItemId == deletedItem.itemId
    );
    if (targetItem != null) {
      targetItem[0].remainingOpenQty =
        targetItem[0].remainingOpenQty != null
          ? targetItem[0].remainingOpenQty
          : 0;
      targetItem[0].remainingOpenQty += deletedItem.quantity;
    }

    prop.onEditAssignedItems(newItemAssigned, display_Deleted);
    //setItemAssigned(newItemAssigned);
  };

  const [selectedMaterial, setSelectedMaterial] =
    useState<_InboundDeliveryItemDetailsV11Dto | null>(null);
  const [openQuantity, setOpenQty] = useState(0);
  const [remainingQuantity, setRemainingQuantity] = useState(0);
  const [requestedQuantity, setRequestedQty] = useState(0);
  const [selectedMaterialQuantity, setSelectedMaterialQuantity] = useState(0);
  const [uom, setUOM] = useState("");
  const handleNumberTextFieldKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // Check if the key pressed is not a number
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  };

  const handleSelectedMaterialQuantityInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValueAsString = event.target.value;
    if (inputValueAsString != undefined && inputValueAsString.length <= 10) {
      const inputValue = Number(event.target.value);
      if (!isNaN(inputValue)) {
        setSelectedMaterialQuantity(inputValue);
      }
      //setSelectedMaterialQuantity(event.target.value);
    }
  };

  const handleSelectedAssignedItemMaterialQuantityInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValueAsString = event.target.value;
    if (inputValueAsString != undefined && inputValueAsString.length <= 10) {
      const inputValue = Number(event.target.value);
      if (!isNaN(inputValue)) {
        if (selectedAssignedItem != undefined) {
          setSelectedAssignedItem({
            ...selectedAssignedItem,
            quantity: inputValue,
          });
        }
      }
      //setSelectedMaterialQuantity(event.target.value);
    }
  };

  function onChangeSelectedItem(
    selectedItem: _InboundDeliveryItemDetailsV11Dto
  ) {
    const index = availableItem.findIndex(
      (item) =>
        item.inboundDeliveryItemId === selectedItem.inboundDeliveryItemId
    );
    if (index !== -1) {
      setSelectedMaterial(selectedItem);

      let qtys = selectedItem.inboundDeliveryItemQuantities;
      if (qtys) {
        const filteredOpenQty = qtys.filter(
          (item) =>
            item.quantityRoleCategory ===
              InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
            item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
        );
        if (filteredOpenQty && filteredOpenQty.length == 1) {
          const qty = filteredOpenQty[0];
          setOpenQty(qty.quantity);
          setUOM(qty.unitOfMeasurement);
        }

        const remainingOpenQtyTemp: number =
          selectedItem.remainingOpenQty != null
            ? selectedItem.remainingOpenQty
            : 0;
        setRemainingQuantity(remainingOpenQtyTemp);
        setSelectedMaterialQuantity(remainingOpenQtyTemp);

        const filteredRequestedQty = qtys.filter(
          (item) =>
            item.quantityRoleCategory ===
              InboundDeliveryItemQuantityRoleCategoryEnum.REQUESTED &&
            item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
        );
        if (filteredRequestedQty && filteredRequestedQty.length == 1) {
          const qty = filteredRequestedQty[0];
          setRequestedQty(qty.quantity);
        }
      }
    } else {
      displayNotification(display_somethingWentWrongSearchMaterial);
    }
  }

  function Line2Display(input: ItemAssignment): string {
    let qtys = input.item.inboundDeliveryItemQuantities;
    if (qtys) {
      const filteredQty = qtys.filter(
        (item) =>
          item.quantityRoleCategory ===
            InboundDeliveryItemQuantityRoleCategoryEnum.OPEN &&
          item.quantityRole === InboundDeliveryItemQuantityRoleEnum.GR
      );

      let uom: string = "";
      if (filteredQty && filteredQty.length == 1) {
        const qty = filteredQty[0];
        uom = qty.unitOfMeasurement;
      }

      //item.item.material?.material + " - " + item.item.material?.name;

      return (
        input.item.material?.material +
        " - " +
        input.item.material?.name +
        " | " +
        input.quantity.toString() +
        " " +
        uom +
        " | ZGS: " +
        input.zgs +
        " | " +
        display_Type +
        ": " +
        input.stockTypeDisplay +
        " | " +
        display_Owner +
        ": " +
        (input.item.owner != undefined ? input.item.owner : "none")
      );
    }
    return "No Quantity";
  }

  // if (selectedHU == null) {
  //   return <div>{display_NoSelectedHU}</div>;
  // }

  return (
    <Box>
      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      {selectedHU == null ? (
        <div>{display_NoSelectedHU}</div>
      ) : (
        <div>
          <Dialog
            onClose={handleAddItemDialogClose}
            aria-labelledby="simple-dialog-title"
            open={showAddItemDialog}
            hideBackdrop={false}
            PaperProps={{
              style: {
                minWidth: "50%",
              },
            }}
          >
            <DialogTitle>
              {display_MaterialRecordedFor +
                selectedHU.handlingUnit +
                " (" +
                selectedHU.handlingUnitType +
                ")"}
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText>{contentText}</DialogContentText> */}
              <Box sx={{ mt: 8 }}>
                <Grid container spacing={3}>
                  {selectedMaterial == null ? (
                    <InboundDeliveryItemListing
                      items={availableItem}
                      onChangeSelectedItem={onChangeSelectedItem}
                      showRemainingQty={true}
                    />
                  ) : (
                    <Grid hidden={selectedMaterial == null} xs={12} sm={10}>
                      <Grid>
                        <TextField
                          label={display_QuantityRequested}
                          variant="outlined"
                          value={requestedQuantity}
                          fullWidth={true}
                          disabled={true}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label={display_QuantityOpen}
                          variant="outlined"
                          value={openQuantity}
                          fullWidth={true}
                          disabled={true}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label={display_RemainingOpenQty}
                          variant="outlined"
                          value={remainingQuantity}
                          fullWidth={true}
                          disabled={true}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          name="selectedMaterialQuantity"
                          label={display_QuantityToPost}
                          variant="outlined"
                          value={selectedMaterialQuantity}
                          fullWidth={true}
                          autoComplete="off" //onKeyPress={handleNumberTextFieldKeyPress}
                          onChange={handleSelectedMaterialQuantityInputChange}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowAddItemDialog(false);
                }}
              >
                {display_cancel}
              </Button>
              <Button
                onClick={handleAddItem}
                disabled={selectedMaterial == null}
              >
                {display_confirm}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            onClose={handleEditItemDialogClose}
            aria-labelledby="simple-dialog-title"
            open={showEditItemDialog}
            hideBackdrop={false}
            PaperProps={{
              style: {
                minWidth: "50%",
              },
            }}
          >
            <DialogTitle>
              {display_MaterialRecordedFor +
                selectedHU.handlingUnit +
                "(" +
                selectedHU.handlingUnitType +
                ")"}
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText>{contentText}</DialogContentText> */}
              <Box sx={{ mt: 8 }}>
                <Grid container spacing={3}>
                  {selectedAssignedItem == null ? (
                    <div>{display_SomethingWentWrong}</div>
                  ) : (
                    <Grid hidden={selectedAssignedItem == null} xs={12} sm={10}>
                      <Grid>
                        <TextField
                          label={display_QuantityRequested}
                          variant="outlined"
                          value={selectedAssignedItem.requestedQuantity}
                          fullWidth={true}
                          disabled={true}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label={display_QuantityOpen}
                          variant="outlined"
                          value={selectedAssignedItem.openQuantity}
                          fullWidth={true}
                          disabled={true}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label={display_QuantityOpen}
                          variant="outlined"
                          value={remainingQuantity}
                          fullWidth={true}
                          disabled={true}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          name="selectedMaterialQuantity"
                          label={display_QuantityToPost}
                          variant="outlined"
                          value={selectedAssignedItem.quantity}
                          fullWidth={true}
                          autoComplete="off" //onKeyPress={handleNumberTextFieldKeyPress}
                          onChange={
                            handleSelectedAssignedItemMaterialQuantityInputChange
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowEditItemDialog(false);
                }}
              >
                {display_cancel}
              </Button>
              <Button
                onClick={handleEditItem}
                disabled={selectedAssignedItem == null}
              >
                {display_save}
              </Button>
            </DialogActions>
          </Dialog>

          <Grid container xs={12}>
            <Grid sm={6}>
              <Typography variant="subtitle2" gutterBottom>
                {display_SelectedHU} {selectedHU.handlingUnit}
              </Typography>
            </Grid>
            <Grid sm={6}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddItemClick}
              >
                {display_MaterialRecorded}
              </Button>
            </Grid>
          </Grid>
          {selectedHU.wmsId.length <= 0 ? (
            <div>{display_NoSelectedHU}</div>
          ) : (
            <div></div>
          )}
        </div>
      )}

      {prop.assignedItems.length <= 0 ? (
        <div>{display_NoItemRecorded}</div>
      ) : (
        <div></div>
      )}

      <List>
        {prop.assignedItems.map((item) =>
          true ? (
            // <ListItem key={item.wmsId} button onClick={() => handleEditItemClick(item)}
            <ListItem
              key={item.orderId}
              button
              onClick={() => handleEditItemClick(item)}
              selected={selectedHU != null && selectedHU.wmsId === item.huId}
              // style={{
              //   backgroundColor:
              //     selectedHU != null && selectedHU.wmsId === item.huId
              //       ? "#376fd0"
              //       : "",
              //   color:
              //     selectedHU != null && selectedHU.wmsId === item.huId
              //       ? "white"
              //       : "",
              // }}
            >
              <ListItemText
                //primary={item.item.material?.name}
                primary={item.huDisplayName}
                secondary={Line2Display(item)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => handleDeleteItemClick(item)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ) : (
            <div></div>
          )
        )}
      </List>
    </Box>
  );
};

export default React.memo(GoodReceiptItemAssigned);
