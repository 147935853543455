import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";
import { KeyValuePair } from "components/platbricks/shared";
import { AlertBox, AlertBoxType } from "components/platbricks/shared/AlertBox";
import { NotificationDialog } from "components/platbricks/shared/NotificationDialog";
import Scanner from "components/platbricks/shared/Scanner";
import { getOrderByQueueNameRequestDto } from "interfaces/v1/order/getOrderByQueueNameRequestDto";
import {
  OrderTask,
  getOrderByQueueNameResponseDto,
} from "interfaces/v1/order/getOrderByQueueNameResponseDto";
import { huCreateRequestV11Dto } from "interfaces/v11/hu/huCreateRequestV11Dto";
import { orderTaskChangeDestinationRequestV11Dto } from "interfaces/v11/order/orderTaskChangeDestinationRequestV11Dto";
import { searchStockV11ResquestDto } from "interfaces/v11/stock/searchStockV11RequestDto";
import { searchStockV11ResponseDto } from "interfaces/v11/stock/searchStockV11ResponseDto";
import { WMSMaterialDetailsV12Dto } from "interfaces/v12/materialDetail/wmsMaterialDetailsV12Dto";
import { huSearchRequestV2Dto } from "interfaces/v2/hu/huSearchRequestV2Dto";
import { confirmTaskRequestV2Dto } from "interfaces/v2/order/confirmTaskRequestV2Dto";
import { outboundDeliveryItemV2Dto } from "interfaces/v2/outboundDelivery/outboundDeliveryV2Dto";
import { QueueDto } from "interfaces/v2/queue/QueueDto";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useHandlingUnitService } from "services/HandingUnitService";
import { useOrderService } from "services/OrderService";
import { useOutboundDeliveryService } from "services/OutboundDeliveryService";
import { usePrintService } from "services/PrintService";
import { useQueueService } from "services/QueueService";
import { useSettingService } from "services/SettingService";
import WmsMaterialService from "services/WmsMaterialService";
import { useWmsService } from "services/WmsService";
import { HuV2Dto } from "interfaces/v2/hu/huV2Dto";
import { huSearchRequestV11Dto } from "interfaces/v11/hu/huSearchRequestV11Dto";
import { huTypeSearchResponseV11Dto } from "interfaces/v11/huType/huTypeSearchResponsetV11Dto";
import { huSearchResponseV11Dto } from "interfaces/v11/hu/huSearchResponseV11Dto";
import { orderTaskChangeSourceRequestV11Dto } from "interfaces/v11/order/orderTaskChangeSourceRequestV11Dto";
import { huTypeSearchRequestV11Dto } from "interfaces/v11/huType/huTypeSearchRequestV11Dto";

interface Option {
  label: string;
  value: string;
}
interface Search {
  search: string;
}

const PickingComponent: React.FC = () => {
  const { locationId } = useParams();

  const maxLength: number = 30;
  const padding: number = 3;

  const queueService = useQueueService();
  const orderService = useOrderService();
  const huService = useHandlingUnitService();
  const wmsService = useWmsService();
  const outboundDeliveryService = useOutboundDeliveryService();
  const printService = usePrintService();
  const settingService = useSettingService();

  const [printServer, setPrintServer] = useState("");
  const [printer, setPrinter] = useState("");
  const [printServerId, setPrintServerId] = useState("");
  const [printerId, setPrinterId] = useState("");

  var showPrinterNotConfiguredWarning: boolean = true;
  const display_printerNotConfigured: string =
    "Die Druckereinstellungen sind nicht konfiguriert. Es wird kein HU-Label gedruckt. ";

  const [debounce, setDebounce] = useState<boolean>(false);
  useEffect(() => {
    //console.log("debounce " + new Date(), debounce);
  }, [debounce]);

  const [defaultHuType, setDefaultHuType] = useState<string>("");

  useEffect(() => {
    settingService
      .getLocationSetting(locationId as string)
      .then((result) => {
        if (result) {
          if (
            result.pickingSetting?.printServer != undefined &&
            result.pickingSetting?.printServer.length > 0 &&
            result.pickingSetting?.printer != undefined &&
            result.pickingSetting?.printer.length > 0 &&
            result.pickingSetting?.printServerId != undefined &&
            result.pickingSetting?.printServerId.length > 0 &&
            result.pickingSetting?.printerId != undefined &&
            result.pickingSetting?.printerId.length > 0
          ) {
            const server: string = result.pickingSetting?.printServer;
            const printer: string = result.pickingSetting?.printer;
            const serverId: string = result.pickingSetting?.printServerId;
            const printerId: string = result.pickingSetting?.printerId;

            setPrintServer(server);
            setPrintServerId(serverId);
            setPrinter(printer);
            setPrinterId(printerId);
          }
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      })
      .catch(() => {
        displayNotification(display_SomethingWentWrong);
      });
  }, [settingService]);

  //notificaiton dialog
  const [notificationText, setNotificationText] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  function displayNotification(message: string) {
    setNotificationText(message);
    setShowNotification(true);
  }

  //queue
  const display_queue: string = "Queue";
  const display_NoQueue: string = "No available queue";
  const [queueOptions, setQueueOptions] = useState<Option[]>([]);
  const [selectedQueueValue, setSelectedQueueValue] = useState("");
  useEffect(() => {
    // Call the API and update the options state
    const fetchQueueData = async () => {
      try {
        queueService
          .getQueue()
          .then((result) => {
            let availableOption: Option[] = [];
            let defaultOption: Option = {
              label: "",
              value: "",
            };
            availableOption.push(defaultOption);

            result.map((item) => {
              let temp: Option = {
                value: item.name,
                label: item.name,
              };
              availableOption.push(temp);
            });

            setQueueOptions(availableOption);
          })
          .catch((y) => {
            console.log(y);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchQueueData();
  }, []);

  const handleSelectQueueChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedQueueValue(event.target.value as string);
  };

  const display_NoOrderToQueuePlaceHolder: string = "{%Queue}";
  const display_NoOrder: string =
    display_NoOrderToQueuePlaceHolder + " ist kein Auftrag zugewiesen.";
  const display_NoOrderTask: string =
    "No Order Task assign to " + display_NoOrderToQueuePlaceHolder;
  const [order, setOrder] = useState<getOrderByQueueNameResponseDto | null>(
    null
  );
  const [outboundDeliveryItemInfo, setOutboundDeliveryItemInfo] =
    useState<outboundDeliveryItemV2Dto | null>(null);
  const [supplierBatch, setSupplierBatch] = useState<string>("");
  const [orderTask, setOrderTask] = useState<OrderTask | null>(null);
  useEffect(() => {
    reloadData();
  }, [selectedQueueValue]);

  function reloadData() {
    resetHuType();
    if (selectedQueueValue.length > 0) {
      const getOrderByQueueNameRequest: getOrderByQueueNameRequestDto = {
        configKey: "",
        amount: 1,
        queueName: selectedQueueValue,
      };
      orderService
        .getOrderByQueueName(getOrderByQueueNameRequest)
        .then((result) => {
          console.log(result);
          if (result.length > 0) {
            let OrderWithOpenTask: getOrderByQueueNameResponseDto[] =
              result.filter(
                (item) =>
                  item.orderTasks != undefined &&
                  item.orderTasks.filter(
                    (subitem) =>
                      subitem.orderTaskStatus == "OPEN" ||
                      subitem.orderTaskStatus == "INPROCESS"
                  ).length > 0
              );

            if (OrderWithOpenTask == null || OrderWithOpenTask.length <= 0) {
              displayNotification(getNoOrderTaskAssignedToQueueMessage());
              setOrder(null);
              setOrderTask(null);
              setOutboundDeliveryItemInfo(null);
              findHandlingUnitId("");
            } else {
              setOrder(OrderWithOpenTask[0]);
              let tasks: OrderTask[] = OrderWithOpenTask[0].orderTasks!.filter(
                (item) => item.orderTaskStatus == "OPEN"
              );
              if (tasks != null && tasks.length > 0) {
                orderService
                  .getOrderTaskById(tasks[0].orderTaskId)
                  .then(async (result) => {
                    const targetTask = tasks[0];
                    targetTask.orderTaskDto = result;

                    //console.log("orderTaskDto", targetTask.orderTaskDto);
                    //let searchSrcHuDto: huSearchRequestV11Dto = ;
                    var srcHU = await huService.getHandlingUnit(
                      targetTask.orderTaskDto.srcHuId!
                    );
                    if (srcHU && srcHU.length > 0) {
                      setDefaultHuType(srcHU[0].handlingUnitType);
                    }
                    //console.log("defaultHuType", defaultHuType);

                    if (
                      targetTask.orderTaskDto.wmsMaterialId != null &&
                      targetTask.orderTaskDto.wmsMaterialId.length > 0
                    ) {
                      targetTask.isHuTask = false;
                      targetTask.orderTaskDto.wmsMaterialDto =
                        await WmsMaterialService.getMaterialById(
                          targetTask.orderTaskDto.wmsMaterialId
                        );
                    } else {
                      //is hu movement, find stock under material. get material detail of first stock found.
                      if (
                        targetTask.orderTaskDto.srcHuId != undefined &&
                        targetTask.orderTaskDto.srcHuId.length > 0
                      ) {
                        targetTask.isHuTask = true;
                        const searchStockRequest: searchStockV11ResquestDto = {
                          locationId: locationId!,
                          parentElementId: targetTask.orderTaskDto.srcHuId,
                        };

                        wmsService
                          .findStock(searchStockRequest)
                          .then(async (result) => {
                            if (result != undefined && result.length > 0) {
                              //by right we should only expect 1 stock
                              //if result somehow return more than 1 stock, we always grab the first one
                              var targetStock = result[0];
                              if (
                                targetStock.materialId != null &&
                                targetStock.materialId.length > 0
                              ) {
                                targetTask.orderTaskDto!.wmsMaterialDto =
                                  await WmsMaterialService.getMaterialById(
                                    targetStock.materialId
                                  );

                                targetTask.targetQtyAuom =
                                  targetStock.avlQtyAuom;
                              }
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            displayNotification(display_SomethingWentWrong);
                          });
                      }
                    }

                    targetTask.isOutboundDeliveryTask = false;

                    if (
                      targetTask.orderTaskDto != null &&
                      targetTask.orderTaskDto.outboundDeliveryId != null &&
                      targetTask.orderTaskDto.outboundDeliveryId.length > 0 &&
                      targetTask.orderTaskDto.outboundDeliveryId !=
                      "00000000-0000-0000-0000-000000000000"
                    ) {
                      outboundDeliveryService
                        .getOutboundDeliveryById(
                          targetTask.orderTaskDto.outboundDeliveryId
                        )
                        .then((result) => {
                          if (result) {
                            targetTask.isOutboundDeliveryTask = true;
                            if (
                              result.outboundDeliveryItems != undefined &&
                              result.outboundDeliveryItems.length > 0
                            ) {
                              if (
                                targetTask.orderTaskDto!
                                  .outboundDeliveryItemId != null &&
                                targetTask.orderTaskDto!.outboundDeliveryItemId
                                  .length > 0 &&
                                targetTask.orderTaskDto!
                                  .outboundDeliveryItemId !=
                                "00000000-0000-0000-0000-000000000000"
                              ) {
                                targetTask.isOutboundDeliveryTask = true;
                                let targetObdItemFilter =
                                  result.outboundDeliveryItems?.filter(
                                    (x) =>
                                      x.outboundDeliveryItemId ==
                                      targetTask!.orderTaskDto!
                                        .outboundDeliveryItemId
                                  );
                                if (
                                  targetObdItemFilter != null &&
                                  targetObdItemFilter.length == 1
                                ) {
                                  var obdItem = targetObdItemFilter[0];
                                  setOutboundDeliveryItemInfo(obdItem);
                                  findHandlingUnitId(
                                    targetTask.orderTaskDto!.srcHuId
                                      ? targetTask.orderTaskDto!.srcHuId
                                      : ""
                                  );
                                }
                                setOrderTask(targetTask);
                              } else {
                                //obd item is null, assume only as first obd item
                                const obdItem = result.outboundDeliveryItems[0];
                                setOutboundDeliveryItemInfo(obdItem);
                                findHandlingUnitId(
                                  targetTask.orderTaskDto!.srcHuId
                                    ? targetTask.orderTaskDto!.srcHuId
                                    : ""
                                );
                                setOrderTask(targetTask);
                              }
                            }
                          } else {
                            console.log(result);
                            displayNotification(
                              "Something went wrong when finding associated outbound delivery. "
                            );
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          displayNotification(
                            "Something went wrong when finding associated outbound delivery. "
                          );
                        });
                    } else {
                      displayNotification(display_NotOutboundDeliveryTask);
                    }
                    setOrderTask(targetTask);
                    setTaskCompleted(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    displayNotification(
                      display_SomethingWentWrongWhenGetMoreDetailOrderTask
                    );
                  });
              }
            }
          } else {
            displayNotification(getNoOrderAssignedToQueueMessage());
            setOrder(null);
            setOrderTask(null);
            setOutboundDeliveryItemInfo(null);
            findHandlingUnitId("");
          }
        })
        .catch((err) => {
          console.log(err);
          displayNotification(display_SomethingWentWrong);
        });
    }
  }

  useEffect(() => {
    if (outboundDeliveryItemInfo) {
      findSupplierBatch(outboundDeliveryItemInfo);
    } else {
      setSupplierBatch("");
    }
  }, [outboundDeliveryItemInfo]);

  function findSupplierBatch(obdItem: outboundDeliveryItemV2Dto): void {
    const supplierBatchValue =
      obdItem.suppliersBatch != undefined ? obdItem.suppliersBatch : "";
    setSupplierBatch(supplierBatchValue);
  }

  const [destinationHUId, setDestinationHUId] = useState<string>("");
  function findHandlingUnitId(huId: string): void {
    setDestinationHUId(huId);
  }

  function getNoOrderAssignedToQueueMessage(): string {
    let result: string = display_NoOrder;
    result = result.replace(
      display_NoOrderToQueuePlaceHolder,
      selectedQueueValue
    );
    return result;
  }

  function getNoOrderTaskAssignedToQueueMessage(): string {
    let result: string = display_NoOrderTask;
    result = result.replace(
      display_NoOrderToQueuePlaceHolder,
      selectedQueueValue
    );
    return result;
  }

  const display_OrderNumber: string = "Auftragsnummer";
  const display_Priority: string = "Priorität";
  const display_MaterialNumber: string = "Sachnummer";
  const display_MaterialUnit: string = "Mengeneinheit";
  const display_OrderTaskQty: string = "Menge";
  const display_SourceHu: string = "HU";
  const display_SourceBin: string = "Von Platz";
  const display_DestinationBin: string = "Nach Platz";

  const display_Batch: string = "ZGS";
  const display_Owner: string = "Baulos";
  const display_SupplierBatch: string = "Anzahl Soll- Ladungsträger";

  useEffect(() => {
    setScannedWmsSource("");
    setScannedWmsDestination("");
  }, [order]);

  //scan source
  const [scanningSource, setScanningSource] = useState<boolean>(true);
  const display_ScanSourceInvalid: string =
    "Scanned Item does not match Source HU";
  const display_ScanSource: string = "Scannen (HU)";
  const display_ScanningSource: string = "Scan abbrechen (HU)";
  const [scannedWmsSource, setScannedWmsSource] = useState<string>("");
  function resetScanWmsSource() {
    setScannedWmsSource("");
  }
  function scanWmsSourceStarted() {
    resetScanWmsSource();
    setScanningSource(true);
  }
  function scanWmsSourceCompleted(scannedValue: string) {
    setScanningSource(false);
    if (orderTask != null) {
      let sourceHU: string = orderTask.srcHu;
      if (scannedValue != sourceHU) {
        displayNotification(display_ScanSourceInvalid);
      } else {
        setScannedWmsSource(scannedValue);
      }
    }
  }
  function scanWmsSourceCancelled() {
    setScanningSource(false);
  }

  //scan destination
  const [scanningDestination, setScanningDestination] =
    useState<boolean>(false);

  const display_ScanDestinationInvalid: string =
    "Scanned Item does not match Destination Bin";
  const display_ScanDestination: string = "Scannen (Nach Platz)";
  const display_ScanningDestination: string = "Scan abbrechen (Nach Platz)";
  const [scannedWmsDestination, setScannedWmsDestination] =
    useState<string>("");
  function resetScanWmsDestination() {
    setScannedWmsDestination("");
  }
  function scanWmsDestinationStarted() {
    resetScanWmsDestination();
    setScanningDestination(true);
  }
  function scanWmsDestinationCompleted(scannedValue: string) {
    setScanningDestination(false);
    if (orderTask != null) {
      let destinationBin: string = order!.orderTasks![0].dstBin;
      if (scannedValue != destinationBin) {
        displayNotification(display_ScanDestinationInvalid);
      } else {
        setScannedWmsDestination(scannedValue);
      }
    }
  }
  function scanWmsDestinationCancelled() {
    setScanningDestination(false);
  }

  useEffect(() => {
    if (scannedWmsSource.length > 0) {
      scanWmsDestinationStarted();
    }
  }, [scannedWmsSource]);

  const display_NotOutboundDeliveryTask: string =
    "This order task does not associated with any outbound delivery. Please contact IT as this should not happen for pickings. ";
  const display_confirmMovement: string = "Bestätige Platz";
  function simulateCorrectDestinationBinScanned() {
    let destinationBin: string = orderTask!.dstBin;
    setScannedWmsDestination(destinationBin);
  }

  //change destination HU
  const display_SomethingWentWrongWhenGetMoreDetailOrderTask: string =
    "Something went wrong when getting more detail info for order task. ";
  const display_DefaultHUTypeIsNotConfigured: string =
    "Default HU Type is not configured. ";
  const display_somethingWentWrongWhenCreateHU: string =
    "Something went wrong when create HU. ";
  const display_SomethingWentWrongWhenChangeOrderTaskDestination: string =
    "Something went wrong when change order task destination. ";
  const ModifyStockMovementWithoutDestinationHU: boolean = true;
  function OrderTaskIsStockMovementWithoutDestinationHU(): boolean {
    // if (
    //   orderTask != null &&
    //   orderTask.targetQtyAuom > 0 &&
    //   (orderTask.dstHu == undefined || orderTask.dstHu.length <= 0)
    // ) {
    //   return true;
    // }
    // return false;
    // console.log(
    //   "OrderTaskIsStockMovementWithoutDestinationHU",
    //   OrderTaskIsStockMovementWithoutDestinationHU
    // );
    if (
      orderTask != null &&
      !orderTask.isHuTask &&
      (orderTask.dstHu == undefined || orderTask.dstHu.length <= 0)
    ) {
      return true;
    }
    return false;
  }
  function changeDestinationHUAndConfirmTask() {
    if (debounce) {
      return;
    }
    setDebounce(true);

    if (orderTask == null) {
      displayNotification(display_OrderTaskNotFound);
      setDebounce(false);
      return;
    } else if (orderTask.orderTaskDto == null) {
      displayNotification(display_SomethingWentWrongWhenGetMoreDetailOrderTask);
      setDebounce(false);
      return;
    }

    const binId: string = orderTask.orderTaskDto.dstBinId;

    if (defaultHuType.length <= 0) {
      displayNotification(display_DefaultHUTypeIsNotConfigured);
      setDebounce(false);
      return;
    }

    let finalDestinationHuType: string =
      stockMovementDestinationHUType.length > 0
        ? stockMovementDestinationHUType
        : defaultHuType;

    let createHURequest: huCreateRequestV11Dto = {
      locationId: locationId as string,
      handlingUnitType: finalDestinationHuType,
      parentElement: binId,
      rootElement: binId,
      type: 1,
    };

    huService
      .createHU(createHURequest)
      .then((result) => {
        const newDstHuId: string = result.wmsId;

        //change order task destination
        const changeDestinationRequest: orderTaskChangeDestinationRequestV11Dto =
        {
          orderTaskId: orderTask.orderTaskId,
          dstBinId: binId,
          dstHuId: newDstHuId,
        };
        orderService
          .changeOrderTaskDestination(changeDestinationRequest)
          .then((response) => {
            setDebounce(false);
            //console.log(response);
            if (response.status != undefined && response.status == "Success") {
              confirmTask(newDstHuId);
            } else if (
              response.messages != undefined &&
              response.messages.length > 0
            ) {
              displayNotification(response.messages.join(" "));
            } else {
              displayNotification(display_SomethingWentWrong);
            }
          })
          .catch((err) => {
            setDebounce(false);
            console.log(err);
            displayNotification(display_SomethingWentWrong);
          });
      })
      .catch(() => {
        setDebounce(false);
        displayNotification(display_somethingWentWrongWhenCreateHU);
      });
  }

  //confirm task
  useEffect(() => {
    if (scannedWmsDestination.length > 0) {
      // if (!orderTask?.isOutboundDeliveryTask) {
      //   displayNotification(display_NotOutboundDeliveryTask);
      //   return;
      // }
      if (ModifyStockMovementWithoutDestinationHU) {
        if (OrderTaskIsStockMovementWithoutDestinationHU()) {
          changeDestinationHUAndConfirmTask();
        } else {
          confirmTask(destinationHUId);
        }
      } else {
        confirmTask(destinationHUId);
      }
    }
  }, [scannedWmsDestination]);

  const display_SomethingWentWrong: string = "Ein Fehler ist aufgetreten.";
  const display_SomethingWentWrongWhenPrintShippingLabel: string =
    "Something went wrong when print shipping label";
  const display_OrderTaskNotFound: string = "Order not found";
  const display_SrcHUNotScanned: string = "Source HU not scaneed";
  const display_DstBinNotScanned: string = "Destination Bin not scaneed";
  const [statusText, setStatusText] = useState<string>("");
  const display_TaskCompleting: string = "Completing task";
  const display_TaskCompleted: string = "Kommissionierung abgeschlossen.";

  const [taskCompleted, setTaskCompleted] = useState<boolean>(false);
  function confirmTask(huId: string) {
    //console.log("confirmTask for " + huId);
    if (debounce) {
      return;
    }
    setDebounce(true);

    if (huId.length <= 0) {
      displayNotification("HU ID is empty");
      setDebounce(false);
      return;
    }
    if (scannedWmsSource.length <= 0) {
      displayNotification(display_SrcHUNotScanned);
      setDebounce(false);
      return;
    }
    if (scannedWmsDestination.length <= 0) {
      displayNotification(display_DstBinNotScanned);
      setDebounce(false);
      return;
    }

    if (orderTask == null) {
      displayNotification(display_OrderTaskNotFound);
      setDebounce(false);
      return;
    }

    setStatusText(display_TaskCompleting);

    let request: confirmTaskRequestV2Dto = {
      id: orderTask.orderTaskId,
    };

    orderService
      .confirmOrderTask(request)
      .then((result) => {
        setDebounce(false);
        //console.log(result);
        if (result == undefined) {
          displayNotification(display_SomethingWentWrong);
        } else {
          if (result.status != undefined && result.status == "Success") {
            if (orderTask.isHuTask) {
              console.log(
                "Confirmed Task is HU movement. No printing from WebApp needed. "
              );
            } else {
              console.log(
                "Confirmed Task is stock movement. Printing HU label from WebApp. "
              );
              printHuLabel(
                orderTask!.orderTaskDto!.srcHuId!,
                printServerId,
                printerId
              );
            }

            setTaskCompleted(true);
          } else {
            let message: string = display_SomethingWentWrong;
            if (result.messages != undefined && result.messages.length > 0) {
              message = result.messages[0];
            }
            displayNotification(message);
            setStatusText(message);
          }
        }
      })
      .catch((error) => {
        setDebounce(false);
        console.log(error);
        if (error.errorMessage && error.errorMessage.length > 0) {
          let display: string = error.errorMessage;
          if (error.message && error.message.length > 0) {
            display += " " + error.message;
          }
          displayNotification(display);
        } else {
          displayNotification(display_SomethingWentWrong);
        }
        setStatusText(display_SomethingWentWrong);
      });
  }

  const printHULabelForConfirmedStockMovement: boolean = true;
  function printHuLabel(
    huId: string,
    printServerId: string,
    printerId: string
  ) {
    console.log(
      "Print HU label, " +
      "huId: " +
      huId +
      ", printServerId: " +
      printServerId +
      ", printerId: " +
      printerId
    );

    if (printServerId.length <= 0 || printerId.length <= 0) {
      displayNotification(display_printerNotConfigured);
      return;
    }

    if (huId == undefined || huId.length <= 0) {
      displayNotification("Source HU Id is empty");
      return;
    }

    printService
      .printHU({
        handlingUnitId: huId,
        printServerId: printServerId,
        printerId: printerId,
      })
      .then((result) => {
        let msg: string = "";
        msg = JSON.stringify(result);
        console.log("Print request sent. " + result);
      })
      .catch((result) => {
        let errorMsg: string = "";
        if (result.errorMessage != undefined) {
          errorMsg = result.errorMessage;
        } else {
          errorMsg = JSON.stringify(result);
        }
        console.log("Failed to print. " + errorMsg);
      });
  }

  function printShippingLabel(
    HuId: string,
    printerServerId: string,
    printerId: string
  ) {
    if (printerServerId.length <= 0 || printerId.length <= 0) {
      displayNotification(display_printerNotConfigured);
      return;
    }

    printService
      .printShippingLabel({
        handlingUnitId: HuId,
        printServerId: printerServerId,
        printerId: printerId,
      })
      .then((result) => {
        console.log("Print shipping label " + HuId + " success. ");
      })
      .catch((result) => {
        let errorMsg: string = "";
        if (result.errorMessage != undefined) {
          errorMsg = result.errorMessage;
        } else {
          errorMsg = JSON.stringify(result);
        }
        let displayMessage: string =
          "Print shipping label " + HuId + " failed. " + errorMsg;
        console.log(displayMessage);
        displayNotification(display_SomethingWentWrongWhenPrintShippingLabel);
      });
  }

  useEffect(() => {
    if (taskCompleted == true) {
      setStatusText(display_TaskCompleted);
      displayNotification(display_TaskCompleted);
      Reset();
    }
  }, [taskCompleted]);

  function Reset() {
    setSelectedQueueValue("");
    setOrder(null);
    setOrderTask(null);
    setOutboundDeliveryItemInfo(null);
    findHandlingUnitId("");
    setScannedWmsSource("");
    setScannedWmsDestination("");
  }

  const display_ChangeSourceHUInstruction: string =
    "Nutzen Sie dieses Feld, um den LT zu ändern.";
  const display_ChangeSourceHU: string = "Change source HU";
  const display_NoOrderTaskFound: string = "No Order Task Found";
  const display_NewSrcHU: string = "Neuer LT";
  const display_SrcHU: string = "Source HU";
  const display_ChangeSourceSucceed: string = "Change Source Success";
  const display_noOptionMsg: string = "keine Optionen vorhanden";
  const display_confirm: string = "Bestätigen";
  const display_cancel: string = "Abbrechen";
  const display_PleaseSelectNewSrcHU: string = "Please select new source HU";
  const [showChangeSourceDialog, setShowChangeSourceDialog] = useState(false);
  const handleAttempToChangeSourceClick = useCallback(async () => {
    if (orderTask == null) {
      displayNotification(display_NoOrderTaskFound);
      return;
    }
    setShowChangeSourceDialog(true);
  }, [orderTask]);
  const handleChangeSourceClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason && reason == "backdropClick") return;
    setShowChangeSourceDialog(false);
  };
  const [newHUOptions, setNewHUOptions] = useState<Option[]>([]);
  const [selectedNewHUOptions, setSelectedNewHUOptions] =
    useState<Option | null>(null);
  const [newHUId, setNewHUId] = useState<string | null>(null);
  const [searchNewHUQuery, setSearchNewHUQuery] = useState<Search>({
    search: "",
  });
  const handleNewHUChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      setNewHUId(option.value);
      //setHUType(option.label);
      setSelectedNewHUOptions(option);
    } else {
      setNewHUId(null);
      //setHUType("");
      setSelectedNewHUOptions(null);
    }
  };
  const handleNewHUInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchNewHUQuery({ search: event.target.value });
  };
  function convertNewHUsearchResultToSelectOptions(
    data: huSearchResponseV11Dto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.wmsId,
      label: item.handlingUnit,
    }));
  }

  useEffect(() => {
    if (searchNewHUQuery.search.length >= 3) {
      let binId: string = "";
      binId = orderTask!.orderTaskDto?.srcBinId!;
      let searchDto: huSearchRequestV11Dto;
      searchDto = {
        locationId: locationId!,
        handlingUnitNumberContains: searchNewHUQuery.search,
        rootElementId: binId,
        allowMoreThanOneParameter: true,
      };

      huService
        .searchHandlingUnitV11(searchDto)
        .then((result) => {
          console.log(result);
          setNewHUOptions(convertNewHUsearchResultToSelectOptions(result));
        })
        .catch(() => console.log(display_SomethingWentWrong));
    }
  }, [searchNewHUQuery.search]);

  const handleChangeSource = () => {
    if (debounce) {
      return;
    }
    setDebounce(true);

    if (orderTask == null) {
      displayNotification(display_NoOrderTaskFound);
      setDebounce(false);
      return;
    }

    if (newHUId == null) {
      displayNotification(display_PleaseSelectNewSrcHU);
      setDebounce(false);
      return;
    }

    const changeSourceHURequest: orderTaskChangeSourceRequestV11Dto = {
      orderTaskId: orderTask.orderTaskId,
      srcHuId: newHUId,
    };

    orderService
      .changeOrderTaskSourceHu(changeSourceHURequest)
      .then((response) => {
        console.log(response);
        if (response.status == undefined && response.messages == undefined) {
          displayNotification(display_ChangeSourceSucceed);
          setShowChangeSourceDialog(false);
          setDebounce(false);
          reloadData();
        } else if (
          response.messages != undefined &&
          response.messages.length > 0
        ) {
          displayNotification(response.messages.join(" "));
          setDebounce(false);
        } else {
          displayNotification(display_SomethingWentWrong);
          setDebounce(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setDebounce(false);
        if (err.errorMessages && err.errorMessages.length > 0) {
          let displayError: string = err.errorMessages.join("<br/>");
          displayNotification(displayError);
        } else {
          displayNotification(display_SomethingWentWrong);
        }
      });
  };

  function getStockMovementChangeHuTypeInstruction(): string {
    // const defaultHuType: string =
    //   process.env.REACT_APP_PIKCING_DEFAULT_HU_TYPE!;
    const result: string =
      "Soll der LT-Typ " +
      defaultHuType +
      " für den neuen LT verwendet werden? Wenn ein anderer LT-Typ verwendet werden soll, wählen Sie diesen hier aus.";
    return result;
  }

  const display_somethingWentWrongSearchHUType: string =
    "Something went wrong. Could not find HU Type. ";
  const [stockMovementDestinationHUType, setStockMovementDestinationHUType] =
    useState("");
  const [huTypeOptions, setHuTypeOptions] = useState<Option[]>([]);
  const [selectedHUTypeOptions, setSelectedHUTypeOptions] =
    useState<Option | null>(null);
  const display_HUType: string = "LT Typ";
  const handleHuTypeChange = (
    event: React.ChangeEvent<{}>,
    option: Option | null
  ) => {
    if (option) {
      setStockMovementDestinationHUType(option.label);
      setSelectedHUTypeOptions(option);
    } else {
      setStockMovementDestinationHUType("");
      setSelectedHUTypeOptions(null);
    }
  };
  const [searchHuTypeQuery, setSearchHuTypeQuery] = useState<Search>({
    search: "",
  });
  const handleSearchHuTypeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchHuTypeQuery({ search: event.target.value });
  };
  useEffect(() => {
    if (searchHuTypeQuery.search.length >= 3) {
      let searchTexts: string[];
      searchTexts = [];
      searchTexts.push(searchHuTypeQuery.search);

      let searchDto: huTypeSearchRequestV11Dto;
      searchDto = {
        page: 0,
        pageSize: 20,
        nameContains: [],
      };
      searchDto.nameContains = searchTexts;

      huService
        .getHandlingUnitType(searchDto)
        .then((result) => {
          setHuTypeOptions(convertHuTypeResultToSelectOptions(result));
        })
        .catch(() => console.log(display_somethingWentWrongSearchHUType));
    }
  }, [searchHuTypeQuery.search]);
  function convertHuTypeResultToSelectOptions(
    data: huTypeSearchResponseV11Dto[]
  ): Option[] {
    return data.map((item) => ({
      value: item.handlingUnitTypeId,
      label: item.name,
    }));
  }
  function resetHuType() {
    setStockMovementDestinationHUType("");
    setSelectedHUTypeOptions(null);
  }

  return (
    <div>
      {showPrinterNotConfiguredWarning &&
        (printServerId.length == 0 || printerId.length == 0) ? (
        <div>
          <AlertBox
            alertType={AlertBoxType.info}
            contentText={display_printerNotConfigured}
          />
        </div>
      ) : (
        <div></div>
      )}

      <NotificationDialog
        title="Info"
        contentText={notificationText}
        onClose={() => {
          setShowNotification(false);
        }}
        visible={showNotification}
        onConfirm={() => {
          setShowNotification(false);
        }}
      />

      <Dialog
        onClose={handleChangeSourceClose}
        aria-labelledby="simple-dialog-title"
        open={showChangeSourceDialog}
        hideBackdrop={false}
        PaperProps={{
          style: {
            minWidth: "50%",
          },
        }}
      >
        <DialogTitle>{display_NewSrcHU}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>{contentText}</DialogContentText> */}
          <Box sx={{ mt: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  noOptionsText={display_noOptionMsg}
                  value={selectedNewHUOptions}
                  onChange={handleNewHUChange}
                  options={newHUOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={display_SrcHU}
                      variant="outlined"
                      onChange={handleNewHUInputChange}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowChangeSourceDialog(false);
            }}
          >
            {display_cancel}
          </Button>
          <Button onClick={handleChangeSource}>{display_confirm}</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {queueOptions.length <= 0 ? (
            <div>
              {" "}
              <AlertBox
                alertType={AlertBoxType.warning}
                contentText={display_NoQueue}
              />
            </div>
          ) : (
            <div>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    {display_queue}
                  </InputLabel>
                  <NativeSelect
                    value={selectedQueueValue}
                    // onChange={(event) =>
                    //   setSelectedQueueValue(event.target.value)
                    // }
                    onChange={handleSelectQueueChange}
                  >
                    {queueOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <div hidden={selectedQueueValue.length <= 0}>
            {order == null || orderTask == null ? (
              <div>{getNoOrderAssignedToQueueMessage()}</div>
            ) : (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <div style={{ padding: padding }}>
                      <TextField
                        label={display_OrderNumber}
                        variant="outlined"
                        value={order.orderNumber}
                        disabled={true}
                        fullWidth={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ padding: padding }}>
                      <TextField
                        label={display_Priority}
                        variant="outlined"
                        value={order.priority}
                        disabled={true}
                        fullWidth={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ padding: padding }}>
                      <TextField
                        label={display_MaterialNumber}
                        variant="outlined"
                        value={
                          orderTask!.orderTaskDto &&
                            orderTask!.orderTaskDto.wmsMaterialDto
                            ? orderTask!.orderTaskDto.wmsMaterialDto.material
                            : ""
                        }
                        disabled={true}
                        fullWidth={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div style={{ padding: padding }}>
                      <TextField
                        label={display_Batch}
                        variant="outlined"
                        value={
                          outboundDeliveryItemInfo != undefined &&
                            outboundDeliveryItemInfo.batch
                            ? outboundDeliveryItemInfo.batch
                            : ""
                        }
                        disabled={true}
                        fullWidth={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div style={{ padding: padding }}>
                      <TextField
                        label={display_Owner}
                        variant="outlined"
                        value={
                          outboundDeliveryItemInfo != undefined &&
                            outboundDeliveryItemInfo.owner
                            ? outboundDeliveryItemInfo.owner
                            : ""
                        }
                        disabled={true}
                        fullWidth={true}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div style={{ padding: padding }}>
                    <TextField
                      label={display_OrderTaskQty}
                      variant="outlined"
                      value={orderTask!.targetQtyAuom}
                      disabled={true}
                      fullWidth={true}
                    />
                  </div>
                  <div style={{ padding: padding }}>
                    <TextField
                      label={display_SourceHu}
                      variant="outlined"
                      value={orderTask!.srcHu}
                      disabled={true}
                      fullWidth={true}
                      InputProps={{
                        style: { fontWeight: "900", fontSize: "large" },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div
                    hidden={
                      orderTask != undefined && scannedWmsSource.length > 0
                    }
                  >
                    <div style={{ padding: padding }}>
                      <TextField
                        label={display_SourceBin}
                        variant="outlined"
                        value={orderTask!.srcBin}
                        disabled={true}
                        fullWidth={true}
                        InputProps={{
                          style: { fontWeight: "900", fontSize: "large" },
                        }}
                      />
                    </div>
                    <div style={{ padding: padding }}>
                      <Scanner
                        startScan={scanningSource}
                        beforeScanText={display_ScanSource}
                        duringScanText={display_ScanningSource}
                        scanCompleted={scanWmsSourceCompleted}
                        scanStarted={scanWmsSourceStarted}
                        scanCancelled={scanWmsSourceCancelled}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <div
                      style={{ padding: padding }}
                      hidden={
                        order != undefined && scannedWmsSource.length <= 0
                      }
                    >
                      <TextField
                        label={display_DestinationBin}
                        variant="outlined"
                        value={orderTask!.dstBin}
                        disabled={true}
                        fullWidth={true}
                        InputProps={{
                          style: { fontWeight: "900", fontSize: "large" },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div
                      style={{ padding: padding }}
                      hidden={
                        order != undefined && scannedWmsSource.length <= 0
                      }
                    >
                      <TextField
                        label={display_SupplierBatch}
                        variant="outlined"
                        value={supplierBatch}
                        disabled={true}
                        fullWidth={true}
                        InputProps={{
                          style: { fontWeight: "900", fontSize: "large" },
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div
                    hidden={order != undefined && scannedWmsSource.length <= 0}
                  >
                    <div style={{ padding: padding }}>
                      <div style={{ padding: padding }}>
                        {OrderTaskIsStockMovementWithoutDestinationHU() ? (
                          <Grid item xs={12} sm={12}>
                            <div
                              style={{
                                border: "1px solid grey",
                                borderRadius: "5px",
                                padding: "5px",
                              }}
                            >
                              <Grid item xs={12} sm={12}>
                                <AlertBox
                                  alertType={AlertBoxType.info}
                                  contentText={getStockMovementChangeHuTypeInstruction()}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Autocomplete
                                  noOptionsText={display_noOptionMsg}
                                  value={selectedHUTypeOptions}
                                  onChange={handleHuTypeChange}
                                  options={huTypeOptions}
                                  getOptionLabel={(option) => option.label}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={display_HUType}
                                      variant="outlined"
                                      onChange={handleSearchHuTypeInputChange}
                                    />
                                  )}
                                />
                              </Grid>
                            </div>
                            <br />
                          </Grid>
                        ) : (
                          <div></div>
                        )}

                        <Scanner
                          startScan={scanningDestination}
                          beforeScanText={display_ScanDestination}
                          duringScanText={display_ScanningDestination}
                          scanCompleted={scanWmsDestinationCompleted}
                          scanStarted={scanWmsDestinationStarted}
                          scanCancelled={scanWmsDestinationCancelled}
                        />
                      </div>
                      <div style={{ padding: padding }}>
                        <Button
                          disabled={debounce}
                          onClick={() => {
                            simulateCorrectDestinationBinScanned();
                          }}
                          variant="contained"
                          fullWidth={true}
                        >
                          <div>
                            <br />
                            <br />
                          </div>
                          {display_confirmMovement}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>

                {orderTask != null ? (
                  <Grid item xs={12} sm={12}>
                    <br />
                    <br />
                    <br />
                    <div
                      style={{
                        border: "1px solid grey",
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                    >
                      <Grid item xs={12} sm={12}>
                        <AlertBox
                          alertType={AlertBoxType.info}
                          contentText={display_ChangeSourceHUInstruction}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button
                          variant="contained"
                          fullWidth={true}
                          startIcon={<WarningIcon />}
                          disabled={debounce}
                          onClick={async () => {
                            await handleAttempToChangeSourceClick();
                          }}
                        >
                          <div>
                            <br />
                          </div>
                          {display_ChangeSourceHU}
                        </Button>
                      </Grid>
                    </div>
                  </Grid>
                ) : (
                  <div></div>
                )}

                <Grid item xs={12} sm={12}>
                  <div
                    hidden={
                      order != undefined && scannedWmsDestination.length <= 0
                    }
                  >
                    {statusText}
                  </div>
                </Grid>
              </div>
            )}
          </div>
        </Grid>

        {/* {OrderTaskIsStockMovementWithoutDestinationHU()
          ? "is hu task"
          : "is stock task"} */}
        {/* <div>
          <Button
            variant="contained"
            fullWidth={true}
            onClick={() => {
              printShippingLabel(
                "11d44dec-856f-45ca-bb9d-83a8979e8ded",
                "11d44dec-856f-45ca-bb9d-83a8979e8ded",
                "99DFBBC5-B68E-48E7-BC27-2D7AEE533FE8"
              );
            }}
          >
            test print
          </Button>
        </div> */}
      </Grid>
    </div>
  );
};

export default React.memo(PickingComponent);
